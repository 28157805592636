import { Component, Input, OnInit } from '@angular/core';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { EYIModel } from '../../../Model/EYIModel';

@Component({
  selector: 'app-view-case-eyiupload',
  templateUrl: './view-case-eyiupload.component.html',
  styleUrls: ['./view-case-eyiupload.component.scss']
})
export class ViewCaseEYIUploadComponent implements OnInit {
  public pageSize = 10;
  public skip = 0;
  public caseList: any;
  public gridViewData: any[];
  @Input() documentInfo: EYIModel;
  @Input() packagesStatus: any;
  public gridState: DataStateChangeEvent = {

    skip: this.skip,
    take: this.pageSize
  };
  constructor() { }

  ngOnInit(): void {
    
  }

}
