import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { NgModelGroup } from '@angular/forms';
@Injectable({
  providedIn: 'root'
})
export class DetailedViewService {
 // Initialize variables
 public headers = new HttpHeaders ({
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json'
});
public options = {
  headers: this.headers
};
  constructor(private _http: HttpClient) { }
  getAIResult(insZoomId: string) {
    return this._http.get<any>(environment.ApiUrl + 'Dashboard/GetAIResult?insZoomId=' + insZoomId, {});
  }
  getStatusMaster() {
    return this._http.get<any>(environment.ApiUrl + 'Dashboard/getStatusMaster', {});
  }
  updateCase( model: any, insZoomId: any, statusId: any,time:number) {
    return this._http.post<any>(environment.ApiUrl + 'Dashboard/UpdateCase?insZoomId=' + insZoomId + '&statusId=' + statusId +'&time=' +time,
    model , this.options);
  }
  GetChecklistWithID(id: string) {
    return this._http.get<any>(environment.ApiUrl + 'Dashboard/GetChecklistWithID?id=' + id, {});
  }
  UpdateCompilationRemarks(model: any, id: any, statusId: any, time: number) {
    return this._http.post<any>(environment.ApiUrl + 'Dashboard/UpdateCompilationRemarks?id=' + id + '&statusId=' + statusId + '&time=' + time,
      model, this.options);
  }
}
