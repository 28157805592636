import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { h1BFilingType, jobPositionDetails } from 'src/app/DAF/Models/JobPositionDetails';
import { DafService } from 'src/app/DAF/services/dafService';

@Component({
  selector: 'app-job-position-assessment',
  templateUrl: './job-position-assessment.component.html',
  styleUrls: ['./job-position-assessment.component.scss']
})
export class JobPositionAssessmentComponent implements OnInit {

  jobPositionForm: FormGroup;
  radioButtonOptionValues: Array<any> = [{ value: true, text: "Yes" }, { value: false, text: "No" }];
  radiobasisValues: Array<any> = [{ value: 1, text: "New" }, { value: 2, text: "Continuation" }, { value: 3, text: "Change" }, { value: 4, text: "Concurrent" }, { value: 5, text: "COE" }, { value: 6, text: "Amendment" }];
  caseId: number;
  jobPositionDetails: jobPositionDetails;
  public isPageReady = true;
  isEditEnabled: boolean;
  basisOfClassification: any = [];
  requestedAction: any = [];
  h1b1Types: any = [];
  classifications: any = [];
  @Input() caseSummaryId = 0;
  private notifier: NotifierService;
  private salaryFrom: number;
  private salaryTo: number;
  public isActivate=false;
  public isActivateAddress=false;
  public isActivateLca=false;
  i129FromDate: any;
  i129ToDate: any;

  constructor(private formBuilder: FormBuilder,
    private _dafService: DafService, notifier: NotifierService) { this.notifier = notifier; }

    optionalFormControls = ["basisOfClassification","requestedAction","h1BFilingType","signatoryTelephone"];
    formErrors = {
      'basisOfClassification': '',
      'requestedAction': '',
      'h1BFilingType': '',
      'reasonForExcemption': '',
      'caseType': '',
      'jobTitle': '',
      'jobDetails': '',
      'jobRequirements': '',
      'i129FromDate': '',
      'i129ToDate': '',
      'actualSalary': '',
      'isInSalaryRange': '',
      'salaryRangeFrom': '',
      'salaryRangeTo': '',
      'Isbasis':''
    };
    
    validationMessages = {
      'jobTitle': {
        'required': 'Job Title is required.',
      },
      'caseType': { 
        'required': 'Case Type is required.', 
      },
      'salaryRangeFrom': { 
        'required': 'Salary From Range is required.', 
      },
      'salaryRangeTo': { 
        'required': 'Salary To Range is required.', 
      }
    };

  ngOnInit() {
    this.getJobPositionDetails();
  }

  addDynamicControls(): void {
    this.basisOfClassification.forEach(key => {
      console.log(key);
      (this.jobPositionForm.get('basisOfClassification') as FormGroup).addControl(key.shortForm, new FormControl({ value: key.isSelected, disabled: true }))
    })

    this.requestedAction.forEach(key => {
      console.log(key);
      (this.jobPositionForm.get('requestedAction') as FormGroup).addControl(key.requestedActionType, new FormControl({ value: key.isSelected, disabled: true }))
    })

    this.h1b1Types.forEach(key => {
      console.log(key);
      (this.jobPositionForm.get('h1BFilingType') as FormGroup).addControl(key.h1BFilingType, new FormControl({ value: key.isSelected, disabled: true }))
    })

  }
  OnbasicClassificationchekced(classtype) {
    
    this.classifications = ["Continuation", "Change", "Concurrent", "COE","Amendment"];
    var arraycontains = (this.classifications.indexOf(classtype) > -1);
    if (arraycontains) {

      for (let i = 0; i < this.h1b1Types.length; i++) {

        this.formControls['h1BFilingType'].get('CAP Exempt').setValue(true);
      }
    }
    else {
      this.formControls['h1BFilingType'].get('CAP Exempt').setValue(false);
    }


  }
  getJobPositionDetails() {
    this.isPageReady = false;
    this._dafService.getJobPositionDetailsByCaseSummaryId(this.caseSummaryId).toPromise().then((data: any) => {
      //alert(data.jobPositionDetails.Isbasis);
      this.jobPositionDetails = data;
      //this.jobPositionDetails.Isbasis = data.jobPositionDetails.Isbasis;
      this.basisOfClassification = this.jobPositionDetails.basisOfClassification;
      this.h1b1Types = this.jobPositionDetails.h1BFilingType;
      this.requestedAction = this.jobPositionDetails.requestedAction;
      console.log(this.jobPositionDetails);
      console.log(this.basisOfClassification);
      console.log(data);
      this.initializeForm();
      this.addDynamicControls();
    },
      (error) => {
      }
    );
  }

  public cancelHandler(event): void {
    if(event=="Address"){
      this.isActivate=false;


    }
    else if(event=="Lca"){
      this.isActivateAddress=false;

    }
    else{
      this.isActivateAddress=false;
      this.isActivate=false;  
      this.isActivateLca=false;
    }
  }

    openWizard(value){
      if(value==1){
      this.isActivate=true;
      this.isActivateAddress=false;
      this.isActivateLca=false;
      }
      else if (value==2){
        this.isActivate=false;
        this.isActivateAddress=true;
        this.isActivateLca=false;
      }
      else if (value==3){
        this.isActivate=false;
        this.isActivateAddress=false;
        this.isActivateLca=true;
      }
    }


  initializeForm() {
    let decodedNotes = this.decodeHTMLEntities(this.jobPositionDetails.jobPositionNotes);
    let decodedJobDetails = this.decodeHTMLEntities(this.jobPositionDetails.jobDetails);
    let decodedJobReq = this.decodeHTMLEntities(this.jobPositionDetails.jobRequirements);
    let decodedJobTitle = this.decodeHTMLEntities(this.jobPositionDetails.jobTitle);
    try {
      this.jobPositionForm = this.formBuilder.group({
        basisOfClassification: this.formBuilder.group({}),
        requestedAction: this.formBuilder.group({}),
        h1BFilingType: this.formBuilder.group({}),
        reasonForExcemption: this.formBuilder.group({}),
        caseType: new FormControl({ value: this.jobPositionDetails.caseType, disabled: true }, Validators.required),
        jobTitle: new FormControl({ value: decodedJobTitle, disabled: true }, Validators.required),
        jobDetails: new FormControl({ value: decodedJobDetails, disabled: true }),
        jobRequirements: new FormControl({ value: decodedJobReq, disabled: true }),
        i129FromDate: new FormControl({ value: this.jobPositionDetails.i129FromDate ? new Date(this.jobPositionDetails.i129FromDate) :null, disabled: true }),
        i129ToDate: new FormControl({ value: this.jobPositionDetails.i129ToDate == null ? null : new Date(this.jobPositionDetails.i129ToDate), disabled: true }),
        actualSalary: new FormControl({ value: this.jobPositionDetails.actualSalary, disabled: true }),
        isInSalaryRange: new FormControl({ value: this.jobPositionDetails.isInSalaryRange, disabled: true }),
        isbasis: new FormControl({ value: this.jobPositionDetails.isbasis, disabled: true }),
        salaryRangeFrom: new FormControl({ value: this.jobPositionDetails.salaryRangeFrom, disabled: true }),
        salaryRangeTo: new FormControl({ value: this.jobPositionDetails.salaryRangeTo, disabled: true }),
        jobPositionNotes: new FormControl({ value: decodedNotes, disabled: true }),

      });
      this.isPageReady = true;
      this.subscribeToFormControlValueChange();
      this.jobPositionForm.valueChanges.subscribe((val=>{
        this.logOnChangeValidationErrors();
      }));
    }
    catch (err) {
      console.log(err);
    }
  }

  enableEdit() {
    try {
      this.isEditEnabled = true;
      this._dafService.disableEnableAllFormControls(this.jobPositionForm, ["salaryRangeFrom", "salaryRangeTo"], false);

    }
    catch (err) {
      console.log(err);
    }
  }

  resetForm() {
    try {
      this.getJobPositionDetails();
      this.isEditEnabled = false;
      this._dafService.disableEnableAllFormControls(this.jobPositionForm, [], true);
    }
    catch (err) {
      console.log(err);
    }
  }

  resetFormData() {
    this.isPageReady = false;
    this.getJobPositionDetails();
    this.initializeForm();
  }

  public saveCaseSummaryData() {
    this.logValidationErrors();
    this.readFormValues(this.jobPositionForm);
    if (this.jobPositionForm.valid) {
      //alert(this.jobPositionForm.controls["Isbasis"].value);
      this.jobPositionDetails.isbasis = this.jobPositionForm.controls["isbasis"].value;
      this.jobPositionDetails.jobPositionNotes = this.jobPositionDetails.jobPositionNotes ? this.encodeHTMLEntities(this.jobPositionDetails.jobPositionNotes) : null;
      this.jobPositionDetails.jobDetails = this.jobPositionDetails.jobDetails ? this.encodeHTMLEntities(this.jobPositionDetails.jobDetails) : null;
      this.jobPositionDetails.jobRequirements = this.jobPositionDetails.jobRequirements ? this.encodeHTMLEntities(this.jobPositionDetails.jobRequirements) : null;
      this.jobPositionDetails.jobTitle = this.jobPositionDetails.jobTitle ? this.encodeHTMLEntities(this.jobPositionDetails.jobTitle) : null;

      if (this.jobPositionDetails.i129FromDate == null) {
        this.i129FromDate = null;
      }
      else {
        this.i129FromDate = this.jobPositionDetails.i129FromDate.toDateString();
      }

      if (this.jobPositionDetails.i129ToDate == null) {
        this.i129ToDate = null;
      }
      else {
        this.i129ToDate = this.jobPositionDetails.i129ToDate.toDateString();
      }
      if (this.validateJobPositionData(this.jobPositionDetails) === true) {
        this.isPageReady = false;
        this._dafService.saveJobPositionDetails(this.caseSummaryId, this.i129FromDate, this.i129ToDate, this.jobPositionDetails).toPromise().then(() => {
          this._dafService.disableEnableAllFormControls(this.jobPositionForm, [], true);
          this.getJobPositionDetails();
          this.isEditEnabled = false;
          // this.isUnlocked = false;
          this.isPageReady = true;
          this.notifier.notify('success', 'Job position details have been saved successfully');
        }).catch((err) => {
          this.notifier.notify('error', 'Operation Failed');
          this.isPageReady = true;
        });
      }
    }
  }

  private readFormValues(group: FormGroup) {
    Object.keys(this.jobPositionForm.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.getDynamicControlValue(abstractControl, key);
      }
      else {
        this.jobPositionDetails[key] = abstractControl.value;
      }
    });
  }

  private getDynamicControlValue(formGroup: FormGroup, childFormGroup: string) {
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      console.log(abstractControl);     
      if(childFormGroup === 'basisOfClassification'){
        this.jobPositionDetails.basisOfClassification.filter(p=>p.shortForm == key)[0].isSelected = abstractControl.value;
      }
      else if(childFormGroup === 'requestedAction'){
        this.jobPositionDetails.requestedAction.filter(p=>p.requestedActionType == key)[0].isSelected = abstractControl.value;
      }
      else if(childFormGroup === 'h1BFilingType'){
        this.jobPositionDetails.h1BFilingType.filter(p=>p.h1BFilingType == key)[0].isSelected = abstractControl.value;
      }
    });

  }

  private validateJobPositionData(jobPositionDetails: jobPositionDetails) {
    let isValidationSuccess = true;
    if (jobPositionDetails.i129ToDate < jobPositionDetails.i129FromDate) {
      this.notifier.notify('error', 'I-129 from date cannot be less than to date');
      isValidationSuccess = false;
    }
    else if (jobPositionDetails.isInSalaryRange === true) {
      if (jobPositionDetails.salaryRangeTo < jobPositionDetails.salaryRangeFrom) {
        this.notifier.notify('error', 'Salary range to cannot be less than salary range from');
        isValidationSuccess = false;
      }
      else if ((jobPositionDetails.actualSalary > jobPositionDetails.salaryRangeTo) ||
        (jobPositionDetails.actualSalary < jobPositionDetails.salaryRangeFrom)) {
        this.notifier.notify('error', 'Actual salary should be in between salary from range and salary to range');
        isValidationSuccess = false;
      }

    }

    return isValidationSuccess;
  }

  get formControls() {
    return this.jobPositionForm.controls;
  }

  private subscribeToFormControlValueChange() {
    this.jobPositionForm.get("isInSalaryRange").valueChanges.subscribe(isSalaryRange => {
      if (isSalaryRange) {
        this._dafService.disableEnableFormControl(this.jobPositionForm, ["salaryRangeFrom", "salaryRangeTo"], false);
        if (this.salaryFrom > 0) {
          this.formControls['salaryRangeFrom'].setValue(this.salaryFrom);
        }
        if (this.salaryTo > 0) {
          this.formControls['salaryRangeTo'].setValue(this.salaryTo);
        }
        this.formControls['salaryRangeFrom'].setValidators([Validators.required, Validators.nullValidator]);
        this.formControls['salaryRangeTo'].setValidators([Validators.required, Validators.nullValidator]);
        this.jobPositionForm.updateValueAndValidity();
      } else {

        this._dafService.disableEnableFormControl(this.jobPositionForm, ["salaryRangeFrom", "salaryRangeTo"], true);
        this.salaryFrom = this.formControls['salaryRangeFrom'].value;
        this.salaryTo = this.formControls['salaryRangeTo'].value;
        this.formControls['salaryRangeFrom'].setValue(null);
        this.formControls['salaryRangeTo'].setValue(null);
        this.formControls["salaryRangeFrom"].clearValidators();
        this.formControls["salaryRangeTo"].clearValidators();
        this.jobPositionForm.updateValueAndValidity();
      }
    });
  }

  logValidationErrors(group: FormGroup = this.jobPositionForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  logOnChangeValidationErrors(group: FormGroup = this.jobPositionForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid
             && (abstractControl.touched || abstractControl.dirty)
            ) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  encodeHTMLEntities(text) {
    let textArea = document.createElement('textarea');
    let escapeQuotes = text;
    if (text) {
      escapeQuotes = text.replace(/['"()]/g, escape);
    }

    textArea.innerText = escapeQuotes;
    let encodedOutput = textArea.innerHTML;
    let arr = encodedOutput.split('<br>');
    encodedOutput = arr.join('\n');
    return encodedOutput;
  }

  decodeHTMLEntities(text) {
    let textArea = document.createElement('textarea');
    let unescapeQuotes = text;
    if (text) {
      unescapeQuotes = text.replace("%27", unescape);
      unescapeQuotes = unescapeQuotes.replace("%22", unescape);
      unescapeQuotes = unescapeQuotes.replaceAll("%28", unescape);
      unescapeQuotes = unescapeQuotes.replaceAll("%29", unescape);
    }
    textArea.innerHTML = unescapeQuotes;
    return textArea.value;
  }
}
