import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './components/button/button.component';
import { NumberInputComponent } from './components/number-input/number-input.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule, FloatingLabelModule } from "@progress/kendo-angular-label";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePickerModule, DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { AutoCompleteComponent } from './components/auto-complete/auto-complete.component';
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";


@NgModule({
  declarations: [ButtonComponent,NumberInputComponent, 
    AutoCompleteComponent],
  imports: [
    CommonModule, InputsModule, FloatingLabelModule, LabelModule, 
    FormsModule, ReactiveFormsModule,
    DatePickerModule, DateInputsModule, DropDownsModule
  ],
  exports: [
    ButtonComponent,
    NumberInputComponent,
    FloatingLabelModule,LabelModule,
    FormsModule, ReactiveFormsModule,
    DatePickerModule, DateInputsModule,DropDownsModule,
    AutoCompleteComponent
  ]
})
export class SharedModule { }
