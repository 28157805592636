import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { NotificationService } from '@progress/kendo-angular-notification';
import { NotifierService } from 'angular-notifier';
import { jobDetailsWizardInfo } from 'src/app/DAF/Models/jobDetailsWizardInfo';
import { jobTitleWizards } from 'src/app/DAF/Models/jobTitleWizards';
import { DafService } from 'src/app/DAF/services/dafService';
import { ActionsLayout } from "@progress/kendo-angular-dialog";


@Component({
  selector: 'app-job-details-wizard',
  templateUrl: './job-details-wizard.component.html',
  styleUrls: ['./job-details-wizard.component.scss']
})
export class JobDetailsWizardComponent implements OnChanges {
  public emailForm: FormGroup;

  public isReady=false;
  public WorkSite="Job Details Wizard";
  private hideAfter = 2000;
  public WorkSiteDetails: any = [];
  radioButtonOptionValues: Array<any> = [{ value: true, text: "Yes" }, { value: false, text: "No" }];
  public dateDifference=0;
  private notifier: NotifierService;
  public primaryworksiteInfo:jobTitleWizards[]=[];
  public view: Array<jobTitleWizards> = [];
  public pageSize = 5;
    public actionsLayout: ActionsLayout = "normal";
  public skip = 0;
  jobDetails: jobDetailsWizardInfo = new jobDetailsWizardInfo();
  public gridView: GridDataResult;
  public active: boolean = false;
  @Input() caseSummaryId = 0;

  @Output() cancel = new EventEmitter<string>();




  constructor(private formBuilder: FormBuilder,@Inject(NotificationService) private notificationService: NotificationService, private _dafService: DafService,notifier: NotifierService) 
  {   
    this.notifier = notifier; 
  }


  ngOnChanges(): void {
    this.getJobTitleInformation();
    this.getjobTitleInfos();
  }

  private loadItems(): void {
    this.gridView = {
      data: this.view.slice(this.skip, this.skip + this.pageSize),
      total: this.view.length,
    };
  }


  getJobTitleInformation(){
    this.isReady = false;
    this._dafService.getJobTitleInformation(this.caseSummaryId).toPromise().then((data: any) => {
      this.primaryworksiteInfo = data;
      this.jobDetails = new jobDetailsWizardInfo(data);
      this.initializeForm();
    },
      (error) => {
        console.log(error)
      }
    );
  }

  getjobTitleInfos(){
    this.isReady = false;
    this._dafService.getjobTitleInfos(this.caseSummaryId).toPromise().then((data: any) => {
      this.primaryworksiteInfo = data;
      this.view = this.primaryworksiteInfo;

      this.loadItems();
      this.isReady = true;
    },
      (error) => {
        console.log(error)
        this.isReady = true;
      }
    );
  }



  

  public closeForm(): void {
    this.cancel.emit("Close");
}

 
  


 

 

  initializeForm() {
    try {
      this.emailForm = this.formBuilder.group({
        jobTitleWizardSaveID: new FormControl(this.jobDetails.jobTitleWizardSaveID),
        jobTitle: new FormControl( this.jobDetails.jobTitle),
        salaryOffered: new FormControl( this.jobDetails.salaryOffered),
        jobDetails: new FormControl( this.jobDetails.jobDetails),
        jobRequirements: new FormControl( this.jobDetails.jobRequirements),
        caseId: new FormControl(this.jobDetails.caseId),
        caseSummaryId: new FormControl(this.jobDetails.caseSummaryId)
      });
      //this.isReady = true;
      this.active = true;
    }
    catch (err) {
      console.log(err);
    }
  }


  private readFormValues(group: FormGroup) {
    Object.keys(this.emailForm.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.readFormValues(abstractControl);
      }
      else {
        this.jobDetails[key] = abstractControl.value;
      }
    });
  }


  saveJobDetailsInfo(){
    this.isReady = false;

    this.readFormValues(this.emailForm);
    if(this.emailForm.valid){
      this._dafService.saveJobDetailsWizard(this.jobDetails,this.caseSummaryId).toPromise().then(()=>{
        this.isReady = true;
        this.notifier.notify('success', 'JobTitle Wizard Information have been saved successfully');
        this.cancel.emit("Close");
      }).catch((err)=>{
        this.notifier.notify('error', 'Operation Failed');
        this.cancel.emit("Close");
      });
    }
  }

  onAddressAdd(){
    this.cancel.emit("Address");
  }


  public onCancel(e: PointerEvent): void {
    e.preventDefault();
    this.closeForm();
}



  

  invalidMessage(){
    this.notificationService.show({
      content: "Please ensure to add all the required Data",
      position: { horizontal: "right", vertical: "top" },
      animation: { type: "fade", duration: 800 },
      type: { style: "warning", icon: true },
      hideAfter: this.hideAfter,
    });
  }



}

