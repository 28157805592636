import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter, OnChanges } from '@angular/core';
import {DashboardView} from '../awaitdocextraction/model/dashboardview';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { DetailedViewService } from 'src/app/immigrationcompilation/detailedview/service/detailedview.service';
import { NotifierService } from 'angular-notifier';
@Component({
  selector: 'app-detailedview',
  templateUrl: './detailedview.component.html',
  styleUrls: ['./detailedview.component.scss']
})
export class DetailedviewComponent implements OnInit {
  public data: any[];
  public selectedStatus = 0;
  public parentBnfId: number;
  public parentZoomId: number;
  public applicantType: string;
  public time:number;
  public requestType: string;
  public caseType: string;
  public insZoomId: string;
  public isLast: boolean;
  public detailsList: any[];
  public statusMaster: any[];
  public isPageReady = true;
  public action="Time Spent";
  take: number;
  public id;
  public gridHeight = {
    height: 'calc(100vh - 220px)'
  };
  constructor(private route: ActivatedRoute, private router: Router, private location: Location, 
    private notifier: NotifierService,  private detailedService: DetailedViewService) { }
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.parentBnfId = params['parentBnfId'];
      this.applicantType = params['applicantType'];
      this.requestType = params['requestType'];
      this.caseType = params['caseType'];
      this.insZoomId = params['insZoomId'];
      this.isLast = params['isLast'];
      this.id = params['Id'];
    });
    this.LoadStatusMaster();
    //this.refreshDetailsList();
    this.GetChecklistWithID();
  }
  refreshDetailsList() {
    this.isPageReady=false;
    this.detailedService.getAIResult(this.insZoomId).subscribe((data: any) => {
        // Binds the data to grid
        this.detailsList = data;
        this.isPageReady=true;
      },
      (error) => {
        this.isPageReady=true;
        this.notifier.notify('error', 'Error occured while getting data from server');
      }
      );
      }
  cancelClick() {
 this.location.back();
  }
updateCase(detailedView: any, statusId: any) {
detailedView = JSON.stringify(detailedView);
console.log(detailedView);
this.detailedService.updateCase(detailedView, this.insZoomId, statusId.value,this.time).subscribe((data: any) => {
  this.location.back();
  this.notifier.notify('success', 'Case Id is updated with entered remarks and status');
  console.log(this.detailsList);
},
(error) => {
  this.notifier.notify('error', 'Error occured while updating data to the server');
}
);
}
LoadStatusMaster() {
  this.detailedService.getStatusMaster().subscribe((statusresult: any[]) => {
   this.statusMaster = statusresult;
 }, error => {console.error(error); console.log('error'); });
}
// valueChange($event: any, dataItem: any) {
// var remarks = $event.target.value;
// this.detailsList[0].gridView[0].gridView.find(x => x.id == dataItem.id).remarks = remarks;
// }
  public fetchChildren = (item: any): any[] => {
    var result = item.gridView;
    if (item.gridView.length > 1 && item.gridView.find(x => x.fileNames == "Not Found")) {
      result = item.gridView.filter(x => x.fileNames != "Not Found")
    }
    return result;
}

public hasChildren = (item: any): boolean => {
  return item.gridView && item.gridView.length > 0;
}
  GetChecklistWithID() {
    this.isPageReady = false;
    this.detailedService.GetChecklistWithID(this.id).subscribe((data: any) => {
      // Binds the data to grid
      this.detailsList = data;
      this.isPageReady = true;
    },
      (error) => {
        this.isPageReady = true;
        this.notifier.notify('error', 'Error occured while getting data from server');
      }
    );
  }

  UpdateCompilationRemarks(detailedView: any, statusId: any) {
    detailedView = JSON.stringify(detailedView);
    this.detailedService.UpdateCompilationRemarks(detailedView, this.id, statusId.value, this.time).subscribe((data: any) => {
      this.location.back();
      this.notifier.notify('success', 'Case Id is updated with entered remarks and status');
    },
      (error) => {
        this.notifier.notify('error', 'Error occured while updating data to the server');
      }
    );
  }
}
