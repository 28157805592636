import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AddEvent, GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { NotifierService } from 'angular-notifier';
import { delay } from 'rxjs/operators';
import { primaryWorksiteAddress } from 'src/app/DAF/Models/PrimaryWorksiteAddress';
import { DafService } from 'src/app/DAF/services/dafService';
import { AddLCAService } from 'src/app/immigrationcompilation/add-lca/service/add-lca.service';
import { addressWizardInfos } from '../../../Models/addressWizardInfos';
import { basisOfClassification, worksiteAddressModal } from '../../../Models/LCAAssessmentDetails';


@Component({
  selector: 'app-primary-worksite-address',
  templateUrl: './primary-worksite-address.component.html',
  styleUrls: ['./primary-worksite-address.component.scss']
})
export class PrimaryWorksiteAddressComponent implements OnInit {

  radioButtonOptionValues: Array<any> = [{ value: true, text: "Yes" }, { value: false, text: "No" }];
  private notifier: NotifierService;
  public workSiteData: primaryWorksiteAddress = new primaryWorksiteAddress();
  public isUnlocked: boolean = false;
  public isEditEnabled: boolean = false;
  public isAdditineray: boolean = false;
  public isOffc: boolean = false;

   
  public isPageReady = true;
  worksiteForm: FormGroup = null;
  addressDataSource = [];
  addressList = [];
  statesDataSource = [];
  statesList=[];
  public zipcodeList = [[]];
  public addressTypesList = [];
  addressTypes: any[] = [];
  public state;
  public zipcodeSource = [];
  public zipcodeSourcefrOffc = [];
  public cityArray=[[]];
  public countyArray = [[]];
  public isReady=false;
  public isActivate=false;
  citySourceArray: any[]=[];
  countyDataArray: any[] = [];
  public cityList = [];
  public citySource = [];
  public countyList = [];
  public countySource = [];
  public geographyList = [];
  public geographySource = [];
  public OfficeDataSource = [];
  lcaValidityFrom: any;
  lcaValidityTo: any;
  public officeTypeList: Array<string> = [
    "Apt.",
    "Ste.",
    "Flr."
  ];

  @Input() caseId = 0;
  
  @Output() page = new EventEmitter<number>();
  @Output() collapseCSPanel = new EventEmitter<any>();
  @ViewChild('autocomplete', { static: false }) autocomplete: any;

  optionalFormControls = ["middleName","signatoryName","signatoryTitle","signatoryTelephone","apt"];
  formErrors = {
    'state': '',
    'city': '',
    'county': '',
    'postalCode': '',
    'streetName': '',  
    'streetNumber': '',
    'apt':'',
    'countryOnOLFC':'',
    'employeeOffsite':'',
    'compliance':'',
    'reasonForExemption': '',

    'jobTitle': '',
    'salaryRangeFrom': '',
    'salaryRangeTo': '',
    'secondaryEntityName': ''
  };
  
  validationMessages = {
    'state': {
      'required': 'State is required.',
    },
    'city': { 
      'required': 'City is required.', 
    },
    'county': { 
      'required': 'county is required.', 
    },
    'postalCode': { 
      'required': 'Postal Code is required.', 
    },
    'streetName': { 
      'required': 'Street Name is required.', 
    },
    'streetNumber': { 
      'required': 'Street Number is required.', 
    },
    'apt': { 
      'required': 'Apartment Number required.', 
    },
    'countryOnOLFC': { 
      'required': 'Countr/City/Township on OLFC is required.', 
    },
    'employeeOffsite': { 
      'required': 'This field is required.', 
    },
    'compliance': { 
      'required': 'Compliance is required.', 
    },
    'reasonForExemption': { 
      'required': 'Reason is required.', 
    },


    'jobTitle': {
      'required': 'Job Title is required.',
    },
    'secondaryEntityName': {
      'required': 'Secondary Entity Name is required.',
    },
    'salaryRangeFrom': {
      'required': 'Salary From Range is required.',
    },
    'salaryRangeTo': {
      'required': 'Salary To Range is required.',
    }
  };

  //LCA Assessment Variables
  @Input() caseSummaryId = 0;
  //worksiteForm: FormGroup;
  //public isPageReady = true;
  //private workSiteData: workSiteData = new workSiteData();
  basisOfClassification: Array<basisOfClassification> = [];
  wageLevel: any = [];
  additionalLCAType: Array<basisOfClassification> = [];
  //isEditEnabled: boolean;
  //radioButtonOptionValues: Array<any> = [{ value: true, text: "Yes" }, { value: false, text: "No" }];
  radiobasisValues: Array<any> = [{ value: 1, text: "New" }, { value: 2, text: "Continuation" }, { value: 3, text: "Change" }, { value: 4, text: "Concurrent" }, { value: 5, text: "COE" }, { value: 6, text: "Amendment" }];
  onetocsSDetails: any;
  onetocsSTitle: any;
  onetocsSCode: any;
  //statesList: any;
  stateMaster: any;
  zipCodeList: any;
  zipCodeMaster: any;
  additionalLCAONETOCSSTitle: any;
  additionalLCAONETOCSSCode: any;
  private salaryFrom: number;
  private salaryTo: number;
  //private notifier: NotifierService;
  public formGroup: FormGroup;
  public isGridEditable: boolean;
  showDialog: boolean = false;
  deleteIndex: number;
  addressInfo = [];
  public view = [];
  public pageSize = 5;
  public skip = 0;
  public gridView: GridDataResult;
  public addressDetails = [];
  public dotCodeList = [];
  public editDataItem: worksiteAddressModal;
  private isNew: boolean;
  private editedRowIndex: number;
  private grid: GridComponent;
  public modifiedRecords: Array<addressWizardInfos> = [];
  public deleteRow: any;
  private isDeleteClicked: boolean = false;
  public selectedWageLevelId: any;
  public deleteMessage;

  constructor(private _dafService: DafService, notifierService: NotifierService, public _addLCAService: AddLCAService, private formBuilder: FormBuilder, public addLCAService: AddLCAService) {
    this.notifier = notifierService; 
  }

  ngOnInit() {

    this.wageLevel = [
      { lcaWageLevelId: 1, wageLevelId: 1, wageLevel: "I", isSelected: false },
      { lcaWageLevelId: 2, wageLevelId: 2, wageLevel: "II", isSelected: false },
      { lcaWageLevelId: 3, wageLevelId: 3, wageLevel: "III", isSelected: false },
      { lcaWageLevelId: 4, wageLevelId: 4, wageLevel: "IV", isSelected: false },
    ]
    this.getetONETOCSSDetails();
    //this.getworkSiteData();
    this.getMasterData();
    this.getAddressInformation();

    
    // this.getStates();
    // this.fetchcaseData(this.caseId);
  }

  async getMasterData (){
    this.getStates();
    this.fetchcaseData(this.caseId);
    this.getOfficeAddress();
  }

  fetchcaseData(caseId: number) {
    
    this.isPageReady = false;
   
    this._dafService.GetPrimaryWorksiteAddress(caseId).toPromise().then((data: any) => {
      this.workSiteData = new primaryWorksiteAddress(data);
      this.basisOfClassification = this.workSiteData.basisOfClassification;
      this.selectedWageLevelId = this.workSiteData.wageLevelId;
      if (this.workSiteData.companyName === "EY US" || this.workSiteData.companyName === "Ernst & Young Infrastructure Advisors LLC" || this.workSiteData.companyName === "Ernst & Young U.S. LLP" ) {
        this.isOffc = true;
      }
      else { this.isOffc = false; }
      this.initializeForm();

      if (this.wageLevel.filter(p => p.wageLevelId == this.workSiteData.wageLevelId).length > 0) {
        this.wageLevel.filter(p => p.wageLevelId == this.workSiteData.wageLevelId)[0].isSelected = true;
      }
    },
      (error) => {
      }
    );
  }

  openWizard(){
    this.isActivate=true;
  }

  async initializeForm() {
    await this.GetZipcodes(this.workSiteData.stateID);
    await this.GetCounty(this.workSiteData.stateID);
    await this.GetGeography(this.workSiteData.stateID);
    await this.GetCity(this.workSiteData.zipCodeId);

    if (this.workSiteData.onetOcssId)
      await this.getDotCodeBySOCCd(this.workSiteData.onetOcssId);

    let decodedNotes = this.decodeHTMLEntities(this.workSiteData.notes);
    let decodedjobTitle = this.decodeHTMLEntities(this.workSiteData.jobTitle);
    try {

      this.worksiteForm = this.formBuilder.group({
        caseId: new FormControl(this.caseId),
        primWorkAddID: new FormControl(this.workSiteData.primWorkAddID),
        state: new FormControl({ value: this.workSiteData.state, disabled: true }),
        officeaddress: new FormControl({ value: this.workSiteData.officeaddress, disabled: true }),
        city: new FormControl({ value: null, disabled: true}),
        county: new FormControl({ value: this.workSiteData.county, disabled: true }),
        postalCode: new FormControl({ value: this.workSiteData.postalCode, disabled: true }),
        streetName: new FormControl({ value: this.workSiteData.streetName, disabled: true },[ Validators.required,Validators.nullValidator]),
        streetNumber: new FormControl({ value: this.workSiteData.streetNumber, disabled: true }, [Validators.required,Validators.nullValidator]),
        apt: new FormControl({ value: this.workSiteData.apt, disabled: true },),
        officeType: new FormControl({ value: this.workSiteData.officeType, disabled: true },),
        countryOnOLFC: new FormControl({ value: this.workSiteData.countryOnOLFC, disabled: false }),
        employeeOffsite: new FormControl({ value: this.workSiteData.employeeOffsite, disabled: true }),
        compliance: new FormControl({ value: this.workSiteData.compliance, disabled: true }),
        addnlWorksites: new FormControl({ value: this.workSiteData.addnlWorksites, disabled: true }),
        h1BDependantER: new FormControl({ value: this.workSiteData.h1BDependantER, disabled: true }, [Validators.required,Validators.nullValidator]),
        willfulViolator: new FormControl({ value: this.workSiteData.willfulViolator, disabled: true }, [Validators.required,Validators.nullValidator]),
        exemptH1Bworker: new FormControl({ value: this.workSiteData.exemptH1Bworker, disabled: true }, [Validators.required,Validators.nullValidator]),
        reasonForExemption: new FormControl({ value: this.workSiteData.reasonForExemption, disabled: true }),
        educCoveredByACIWA: new FormControl({ value: this.workSiteData.educCoveredByACIWA, disabled: true }, [Validators.required,Validators.nullValidator]),
        notes: new FormControl({ value: decodedNotes, disabled: true }),
        stateId: new FormControl({ value: this.workSiteData.stateID, disabled: true }, [Validators.required, Validators.nullValidator]),
        officeaddressId: new FormControl({ value: this.workSiteData.officeaddressId, disabled: true }),
        zipCodeId: new FormControl({ value: this.workSiteData.zipCodeId, disabled: true }, [Validators.required, Validators.nullValidator]),
        cityId: new FormControl({ value: this.workSiteData.cityId, disabled: true }, [Validators.required, Validators.nullValidator]),
        countyId: new FormControl({ value: this.workSiteData.countyId, disabled: true }),
        geographyId: new FormControl({ value: this.workSiteData.geographyId, disabled: true }),
        
        //LCA Classification
        basisOfClassification: this.formBuilder.group({}),
        wageLevel: this.formBuilder.group({}),
        additionalLCAType: this.formBuilder.group({}),
        jobTitle: new FormControl({ value: decodedjobTitle, disabled: true }, Validators.required),
        oesSOCTitle: new FormControl({ value: this.workSiteData.oesSOCTitle, disabled: true }),
        onetOcssId: new FormControl({ value: this.workSiteData.onetOcssId, disabled: true }, Validators.required),
        oessocCode: new FormControl({ value: this.workSiteData.oessocCode, disabled: true }),
        lcaValidityFrom: new FormControl({ value: this.workSiteData.lcaValidityFrom ? new Date(this.workSiteData.lcaValidityFrom) : null, disabled: true }),
        lcaValidityTo: new FormControl({ value: this.workSiteData.lcaValidityTo ? new Date(this.workSiteData.lcaValidityTo) : null, disabled: true }),
        dotCode: new FormControl({ value: this.workSiteData.dotCode, disabled: true }),
        wageSourceAndYear: new FormControl({ value: this.workSiteData.wageSourceAndYear, disabled: true }),
        prevailingWage: new FormControl({ value: this.workSiteData.prevailingWage ? this.workSiteData.prevailingWage : 0, disabled: true }, Validators.required),
        actualSalary: new FormControl({ value: this.workSiteData.actualSalary, disabled: true }, Validators.required),
        isInSalaryRange: new FormControl({ value: this.workSiteData.isInSalaryRange, disabled: true }),
        salaryRangeFrom: new FormControl({ value: this.workSiteData.salaryRangeFrom, disabled: true }, Validators.required),
        salaryRangeTo: new FormControl({ value: this.workSiteData.salaryRangeTo, disabled: true }, Validators.required),
        emailAddressForLCA: new FormControl({ value: this.workSiteData.emailAddressForLCA ? this.workSiteData.emailAddressForLCA : "certified.lca@ca.ey.com", disabled: true, }),
        headcount: new FormControl({ value: this.workSiteData.headcount ? this.workSiteData.headcount : 1, disabled: true }, Validators.required),
        isbasis: new FormControl({ value: this.workSiteData.isbasis, disabled: true })
      });
      this.addDynamicControls();
      this.isPageReady = true;
      this.subscribeToFormControlValueChanges();

      this.worksiteForm.valueChanges.subscribe((val=>{
        this.logOnChangeValidationErrors();
      }));
    }
    catch (err) {
      console.log(err);
    }
  }

  addDynamicControls(): void {

    this.basisOfClassification.forEach(key => {
      console.log(key);
      (this.worksiteForm.get('basisOfClassification') as FormGroup).addControl(key.shortForm, new FormControl({ value: key.isSelected, disabled: true }))
    });

    this.wageLevel.forEach(key => {
      console.log(key);

      (this.worksiteForm.get('wageLevel') as FormGroup).addControl(key.wageLevel, new FormControl({ value: key.isSelected, disabled: true }))
    })

    this.additionalLCAType.forEach(key => {
      console.log(key);
      (this.worksiteForm.get('additionalLCAType') as FormGroup).addControl(key.shortForm, new FormControl({ value: key.isSelected, disabled: true }))
    })

  }

  get formControls() {
    return this.worksiteForm.controls;
  }

  subscribeToFormControlValueChanges() {
    this.worksiteForm.get("isInSalaryRange").valueChanges.subscribe(isSalaryRange => {
      if (isSalaryRange) {
        this._dafService.disableEnableFormControl(this.worksiteForm, ["salaryRangeFrom", "salaryRangeTo"], false);
        if (this.salaryFrom > 0) {
          this.formControls['salaryRangeFrom'].setValue(null);
        }
        if (this.salaryTo > 0) {
          this.formControls['salaryRangeTo'].setValue(null);
        }
        this.worksiteForm.updateValueAndValidity();
      } else {

        this._dafService.disableEnableFormControl(this.worksiteForm, ["salaryRangeFrom", "salaryRangeTo"], true);
        this.salaryFrom = this.formControls['salaryRangeFrom'].value;
        this.salaryTo = this.formControls['salaryRangeTo'].value;
        this.formControls['salaryRangeFrom'].setValue(null);
        this.formControls['salaryRangeTo'].setValue(null);
        this.worksiteForm.updateValueAndValidity();
      }
    });

    //this.additionalWorksiteValueChange();
    //this.additionalLCAValueChange();
    this.additionalLCAWageRangeValueChange();
    //this.secondaryEntityValueChange();
  }

  enableEdit() {
    try {
      if (this.worksiteForm.controls["addnlWorksites"].value == true) {
        this.isAdditineray = true;
      }
      else {
        this.isAdditineray = false;
      }
      this.isEditEnabled = true;
      this._dafService.disableEnableAllFormControls(this.worksiteForm, [], false);
      this.enableDisableControls();
      this.isGridEditable = true;
    }
    catch (err) {
      console.log(err);
    }
  }

  unlockFields() {
    try {
      this.isUnlocked = true;
      this._dafService.disableEnableFormControl(this.worksiteForm,["county","countryOnOLFC"], false);
    }
    catch (err) {
      console.log(err);
    }
  }

  lockFields() {
    try {
      this.isUnlocked = false;
      //this._dafService.disableEnableFormControl(this.worksiteForm,["employeeEmail"], true);
    }
    catch (err) {
      console.log(err);
    }
  }

  enableAdditionalworksite() {

    if (this.worksiteForm.controls["addnlWorksites"].value == true) {
      this.isAdditineray = true;
    }
    else {
      this.isAdditineray = false;
    }
  }


  saveWorksiteAddressData() {
    debugger;
    this.isPageReady = false;
    //this.logValidationErrors();
    this.isPageReady = true;
    this.readFormValues(this.worksiteForm);

    if (this.worksiteForm.valid && this.validateLCAData(this.workSiteData) ) {
      this.workSiteData.worksiteAddress = this.modifiedRecords;
      if (this.workSiteData.notes) {
        this.workSiteData.notes = this.encodeHTMLEntities(this.workSiteData.notes);
        }
        if (this.workSiteData.lcaValidityFrom == null) {
            this.lcaValidityFrom = null;
        }
        else {
            this.lcaValidityFrom = this.workSiteData.lcaValidityFrom.toDateString();
        }
        if (this.workSiteData.lcaValidityTo == null) {
            this.lcaValidityTo = null;
        }
        else {
            this.lcaValidityTo = this.workSiteData.lcaValidityTo.toDateString();
        }
      if (this.workSiteData.jobTitle) {
        this.workSiteData.jobTitle = this.encodeHTMLEntities(this.workSiteData.jobTitle);
      }
      this._dafService.savePrimaryWorksiteAddress(this.workSiteData, this.lcaValidityFrom, this.lcaValidityTo).toPromise().then(() => {
        
        this.getMasterData();
        this.getAddressInformation();
        this._dafService.disableEnableAllFormControls(this.worksiteForm, [], true);
        this.isEditEnabled = false;
        this.isAdditineray = false;
        this.isUnlocked = false;
        this.isPageReady = true;
        this.isGridEditable = false;
        this.notifier.notify('success', 'Worksite address details have been saved successfully');
      }).catch((err) => {
        this.notifier.notify('error', 'Error occurred while saving the details');
      });
    }
    else {
      this.notifier.notify('error', 'All fields are required and should have data');
      this.isPageReady = true;
    }
  }

 

  private readFormValues(group: FormGroup) {
    Object.keys(this.worksiteForm.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.getDynamicControlValue(abstractControl, key);
      }
      else {
        this.workSiteData[key] = abstractControl.value;
      }
    });
  }

  resetForm() {
    try {
      this.fetchcaseData(this.caseId);
      this.isEditEnabled = false;
      this.isUnlocked = false;
      this.isGridEditable = false;
      this._dafService.disableEnableAllFormControls(this.worksiteForm,[],true);
    }
    catch (err) {
      console.log(err);
    }
  }

  get f() {
    return this.worksiteForm.controls;
  }

  logValidationErrors(group: FormGroup = this.worksiteForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid
            // && (abstractControl.touched || abstractControl.dirty)
            ) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  logOnChangeValidationErrors(group: FormGroup = this.worksiteForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid
             && (abstractControl.touched || abstractControl.dirty)
            ) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  setCSPanelState() {
    this.collapseCSPanel.emit(0);
  }

//  public cancelHandler(): void {
//this.isActivate=false;  }


  ngOnDestroy(): void {
    // this.worksiteForm.get("expediteCase").valueChanges.subscribe().unsubscribe();
    // this.worksiteForm.get("placeOnHold").valueChanges.subscribe().unsubscribe();
  }

  getOfficeAddress() {
    this._dafService.getOfficeAddress().subscribe((data: any) => {
      this.addressDataSource = data;
      this.addressList = data;
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
  }

  getStates(){
    this._dafService.getStates().subscribe((data: any) => {
      this.statesDataSource = data;
      this.statesList = data;
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
  }

  onStateChange(event) {
    this.worksiteForm.controls["zipCodeId"].setValue(null);
    this.worksiteForm.controls["cityId"].setValue(null);
    this.worksiteForm.controls["cityId"].disable();
    this.worksiteForm.controls["geographyId"].setValue(null);
    this.worksiteForm.controls["countyId"].setValue(null);
    this.GetZipcodes(event);
    this.GetCounty(event);
    this.GetGeography(event);
  }

  GetZipcodes(event): Promise<any> {
    this.zipcodeList = [];
    this.zipcodeSourcefrOffc = [];
    let res = this._dafService.getZipcodeByState(event).toPromise().then((data: any) => {
      this.zipcodeList = data;
      this.zipcodeSource = data;
      this.zipcodeSourcefrOffc = data;
      //if (data.length > 0) {
      //  data.forEach(element => {
      //    this.zipcodeList[index].push(element.zipcode.toString());
      //  });
      //}
      return Promise.resolve(data);
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
    //this._dafService.disableEnableFormControl(this.caseSummaryForm,["signatoryZipcode"],false);
    return res;
  }
  GetZipcodesforOffc(event): Promise<any> {
    
    this.zipcodeSourcefrOffc = [];
    let res = this._dafService.getZipcodeByState(event).toPromise().then(async (data: any) => {
      this.zipcodeSourcefrOffc = data;

      const zipres = this.zipcodeSourcefrOffc.filter(s => s.zipcode == this.OfficeDataSource[0].zip);
      this.worksiteForm.controls["zipCodeId"].setValue(zipres[0].zipCodeId);
      this.worksiteForm.controls["cityId"].enable();
      await this.GetCityfroffc(zipres[0].zipCodeId);
           
      //const zipres1 = this.countySource.filter(s => s.value == this.OfficeDataSource[0].country);
      //this.worksiteForm.controls["countyId"].setValue(zipres1[0].id);
      

      return Promise.resolve(data);
    },
      (error) => {
       
      }
    );
    
    return res;
  }
  onZipCodeChange(event) {
    this.worksiteForm.controls["cityId"].setValue(null);
    this.worksiteForm.controls["cityId"].enable();
    this.GetCity(event);
  }

  GetCountyInfo(event, index: any): Promise<any>  {
    this.cityArray = [];
    this.countyArray = [];
    let res = this._addLCAService.GetCountyInfo(event).toPromise().then((data: any) => {
      if (data.length > 0) {
        //this.citySourceArray = data.map(x=>x.cityName);
        //this.worksiteForm.controls["city"].setValue(data[0].cityName);
        //this.worksiteForm.controls["county"].setValue(data[0].countyName);
        this.worksiteForm.updateValueAndValidity();
      }
      return Promise.resolve(data);
    },
      (error) => {
      }
    );
    return res;
  }

  setCounty(event, index: any){
    try{
      this.worksiteForm.controls["city"].setValue(event);
    }
    catch(err){
      console.log(err);
    }
  }

  GetCounty(event): Promise<any> {
    this.countyList = [];
    let res = this._dafService.getCountyByState(event).toPromise().then((data: any) => {
      this.countyList = data;
      this.countySource = data;

      return Promise.resolve(data);
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
    //this._dafService.disableEnableFormControl(this.caseSummaryForm,["signatoryZipcode"],false);
    return res;
  }

  GetCity(event): Promise<any> {
    
    this.cityList = [];
    let res = this._dafService.getCityByZipcode(event).toPromise().then(async(data: any) => {
      this.cityList = data;
      this.citySource = data;

      return Promise.resolve(data);
    },
      (error) => {
        this.notifier.notify('error', 'Error occured while fetching the city list');
      }
    );
    //this._dafService.disableEnableFormControl(this.caseSummaryForm,["signatoryZipcode"],false);
    return res;
  }

  public officeAddressFilterChange(filter: any): void {
    this.addressList = this.addressDataSource.filter(x => x.displayOffcName.toLowerCase().includes(filter.toLowerCase()));
  }

GetCityfroffc(event): Promise < any > {

  this.cityList = [];
   
  let res = this._dafService.getCityByZipcode(event).toPromise().then(async (data: any) => {
    this.cityList = data;
    this.citySource = data;
    console.log(this.citySource);
    const cityres = this.citySource.filter(s => s.city.toLowerCase() == this.OfficeDataSource[0].city.toLowerCase());
    this.worksiteForm.controls["cityId"].setValue(cityres[0].cityID);
    return Promise.resolve(data);
  },
    (error) => {
      this.notifier.notify('error', 'Error occured while fetching the city list');
    }
  );
  
  return res;
}
  public stateFilterChange(filter: any): void {
    this.statesList = this.statesDataSource.filter(x => x.stateName.toLowerCase().includes(filter.toLowerCase()));
  }

  public zipFilterChange(filter: any): void {
    this.zipcodeList = this.zipcodeSource.filter(x => x.zipcode.includes(filter));
  }

  public cityFilterChange(filter: any): void {
    this.cityList = this.citySource.filter(x => x.city.toLowerCase().includes(filter));
  }

  public msaFilterChange(filter: any): void {
    this.geographyList = this.geographySource.filter(x => x.value.toLowerCase().includes(filter));
  }

  public countyFilterChange(filter: any): void {
    this.countyList = this.countySource.filter(x => x.value.toLowerCase().includes(filter));
  }

  GetGeography(event): Promise<any> {
    this.geographyList = [];
    let res = this._dafService.getGeographyByState(event).toPromise().then((data: any) => {
      this.geographyList = data;
      this.geographySource = data;

      return Promise.resolve(data);
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
    //this._dafService.disableEnableFormControl(this.caseSummaryForm,["signatoryZipcode"],false);
    return res;
  }

  OnCountyChange(event) {
    this.geographyList = [];
    let selectedState = this.worksiteForm.get("stateId").value;
    let res = this._dafService.GetGeographyByCounty(selectedState, event).toPromise().then((data: any) => {
      this.geographyList = data;
      this.geographySource = data;

      return Promise.resolve(data);
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
    //this._dafService.disableEnableFormControl(this.caseSummaryForm,["signatoryZipcode"],false);
    return res;
  }

  OnGeographyChange(event) {
    this.countyList = [];
    let selectedState = this.worksiteForm.get("stateId").value;
    this.getFLCWageOnMSAChange();
    let res = this._dafService.GetCountyByGeography(selectedState, event).toPromise().then((data: any) => {
      this.countyList = data;
      this.countySource = data;

      return Promise.resolve(data);
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
    //this._dafService.disableEnableFormControl(this.caseSummaryForm,["signatoryZipcode"],false);
    return res;
  }

  encodeHTMLEntities(text) {
    let textArea = document.createElement('textarea');
    let escapeQuotes = text;
    if (text) {
      escapeQuotes = text.replace(/['"()]/g, escape);
    }

    textArea.innerText = escapeQuotes;
    let encodedOutput = textArea.innerHTML;
    let arr = encodedOutput.split('<br>');
    encodedOutput = arr.join('\n');
    return encodedOutput;
  }

  decodeHTMLEntities(text) {
    let textArea = document.createElement('textarea');
    let unescapeQuotes = text;
    if (text) {
      unescapeQuotes = text.replace("%27", unescape);
      unescapeQuotes = unescapeQuotes.replace("%22", unescape);
      unescapeQuotes = unescapeQuotes.replaceAll("%28", unescape);
      unescapeQuotes = unescapeQuotes.replaceAll("%29", unescape);
    }
    textArea.innerHTML = unescapeQuotes;
    return textArea.value;
  }

  OnOfficeChange(event) {

    this.OfficeDataSource = [];
    let selectedoffc = this.worksiteForm.get("officeaddressId").value;
    let res = this._dafService.getOfficeInfoById(selectedoffc).toPromise().then(async (data: any) => {
      this.OfficeDataSource = data;
      
      this.worksiteForm.controls["streetName"].setValue(this.OfficeDataSource[0].street1);
      this.worksiteForm.controls["streetNumber"].setValue(this.OfficeDataSource[0].street2);
      const result = this.statesDataSource.filter(s => s.state == this.OfficeDataSource[0].state);
      this.worksiteForm.controls["stateId"].setValue(result[0].stateId);
      this.onStateChange(result[0].stateId);
      this.GetZipcodesforOffc(result[0].stateId);
      return Promise.resolve(data);
    },
      (error) => {

      }
    );

    return res;
  }

  getetONETOCSSDetails() {
    this.isPageReady = false;
    this._dafService.getetONETOCSSDetails().toPromise().then((data: any) => {
      this.onetocsSDetails = data;
      this.onetocsSTitle = data;
      this.onetocsSCode = data;
      this.additionalLCAONETOCSSTitle = data;
      this.additionalLCAONETOCSSCode = data;
      console.log(this.onetocsSDetails);

    },
      (error) => {
      }
    );
  }

  getDotCodeBySOCCd(event) {
    if (this.worksiteForm)
      this.worksiteForm.controls["dotCode"].setValue(null);
    this._dafService.getDotCodeBySOCCd(event).toPromise().then((data: any) => {
      this.dotCodeList = data;
    },
      (error) => {
      }
    );
  }

  public onOESSOCTitlevalueChange(event) {
    this.worksiteForm.controls["onetOcssId"].setValue(event.onetOcssId);
    //this.getLCAPrevailingwage(event, false);
    this.getDotCodeBySOCCd(event.onetOcssId);
    this.worksiteForm.updateValueAndValidity

    let socCode = event.onetCode;
    this.worksiteForm.get("oessocCode").setValue(socCode);
    this.getFLCWageOnMSAChange();
    this.view.forEach((key: any, i: number) => {
      this.getFLCWage(key.geographyId, socCode, this.selectedWageLevelId, i);
    });
  }

  public onOESSOCTitleFilterChange(event) {
    this.onetocsSTitle = this.onetocsSDetails.filter(x => x.onetTitle.toLowerCase().includes(event.toLowerCase()));

  }

  public onOESSOCCodevalueChange(event) {
    this.worksiteForm.controls["onetOcssId"].setValue(event.onetOcssId);
    //this.getLCAPrevailingwage(event, false);
    this.getDotCodeBySOCCd(event.onetOcssId);
    this.worksiteForm.updateValueAndValidity();

    let socCode = event.onetCode;
    this.worksiteForm.get("oessocCode").setValue(socCode);
    //let geographyId = this.addressInfo.filter(x => x.addressType == "Primary")[0].geographyId;
    this.getFLCWageOnMSAChange();
    this.view.forEach((key: any, i: number) => {
      this.getFLCWage(key.geographyId, socCode, this.selectedWageLevelId, i);
    });
    //if (this.selectedWageLevelId && socCode && geographyId) {
    //  this.getFLCWage(geographyId, socCode, this.selectedWageLevelId);
    //}
  }

  public onAdditionalLCAOESSOCTitlevalueChange(event) {
    this.worksiteForm.controls["additionalLCAonetOcssId"].setValue(event);
    //this.getLCAPrevailingwage(event, true);
    this.worksiteForm.updateValueAndValidity();

  }

  public onAdditionalLCAOESSOCCodevalueChange(event) {
    this.worksiteForm.controls["additionalLCAonetOcssId"].setValue(event);
    this.getLCAPrevailingwage(event, true);
    this.worksiteForm.updateValueAndValidity();

  }

  public onOESSOCCodeFilterChange(event) {
    this.onetocsSCode = this.onetocsSDetails.filter(x => x.onetCode.toLowerCase().includes(event.toLowerCase()));

  }

  getAddressInformation() {
    this.isPageReady = false;
    this._dafService.getAddressWizardInformation(this.caseId).toPromise().then((data: any) => {
      this.addressInfo = data;
      //if(this.addressInfo.length==0){
      //  for(let i=0;i<3;i++){
      //    var newAddress= new addressWizardInfos();
      //    newAddress.caseSummaryId=this.caseSummaryId;
      //    this.addressInfo.push(newAddress);
      //  }
      //  this.addressInfo[0].addressType="Primary";
      //  this.addressInfo[1].addressType="Additional";
      //  this.addressInfo[2].addressType="Secondary";

      //}
      this.view = this.addressInfo;


      this.loadItems();
      this.isPageReady = true;
    },
      (error) => {
        this.isPageReady = true;
      }
    );
  }

  private loadItems(): void {
    this.gridView = {
      data: this.view.slice(this.skip, this.skip + this.pageSize),
      total: this.view.length,
    };
  }

  isAllSelected(item) {
    var formGroup = this.worksiteForm.get("wageLevel") as FormGroup;
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      if (key === item.key) {
        let currValue = abstractControl.value as boolean;
        abstractControl.setValue(true);
      }
      else {
        abstractControl.setValue(false);
      }
    });

    this.selectedWageLevelId = this.wageLevel.filter(p => p.wageLevel == item.key)[0].wageLevelId;
    let socCode = this.worksiteForm.get("oessocCode").value;
    this.getFLCWageOnMSAChange();
    this.view.forEach((key: any, i: number) => {
      this.getFLCWage(key.geographyId, socCode, this.selectedWageLevelId, i);
    });
  }

  getFLCWage(geographyId: number, socCode: any, selectedWageLevelId: number, i: number) {

    this._dafService.getFLCWage(geographyId, socCode, selectedWageLevelId).toPromise().then((data: any) => {
      let PLCwage = data;
      if (this.view[i].addressType == "Primary")
        this.worksiteForm.get("prevailingWage").setValue(PLCwage);

      this.view[i].flcWage = PLCwage;
      this.view[i].pwi = PLCwage >= this.view[i].salary ? true : false;
      this.view[i].changeType = "UPDATE";
      this.saveHandler(this.view[i]);
      //this.worksiteForm.get("prevailingWage").setValue(data);
      //this.view.find(x => x.addressType == "Primary").flcWage = data;
      //this.view.find(x => x.addressType == "Primary").pwi = data >= this.worksiteForm.get("actualSalary").value ? true : false;
      //this.view.find(x => x.addressType == "Primary").changeType = "UPDATE";
      //this.saveHandler(this.view.find(x => x.addressType == "Primary"));
      return Promise.resolve(data);
    },
      (error) => {
        this.notifier.notify('error', 'Error occured while getting the FLC Wage');
      }
    );
  }

  getFLCWageOnMSAChange() {
    let socCode = this.worksiteForm.get("oessocCode").value;
    let geographyId = this.worksiteForm.get("geographyId").value;
    this._dafService.getFLCWage(geographyId, socCode, this.selectedWageLevelId).toPromise().then((data: any) => {
      let PLCwage = data;
      
      this.worksiteForm.get("prevailingWage").setValue(PLCwage);

      this.view.forEach((key: any, i: number) => {
        if (this.view[i].addressType == "Primary") {
          this.view[i].flcWage = data;
          this.view[i].geography = this.geographySource.find(x => x.id == geographyId).value;
          this.view[i].geographyId = geographyId;
          this.view[i].pwi = PLCwage >= this.view[i].salary ? true : false;
          this.view[i].changeType = "UPDATE";
          this.saveHandler(this.view[i]);
        }
      });

      return Promise.resolve(data);
    },
      (error) => {
        this.notifier.notify('error', 'Error occured while getting the FLC Wage');
      }
    );
  }

  private getDynamicControlValue(formGroup: FormGroup, childFormGroup: string) {
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      console.log(abstractControl);
      if (childFormGroup === 'basisOfClassification') {
        this.workSiteData.basisOfClassification.filter(p => p.shortForm == key)[0].isSelected = abstractControl.value;
      }
      else if (childFormGroup === 'wageLevel') {
        if (abstractControl.value === true) {
          this.workSiteData.wageLevelId = this.wageLevel.filter(p => p.wageLevel == key)[0].wageLevelId;
        }
      }
    });

  }
  private enableDisableControls() {
    if (this.worksiteForm.controls["isInSalaryRange"].value == true) {
      this._dafService.disableEnableFormControl(this.worksiteForm, ["salaryRangeFrom", "salaryRangeTo"], false);
      this.worksiteForm.updateValueAndValidity();
    }
    else {
      this._dafService.disableEnableFormControl(this.worksiteForm, ["salaryRangeFrom", "salaryRangeTo"], true);
      this.formControls['salaryRangeFrom'].setValue(null);
      this.formControls['salaryRangeTo'].setValue(null);
      this.worksiteForm.updateValueAndValidity();
    }
  }
  private enableAdditionalLCA() {
    this._dafService.disableEnableFormControl(this.worksiteForm, ["additionalLCAJobTitle", "additionalLCAOESSOCTitle", "additionalLCAonetOcssId", "additionalLCAPrevailingWage", "additionalLCAactualSalary", "isAdditionalLCAWageRange", "additionalLCASalaryRangeTo", "additionalLCAType"], false);
    this.formControls['additionalLCAJobTitle'].setValue(this.formControls['jobTitle'].value);
    this.worksiteForm.controls["additionalLCAonetOcssId"].setValue(this.formControls['onetOcssId'].value);
    this.formControls['additionalLCAPrevailingWage'].setValue(this.formControls['prevailingWage'].value);
    this.formControls['additionalLCAactualSalary'].setValue(this.formControls['actualSalary'].value);
    this.formControls['isAdditionalLCAWageRange'].setValue(this.formControls['isInSalaryRange'].value);
    this.formControls['additionalLCASalaryRangeFrom'].setValue(this.formControls['salaryRangeFrom'].value);
    this.formControls['additionalLCASalaryRangeTo'].setValue(this.formControls['salaryRangeTo'].value);
  }

  private disableAdditionalLCA() {
    this._dafService.disableEnableFormControl(this.worksiteForm, ["additionalLCAJobTitle", "additionalLCAOESSOCTitle", "additionalLCAonetOcssId", "additionalLCAPrevailingWage", "additionalLCAactualSalary", "isAdditionalLCAWageRange", "additionalLCASalaryRangeTo", "additionalLCAType"], true);
    this.formControls['additionalLCAJobTitle'].setValue("");
    this.formControls['additionalLCAOESSOCTitle'].setValue("");

    this.formControls['additionalLCAonetOcssId'].setValue(null);
    this.formControls['additionalLCAPrevailingWage'].setValue(null);
    this.formControls['additionalLCAactualSalary'].setValue(null);
    this.formControls['isAdditionalLCAWageRange'].setValue(false);
    this.formControls['additionalLCASalaryRangeFrom'].setValue(null);
    this.formControls['additionalLCASalaryRangeTo'].setValue(null);
    this.resetLCAType();
    this.worksiteForm.updateValueAndValidity();

  }

  private additionalLCAWageRangeValueChange() {
    this.worksiteForm.get("isAdditionalLCAWageRange").valueChanges.subscribe(isTrue => {
      if (isTrue) {
        this.enableadditionalLCAWageRange();
      } else {
        this.disableadditionalLCAWageRange();
      }
    });
  }

  private enableadditionalLCAWageRange() {
    this._dafService.disableEnableFormControl(this.worksiteForm, ["additionalLCASalaryRangeFrom", "additionalLCASalaryRangeTo"], false);
  }

  private disableadditionalLCAWageRange() {
    this._dafService.disableEnableFormControl(this.worksiteForm, ["additionalLCASalaryRangeFrom", "additionalLCASalaryRangeTo"], true);
    this.formControls['additionalLCASalaryRangeFrom'].setValue(null);
    this.formControls['additionalLCASalaryRangeTo'].setValue(null);
    this.worksiteForm.updateValueAndValidity();
  }

  private validateLCAData(lcaAssessmentData: any) {
    let isValidationSuccess = true;
    if (lcaAssessmentData.lcaValidityTo < lcaAssessmentData.lcaValidityFrom) {
      this.notifier.notify('error', 'LCA Validity to date cannot be less than from date');
      isValidationSuccess = false;
    }
    if (lcaAssessmentData.isInSalaryRange === true) {
      if (lcaAssessmentData.salaryRangeTo < lcaAssessmentData.salaryRangeFrom) {
        this.notifier.notify('error', 'Salary range to cannot be less than salary range from');
        isValidationSuccess = false;
      }
      else if ((lcaAssessmentData.actualSalary > lcaAssessmentData.salaryRangeTo) ||
        (lcaAssessmentData.actualSalary < lcaAssessmentData.salaryRangeFrom)) {
        this.notifier.notify('error', 'Actual salary should be in between salary from range and salary to range');
        isValidationSuccess = false;
      }

    }
    if (lcaAssessmentData.isAdditionalLCAWageRange === true) {
      if (lcaAssessmentData.additionalLCASalaryRangeTo < lcaAssessmentData.additionalLCASalaryRangeFrom) {
        this.notifier.notify('error', 'Additional LCA salary range to cannot be less than salary range from');
        isValidationSuccess = false;
      }
      else if ((lcaAssessmentData.additionalLCAactualSalary > lcaAssessmentData.additionalLCASalaryRangeTo) ||
        (lcaAssessmentData.additionalLCAactualSalary < lcaAssessmentData.additionalLCASalaryRangeFrom)) {
        this.notifier.notify('error', 'Additional LCA actual salary should be in between salary from range and salary to range');
        isValidationSuccess = false;
      }

    }

    if (this.selectedWageLevelId = null) {
      this.notifier.notify('error', 'Wage Level is mandatory. Please select the value and try again.');
      isValidationSuccess = false;
    }

    return isValidationSuccess;
  }

  private getLCAPrevailingwage(onetId: string, isAdditionalLCA: boolean) {
    this.isPageReady = false;
    let prevailingwage: number = 0;
    let onetCode: string
    if (this.onetocsSDetails.filter(p => p.onetOcssId == onetId) != null) {
      onetCode = this.onetocsSDetails.filter(p => p.onetOcssId == onetId)[0].onetCode;
    }
    this._dafService.getLCAPrevailingwage(this.caseId, onetCode).toPromise().then((data: any) => {
      prevailingwage = data;
      if (isAdditionalLCA) {
        this.worksiteForm.controls["additionalLCAPrevailingWage"].setValue(prevailingwage);
      }
      else {
        this.worksiteForm.controls["prevailingWage"].setValue(prevailingwage);

      }

      this.isPageReady = true;
    },
      (error) => {
        this.isPageReady = true;
      }
    );

    return prevailingwage;
  }

  resetLCAType() {
    var formGroup = this.worksiteForm.get("additionalLCAType") as FormGroup;
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      abstractControl.setValue(false);
    });
  }

  public addHandler(): void {
    var address = new addressWizardInfos();
    var modalData = new worksiteAddressModal();
    address.caseID = "";
    address.caseSummaryID = 0;
    address.addressWizardID = 0,
      address.addressType = "",
      address.entityName = "",
      address.streetNumber = "",
      address.streetName = "",
      address.apt = "",
      address.city = "",
      address.state = "",
      address.postalCode = "",
      address.county = "",
      address.countyOnOLFC = "",
      address.salary = this.worksiteForm.value.actualSalary,
      address.flcWage = 0,
      address.pwi = null

    modalData.oesSOCCode = this.worksiteForm.get("oessocCode").value;

    modalData.wageLevelId = this.selectedWageLevelId ? this.selectedWageLevelId : null;
    modalData.addressWizardInfos = address;
    this.editDataItem = modalData;
    this.isNew = true;
  }

  public editHandler(args: AddEvent): void {
    var modalData = new worksiteAddressModal();
    modalData.oesSOCCode = this.worksiteForm.get("oessocCode").value;
    modalData.wageLevelId = this.selectedWageLevelId ? this.selectedWageLevelId : null;
    modalData.addressWizardInfos = args.dataItem;

    this.editDataItem = modalData;

    this.isNew = false;
  }

  public cancelHandler(): void {
    this.closeEditor();
  }

  private closeEditor(): void {
    this.grid.closeRow(this.editedRowIndex);

    this.isNew = false;
    this.editedRowIndex = undefined;
  }

  public saveHandler(product: addressWizardInfos): void {
    if (this.isNew) {
      this.view.splice(0, 0, product);
      this.modifiedRecords.push(product);
    }
    else {
      Object.assign(
        this.view.find(({ addressWizardID }) => addressWizardID === product.addressWizardID),
        product
      );

      if (this.modifiedRecords.find(({ addressWizardID }) => addressWizardID === product.addressWizardID)) {
        Object.assign(
          this.modifiedRecords.find(({ addressWizardID }) => addressWizardID === product.addressWizardID),
          product
        );

        if (product.addressType == "Primary")
          this.worksiteForm.get("prevailingWage").setValue(product.flcWage);
      }
      else {
        this.modifiedRecords.push(product);
      }
    }

    this.editDataItem = undefined;
    this.isNew = false;
    this.loadItems();
  }

  public setDependentIndexToDelete(args: any) {
    this.deleteMessage = "";
    this.isDeleteClicked = true;
    this.deleteRow = args;
    this.deleteIndex = args.rowIndex;
    if (args.dataItem.addressType == "Primary") {
      this.deleteMessage = "Deleting this record will remove address details from Primary Worksite Address module.";
    }
    this.showDialog = true;
  }

  public deleteDependent() {
    this.view.splice(this.deleteIndex, 1);
    this.deleteRow.dataItem.changeType = "DELETE";
    this.modifiedRecords.push(this.deleteRow.dataItem);
    this.loadItems();
    this.showDialog = false;
  }

  public cancelDelete() {
    this.showDialog = false;
  }

  public onDialogClose() {
    this.showDialog = false;
  }
}
