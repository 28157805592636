import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '@progress/kendo-angular-notification';
import { NotifierService } from 'angular-notifier';
import { RecaptureWizards } from 'src/app/DAF/Models/RecaptureWizards';
import { DafService } from 'src/app/DAF/services/dafService';

@Component({
  selector: 'app-recapture-wizard',
  templateUrl: './recapture-wizard.component.html',
  styleUrls: ['./recapture-wizard.component.scss']
})
export class RecaptureWizardComponent implements OnInit {
  public emailForm: FormGroup;
  public isReady=false;
  public WorkSite="Recapture Info";
  private hideAfter = 2000;
  public WorkSiteDetails: any = [];
  radioButtonOptionValues: Array<any> = [{ value: true, text: "Yes" }, { value: false, text: "No" }];
  public dateDifference=0;
  private notifier: NotifierService;
  public primaryworksiteInfo:RecaptureWizards[]=[];

  tripNumber:number;
  dateExited:Date;
  dateEntered:Date;
  daysOutSideUS:number;
  Notes:string;
  
  private gridData: any[] = [
    {
      "tripNumber": 1,
      "dateExited": null,
      "dateEntered": null,
      "daysOutSideUS": 0,
      "notes": ""
    },
    {
      "tripNumber": 2,
      "dateExited": null,
      "dateEntered": null,
      "daysOutSideUS": 0,
      "notes": ""
    },
    {
      "tripNumber": 3,
      "dateExited": null,
      "dateEntered": null,
      "daysOutSideUS": 0,
      "notes": ""
    }];

  @Input() caseSummaryId = 0;


  @Output() cancel: EventEmitter<undefined> = new EventEmitter();




  constructor(private formBuilder: FormBuilder,@Inject(NotificationService) private notificationService: NotificationService, private _dafService: DafService,notifier: NotifierService) 
  {   
    this.createForm();
    this.notifier = notifier; 
  }

  createForm(){
   this.emailForm = this.formBuilder.group({
     emails: this.formBuilder.array([this.createEmailFormGroup()])
   });
   this.isReady=true;
  }

  ngOnInit(): void {
    this.getprimaryworkSiteItineraryDetails()

  }




  getprimaryworkSiteItineraryDetails(){
    this.isReady = false;
    this._dafService.getprimaryworkSiteItineraryDetails(this.caseSummaryId).toPromise().then((data: any) => {
      this.primaryworksiteInfo = data;

      
      var arrayControl = this.emailForm.get('emails') as FormArray;
      if(this.primaryworksiteInfo.length>0){
      while (arrayControl.length) {
        arrayControl.removeAt(0);
      }
      this.primaryworksiteInfo.forEach(async (staff,i) => {
        arrayControl.push(this.formBuilder.group(staff))
      });
    }
      this.isReady = true;
    },
      (error) => {
        console.log(error)
        this.isReady = true;
      }
    );
  }



  onstartDateChange(event,index){

      var data=this.emailForm.controls.emails['controls'][index].value;
      data.periodFrom=event;
      this.emailForm.controls.emails['controls'][index].setValue(data);
      this.emailForm.controls.emails['controls'][index].updateValueAndValidity();
      this.onStartsDateChange(event,index);
    
  }

  onEndDateChange(event,index){
    var data=this.emailForm.controls.emails['controls'][index].value.periodFrom; 
    if((data!=""&&data!=null) && (event!=""&&event!=null)){
    this.dateDifference = Math.round((event - data)/1000/60/60/24);
    }
    else{
      this.dateDifference=null;
    }
    var data=this.emailForm.controls.emails['controls'][index].value;
    data.daysPerMonth=this.dateDifference;
    this.emailForm.controls.emails['controls'][index].setValue(data);
    this.emailForm.controls.emails['controls'][index].updateValueAndValidity();
    
  }

  public closeForm(): void {
    this.cancel.emit();
}

  onEndDateChanges(event,index){

      var data=this.emailForm.controls.emails['controls'][index].value;
      data.periodTo=event;
      this.emailForm.controls.emails['controls'][index].setValue(data);
      this.emailForm.controls.emails['controls'][index].updateValueAndValidity();
      this.onEndDateChange(event,index);

  }


  
  public onCancel(e: PointerEvent): void {
    e.preventDefault();
    this.closeForm();
}



  onStartsDateChange(event,index){
    var data=this.emailForm.controls.emails['controls'][index].value.periodTo; 
    if((data!=""&&data!=null) && (event!=""&&event!=null)){
      this.dateDifference = Math.round((data - event)/1000/60/60/24);   
       }
      else{
        this.dateDifference=null;
      }
   
    var data=this.emailForm.controls.emails['controls'][index].value;
    data.daysPerMonth=this.dateDifference;
    this.emailForm.controls.emails['controls'][index].setValue(data);
    this.emailForm.controls.emails['controls'][index].updateValueAndValidity();
    }
  


  private createEmailFormGroup(): FormGroup {
    return new FormGroup({
      'recaptureWizard': new FormControl(false,Validators.required),
      'statusStart': new FormControl(''),
      'initialStatusEnd': new FormControl('',Validators.required),
      'recaptureTime': new FormControl('',Validators.required),
      'numberOfYears': new FormControl('',Validators.required),
      'additionalTime': new FormControl('',Validators.required),
      'adjustedStatusEndDate' : new FormControl('')
    })
  }

  public addEmailFormGroup() {
    const emails = this.emailForm.get('emails') as FormArray
    if(emails.length<10){
    emails.push(this.createEmailFormGroup())
    }

  }

  invalidMessage(){
    this.notificationService.show({
      content: "Please ensure to add all the required Data",
      position: { horizontal: "right", vertical: "top" },
      animation: { type: "fade", duration: 800 },
      type: { style: "warning", icon: true },
      hideAfter: this.hideAfter,
    });
  }



}

