// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  //ApiUrl: 'https://localhost:44369/api/',
  ApiUrl: 'https://ares.ey.com/api/',
  //ApiUrl: 'https://ares-ua.ey.com/api/',
  baseURL : 'http://localhost:60658/',
  APIScope: 'api://04b6f4c0-b568-4999-aed3-dccf14db68da/user_impersonation',
  GraphUri: 'https://graph.microsoft.com/',
  FrontEndClientId: '031ff1d7-61c7-43ca-ae5f-68348ef991aa',
  Authority: 'https://login.microsoftonline.com/EYGS.onmicrosoft.com/',
  //RedirectUri: 'https://localhost:44369'
  //RedirectUri: 'https://ares-ua.ey.com'
  RedirectUri: 'https://ares.ey.com'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
