import { Component, Input, OnInit, OnChanges,AfterViewInit, Output , EventEmitter } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() title = null;
  @Input() doughnutCenterTemplate;
  doughnutCenterTemplateString = ""
  showLegend = true;
  @Input() public chartData;
  @Output() seriesClick = new EventEmitter<any>();
  constructor(private router: Router) {

  }
  ngOnInit() {
  }

  ngOnChanges(): void {
    this.doughnutCenterTemplateString = `Total Cases: \n ${this.doughnutCenterTemplate}`;
  }

  ngAfterViewInit(): void {
    // const total = this.chartData.map(x=>x.value).reduce((a,b)=>a+b,0)
    // this.doughnutCenterTemplateString = `Total Cases: \n ${total}`;
  }

  public onSeriesClick(event): void {
    this.seriesClick.emit(event);
  }

}
