import { Component, OnInit } from '@angular/core';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { NotifierService } from 'angular-notifier';
import { LCAService } from '../lca-compliance/service/lca.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from '@progress/kendo-data-query';


@Component({
  selector: 'app-reposting-completed',
  templateUrl: './reposting-completed.component.html',
  styleUrls: ['./reposting-completed.component.scss']
})
export class RepostingCompletedComponent implements OnInit {
  public current = 0;
  public userList: any[] = [];
  public sourceUserList:any[]=[];
  roleData:any[]=[];
  public isPageReady = true;
  public pageSize = 10;
  public status='submitted'
  public skip = 0;
  public gridState: DataStateChangeEvent = {
    skip: this.skip,
    take: this.pageSize
  };
  private readonly notifier: NotifierService;
  public gridViewSearch: any;
  public gridView: any;
  public searchTerm = '';
  public TemplateHeader="";
  dateFilterModel: DateFilterModel;
  public steps = [
      { label: 'Search Page', icon: 'user' },
      { label: 'LCA Assessment Review', icon: 'dictionary-add' },
      { label: 'Reposting Tracker', icon: 'attachment' },

  ];
  constructor(private lcaService: LCAService,private route: ActivatedRoute,private router: Router) {
    this.allData = this.allData.bind(this);
    this.dateFilterModel = new DateFilterModel(new Date(2021, 0, 1), new Date());
   }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.status = params['status'];
      if(this.status){
        this.TemplateHeader = this.status+" Request Details";
        }
         // Loads data to grid
    this.dataStateChange(this.gridState,this.status);
      
  });
  
  }


  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: this.userList
    };
  
    return result;
  }

  editInternalData(workSite: any) {
    this.router.navigate(['/editInternalEY'], { queryParams: { requestId: workSite.requestId,status:this.status } })
  }

  GotoBack(){
    this.router.navigate(['/internalLca'])
  }



  updateUserList() {
    this.dataStateChange(this.gridState,this.status);
  }
  dataStateChange(state: DataStateChangeEvent,status:string) {
    this.isPageReady = false;
    this.gridState = state;

    // Gets data from api
    this.sourceUserList=[];
    this.lcaService.searchRepostingData(this.searchTerm, this.status, this.dateFilterModel.startDate.toDateString(), this.dateFilterModel.endDate.toDateString()).subscribe((data: any) => {
      // Binds the data to grid
      this.gridViewSearch = data;
      this.sourceUserList=data;
      let filterData = this.gridViewSearch;

      this.gridView = process(filterData, this.gridState);
      this.userList = data;

      this.isPageReady = true;
    },
    (error) => {
      this.notifier.notify('error', 'Error occured while getting data from server');
      this.isPageReady = true;
    }
    );
  }

  applyDateFilter() {
    this.dataStateChange(this.gridState, this.status);
  }

  resetFilter() {
    this.dateFilterModel = new DateFilterModel(new Date(2022, 0, 1), new Date());
    this.dataStateChange(this.gridState, this.status);
  }
}
export class DateFilterModel {
  public startDate: Date;
  public endDate: Date;
  constructor(startDate: Date, endDate: Date) {
    this.startDate = startDate;
    this.endDate = endDate;
  }
}
