export class WorkExperience{
     workExperienceWizardID : number;
     caseID : string;
    caseSummaryID :number;
     employerName :string;
     jobTitle : string;
    fromDates : Date;
    toDates : Date;
     totalNumberOfDays :string;
     years :string;
     months :string;
     days :string;
}