import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { CaseFeesDetailModel, h1BFilingType, jobPositionDetails } from 'src/app/DAF/Models/JobPositionDetails';
import { DafService } from 'src/app/DAF/services/dafService';

@Component({
  selector: 'app-daf-case-fees',
  templateUrl: './daf-case-fees.component.html',
  styleUrls: ['./daf-case-fees.component.scss']
})
export class DafCaseFeesComponent implements OnInit {

  caseFeesForm: FormGroup;
 
  caseId: number;
  caseFeeDetails: CaseFeesDetailModel;
  public isPageReady = true;
  isEditEnabled: boolean;
  requestedAction: any = [];
  @Input() caseSummaryId = 0;
  private notifier: NotifierService;
  public notes;
  constructor(private formBuilder: FormBuilder,
    private _dafService: DafService, notifier: NotifierService) { this.notifier = notifier; }



  ngOnInit() {
    this.getCaseFeeDetails();
  }

  addDynamicControls(): void {

    this.requestedAction.forEach(key => {
      (this.caseFeesForm.get('requestedAction') as FormGroup).addControl(key.fees, new FormControl({ value: key.isSelected, disabled: true }))
    })
  }
  resetForm() {
    try {
      this.getCaseFeeDetails();
      this.isEditEnabled = false;
      this._dafService.disableEnableAllFormControls(this.caseFeesForm, [], true);
    }
    catch (err) {
      console.log(err);
    }
  }

  getCaseFeeDetails() {
    this.isPageReady = false;
    this._dafService.getCaseFeesByCaseSummaryId(this.caseSummaryId).toPromise().then((data: any) => {
      this.caseFeeDetails = data;
      this.requestedAction = this.caseFeeDetails.caseFeesDetails;
      
      this.initializeForm();
      this.addDynamicControls();
    },
      (error) => {
      }
    );
  }




  initializeForm() {

    try {
      this.caseFeesForm = this.formBuilder.group({
        requestedAction: this.formBuilder.group({}),
        notes: new FormControl({ value: this.caseFeeDetails.notes, disabled: true }),
      });
      this.isPageReady = true;

    }
    catch (err) {
      console.log(err);
    }
  }



  public saveCaseSummaryData() {
    this.readFormValues(this.caseFeesForm);

    this.isPageReady = false;

    //var caseFeesArray = [...this.caseFeeDetails.caseFeesDetails];

    this.caseFeeDetails.caseFeesDetails = this.caseFeeDetails.caseFeesDetails.map(obj => {
     
      return { ...obj, fees: obj.fees.replace('$', 'USD') };

      return obj;
    });
    debugger;
    this._dafService.saveCaseFeesDetails(this.caseSummaryId, this.caseFeeDetails, this.caseFeesForm.value.notes).toPromise().then(() => {
      this._dafService.disableEnableAllFormControls(this.caseFeesForm, [], true);
      this.getCaseFeeDetails();
      this.isEditEnabled = false;

      this.isPageReady = true;
      this.notifier.notify('success', 'Case Fees Details have been saved successfully');
    }).catch((err) => {
      this.notifier.notify('error', 'Operation Failed');
      this.isPageReady = true;
    });

  }

  private readFormValues(group: FormGroup) {
    Object.keys(this.caseFeesForm.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.getDynamicControlValue(abstractControl, key);
      }
      else {
        this.caseFeeDetails[key] = abstractControl.value;
      }
    });
  }

  private getDynamicControlValue(formGroup: FormGroup, childFormGroup: string){

    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      console.log(abstractControl);     
     
      if (childFormGroup === 'requestedAction') {
        this.caseFeeDetails.caseFeesDetails.filter(p => p.fees == key)[0].isSelected = abstractControl.value;
      }
      
    });

  }



  get formControls() {
    return this.caseFeesForm.controls;
  }
  enableEdit() {
    try {
      this.isEditEnabled = true;
      this._dafService.disableEnableAllFormControls(this.caseFeesForm, ["salaryRangeFrom", "salaryRangeTo"], false);

    }
    catch (err) {
      console.log(err);
    }
  }
}
