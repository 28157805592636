export namespace dafAppConstants{
    export class caseStatus {
        static initialted: string = "Initiated";
        static drafted: string = "Case Drafted";
        static supportReview: string = "Support Review";
        static attorneyReview: string = "Attorney Review";
        static caseManaerReview: string = "Case Manager Review";
        static onHold: string = "On Hold";
        static approved: string = "Approved";
        static rejected: string = "Rejected";
    }

    export class sessionStorageVariables{
        static currentStatus: string = "caseStatus";
        static currentCaseType : string = "caseType";
        static currentCaseSummaryId : string = "caseSummaryId";
    }
}