import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { relationDetail } from 'src/app/DAF/Models/SpouseAndDependentDetails';

@Component({
  selector: 'kendo-grid-edit-form',
  templateUrl: './kendo-grid-edit-form.component.html',
  styleUrls: ['./kendo-grid-edit-form.component.scss'],
  styles: [
    `
        input[type='text'] {
            width: 100%;
        }
        .k-inline-checkbox {
            display: inline-flex;
        }
    `
]
})
export class KendoGridEditFormComponent {

  public active = false;
  private notifier: NotifierService;
  public relationTypeList = [
    {
      relationId: 1,
      relationType: 'Dependents'
    },
    {
      relationId: 2,
      relationType: 'Spouse'
    }
  ];
  radioButtonOptionValues: Array<any> = [{ value: true, text: "Yes" }, { value: false, text: "No" }];

    public editForm: FormGroup = new FormGroup({
      relationId: new FormControl(0, Validators.required),
      firstName: new FormControl('', Validators.required),
      middleName: new FormControl(''),
      lastName: new FormControl(''),
      isSpouseEmployedFollowingLatestEntry: new FormControl(false),
      i539Required: new FormControl(false),
      i765Required: new FormControl(false),
      i131Required: new FormControl(false),
      spouseDepAppID: new FormControl(0),
    });

    

    @Input() public isNew = false;
    @Input() public isSpouse = false;

    @Input() public set model(product: relationDetail) {
        this.editForm.reset(product);

        // toggle the Dialog visibility
        this.active = product !== undefined;
    }

    @Output() cancel: EventEmitter<undefined> = new EventEmitter();
    @Output() save: EventEmitter<relationDetail> = new EventEmitter();

    constructor(notifier: NotifierService) {this.notifier = notifier;}

    public onSave(e: PointerEvent): void {
        e.preventDefault();
        if(this.isSpouse&&this.editForm.controls.relationId.value==3){
        this.notifier.notify('error', 'Spouse Can only be selected Once');
        }
        else{
        this.save.emit(this.editForm.value);
        this.active = false;
        }
    }

    public onCancel(e: PointerEvent): void {
        e.preventDefault();
        this.closeForm();
    }


    public closeForm(): void {
        this.active = false;
        this.cancel.emit();
    }

}
