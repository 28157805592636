export class Lawyer {
   lawyerId: number;
   name: string;
   email: string;
   status: boolean;
   createdBy: string;
   createdDate: Date;
   modifiedBy: string;
   modifiedDate: Date;
}
