export class ExportParams {
  FilterParams: any[] = [];
  PagingParams: PagingParams;
  SortParams: SortParams;
  FromDate: any;
  ToDate: any
}

export class CompositeFilterDescriptor {
  Logic: string
  Filters: object[];
}


export class PagingParams {
  PageNumber: number;
  PageSize: number
}

export class SortParams {
  Direction: string;
  Field: string;
}
