export class LcaData {
    id: number;
    lcA_Number: string;
    lcA_Validity_StartDate:Date;
    lcA_ExpiryDate: Date;
    lcA_Primary_Work_City: boolean;
    lcA_Primary_Work_Sheet: string;
    lcA_Primary_Work_State: Date;
    lcA_Primary_Work_Country: string;
    lcA_Primary_Work_ZipCode: Date;
    lcA_Addl_Primary_Work_1_City: boolean;
    lcA_Addl_Primary_Work_1_Sheet: string;
    lcA_Addl_Primary_Work_1_State: Date;
    clcA_Addl_Primary_Work_1_ZipCode: string;
    lcA_Addl_Primary_Work_2_City: Date;
    lcA_Addl_Primary_Work_2_Sheet: boolean;
    lcA_Addl_Primary_Work_2_State: string;
    lcA_Addl_Primary_Work_2_ZipCode: Date;
 }

 export class WorkSiteData{
     county:string;
     startDate:Date;
     expiryDate :Date;
     zipCode :string;
     streetAddress :string;
     streetNumber:string;
     houseNumber:string;
     houseName:string;
     city:string;
     clientName: string;
     workFrom?:Date;
     workTo?:Date;
     toalDays: string;
     telePhone : string;
     engagementCode: string;
     interactionId: string;
     engagementPartner:string;
     experienceManager: string;
     state:string;
     addressType:string;
 }

 export class WorkSiteInfo{
     workSiteData:WorkSiteData[];
 }


 export class MissingLCA {
    caseId:string;
    lcaNumber:string;
    county:string;
    startDate:Date;
    expiryDate :Date;
    zipCode :string;
    streetAddress :string;
    state:string;
    city:string;
}

export class WorkSiteRequestCount{
    ticketStatus:string;
    count:number=0;
}

export class FinalDetermination{
    requestId : string;
    finalDeterminations : string;
    remarks : string;
    lawyer: string;
    caseManager: string;
    jobTitle: string;
    degree: string;
    socCode: string;
    wageLevel: string;
}
