import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, Data, PRIMARY_OUTLET, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs = [];
  loadBreadcrumb = false;

  constructor(public router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }))
      .pipe(filter(route => route.outlet === PRIMARY_OUTLET))
      .subscribe(route => {
        this.loadBreadcrumb = false;

        let snapshot = this.router.routerState.snapshot;
        let index = 0;

        let url = snapshot.url.split('?')[0];
        let routeData = route.snapshot.data;

        let label = routeData['breadcrumb'];
        let params = snapshot.root.queryParams;
        index = this.breadcrumbs.findIndex(item => item.label == label);
        if (label != 'Home' && label != '') {
          if (index > -1) {
            this.breadcrumbs.splice(index + 1, this.breadcrumbs.length - index);
          }
          else {

            this.breadcrumbs.push({
              url: url,
              label: label,
              params: params
            });
          }
        }
        else {
          this.breadcrumbs = [];
        }

        this.loadBreadcrumb = true;
      });
  }


  ngOnInit() {


  }



}
