import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CellClickEvent, CreateFormGroupArgs, GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { NotificationService } from '@progress/kendo-angular-notification';
import { NotifierService } from 'angular-notifier';
import { Subscription } from 'rxjs';
import { addressWizardInfos } from 'src/app/DAF/Models/addressWizardInfos';
import { DafService } from 'src/app/DAF/services/dafService';

@Component({
  selector: 'app-address-info-wizard',
  templateUrl: './address-info-wizard.component.html',
  styleUrls: ['./address-info-wizard.component.scss']
})
export class AddressInfoWizardComponent implements OnInit {
  public emailForm: FormGroup;
  public isReady=false;
  addressInfo=[];
  public view = [];
  public pageSize = 5;
  public skip = 0;
  public gridView: GridDataResult;


  
  @Input() caseSummaryId = 0;
  public formGroup: FormGroup;
  public addressDetails=[];
  private notifier: NotifierService;
  public WorkSite="Address Info Wizard";




  @Output() cancel: EventEmitter<string> = new EventEmitter<string>();
  constructor(private formBuilder: FormBuilder,@Inject(NotificationService) private notificationService: NotificationService, private _dafService: DafService,notifier: NotifierService) {
    this.notifier = notifier; 

    this.createFormGroup = this.createFormGroup.bind(this);
   }

  ngOnInit(): void {
    this.getAddressInformation();
  }

  


  public createFormGroup(args: CreateFormGroupArgs): FormGroup {
    const item = args.isNew ? new addressWizardInfos() : args.dataItem;

    this.formGroup = this.formBuilder.group({
      addressWizardID: item.addressWizardID,
      addressType: item.addressType,
      entityName: item.entityName,
      streetNumber: item.streetNumber,
      streetName: item.streetName,
      apt: item.apt,
      city: item.city,
      state: item.state,
      postalCode: item.postalCode,
      county: item.county,
      countyOnOLFC:item.countyOnOLFC,
      salary: item.salary,
      flcWage: item.flcWage,
      pwi: item.pwi
    });

    return this.formGroup;
  }

  saveData(){
    this.addressDetails=[];
    var saveValues = [];

     
   
    this.addressInfo.forEach(item => {
      
     
      var address=new addressWizardInfos();
      address.caseID="";
      address.caseSummaryID=0;
      address.addressWizardID=0,
      address.addressType= item.addressType,
      address.entityName= item.entityName,
      address.streetNumber= item.streetNumber,
      address.streetName= item.streetName,
      address.apt= item.apt,
      address.city= item.city,
      address.state= item.state,
      address.postalCode= item.postalCode,
      address.county= item.county,
      address.countyOnOLFC=item.countyOnOLFC,
      address.salary= item.salary,
      address.flcWage= item.flcWage,
      address.pwi= item.pwi
      this.addressDetails.push(address);
      
    });
    if(this.addressDetails.length>0){
      this._dafService.saveAddressWizard(this.caseSummaryId, this.addressDetails).toPromise().then(() => {
        this.notifier.notify('success', 'Address details have been saved successfully');
      }).catch((err) => {
        this.notifier.notify('error', 'Operation Failed');
      });


    }
  
  }


  onLcaAdd(){
    this.cancel.emit("Lca");
  }


  public onCancel(e: PointerEvent): void {
    e.preventDefault();
    this.closeForm();
}

public closeForm(): void {
  this.cancel.emit("Close");
}


  getAddressInformation(){
    this.isReady = false;
    this._dafService.getAddressWizardInformation(this.caseSummaryId).toPromise().then((data: any) => {
      this.addressInfo = data;
      if(this.addressInfo.length==0){
        for(let i=0;i<3;i++){
          var newAddress= new addressWizardInfos();
          newAddress.caseSummaryID=this.caseSummaryId;
          this.addressInfo.push(newAddress);
        }
        this.addressInfo[0].addressType="Primary";
        this.addressInfo[1].addressType="Additional";
        this.addressInfo[2].addressType="Secondary";

      }
      this.view = this.addressInfo;


      this.loadItems();
      this.isReady = true;
    },
      (error) => {
        console.log(error)
        this.isReady = true;
      }
    );
  }

  private loadItems(): void {
    this.gridView = {
      data: this.view.slice(this.skip, this.skip + this.pageSize),
      total: this.view.length,
    };
  }

}
