export class QuestAddModel {
  constructor(

    public createdDate = new Date(),
    public createdDateString = "",
    public documentName = "",
    public status = "",
    public comments = ""
  ) { }
}
