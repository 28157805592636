export class QuestModel {
  constructor(

    public createdDate = new Date(),
    public createdDateString = "",
    public section = "",
    public subSection = "",
    public subsubSection = "",
    public comment = ""
  ) { }
}
