import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { basisOfClassification, lcaAssessmentDetails, wageLevel, worksiteAddressModal } from 'src/app/DAF/Models/LCAAssessmentDetails';
import { DafService } from 'src/app/DAF/services/dafService';
import { NotifierService } from 'angular-notifier';
import { AddEvent, CreateFormGroupArgs, GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { addressWizardInfos } from 'src/app/DAF/Models/addressWizardInfos';

@Component({
  selector: 'app-lca-assessment',
  templateUrl: './lca-assessment.component.html',
  styleUrls: ['./lca-assessment.component.scss']
})
export class LcaAssessmentComponent implements OnInit {

  @Input() caseSummaryId = 0;
  lcaAssessmentForm: FormGroup;
  public isPageReady = true;
  private lcaAssessmentDetails: lcaAssessmentDetails = new lcaAssessmentDetails();
  basisOfClassification: Array<basisOfClassification> = [];
  wageLevel: any = [];
  additionalLCAType: Array<basisOfClassification> = [];
  isEditEnabled: boolean;
  radioButtonOptionValues: Array<any> = [{ value: true, text: "Yes" }, { value: false, text: "No" }];
  radiobasisValues: Array<any> = [{ value: 1, text: "New" }, { value: 2, text: "Continuation" }, { value: 3, text: "Change" }, { value: 4, text: "Concurrent" }, { value: 5, text: "COE" }, { value: 6, text: "Amendment" }];
  onetocsSDetails: any;
  onetocsSTitle: any;
  onetocsSCode: any;
  statesList: any;
  stateMaster: any;
  zipCodeList: any;
  zipCodeMaster: any;
  additionalLCAONETOCSSTitle: any;
  additionalLCAONETOCSSCode: any;
  private salaryFrom: number;
  private salaryTo: number;
  private notifier: NotifierService;
  public formGroup: FormGroup;
  public isGridEditable: boolean;
  showDialog: boolean = false;
  deleteIndex: number;
  addressInfo = [];
  public view = [];
  public pageSize = 5;
  public skip = 0;
  public gridView: GridDataResult;
  public addressDetails = [];
  public dotCodeList = [];
  //public editDataItem: addressWizardInfos;
  public editDataItem : worksiteAddressModal;
  private isNew: boolean;
  private editedRowIndex: number;
  private grid: GridComponent;
  public modifiedRecords: Array<addressWizardInfos> = [];
  public deleteRow: any;
  private isDeleteClicked: boolean = false;
  public selectedWageLevelId: any;
  public deleteMessage;
  lcaValidityFrom: any;
  lcaValidityTo: any;

  constructor(private formBuilder: FormBuilder,
    private _dafService: DafService,
    notifier: NotifierService) {
      this.notifier = notifier;
    //this.createFormGroup = this.createFormGroup.bind(this);
  }

  formErrors = {
    'jobTitle': '',
    'salaryRangeFrom': '',
    'salaryRangeTo': '',
    'secondaryEntityName': '',
    'stateId': '',
    'postalCodeId': '',
    'streetName': '',
    'streetNumber': '',
    'apartment': '',
    'additionalLCAJobTitle': '',
    'additionalLCASalaryRangeFrom': '',
    'additionalLCASalaryRangeTo': '',
    'additionalLCAonetOcssId': '',
    'Isbasis': ''

  };

  validationMessages = {
    'jobTitle': {
      'required': 'Job Title is required.',
    },
    'secondaryEntityName': {
      'required': 'Secondary Entity Name is required.',
    },
    'salaryRangeFrom': {
      'required': 'Salary From Range is required.',
    },
    'salaryRangeTo': {
      'required': 'Salary To Range is required.',
    },
    'stateId': {
      'required': 'State is required.',
    },
    'postalCodeId': {
      'required': 'Postal code is required.',
    },
    'streetName': {
      'required': 'Stree Name is required.',
    },
    'streetNumber': {
      'required': 'Street Number is required.',
    },
    'apartment': {
      'required': 'Apartment is required.',
    },
    'additionalLCAJobTitle': {
      'required': 'Job Title is required.',
    },
    'additionalLCASalaryRangeFrom': {
      'required': 'Salary Range From is required.',
    },
    'additionalLCASalaryRangeTo': {
      'required': 'Salary Range To is required.',
    },
    'additionalLCAonetOcssId': {
      'required': 'SOC/OES Code is required.',
    }
  };

  ngOnInit() {
    this.wageLevel = [
      { lcaWageLevelId: 1, wageLevelId: 1, wageLevel: "I", isSelected: false },
      { lcaWageLevelId: 2, wageLevelId: 2, wageLevel: "II", isSelected: false },
      { lcaWageLevelId: 3, wageLevelId: 3, wageLevel: "III", isSelected: false },
      { lcaWageLevelId: 4, wageLevelId: 4, wageLevel: "IV", isSelected: false },
    ]
    this.getStates();
    this.getetONETOCSSDetails();
    this.getLCAAssessmentDetails();
    this.getAddressInformation();
  }

  public resetForm() {
    try {
      this.isEditEnabled = false;
      this.getLCAAssessmentDetails();
      this.isGridEditable = false;
      this._dafService.disableEnableAllFormControls(this.lcaAssessmentForm, [], true);
    }
    catch (err) {
      console.log(err);
    }
  }

  //public createFormGroup(args: CreateFormGroupArgs): FormGroup {
  //  const item = args.isNew ? new addressWizardInfos() : args.dataItem;

  //  this.formGroup = this.formBuilder.group({
  //    addressWizardID: item.addressWizardID,
  //    addressType: item.addressType,
  //    entityName: item.entityName,
  //    streetNumber: item.streetNumber,
  //    streetName: item.streetName,
  //    apt: item.apt,
  //    city: item.city,
  //    state: item.state,
  //    postalCode: item.postalCode,
  //    county: item.county,
  //    countyOnOLFC:item.countyOnOLFC,
  //    salary: item.salary,
  //    flcWage: item.flcWage,
  //    pwi: item.pwi
  //  });

  //  return this.formGroup;
  //}

  getLCAAssessmentDetails() {
    this.isPageReady = false;
    this._dafService.getLCAAssessmentDetails(this.caseSummaryId).toPromise().then((data: any) => {
      this.lcaAssessmentDetails = data;
      this.basisOfClassification = this.lcaAssessmentDetails.basisOfClassification;
      this.selectedWageLevelId = this.lcaAssessmentDetails.wageLevelId;
      this.initializeForm();
      this.additionalLCAType = this.lcaAssessmentDetails.additionalLCAType;
      if (this.lcaAssessmentDetails.stateId > 0) {
        this.getZipcodeByState(this.lcaAssessmentDetails.stateId);
      }
      if (this.wageLevel.filter(p => p.wageLevelId == this.lcaAssessmentDetails.wageLevelId).length > 0) {
        this.wageLevel.filter(p => p.wageLevelId == this.lcaAssessmentDetails.wageLevelId)[0].isSelected = true;
      }
      
    },
      (error) => {
      }
    );
  }


  public enableEdit() {
    try {
      this.isEditEnabled = true;
      this._dafService.disableEnableAllFormControls(this.lcaAssessmentForm, [], false);
      this.enableDisableControls();
      this.isGridEditable = true;
    }
    catch (err) {
      console.log(err);
    }
  }



  addDynamicControls(): void {

    this.basisOfClassification.forEach(key => {
      console.log(key);
      (this.lcaAssessmentForm.get('basisOfClassification') as FormGroup).addControl(key.shortForm, new FormControl({ value: key.isSelected, disabled: true }))
    });
    
    this.wageLevel.forEach(key => {
      console.log(key);
      
      (this.lcaAssessmentForm.get('wageLevel') as FormGroup).addControl(key.wageLevel, new FormControl({ value: key.isSelected, disabled: true }))
    })

    this.additionalLCAType.forEach(key => {
      console.log(key);
      (this.lcaAssessmentForm.get('additionalLCAType') as FormGroup).addControl(key.shortForm, new FormControl({ value: key.isSelected, disabled: true }))
    })

  }

  async initializeForm() {
    if (this.lcaAssessmentDetails.onetOcssId)
      await this.getDotCodeBySOCCd(this.lcaAssessmentDetails.onetOcssId);

    let decodedNotes = this.decodeHTMLEntities(this.lcaAssessmentDetails.assessmentNotes);
    try {
      this.lcaAssessmentForm = this.formBuilder.group({
        basisOfClassification: this.formBuilder.group({}),
        wageLevel: this.formBuilder.group({}),
        additionalLCAType: this.formBuilder.group({}),
        jobTitle: new FormControl({ value: this.lcaAssessmentDetails.jobTitle, disabled: true }, Validators.required),
        oesSOCTitle: new FormControl({ value: this.lcaAssessmentDetails.oesSOCTitle, disabled: true }),
        onetOcssId: new FormControl({ value: this.lcaAssessmentDetails.onetOcssId, disabled: true }, Validators.required),
        oessocCode: new FormControl({ value: this.lcaAssessmentDetails.oessocCode, disabled: true }),
        lcaValidityFrom: new FormControl({ value: this.lcaAssessmentDetails.lcaValidityFrom ? new Date(this.lcaAssessmentDetails.lcaValidityFrom) : null, disabled: true }),
        lcaValidityTo: new FormControl({ value: this.lcaAssessmentDetails.lcaValidityTo ? new Date(this.lcaAssessmentDetails.lcaValidityTo) : null, disabled: true }),
        dotCode: new FormControl({ value: this.lcaAssessmentDetails.dotCode, disabled: true }),
        wageSourceAndYear: new FormControl({ value: this.lcaAssessmentDetails.wageSourceAndYear, disabled: true }),
        prevailingWage: new FormControl({ value: this.lcaAssessmentDetails.prevailingWage ? this.lcaAssessmentDetails.prevailingWage : 0, disabled: true }, Validators.required),
        actualSalary: new FormControl({ value: this.lcaAssessmentDetails.actualSalary, disabled: true }, Validators.required),
        isInSalaryRange: new FormControl({ value: this.lcaAssessmentDetails.isInSalaryRange, disabled: true }),
        salaryRangeFrom: new FormControl({ value: this.lcaAssessmentDetails.salaryRangeFrom, disabled: true }, Validators.required),
        salaryRangeTo: new FormControl({ value: this.lcaAssessmentDetails.salaryRangeTo, disabled: true }, Validators.required),
        emailAddressForLCA: new FormControl({ value: this.lcaAssessmentDetails.emailAddressForLCA ? this.lcaAssessmentDetails.emailAddressForLCA : "certified.lca@ca.ey.com", disabled: true , }),
        headcount: new FormControl({ value: this.lcaAssessmentDetails.headcount ? this.lcaAssessmentDetails.headcount : 1, disabled: true }, Validators.required),
        additionalWorksite: new FormControl({ value: this.lcaAssessmentDetails.additionalWorksite, disabled: true }),
        isSecondaryEntity: new FormControl({ value: this.lcaAssessmentDetails.isSecondaryEntity, disabled: true }),
        secondaryEntityName: new FormControl({ value: this.lcaAssessmentDetails.secondaryEntityName, disabled: true }, Validators.required),
        streetNumber: new FormControl({ value: this.lcaAssessmentDetails.streetNumber, disabled: true }, Validators.required),
        streetName: new FormControl({ value: this.lcaAssessmentDetails.streetName, disabled: true }, Validators.required),
        apartment: new FormControl({ value: this.lcaAssessmentDetails.apartment, disabled: true }, Validators.required),
        cityName: new FormControl({ value: this.lcaAssessmentDetails.cityName, disabled: true }),
        stateId: new FormControl({ value: this.lcaAssessmentDetails.stateId, disabled: true }, Validators.required),
        postalCodeId: new FormControl({ value: this.lcaAssessmentDetails.postalCodeId, disabled: true }, Validators.required),
        county: new FormControl({ value: this.lcaAssessmentDetails.county, disabled: true }),
        countryOnOLFC: new FormControl({ value: this.lcaAssessmentDetails.countryOnOLFC, disabled: true }),
        isAdditionalLCA: new FormControl({ value: this.lcaAssessmentDetails.isAdditionalLCA, disabled: true }),
        additionalLCAJobTitle: new FormControl({ value: this.lcaAssessmentDetails.additionalLCAJobTitle, disabled: true }, Validators.required),
        additionalLCAOESSOCTitle: new FormControl({ value: this.lcaAssessmentDetails.additionalLCAOESSOCTitle, disabled: true }),
        additionalLCAonetOcssId: new FormControl({ value: this.lcaAssessmentDetails.additionalLCAonetOcssId, disabled: true }, Validators.required),
        additionalLCAPrevailingWage: new FormControl({ value: this.lcaAssessmentDetails.additionalLCAPrevailingWage, disabled: true }),
        additionalLCAactualSalary: new FormControl({ value: this.lcaAssessmentDetails.additionalLCAactualSalary, disabled: true }),
        isAdditionalLCAWageRange: new FormControl({ value: this.lcaAssessmentDetails.isAdditionalLCAWageRange, disabled: true }),
        additionalLCASalaryRangeFrom: new FormControl({ value: this.lcaAssessmentDetails.additionalLCASalaryRangeFrom, disabled: true }, Validators.required),
        additionalLCASalaryRangeTo: new FormControl({ value: this.lcaAssessmentDetails.additionalLCASalaryRangeTo, disabled: true }, Validators.required),
        assessmentNotes: new FormControl({ value: decodedNotes, disabled: true }),
        geography: new FormControl({ value: this.lcaAssessmentDetails.geography, disabled: true }),
        isbasis: new FormControl({ value: this.lcaAssessmentDetails.isbasis, disabled: true }),
        //wageLevelId: new FormControl({ value: this.lcaAssessmentDetails.wageLevelId, disabled: true })
      });

      this.addDynamicControls();
      this.isPageReady = true;
      this.subscribeToFormControlValueChange();

      this.lcaAssessmentForm.valueChanges.subscribe((val => {
        this.logOnChangeValidationErrors();
      }));
    }
    catch (err) {
      console.log(err);
    }
  }

  getetONETOCSSDetails() {
    this.isPageReady = false;
    this._dafService.getetONETOCSSDetails().toPromise().then((data: any) => {
      this.onetocsSDetails = data;
      this.onetocsSTitle = data;
      this.onetocsSCode = data;
      this.additionalLCAONETOCSSTitle = data;
      this.additionalLCAONETOCSSCode = data;
      console.log(this.onetocsSDetails);

    },
      (error) => {
      }
    );
  }

  getStates() {
    this._dafService.getDAFStates().toPromise().then((data: any) => {
      this.statesList = data;
      this.stateMaster = data;
    },
      (error) => {
      }
    );
  }

  getDotCodeBySOCCd(event) {
    if (this.lcaAssessmentForm)
      this.lcaAssessmentForm.controls["dotCode"].setValue(null);
    this._dafService.getDotCodeBySOCCd(event).toPromise().then((data: any) => {
      this.dotCodeList = data;
    },
      (error) => {
      }
    );
  }

  getZipcodeByState(stateId: number) {
    this.isPageReady = false;
    this._dafService.getZipcodeByState(stateId).toPromise().then((data: any) => {
      this.zipCodeList = data;
      this.zipCodeMaster = data;
      this.isPageReady = true;
    },
      (error) => {
        this.isPageReady = true;
      }
    );
  }

  getCountyAndCityInfoByZipCode(zip: any) {
    this.isPageReady = false;
    this._dafService.getCountyAndCityInfoByZipCode(zip).toPromise().then((data: any) => {
      if (data != null && data.length > 0) {
        this.lcaAssessmentForm.controls["cityName"].setValue(data[0].cityName);
        this.lcaAssessmentForm.controls["county"].setValue(data[0].countyName);
        this.lcaAssessmentDetails.cityId = data[0].cityId;
        this.lcaAssessmentForm.updateValueAndValidity();
      }
      else {
        this.lcaAssessmentForm.controls["cityName"].setValue(null);
        this.lcaAssessmentForm.controls["county"].setValue(null);
        this.lcaAssessmentForm.updateValueAndValidity();
      }
      this.isPageReady = true;

    },
      (error) => {
        this.isPageReady = true;
      }
    );
  }

  public onOESSOCTitlevalueChange(event) {
    this.lcaAssessmentForm.controls["onetOcssId"].setValue(event.onetOcssId);
    //this.getLCAPrevailingwage(event, false);
    this.getDotCodeBySOCCd(event.onetOcssId);
    this.lcaAssessmentForm.updateValueAndValidity

    let socCode = event.onetCode;
    this.lcaAssessmentForm.get("oessocCode").setValue(socCode);

    this.view.forEach((key: any, i: number) => {
      this.getFLCWage(key.geographyId, socCode, this.selectedWageLevelId, i);
    });
  }

  public onOESSOCTitleFilterChange(event) {
    this.onetocsSTitle = this.onetocsSDetails.filter(x => x.onetTitle.toLowerCase().includes(event.toLowerCase()));

  }

  public onOESSOCCodevalueChange(event) {
    this.lcaAssessmentForm.controls["onetOcssId"].setValue(event.onetOcssId);
    //this.getLCAPrevailingwage(event, false);
    this.getDotCodeBySOCCd(event.onetOcssId);
    this.lcaAssessmentForm.updateValueAndValidity();

    let socCode = event.onetCode;
    this.lcaAssessmentForm.get("oessocCode").setValue(socCode);
    //let geographyId = this.addressInfo.filter(x => x.addressType == "Primary")[0].geographyId;

    this.view.forEach((key: any, i:number) => {
      this.getFLCWage(key.geographyId, socCode, this.selectedWageLevelId, i);
    });
    //if (this.selectedWageLevelId && socCode && geographyId) {
    //  this.getFLCWage(geographyId, socCode, this.selectedWageLevelId);
    //}
  }

  public onAdditionalLCAOESSOCTitlevalueChange(event) {
    this.lcaAssessmentForm.controls["additionalLCAonetOcssId"].setValue(event);
    //this.getLCAPrevailingwage(event, true);
    this.lcaAssessmentForm.updateValueAndValidity();

  }

  public onAdditionalLCAOESSOCCodevalueChange(event) {
    this.lcaAssessmentForm.controls["additionalLCAonetOcssId"].setValue(event);
    this.getLCAPrevailingwage(event, true);
    this.lcaAssessmentForm.updateValueAndValidity();

  }

  public onOESSOCCodeFilterChange(event) {
    this.onetocsSCode = this.onetocsSDetails.filter(x => x.onetCode.toLowerCase().includes(event.toLowerCase()));

  }

  getAddressInformation() {
    this.isPageReady = false;
    this._dafService.getAddressWizardInformation(this.caseSummaryId).toPromise().then((data: any) => {
      this.addressInfo = data;
      //if(this.addressInfo.length==0){
      //  for(let i=0;i<3;i++){
      //    var newAddress= new addressWizardInfos();
      //    newAddress.caseSummaryId=this.caseSummaryId;
      //    this.addressInfo.push(newAddress);
      //  }
      //  this.addressInfo[0].addressType="Primary";
      //  this.addressInfo[1].addressType="Additional";
      //  this.addressInfo[2].addressType="Secondary";

      //}
      this.view = this.addressInfo;


      this.loadItems();
      this.isPageReady = true;
    },
      (error) => {
        this.isPageReady = true;
      }
    );
  }

  private loadItems(): void {
    this.gridView = {
      data: this.view.slice(this.skip, this.skip + this.pageSize),
      total: this.view.length,
    };
  }

  public stateFilterChange(event) {
    this.statesList = this.stateMaster.filter(x => x.stateName.toLowerCase().includes(event.toLowerCase()));
  }

  public zipFilterChange(event) {
    this.zipCodeList = this.zipCodeMaster.filter(x => x.zipcode.toLowerCase().includes(event.toLowerCase()));
  }

  get formControls() {
    return this.lcaAssessmentForm.controls;
  }

  isAllSelected(item) {
    var formGroup = this.lcaAssessmentForm.get("wageLevel") as FormGroup;
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      if (key === item.key) {
        let currValue = abstractControl.value as boolean;
        abstractControl.setValue(true);
      }
      else {
        abstractControl.setValue(false);
      }
    });

    this.selectedWageLevelId = this.wageLevel.filter(p => p.wageLevel == item.key)[0].wageLevelId;
    let socCode = this.lcaAssessmentForm.get("oessocCode").value;
    this.view.forEach((key: any, i: number) => {
      this.getFLCWage(key.geographyId, socCode, this.selectedWageLevelId, i);
    });
  }

  getFLCWage(geographyId: number, socCode: any, selectedWageLevelId:number, i:number) {

    this._dafService.getFLCWage(geographyId, socCode, selectedWageLevelId).toPromise().then((data: any) => {
      let PLCwage = data;
      if (this.view[i].addressType == "Primary")
        this.lcaAssessmentForm.get("prevailingWage").setValue(PLCwage);

      this.view[i].flcWage = PLCwage;
      this.view[i].pwi = PLCwage >= this.view[i].salary ? true : false;
      this.view[i].changeType = "UPDATE";
      this.saveHandler(this.view[i]);
      //this.lcaAssessmentForm.get("prevailingWage").setValue(data);
      //this.view.find(x => x.addressType == "Primary").flcWage = data;
      //this.view.find(x => x.addressType == "Primary").pwi = data >= this.lcaAssessmentForm.get("actualSalary").value ? true : false;
      //this.view.find(x => x.addressType == "Primary").changeType = "UPDATE";
      //this.saveHandler(this.view.find(x => x.addressType == "Primary"));
      return Promise.resolve(data);
    },
      (error) => {
        this.notifier.notify('error', 'Error occured while getting the FLC Wage');
      }
    );
  }

  public saveLCADetails() {
    //this.logValidationErrors();

    this.readFormValues(this.lcaAssessmentForm);
    if (this.lcaAssessmentForm.valid && this.selectedWageLevelId != null) {
      this.lcaAssessmentDetails.worksiteAddress = this.modifiedRecords;
      if (this.lcaAssessmentDetails.lcaValidityFrom == null) {
        this.lcaValidityFrom = null;
      }
      else {
        this.lcaValidityFrom = this.lcaAssessmentDetails.lcaValidityFrom.toDateString();
      }
      if (this.lcaAssessmentDetails.lcaValidityTo == null) {
        this.lcaValidityTo = null;
      }
      else {
        this.lcaValidityTo = this.lcaAssessmentDetails.lcaValidityTo.toDateString();
      }
      if (this.validateLCAData(this.lcaAssessmentDetails) == true) {
        this.isPageReady = false;
        //this.jobPositionDetails.isbasis = this.jobPositionForm.controls["isbasis"].value;
        this.lcaAssessmentDetails.assessmentNotes = this.lcaAssessmentDetails.assessmentNotes ? this.encodeHTMLEntities(this.lcaAssessmentDetails.assessmentNotes) : null;
        this._dafService.saveLCAAssessmentDetails(this.caseSummaryId, this.lcaValidityFrom, this.lcaValidityTo ,this.lcaAssessmentDetails).toPromise().then(() => {
          this._dafService.disableEnableAllFormControls(this.lcaAssessmentForm, [], true);
          this.getLCAAssessmentDetails();
          this.isEditEnabled = false;
          this.isPageReady = true;
          this.notifier.notify('success', 'LCA Assessment have been saved successfully');
        }).catch((err) => {
          this.isPageReady = true;
          this.notifier.notify('error', 'Operation Failed');
        });
      }
    }
    else {
      this.notifier.notify('error', 'All fields are required and should have data');
      this.isPageReady = true;
    }
  }

  private readFormValues(group: FormGroup) {
    Object.keys(this.lcaAssessmentForm.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.getDynamicControlValue(abstractControl, key);
      }
      else {
        this.lcaAssessmentDetails[key] = abstractControl.value;
        if (key == 'additionalLCAonetOcssId') {
        }
      }
    });
  }

  private getDynamicControlValue(formGroup: FormGroup, childFormGroup: string) {
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      console.log(abstractControl);
      if (childFormGroup === 'basisOfClassification') {
        this.lcaAssessmentDetails.basisOfClassification.filter(p => p.shortForm == key)[0].isSelected = abstractControl.value;
      }
      else if (childFormGroup === 'additionalLCAType') {
        this.lcaAssessmentDetails.additionalLCAType.filter(p => p.shortForm == key)[0].isSelected = abstractControl.value;
      }
      else if (childFormGroup === 'wageLevel') {
        if (abstractControl.value === true) {
          this.lcaAssessmentDetails.wageLevelId = this.wageLevel.filter(p => p.wageLevel == key)[0].wageLevelId;
        }
      }
    });

  }
  private enableDisableControls() {
    if (this.lcaAssessmentForm.controls["isInSalaryRange"].value == true) {
      this._dafService.disableEnableFormControl(this.lcaAssessmentForm, ["salaryRangeFrom", "salaryRangeTo"], false);
      this.lcaAssessmentForm.updateValueAndValidity();
    }
    else {
      this._dafService.disableEnableFormControl(this.lcaAssessmentForm, ["salaryRangeFrom", "salaryRangeTo"], true);
      this.formControls['salaryRangeFrom'].setValue(null);
      this.formControls['salaryRangeTo'].setValue(null);
      this.lcaAssessmentForm.updateValueAndValidity();
    }

    if (this.lcaAssessmentForm.controls["additionalWorksite"].value == true) {
      this.enableadditionalWorksite();
    }
    else {
      this.disableAdditionalWorksite();
    }

    if (this.lcaAssessmentForm.controls["isAdditionalLCA"].value == true) {
      this.enableAdditionalLCA();
    } else {
      this.disableAdditionalLCA();
    }

    if (this.lcaAssessmentForm.controls["isAdditionalLCAWageRange"].value == true) {
      this.enableadditionalLCAWageRange();
    } else {
      this.disableadditionalLCAWageRange();
    }

    if (this.lcaAssessmentForm.get("isSecondaryEntity").value == true) {

      this._dafService.disableEnableFormControl(this.lcaAssessmentForm, ["secondaryEntityName"], false);
    }
    else {
      this._dafService.disableEnableFormControl(this.lcaAssessmentForm, ["secondaryEntityName"], true);
    }
  }
  private subscribeToFormControlValueChange() {
    this.lcaAssessmentForm.get("isInSalaryRange").valueChanges.subscribe(isSalaryRange => {
      if (isSalaryRange) {
        this._dafService.disableEnableFormControl(this.lcaAssessmentForm, ["salaryRangeFrom", "salaryRangeTo"], false);
        if (this.salaryFrom > 0) {
          this.formControls['salaryRangeFrom'].setValue(null);
        }
        if (this.salaryTo > 0) {
          this.formControls['salaryRangeTo'].setValue(null);
        }
        this.lcaAssessmentForm.updateValueAndValidity();
      } else {

        this._dafService.disableEnableFormControl(this.lcaAssessmentForm, ["salaryRangeFrom", "salaryRangeTo"], true);
        this.salaryFrom = this.formControls['salaryRangeFrom'].value;
        this.salaryTo = this.formControls['salaryRangeTo'].value;
        this.formControls['salaryRangeFrom'].setValue(null);
        this.formControls['salaryRangeTo'].setValue(null);
        this.lcaAssessmentForm.updateValueAndValidity();
      }
    });

    this.additionalWorksiteValueChange();
    this.additionalLCAValueChange();
    this.additionalLCAWageRangeValueChange();
    this.secondaryEntityValueChange();
  }

  private additionalWorksiteValueChange() {
    this.lcaAssessmentForm.get("additionalWorksite").valueChanges.subscribe(isSalaryRange => {
      if (isSalaryRange) {
        this.enableadditionalWorksite();
      } else {

        this.disableAdditionalWorksite();
      }
    });
  }

  private secondaryEntityValueChange() {
    this.lcaAssessmentForm.get("isSecondaryEntity").valueChanges.subscribe(isSalaryRange => {
      if (isSalaryRange) {
        this._dafService.disableEnableFormControl(this.lcaAssessmentForm, ["secondaryEntityName"], false);
      } else {

        this._dafService.disableEnableFormControl(this.lcaAssessmentForm, ["secondaryEntityName"], true);
      }
    });
  }

  private enableadditionalWorksite() {
    this._dafService.disableEnableFormControl(this.lcaAssessmentForm, ["isSecondaryEntity", "secondaryEntityName", "streetNumber", "streetName", "apartment", "cityName", "stateId", "postalCodeId", "county", "countryOnOLFC"], false);
  }

  private disableAdditionalWorksite() {
    this._dafService.disableEnableFormControl(this.lcaAssessmentForm, ["isSecondaryEntity", "secondaryEntityName", "streetNumber", "streetName", "apartment", "cityName", "stateId", "postalCodeId", "county", "countryOnOLFC"], true);
    this.formControls['isSecondaryEntity'].setValue(false);
    this.formControls['secondaryEntityName'].setValue("");
    this.formControls['streetNumber'].setValue("");
    this.formControls['streetName'].setValue("");
    this.formControls['apartment'].setValue("");
    this.formControls['cityName'].setValue("");
    this.formControls['stateId'].setValue(null);
    this.formControls['postalCodeId'].setValue(null);
    this.formControls['county'].setValue("");
    this.formControls['countryOnOLFC'].setValue("");
    this.lcaAssessmentForm.updateValueAndValidity();
  }

  private additionalLCAValueChange() {
    this.lcaAssessmentForm.get("isAdditionalLCA").valueChanges.subscribe(isTrue => {
      if (isTrue) {
        this.enableAdditionalLCA();
      } else {
        this.disableAdditionalLCA();
      }
    });
  }

  private enableAdditionalLCA() {
    this._dafService.disableEnableFormControl(this.lcaAssessmentForm, ["additionalLCAJobTitle", "additionalLCAOESSOCTitle", "additionalLCAonetOcssId", "additionalLCAPrevailingWage", "additionalLCAactualSalary", "isAdditionalLCAWageRange", "additionalLCASalaryRangeTo", "additionalLCAType"], false);
    this.formControls['additionalLCAJobTitle'].setValue(this.formControls['jobTitle'].value);
    this.lcaAssessmentForm.controls["additionalLCAonetOcssId"].setValue(this.formControls['onetOcssId'].value);
    this.formControls['additionalLCAPrevailingWage'].setValue(this.formControls['prevailingWage'].value);
    this.formControls['additionalLCAactualSalary'].setValue(this.formControls['actualSalary'].value);
    this.formControls['isAdditionalLCAWageRange'].setValue(this.formControls['isInSalaryRange'].value);
    this.formControls['additionalLCASalaryRangeFrom'].setValue(this.formControls['salaryRangeFrom'].value);
    this.formControls['additionalLCASalaryRangeTo'].setValue(this.formControls['salaryRangeTo'].value);
  }

  private disableAdditionalLCA() {
    this._dafService.disableEnableFormControl(this.lcaAssessmentForm, ["additionalLCAJobTitle", "additionalLCAOESSOCTitle", "additionalLCAonetOcssId", "additionalLCAPrevailingWage", "additionalLCAactualSalary", "isAdditionalLCAWageRange", "additionalLCASalaryRangeTo", "additionalLCAType"], true);
    this.formControls['additionalLCAJobTitle'].setValue("");
    this.formControls['additionalLCAOESSOCTitle'].setValue("");

    this.formControls['additionalLCAonetOcssId'].setValue(null);
    this.formControls['additionalLCAPrevailingWage'].setValue(null);
    this.formControls['additionalLCAactualSalary'].setValue(null);
    this.formControls['isAdditionalLCAWageRange'].setValue(false);
    this.formControls['additionalLCASalaryRangeFrom'].setValue(null);
    this.formControls['additionalLCASalaryRangeTo'].setValue(null);
    this.resetLCAType();
    this.lcaAssessmentForm.updateValueAndValidity();

  }

  private additionalLCAWageRangeValueChange() {
    this.lcaAssessmentForm.get("isAdditionalLCAWageRange").valueChanges.subscribe(isTrue => {
      if (isTrue) {
        this.enableadditionalLCAWageRange();
      } else {
        this.disableadditionalLCAWageRange();
      }
    });
  }

  private enableadditionalLCAWageRange() {
    this._dafService.disableEnableFormControl(this.lcaAssessmentForm, ["additionalLCASalaryRangeFrom", "additionalLCASalaryRangeTo"], false);
  }

  private disableadditionalLCAWageRange() {
    this._dafService.disableEnableFormControl(this.lcaAssessmentForm, ["additionalLCASalaryRangeFrom", "additionalLCASalaryRangeTo"], true);
    this.formControls['additionalLCASalaryRangeFrom'].setValue(null);
    this.formControls['additionalLCASalaryRangeTo'].setValue(null);
    this.lcaAssessmentForm.updateValueAndValidity();
  }

  private validateLCAData(lcaAssessmentData: any) {
    let isValidationSuccess = true;
    if (lcaAssessmentData.lcaValidityTo < lcaAssessmentData.lcaValidityFrom) {
      this.notifier.notify('error', 'LCA Validity to date cannot be less than from date');
      isValidationSuccess = false;
    }
    if (lcaAssessmentData.isInSalaryRange === true) {
      if (lcaAssessmentData.salaryRangeTo < lcaAssessmentData.salaryRangeFrom) {
        this.notifier.notify('error', 'Salary range to cannot be less than salary range from');
        isValidationSuccess = false;
      }
      else if ((lcaAssessmentData.actualSalary > lcaAssessmentData.salaryRangeTo) ||
        (lcaAssessmentData.actualSalary < lcaAssessmentData.salaryRangeFrom)) {
        this.notifier.notify('error', 'Actual salary should be in between salary from range and salary to range');
        isValidationSuccess = false;
      }

    }
    if (lcaAssessmentData.isAdditionalLCAWageRange === true) {
      if (lcaAssessmentData.additionalLCASalaryRangeTo < lcaAssessmentData.additionalLCASalaryRangeFrom) {
        this.notifier.notify('error', 'Additional LCA salary range to cannot be less than salary range from');
        isValidationSuccess = false;
      }
      else if ((lcaAssessmentData.additionalLCAactualSalary > lcaAssessmentData.additionalLCASalaryRangeTo) ||
        (lcaAssessmentData.additionalLCAactualSalary < lcaAssessmentData.additionalLCASalaryRangeFrom)) {
        this.notifier.notify('error', 'Additional LCA actual salary should be in between salary from range and salary to range');
        isValidationSuccess = false;
      }

    }

    return isValidationSuccess;
  }

  logValidationErrors(group: FormGroup = this.lcaAssessmentForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  logOnChangeValidationErrors(group: FormGroup = this.lcaAssessmentForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid
          && (abstractControl.touched || abstractControl.dirty)
        ) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  private getLCAPrevailingwage(onetId: string, isAdditionalLCA: boolean) {
    this.isPageReady = false;
    let prevailingwage: number = 0;
    let onetCode: string
    if (this.onetocsSDetails.filter(p => p.onetOcssId == onetId) != null) {
      onetCode = this.onetocsSDetails.filter(p => p.onetOcssId == onetId)[0].onetCode;
    }
    this._dafService.getLCAPrevailingwage(this.caseSummaryId, onetCode).toPromise().then((data: any) => {
      prevailingwage = data;
      if (isAdditionalLCA) {
        this.lcaAssessmentForm.controls["additionalLCAPrevailingWage"].setValue(prevailingwage);
      }
      else {
        this.lcaAssessmentForm.controls["prevailingWage"].setValue(prevailingwage);

      }

      this.isPageReady = true;
    },
      (error) => {
        this.isPageReady = true;
      }
    );

    return prevailingwage;
  }

  resetLCAType() {
    var formGroup = this.lcaAssessmentForm.get("additionalLCAType") as FormGroup;
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      abstractControl.setValue(false);
    });
  }

  public addHandler(): void {
    var address = new addressWizardInfos();
    var modalData = new worksiteAddressModal();
    address.caseID = "";
    address.caseSummaryID = 0;
    address.addressWizardID = 0,
    address.addressType = "Additional",
    address.entityName = "",
    address.streetNumber = "",
    address.streetName = "",
    address.apt = "",
    address.city = "",
    address.state = "",
    address.postalCode = "",
    address.county = "",
    address.countyOnOLFC = "",
    address.salary = this.lcaAssessmentForm.value.actualSalary,
    address.flcWage = 0,
    address.pwi = null

    modalData.oesSOCCode = this.lcaAssessmentForm.get("oessocCode").value;

    modalData.wageLevelId = this.selectedWageLevelId ? this.selectedWageLevelId : null;
    modalData.addressWizardInfos = address;
    this.editDataItem = modalData;
    this.isNew = true;
  }

  public editHandler(args: AddEvent): void {
    var modalData = new worksiteAddressModal();
    modalData.oesSOCCode = this.lcaAssessmentForm.get("oessocCode").value;
    modalData.wageLevelId = this.selectedWageLevelId ? this.selectedWageLevelId : null;
    modalData.addressWizardInfos = args.dataItem;

    this.editDataItem = modalData;

    this.isNew = false;
  }

  public cancelHandler(): void {
    this.closeEditor();
  }

  private closeEditor(): void {
    this.grid.closeRow(this.editedRowIndex);

    this.isNew = false;
    this.editedRowIndex = undefined;
  }

  public saveHandler(product: addressWizardInfos): void {
    if (this.isNew) {
      this.view.splice(0, 0, product);
      this.modifiedRecords.push(product);
    }
    else {
      Object.assign(
        this.view.find(({ addressWizardID }) => addressWizardID === product.addressWizardID),
        product
      );

      if (this.modifiedRecords.find(({ addressWizardID }) => addressWizardID === product.addressWizardID)) {
        Object.assign(
          this.modifiedRecords.find(({ addressWizardID }) => addressWizardID === product.addressWizardID),
          product
        );

        if (product.addressType == "Primary")
          this.lcaAssessmentForm.get("prevailingWage").setValue(product.flcWage);
      }
      else {
        this.modifiedRecords.push(product);
      }
    }

    this.editDataItem = undefined;
    this.isNew = false;
    this.loadItems();
  }

  public setDependentIndexToDelete(args: any) {
    this.deleteMessage = "";
    this.isDeleteClicked = true;
    this.deleteRow = args;
    this.deleteIndex = args.rowIndex;
    if (args.dataItem.addressType == "Primary") {
      this.deleteMessage = "Deleting this record will remove address details from Primary Worksite Address module.";
    }
    this.showDialog = true;
  }

  public deleteDependent() {
    this.view.splice(this.deleteIndex, 1);
    this.deleteRow.dataItem.changeType = "DELETE";
    this.modifiedRecords.push(this.deleteRow.dataItem);
    this.loadItems();
    this.showDialog = false;
  }

  public cancelDelete() {
    this.showDialog = false;
  }

  public onDialogClose() {
    this.showDialog = false;
  }

  encodeHTMLEntities(text) {
    let textArea = document.createElement('textarea');
    textArea.innerText = text;
    let encodedOutput = textArea.innerHTML;
    let arr = encodedOutput.split('<br>');
    encodedOutput = arr.join('\n');
    return encodedOutput;
  }

  decodeHTMLEntities(text) {
    let textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
  }
}
