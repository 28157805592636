import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AssessmentFormsRoutingModule } from './assessment-forms-routing.module';
import { GeneralDetailsComponent } from './general-details/general-details.component';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LCAAssessmentComponent } from './lca-assessment/lca-assessment.component';
import { CaseTypeAssessmentComponent } from './case-type-assessment/case-type-assessment.component';
import { EditorModule } from "@progress/kendo-angular-editor";

@NgModule({
  declarations: [GeneralDetailsComponent, LCAAssessmentComponent, CaseTypeAssessmentComponent],
  imports: [
    CommonModule,
    AssessmentFormsRoutingModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    EditorModule
  ]
})
export class AssessmentFormsModule { }
