import { Observable, from } from 'rxjs';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(private msalService: MsalService) { }
  public token: any;
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handleAccess(request, next));
  }

  private async handleAccess(request: HttpRequest<any>, next: HttpHandler):
    Promise<HttpEvent<any>> {
    var finalHeader;
    var requestObj = null;

    if (request.url.includes('https://graph.microsoft.com/')) {
      requestObj = {
        scopes: ["user.read"]
      };

      this.token = await this.msalService.acquireTokenSilent(requestObj).then(function (tokenResponse) {
        // Callback code here
        return Promise.resolve(tokenResponse.accessToken);
        //console.log(tokenResponse.accessToken);
      }).catch(function (error) {
        console.log(error);
        if (error.name === "InteractionRequiredAuthError") {
          this.msalService.acquireTokenRedirect(requestObj).then(
            function (accessTokenResponse) {
              // call API
            }).catch(function (error) {
              console.log(error);
            });
        }
      });
    }
    else {
      requestObj = {
        scopes: [environment.APIScope]
      };
      this.token = await this.msalService.acquireTokenSilent(requestObj).then(function (tokenResponse) {
        // Callback code here
        return Promise.resolve(tokenResponse.accessToken);
        //console.log(tokenResponse.accessToken);
      }).catch(function (error) {
        if (error.name === "InteractionRequiredAuthError") {
          this.msalService.acquireTokenRedirect(requestObj).then(
            function (accessTokenResponse) {
              // call API
            }).catch(function (error) {
              console.log(error);
            });
        }
      });
    }
    let changedRequest = request;
    // HttpHeader object immutable - copy values
    const headerSettings: { [name: string]: string | string[]; } = {};

    for (const key of request.headers.keys()) {
      headerSettings[key] = request.headers.getAll(key);
    }
    if (this.token) {
      headerSettings['Authorization'] = 'Bearer ' + this.token;
    }
    // if(!(request.url.includes("UploadFileTemplateCheck") || request.url.includes("UploadFileDataCheck") || request.url.includes("UploadPayroll") )){
    // headerSettings['Content-Type'] = 'application/json';
    // }
    if (request.url.includes('graph.microsoft.com/v1.0/groups')) {
      headerSettings['ConsistencyLevel'] = 'eventual';
    }
    const newHeader = new HttpHeaders(headerSettings);
    
    if (request.url.includes('photos/48x48/$value')) {
      finalHeader = {
        headers: newHeader,
        responseType: 'blob'
      }
    }
    else {
      finalHeader = {
        headers: newHeader
      }
    }

    changedRequest = request.clone(finalHeader);
    return next.handle(changedRequest).toPromise();
  }

}
