import { Component, OnInit , Input} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input() label = null;
  @Input() type;
  @Input() isDisabled: boolean = false;
  @Input() isHidden: boolean = false;
  @Input() icon = null;
  @Input() iconPlacementFront: boolean = true;

  constructor() { }

  ngOnInit() {
  }

}
