export class DashboardModel {
  category: string = null;
  value: number = 0;
  color: string = null;
  constructor(obj?: any) {
    try {
      if (obj) {
        this.color = obj.color ? obj.color : null;
        this.value = obj.count ? (obj.count == null ? 0 : obj.count) : 0;
        this.category = obj.caseStatus ? obj.caseStatus : null;
      }
    }
    catch (err) {
      console.log(err);
    }
  }
}
