import { Component, OnInit } from '@angular/core';
import { LCAService } from '../lca-compliance/service/lca.service';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { NotifierService } from 'angular-notifier';
import { process } from '@progress/kendo-data-query';
import { ActivatedRoute, Router } from '@angular/router';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';

@Component({
  selector: 'app-ey-lcaassessment',
  templateUrl: './ey-lcaassessment.component.html',
  styleUrls: ['./ey-lcaassessment.component.scss']
})
export class EyLCAAssessmentComponent implements OnInit {
  public current = 0;
  public userList: any[] = [];
  public sourceUserList:any[]=[];
  roleData:any[]=[];
  public isPageReady = true;
  public pageSize = 10;
  public status='submitted'
  public skip = 0;
  public gridState: DataStateChangeEvent = {
    skip: this.skip,
    take: this.pageSize
  };
  private readonly notifier: NotifierService;
  public gridViewSearch: any;
  public gridView: any;
  public searchTerm = '';
  public TemplateHeader="";
  dateFilterModel: DateFilterModel;

  public steps = [
      { label: 'Search Page', icon: 'user' },
      { label: 'LCA Assessment Review', icon: 'dictionary-add' },
      { label: 'Reposting Tracker', icon: 'attachment' },

  ];
  constructor(private lcaService: LCAService,private route: ActivatedRoute,private router: Router) {
    this.allData = this.allData.bind(this);
    this.dateFilterModel = new DateFilterModel(new Date(2021, 0, 1), new Date());
   }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.status = params['status'];
      if(this.status){
        if (this.status =="More Info Required"){
          this.TemplateHeader = "More Info Required Request Details";
        }
        else{
        this.TemplateHeader = this.status+" Request Details";
        }
         // Loads data to grid
    this.dataStateChange(this.gridState,this.status);
      }
  })
  
  }


  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: this.userList
    };
  
    return result;
  }

  editInternalData(workSite: any) {
    this.router.navigate(['/editInternalEY'], { queryParams: { requestId: workSite.requestId,status:this.status } })
  }

  GotoBack(){
    this.router.navigate(['/internalLca'])
  }



  updateUserList() {
    this.dataStateChange(this.gridState,this.status);
  }
  dataStateChange(state: DataStateChangeEvent,status:string) {
    this.isPageReady = false;
    this.gridState = state;

    // Gets data from api
    this.sourceUserList=[];
    this.lcaService.searchWorkSite(this.searchTerm, this.status, this.dateFilterModel.startDate.toDateString(), this.dateFilterModel.endDate.toDateString()).subscribe((data: any) => {
      // Binds the data to grid
      //this.gridViewSearch = data;
      //this.sourceUserList=data;
      //let filterData = this.gridViewSearch;

      this.gridView = process(data, this.gridState);
      //this.gridView = {
      //  data: data.slice(this.skip, this.skip + this.pageSize),
      //  total: data.length
      //};
      //this.gridViewSearch = data.map(x => Object.assign({}, x));
      //debugger;
      //this.userList = data;
      //this.isPageReady = true;
    
      this.userList = data;

      this.isPageReady = true;
    },
    (error) => {
      this.notifier.notify('error', 'Error occured while getting data from server');
      this.isPageReady = true;
    }
    );
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    let filterData = [];
    if (this.status == "Submitted") {
      filterData = this.userList.filter(r => r.requestDate.toString().includes(filterValue) ||
        (r.corporation == null ? "".toString().includes(filterValue) : r.corporation.toLowerCase().includes(filterValue)) ||
        (r.department == null ? "".toString().includes(filterValue) : r.department.toLowerCase().includes(filterValue)) ||
        (r.caseId == null ? "".toString().includes(filterValue) : r.caseId.toLowerCase().includes(filterValue)) ||
        r.requestId.toLowerCase().includes(filterValue) ||
        (r.fullName == null ? "".toString().includes(filterValue) : r.fullName.toLowerCase().includes(filterValue)) ||
        (r.caseManager == null ? "".toString().includes(filterValue) : r.caseManager.toLowerCase().includes(filterValue)) ||
        (r.attorney == null ? "".toString().includes(filterValue) : r.attorney.toLowerCase().includes(filterValue)) ||
        (r.description == null ? "".toString().includes(filterValue) : r.description.toLowerCase().includes(filterValue))
      );
    }
    else {
      filterData = this.userList.filter(r => r.requestDate.toString().includes(filterValue) ||
        (r.corporation == null ? "".toString().includes(filterValue) : r.corporation.toLowerCase().includes(filterValue)) ||
        (r.department == null ? "".toString().includes(filterValue) : r.department.toLowerCase().includes(filterValue)) ||
        (r.caseId == null ? "".toString().includes(filterValue) : r.caseId.toLowerCase().includes(filterValue)) ||
        r.requestId.toLowerCase().includes(filterValue) ||
        (r.fullName == null ? "".toString().includes(filterValue) : r.fullName.toLowerCase().includes(filterValue)) ||
        (r.caseManager == null ? "".toString().includes(filterValue) : r.caseManager.toLowerCase().includes(filterValue)) ||
        (r.attorney == null ? "".toString().includes(filterValue) : r.attorney.toLowerCase().includes(filterValue)));
    }

    
      
    this.gridView.data = filterData.slice(this.skip, this.skip + this.pageSize);
    this.gridView.total = filterData.length;
  
  }

  applyDateFilter() {
    this.dataStateChange(this.gridState, this.status);
  }

  resetFilter() {
    this.dateFilterModel = new DateFilterModel(new Date(2022, 0, 1), new Date());
    this.dataStateChange(this.gridState, this.status);
  }
}
export class DateFilterModel {
  public startDate: Date;
  public endDate: Date;
  constructor(startDate: Date, endDate: Date) {
    this.startDate = startDate;
    this.endDate = endDate;
  }
}
