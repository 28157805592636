import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MsalGuard } from '@azure/msal-angular';
import { AccessDeniedComponent } from './shared/components/access-denied/access-denied.component';
import { UsermanagementComponent } from './usermanagement/usermanagement.component';
import { AwaitdocextractionComponent } from './awaitdocextraction/awaitdocextraction.component';
import { AwaitcompilationComponent } from './awaitcompilation/awaitcompilation.component';
import { CompilationfailedComponent } from '../immigrationcompilation/compilationfailed/compilationfailed.component';
import {CompilationrejectedComponent} from '../immigrationcompilation/compilationrejected/compilationrejected.component';
import { AwaitlawclerkreviewComponent } from '../immigrationcompilation/awaitlawclerkreview/awaitlawclerkreview.component'; 
import { SubmittedtolawyerComponent } from '../immigrationcompilation/submittedtolawyer/submittedtolawyer.component';
import { DetailedviewComponent } from '../immigrationcompilation/detailedview/detailedview.component';
import { CourierPackageComponent } from './courier-package/courier-package.component';
import { CourierpackageSuccessComponent } from './courier-package/courierpackage-success/courierpackage-success.component';
import { CourierpackageFailureComponent } from './courier-package/courierpackage-failure/courierpackage-failure.component';
import { AuthGuard } from '../core/guards/auth.guard';
import { LawyerEmailComponent } from './lawyer-email/lawyer-email.component';
import { MainPageComponent } from './main-page/main-page.component';
import { LcaComplianceComponent } from './lca-compliance/lca-compliance.component';
import { AddLcaComponent } from './add-lca/add-lca.component';
import { WorkSiteAcknowledgeComponent } from './work-site-acknowledge/work-site-acknowledge.component';
import { LcaHomeComponent } from './lca-home/lca-home.component';
import { EyLCAAssessmentComponent } from './ey-lcaassessment/ey-lcaassessment.component';
import { LcaDeterminationComponent } from './lca-determination/lca-determination.component';
import { LcaInternalDashboardComponent } from './lca-internal-dashboard/lca-internal-dashboard.component';
import { EditInternalWorkSiteInfoComponent } from './edit-internal-work-site-info/edit-internal-work-site-info.component';
import { RepostingCompletedComponent } from './reposting-completed/reposting-completed.component';
const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full', data: { breadcrumb: 'Home' } },
  { path: 'dashboard', component: DashboardComponent, canActivate: [MsalGuard,AuthGuard], data: { breadcrumb: 'Immigration Home' } },//, canActivate: [MsalGuard]

  {
    path: 'user-management',
    component: UsermanagementComponent, canActivate: [MsalGuard, AuthGuard],data: { breadcrumb: 'User' }
  },
  {
    path: 'lcaCompliance',
    component: LcaComplianceComponent, canActivate: [MsalGuard], data: { breadcrumb: 'LCA Compliance' }
  },
  {
    path: 'lcaHome',
    component: LcaHomeComponent, canActivate: [MsalGuard], data: { breadcrumb: 'LCA Home' }
  },
  {
    path: 'addLca',
    component: AddLcaComponent, canActivate: [MsalGuard], data: { breadcrumb: 'Add LCA' }
  },
  {
    path: 'EyLca',
    component: EyLCAAssessmentComponent, canActivate: [MsalGuard], data: { breadcrumb: 'Inernal LCA Assessment' }
  },
  {
    path: 'Repostings',
    component: RepostingCompletedComponent, canActivate: [MsalGuard], data: { breadcrumb: 'Inernal LCA Assessment' }
  },
  {
    path: 'editInternalEY',
    component: EditInternalWorkSiteInfoComponent, canActivate: [MsalGuard], data: { breadcrumb: 'Edit LCA' }
  },
  {
    path: 'delermination',
    component: LcaDeterminationComponent, canActivate: [MsalGuard], data: { breadcrumb: 'LCA Determination' }
  },
  {
    path:'internalLca',
    component:LcaInternalDashboardComponent,canActivate: [MsalGuard], data: { breadcrumb: 'Internal LCA' }
  },

  
  {
    path: 'lawyer-management',
    component: LawyerEmailComponent, canActivate: [MsalGuard, AuthGuard], data: { breadcrumb: 'Lawyer Management' }
  },
  { path: 'accessdenied', component: AccessDeniedComponent, data: { breadcrumb: 'Access Denied' }},
  { path: 'home', component: MainPageComponent,canActivate: [MsalGuard],data: { breadcrumb: 'Home' } },
  { path: 'awaitdocextract', component: AwaitdocextractionComponent,canActivate: [ AuthGuard],data: { breadcrumb: 'Await Doc Extract' } },
  { path: 'awaitcompilation', component: AwaitcompilationComponent,canActivate: [ AuthGuard],data: { breadcrumb: 'Await Compilation' } },
  { path: 'compilationfailed', component: CompilationfailedComponent,canActivate: [ AuthGuard],data: { breadcrumb: 'Compilation Failed' } },
  { path: 'awaitlawclerkreview', component: AwaitlawclerkreviewComponent,canActivate: [ AuthGuard],data: { breadcrumb: 'Await Law ClerkReview' } },
  { path: 'submittedtolawyer', component: SubmittedtolawyerComponent,canActivate: [ AuthGuard],data: { breadcrumb: 'Submitted to Lawyer' } },
  { path: 'compilationrejected', component: CompilationrejectedComponent,canActivate: [ AuthGuard],data: { breadcrumb: 'Compilation Rejected' } },
  { path: 'courierpackage', component:CourierPackageComponent,canActivate: [ AuthGuard],data: { breadcrumb: 'Courier Package' } },
  { path: 'courierpackageSuccess', component:CourierpackageSuccessComponent,canActivate: [ AuthGuard],data: { breadcrumb: 'Courier Package Success' } },
  { path: 'courierpackageFailure', component:CourierpackageFailureComponent,canActivate: [ AuthGuard],data: { breadcrumb: 'Courier Package Failure' } },
  { path: 'courierpackagePendings', component: CourierpackageFailureComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Courier Package Pending' } },
  { path: 'courierpackageAccessFailure', component: CourierpackageFailureComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Courier Package Access Failure' } },
  { path: 'lcaAcknowledge', component:WorkSiteAcknowledgeComponent, canActivate: [MsalGuard],data: { breadcrumb: 'LCA Acknowledge' } }, 
    { path: 'detailedview/:Id/:applicantType/:requestType/:caseType/:insZoomId/:isLast', component: DetailedviewComponent }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class ImmigrationcompilationRoutingModule { }
