import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { NotificationService } from '@progress/kendo-angular-notification';
import { NotifierService } from 'angular-notifier';
import { DafService } from 'src/app/DAF/services/dafService';

@Component({
  selector: 'app-lca-info-wizard',
  templateUrl: './lca-info-wizard.component.html',
  styleUrls: ['./lca-info-wizard.component.scss']
})
export class LcaInfoWizardComponent implements OnChanges {
  public view = [];
  public pageSize = 5;
  public skip = 0;
  public gridView: GridDataResult;
  public emailForm: FormGroup;
  public isReady=false;
  public WorkSite="Lca Info Wizard";
  public lcaInfo=[];
  public savedLcaInfo:any;

  @Input() caseSummaryId = 0;


  @Output() cancel: EventEmitter<string> = new EventEmitter();
  constructor(private formBuilder: FormBuilder,@Inject(NotificationService) private notificationService: NotificationService, private _dafService: DafService,notifier: NotifierService) { }

  ngOnChanges(): void {
    this.getlcaInformation();
    this.getLcaSavedInfos();
  }


  private loadItems(): void {
    this.gridView = {
      data: this.view.slice(this.skip, this.skip + this.pageSize),
      total: this.view.length,
    };
  }

  public onCancel(e: PointerEvent): void {
    e.preventDefault();
    this.closeForm();
}


public closeForm(): void {
  this.cancel.emit("Close");
}


  getLcaSavedInfos(){
    this.isReady = false;
    this._dafService.getLCAWizardInformation(this.caseSummaryId).toPromise().then((data: any) => {
      this.savedLcaInfo = data;

      this.isReady = true;
    },
      (error) => {
        console.log(error)
        this.isReady = true;
      }
    );
  }

  getlcaInformation(){
    this.isReady = false;
    this._dafService.getLCAInformation(this.caseSummaryId).toPromise().then((data: any) => {
      this.lcaInfo = data;
      this.view = this.lcaInfo;

      this.loadItems();
      this.isReady = true;
    },
      (error) => {
        console.log(error)
        this.isReady = true;
      }
    );
  }

}
