import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { DafService } from 'src/app/DAF/services/dafService';
import { EympService } from 'src/app/eymp-inszoom-integration/services/EympService';
import { Router } from '@angular/router';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  dateFilterModel: DateFilterModel;
  fromReportDate: any;
  toReportDate: any;
  public isPageReady = false;
  public caseList: any[];
  caseSuccess: number;
  caseFailure: number;
  caseReady: number;
  caseQuest: number;
  caseeyiupload: number;

  pfsuccess: number;
  pffailure: number;
  pfready: number;

  caseDocSuccess: number;
  caseDocFailure: number;
  caseDocReady: number;

  constructor(private router: Router, private commonService: CommonService, public _dafService: DafService, public _eympservice: EympService ) {
    this.dateFilterModel = new DateFilterModel(new Date(2020, 1, 1), new Date());
  }

  ngOnInit(): void {
    this.getCaseDraftReportDate();
    this.isPageReady = true;
  }
  getCaseDraftReportDate() {

    this.commonService.getINSZoomReportDate()
      .subscribe((data: any) => {
        this._dafService.updateINSDate({ fromDate: data.reportDate, toDate: data.reportDate });
        this.getCaseDetails();
      });
  }
  getCaseDetails() {

    this._dafService.currentInsDates
      .subscribe((data: any) => {
        this.fromReportDate = data.fromDate;
        this.toReportDate = data.toDate;
        this.dateFilterModel = new DateFilterModel(new Date(this.fromReportDate), new Date(this.toReportDate));
        this._eympservice.getDashboardCount(this.dateFilterModel.startDate.toDateString(), this.dateFilterModel.endDate.toDateString()).subscribe((data: any) => {
          // Binds the data to grid
          this.caseList = data;
          this.caseSuccess = data.caseSuccess;
          this.caseeyiupload = data.awaitingEYIUpload;
          this.caseFailure = data.caseFailure;
          this.caseReady = data.casestobeCreated;
          this.caseQuest = data.awaitingQuestionnaireUpload;

          this.pfready = data.profiletobeCreated;
          this.pffailure = data.profileFailure;
          this.pfsuccess = data.profileSuccess;

          this.caseDocReady = data.caseDoctobeCreated;
          this.caseDocSuccess = data.caseDocSuccess;
          this.caseDocFailure = data.caseDocFailure;

          this.isPageReady = true;
          console.log(this.caseList);
        },
          (error) => {
            this.isPageReady = true;
            // this.notifier.notify('error', 'Error occured while getting data from server');
          }
        );

      },
        (error) => {
          //this.notifier.notify('error', 'Error occured while updating the user details');
        }
      );

  }
  filterCaseList() {

    this._dafService.updateINSDate({ fromDate: this.dateFilterModel.startDate.toDateString(), toDate: this.dateFilterModel.endDate.toDateString() });
  }
  getProfileReadydetails() {
    this.router.navigate(['/draftdetails'], { queryParams: { 'Status': 'Ready for Profile and Case Creation', 'ProfileCase':'Profile' } });
  }

  getProfileSuccess() {
    this.router.navigate(['/draftdetails'], { queryParams: { 'Status': 'Success', 'ProfileCase': 'Profile' } });
  }

  getProfileFailure() {
    this.router.navigate(['/draftdetails'], { queryParams: { 'Status': 'Failure', 'ProfileCase': 'Profile' } });
  }

  getCaseReady() {
    this.router.navigate(['/draftdetails'], { queryParams: { 'Status': 'Ready for Profile and Case Creation', 'ProfileCase': 'Case' } });
  }

  getCaseFailure() {
    this.router.navigate(['/draftdetails'], { queryParams: { 'Status': 'Failure', 'ProfileCase': 'Case' } });
  }
  getCaseSuccess() {
    this.router.navigate(['/draftdetails'], { queryParams: { 'Status': 'Success', 'ProfileCase': 'Case' } });
  }
  getCaseEyiupload() {
    this.router.navigate(['/draftdetails'], { queryParams: { 'Status': 'Ready for EYI Upload', 'ProfileCase': 'Case' } });
  }

  getCaseQuest() {
    this.router.navigate(['/draftdetails'], { queryParams: { 'Status': 'Ready for Case Questionnaire Update', 'ProfileCase': 'Case' } });
  }

  getCaseDocumentReady() {
    this.router.navigate(['/draftdetails'], { queryParams: { 'Status': 'Ready for Document Upload', 'ProfileCase': 'Document' } });
  }
  getCaseDocumentSuccess() {
    this.router.navigate(['/draftdetails'], { queryParams: { 'Status': 'Success', 'ProfileCase': 'Document' } });
  }
  getCaseDocumentFailure() {
    this.router.navigate(['/draftdetails'], { queryParams: { 'Status': 'Failure', 'ProfileCase': 'Document' } });
  }
}
export class DateFilterModel {
  public startDate: Date;
  public endDate: Date;
  constructor(startDate: Date, endDate: Date) {
    this.startDate = startDate;
    this.endDate = endDate;
  }
}
