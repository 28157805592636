import { FilingDetails } from "./filingdetails";
import { GeneralDetailsModel } from "./GeneralDetails";

export class individualBioInfo {
    individualBioInfoID: number = 0;
    caseId:string=null;
    caseSummaryId:number=0;
    countryOfBirth: number = null;
    citizenship: number = null;
    dualCitizenship: boolean = null;
    secondCitizenship: string = null;
    currentStatus: string = null;
    statusDuration:boolean=null;
    ped: Date = null;
    priorH1L1Time: boolean = null;
    priorJ1: boolean = null;
    j1Notes: string = null;
    notes:string=null;
    subjectTo212: boolean = null;
    i94ExpDate: Date = null;
    visaExpDate: Date = null; 
    h1L1statusStartDate: Date = null;
    h1L1statusToDate: Date = null;
    dateFrom: Date = null;
    dateTo: Date = null;
    fileHistoryReport: Array<FilingDetails> = [];
    nationality: number = null;
    alienNumber: string = null;
    constructor(obj?: any) {
        try {
            if (obj) {
                this.caseSummaryId = obj.caseSummaryId ? obj.caseSummaryId : 0;
                // this.individualBioInfoID = obj.bioInfoID ? obj.bioInfoID : null;
                // this.countryOfBirth = 105;
                // this.citizenship = 104;
                // this.currentStatus = obj.currentStatus ? "Active" : "Active";
                // this.priorH1L1Time = obj.priorH1orL1Time ? true :true;
                // this.dateFrom = obj.dateOfBirth ? new Date(1992,21,2) : new Date(1992,21,2);
                // this.dateTo = obj.caseManager ? new Date(2020,21,2) : new Date(2020,21,2);
                // this.i94ExpDate = obj.i94ExpDate ? new Date() : new Date();
                // this.visaExpDate = obj.visaExpDate ? new Date() : new Date();
                // this.subjectTo212 = obj.subjectTo212E ? true : true;
                // this.ped = obj.ped ? "PED Value" : "PED Value";
                // this.priorJ1 = false;
                // this.j1Notes = "Documentation Complete";
                // this.h1L1statusStartDate = new Date(2000,10,12);
                // this.h1L1statusToDate = new Date(2002,10,12);
                this.caseId = obj.caseId ? obj.caseId : null;
                this.individualBioInfoID = obj.bioInfoID ? obj.bioInfoID : 0;
                this.countryOfBirth = obj.countryOfBirth ? +obj.countryOfBirth : null;
                this.nationality = obj.nationality ? +obj.nationality : null;
                this.citizenship = obj.citizenship ? obj.citizenship : null;
                this.currentStatus = obj.currentStatus ? obj.currentStatus : null;
                this.priorH1L1Time = obj.priorH1L1Time ? obj.priorH1L1Time :false;
                this.statusDuration = obj.statusDuration ? obj.statusDuration :false;

                this.dateFrom = obj.dateFrom ? new Date(obj.dateFrom) : null;
                this.dateTo = obj.dateTo ? new Date(obj.dateTo) : null;
                this.i94ExpDate = obj.i94ExpDate ? new Date(obj.i94ExpDate) : null;
                this.visaExpDate = obj.visaExpDate ? new Date(obj.visaExpDate) : null;
                this.subjectTo212 = obj.subjectTo212 ? obj.subjectTo212 : false;
                this.ped = obj.ped ? new Date(obj.ped) : null;
                this.priorJ1 = obj.priorJ1 ? obj.priorJ1 : false;
                this.j1Notes = obj.j1Notes ? obj.j1Notes : null;
                this.secondCitizenship = obj.secondCitizenship ? obj.secondCitizenship : null;
                this.dualCitizenship = obj.dualCitizenship? obj.dualCitizenship :false;
                this.h1L1statusStartDate = obj.h1L1statusStartDate ? new Date(obj.h1L1statusStartDate) : null;
                this.h1L1statusToDate = obj.h1L1statusToDate ? new Date(obj.h1L1statusToDate) : null;
                this.notes = obj.notes ? obj.notes : null;
                this.alienNumber = obj.alienNumber ? obj.alienNumber : null;
            }
        }
        catch (err) {
            console.log(err);
        }
    }
}
export class FilingHistory {
  FileHistoryID: number;
  caseId: string;
  casesummaryId: number;
  FormType: string;
  Classification: string;
  RN: string;
  ReceiptDate: Date;
  ValidFrom: Date;
  ValidTo: Date;
  Status: string;
  IsFiledByPetitioner: boolean;
  changeType: string;
}
