export class EYIModel {
  constructor(
   
    public createdDate = new Date(),
    public createdDateString = "",
    public documentName = "",
    public uploadedPath = "",
    public comments = ""
  ) { }
}
