import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-lca-home-info',
  templateUrl: './lca-home-info.component.html',
  styleUrls: ['./lca-home-info.component.scss']
})
export class LcaHomeInfoComponent implements OnInit {
  @Input() lcaType="";
  @Output() page = new EventEmitter<number>();
  constructor(public router:Router) { }

  ngOnInit() {
  }

  redirectToLca(){
    this.page.emit(1);
  }

}
