import { Component, OnInit, OnChanges, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { EympService } from 'src/app/eymp-inszoom-integration/services/eympservice';
import { QuestInfoModel } from '../../../Model/QuestInfoModel';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-view-casetravel',
  templateUrl: './view-casetravel.component.html',
  styleUrls: ['./view-casetravel.component.scss']
})
export class ViewCasetravelComponent implements OnInit, OnChanges {

  @Input() documentInfo: QuestInfoModel;
  @Input() packagesStatus: any;
  @ViewChild('closeButton', { static: false }) closeButton: ElementRef;
  @ViewChild('addcloseButton', { static: false }) addcloseButton: ElementRef;
  @ViewChild('autocomplete', { static: false }) autocomplete: any;
  @Output() save = new EventEmitter<string>();
  errorMessage: string;
  public documents: QuestInfoModel;
  private notifier: NotifierService;

  constructor(private eympservice: EympService, public notifierService: NotifierService
  ) {
    this.notifier = notifierService;
  }

  ngOnInit(): void {
    console.log(this.documentInfo);
  }
  ngOnChanges() {
    console.log(this.documentInfo);
  }
  closeForm() {
    const closeButton: HTMLElement = this.closeButton
      .nativeElement as HTMLElement;
    closeButton.click();
  }

  addcloseForm() {
    this.autocomplete.reset();
    const addcloseButton: HTMLElement = this.addcloseButton
      .nativeElement as HTMLElement;
    addcloseButton.click();
  }

  saveData() {

    this.eympservice.SaveDocumentExtraction(this.documentInfo).subscribe((result) => {
      document.getElementById("btnClose").click();
      this.closeForm();
      this.notifier.notify('success', 'Case details have been updated successfully');
      this.save.emit("refresh");
    }, error => {
      this.notifier.notify('error', 'Error occured while updating case details');
    });
  }

}
