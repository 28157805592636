import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardPageComponent } from './components/dashboard-page/dashboard-page.component';
import { CaseDetailsComponent } from './components/case-details/case-details.component';

const routes: Routes = [
  {
    path: 'dashboard', pathMatch: 'full',component: DashboardPageComponent
  },
  {
    path:'caseDeatils',pathMatch: 'full',component:CaseDetailsComponent
  }
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class DashboardRoutingModule {



 }
