export class GeneralDetailsModel {
  // firstName: string = null;
  // lastName: string = null;
  // Date: Date = null;
  caseId: number = 0;

  constructor(obj?: any) {
    try{
      if (obj) {
        this.caseId = obj.caseId ? obj.caseId : 0;
      }
    }
    catch(err){
      console.log(err);
    }
  }
}
