import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DafService } from '../../../services/dafService';
import { FilingHistory, individualBioInfo } from '../../../Models/IndividualBioInfo';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AddEvent, DataStateChangeEvent, GridComponent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { EditFilingDetails, FilingDetails } from 'src/app/DAF/Models/filingdetails';

@Component({
  selector: 'app-individual-bio-graphical-information',
  templateUrl: './individual-bio-graphical-information.component.html',
  styleUrls: ['./individual-bio-graphical-information.component.scss']
})
export class IndividualBioGraphicalInformationComponent implements OnInit {

  @Input() caseId = 0;
  private notifier: NotifierService;
  ibiDetails: individualBioInfo = new individualBioInfo();
  public modifiedRecords: Array<FilingDetails> = [];
  public isPageReady = true;
  isEditEnabled: boolean = false;
  isUnlocked: boolean = false;
  haveRadioButtonsChanged
  radioButtonOptionValues: Array<any> = [{ value: true, text: "Yes" }, { value: false, text: "No" }];
  @ViewChild(GridComponent, {static: false})
  private grid: GridComponent;
  public view: Array<FilingDetails> = [];
  public pageSize = 5;
  public skip = 0;
  public gridView: GridDataResult;
  public gridState: DataStateChangeEvent = {
    skip: this.skip,
    take: this.pageSize
  };

  showDialog:boolean = false;
  deleteIndex: number;
  public editDataItem: EditFilingDetails;
  public isGridEditable: boolean;
  private isDeleteClicked: boolean = false;
  dateFrom: any;
  dateTo: any;
  ped: any;
  i94ExpDate: any;
  visaExpDate: any;
  h1L1statusStartDate: any;
  h1L1statusToDate: any;
  public defaultItem: { immigrationStatusDescription: string, immigrationStatusId: number } = { immigrationStatusDescription: "", immigrationStatusId: null };
  // public caseList: any;
  // public gridViewData: any[];
  // private notifier: NotifierService;
  // public pageSize = 10;
  // public skip = 0;
  // public gridState: DataStateChangeEvent = {
  //   skip: this.skip,
  //   take: this.pageSize
  // };
  // public caseStatus = "";
  // public caseType = "";
  private isNew: boolean;
  optionalFormControls = ["h1L1statusStartDate", "h1L1statusToDate", "priorJ1", "j1Notes","visaExpDate"];
  formErrors = {
    'countryOfBirth': '',
    'citizenship': '',
    'currentStatus': '',
    'ped': '',
    'i94ExpDate': '',
    'visaExpDate': '',
    'priorH1L1Time': '',
    'h1L1statusStartDate': '',
    'h1L1statusToDate': '',
    'priorJ1': '',
    'j1Notes': '',
    'dateFrom': '',
    'dateTo': '',
    'subjectTo212': '',
    'secondCitizenship': '',
    'nationality': '',

  };

  private editedRowIndex: number;


  validationMessages = {
    'countryOfBirth': {
      'required': 'Birth Country is required.',
    },
    'citizenship': {
      'required': 'Citizenship is required.',
    },
    'currentStatus': {
      'required': 'Current Status is required.',
    },
    'ped': {
      'required': 'PED is required.',
    },
    'i94ExpDate': {
      'required': 'Date is required.',
    },
    'visaExpDate': {
      'required': 'Date is required.',
    },
    'priorH1L1Time': {
      'required': 'This info is required.',
    },
    'h1L1statusStartDate': {
      'required': 'Date is is required.',
    },
    'h1L1statusToDate': {
      'required': 'Date is required.',
    },
    'j1Notes': {
      'required': 'These notes is required.',
    },
    'priorJ1': {
      'required': 'Prior J1 is required.',
    },
    'dateFrom': {
      'required': 'Date is required.',
    },
    'dateTo': {
      'required': 'Date is required.',
    },
    'subjectTo212': {
      'required': 'SubjectTo212 is required.',
    },
    'secondCitizenship': {
      'required': 'secondCitizenship is required.',
    },
    'nationality': {
      'required': 'nationality is required.',
    },
  };


  constructor(private _dafService: DafService, notifierService: NotifierService,  private router: Router, private formBuilder: FormBuilder) {
    this.notifier = notifierService; 

  }
  chartDatas: any;
  countrySourceList: Array<any> = [];
  CountryList: Array<any> = [];
  immStatusSourceList: Array<any> = [];
  immStatusList: Array<any> = [];
  citizenshipSourceList: Array<any> = [];
  citizenshipList: Array<any> = [];
  nationalitySourceList: Array<any> = [];
  nationalityList: Array<any> = [];
  multicitizenshipSourceList: Array<{ text: string; value: number }> = [];
  multicitizenshipList:Array<{ text: string; value: number }>= [];
  IBIform: FormGroup;
  IndFileHsitory: any;
  FileHistory: any;
  citizenships:any[];
  public deleteRow: any;

  ngOnInit() {
    this.getCountryList();
    this.getIndFileDetails();
  }

  getCountryList() {
    this._dafService.getCountriesCitizenship().subscribe((data: any) => {
      this.CountryList = data;
      this.countrySourceList = data;
      this.citizenshipList=data;
      this.citizenshipSourceList = data;
      this.nationalityList = data;
      this.nationalitySourceList = data;
      this.multicitizenshipList=[];
      this.multicitizenshipSourceList=[];;
      data.forEach(element => {
        if(element.nationality){
        this.multicitizenshipList.push({'text':element.nationality,'value':element.countryId});
        this.multicitizenshipSourceList.push({'text':element.nationality,'value':element.countryId});
        }
      });
  this.getImmigrationStatus();
      this.getCaseDetails(this.caseId);
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
  }

  getImmigrationStatus(){
    this._dafService.getImmigrationStatus().subscribe((data: any) => {
      this.immStatusList = data;
      this.immStatusSourceList = data;
  });
}

public pageChange(event: PageChangeEvent): void {
  this.skip = event.skip;
  this.loadItems();
}

private loadItems(): void {
  this.gridView = {
    data: this.view.slice(this.skip, this.skip + this.pageSize),
    total: this.view.length,
  };
}


  initializeForm() {
    try {
      let decodedNotes = this.decodeHTMLEntities(this.ibiDetails.notes);
      this.IBIform = this.formBuilder.group({
        individualBioInfoID: new FormControl(this.ibiDetails.individualBioInfoID),
        countryOfBirth: new FormControl({ value: this.ibiDetails.countryOfBirth, disabled: { onlySelf: true } }, Validators.required),
        nationality: new FormControl({ value: this.ibiDetails.nationality, disabled:  true  }, Validators.required),
        citizenship: new FormControl({ value: this.ibiDetails.citizenship, disabled: true }),
        currentStatus: new FormControl({ value: this.ibiDetails.currentStatus, disabled: true }),
        ped: new FormControl({ value: this.ibiDetails.ped, disabled: true }, Validators.required),
        i94ExpDate: new FormControl({ value: this.ibiDetails.i94ExpDate ? new Date(this.ibiDetails.i94ExpDate) : null, disabled: true }, Validators.required),
        statusDuration: new FormControl({ value: this.ibiDetails.statusDuration, disabled: true }, Validators.required),
        visaExpDate: new FormControl({ value: this.ibiDetails.visaExpDate ? new Date(this.ibiDetails.visaExpDate) : null, disabled: true }),
        priorH1L1Time: new FormControl({ value: this.ibiDetails.priorH1L1Time, disabled: true }, Validators.required),
        h1L1statusStartDate: new FormControl({ value: this.ibiDetails.h1L1statusStartDate ? new Date(this.ibiDetails.h1L1statusStartDate) : null, disabled: true }),
        h1L1statusToDate: new FormControl({ value: this.ibiDetails.h1L1statusToDate ? new Date(this.ibiDetails.h1L1statusToDate) : null, disabled: true }),
        priorJ1: new FormControl({ value: this.ibiDetails.priorJ1, disabled: true }),
        j1Notes: new FormControl({ value: this.ibiDetails.j1Notes, disabled: true }),
        dualCitizenship: new FormControl({ value: this.ibiDetails.dualCitizenship, disabled: true }, Validators.required),
        secondCitizenship: new FormControl({ value: this.ibiDetails.secondCitizenship, disabled: true }),
        dateFrom: new FormControl({ value: this.ibiDetails.dateFrom ? new Date(this.ibiDetails.dateFrom) : null, disabled: true }, Validators.required),
        dateTo: new FormControl({ value: this.ibiDetails.dateTo ? new Date(this.ibiDetails.dateTo) : null, disabled: true }, Validators.required),
        subjectTo212: new FormControl({ value: this.ibiDetails.subjectTo212, disabled: true }, Validators.required),
        notes: new FormControl({ value: decodedNotes, disabled: true }),
        alienNumber: new FormControl({ value: this.ibiDetails.alienNumber, disabled: true }, Validators.required)
      });
      if (this.ibiDetails.priorH1L1Time != true) {
        this.IBIform.get("ped").setValidators(null);
      }
      if (this.ibiDetails.priorJ1 != true) {
        this.IBIform.get("dateFrom").setValidators(null);
        this.IBIform.get("dateTo").setValidators(null);
        this.IBIform.get("subjectTo212").setValidators(null);
      }
      this.isPageReady = true;
      this.subscribeToFormControlValueChanges();
      this.IBIform.valueChanges.subscribe((val => {
        this.logOnChangeValidationErrors();
      }));
    }
    catch (err) {
      console.log(err);
    }
  }

  subscribeToFormControlValueChanges(){
    this.IBIform.get("dualCitizenship").valueChanges.subscribe(val => {
      if(val){
        this.ibiDetails.dualCitizenship = true;
      }
      else{
        this.ibiDetails.dualCitizenship = false;
      }
    });

  }

  countryFilterChange(filter: any) {
    try {
        this.CountryList = this.countrySourceList.filter(x => x.countryName.toLowerCase().includes(filter.toLowerCase()));
    }
    catch (err) {
      console.log(err);
    }
    finally {

    }
  }

  CurrentStatusFilter(filter: any) {
    try {
      this.immStatusList = this.immStatusSourceList.filter(x => x.immigrationStatusDescription.toLowerCase().includes(filter.toLowerCase()));
    }
    catch (err) {
      console.log(err);
    }
    finally {

    }
  }

  getCitizenship(event: any) {
    try {
      this.citizenshipList = this.citizenshipSourceList.filter(
        (s) => s.nationality?.toLowerCase().includes(event.toLowerCase())
      );
    }
    catch (err) {

    }
  }

  MultiCitizenshipFilter(event: any) {

    try {
      this.multicitizenshipList = this.multicitizenshipSourceList.filter(
        (s) => s.text?.toLowerCase().includes(event.toLowerCase())
      );
    }
    catch (err) {

    }
  }

  getNationality(filter: any) {
    try {
        this.nationalityList = this.nationalitySourceList.filter(x => (x.nationality ? x.nationality : '').toLowerCase().includes(filter.toLowerCase()));
    }
    catch (err) {
      console.log(err);
    }
    finally {

    }
  }
  getCaseDetails(caseId: number) {
    this.isPageReady = false;
    this._dafService.GetIndividualBio(this.caseId).toPromise().then((data: any) => {
      console.log(data);
      this.ibiDetails = new individualBioInfo(data);
      this.citizenships = this.ibiDetails.secondCitizenship?.split(',').map(function(item) {
        return parseInt(item, 10);
    });
      this.initializeForm();
    },
      (error) => {
        this.notifier.notify('error', 'Error occured while fetching the data');
        this.isPageReady = true;
      }
    );
  }

  getIndFileDetails() {
    this.isPageReady = false;
    this._dafService.GetFileHistoryInfo(this.caseId).subscribe((data: any) => {
      this.IndFileHsitory = data;
      this.IndFileHsitory.forEach(item => {
        if (item.receiptDate != undefined) {
          item.receiptDate = new Date(item.receiptDate);
        }
        if (item.validFrom != undefined) {
          item.validFrom = new Date(item.validFrom);
        }
        if (item.validTo != undefined) {
          item.validTo = new Date(item.validTo);
        }
      });
      this.view = this.IndFileHsitory;

      this.loadItems();
      this.isPageReady = true;
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      });
  }

  enableEdit() {
    try {
      this.isEditEnabled = true;
      this._dafService.disableEnableAllFormControls(this.IBIform, [], false);
      this.isGridEditable = true;

    }
    catch (err) {
      console.log(err);
    }
  }

  get formControls() {
    return this.IBIform.controls;
  }

  unlockFields() {
    try {
      this.isUnlocked = true;
      this._dafService.disableEnableFormControl(this.IBIform, ["countryOfBirth", "citizenship"], false);
    }
    catch (err) {
      console.log(err);
    }
  }

  lockFields() {
    try {
      this.isUnlocked = false;
      this._dafService.disableEnableFormControl(this.IBIform, ["countryOfBirth", "citizenship"], true);
    }
    catch (err) {
      console.log(err);
    }
  }

  resetForm() {
    try {
      this.getCaseDetails(this.caseId);
      this.isEditEnabled = false;
      this.isUnlocked = false;
      this.isGridEditable = false;
      this._dafService.disableEnableAllFormControls(this.IBIform, [], true);
    }
    catch (err) {
      console.log(err);
    }
  }

  logValidationErrors(group: FormGroup = this.IBIform): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid
          // && (abstractControl.touched || abstractControl.dirty)
        ) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }


  public addHandler(): void {
    this.editDataItem = new EditFilingDetails();
    this.editDataItem.formType="";
    this.editDataItem.classification ="";
    this.editDataItem.rn = "";
    this.editDataItem.receiptDate = null;
    this.editDataItem.validFrom =null;
    this.editDataItem.validTo =null;
    this.editDataItem.status = "";
    this.editDataItem.isFiledByPetitioner = false;
    this.editDataItem.fileHistoryID =0;

    this.isNew = true;
  }

  public editHandler(args: AddEvent): void {
    this.editDataItem = args.dataItem;

    this.isNew = false;
  }

  public cancelHandler(): void {
    this.closeEditor();
  }

  private closeEditor(): void {
    this.grid.closeRow(this.editedRowIndex);

    this.isNew = false;
    this.editedRowIndex = undefined;
  }

  logOnChangeValidationErrors(group: FormGroup = this.IBIform): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid
          && (abstractControl.touched || abstractControl.dirty)
        ) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  static stringMatchValidator(control: AbstractControl) {
    const password: string = control.get('citizenship').value; // get password from our password form control
    const confirmPassword: string = control.get('secondCitizenship').value; // get password from our confirmPassword form control
    // compare is the password math
    if (password === confirmPassword) {
      // if they don't match, set an error in our confirmPassword form control
      control.get('secondCitizenship').setErrors({ NoPassswordMatch: true });
    }
  }

  private readFormValues(group: FormGroup) {
    Object.keys(this.IBIform.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.readFormValues(abstractControl);
      }
      else {
        this.ibiDetails[key] = abstractControl.value;
      }
    });
  }

  public saveHandler(product: FilingDetails): void {
    if (product) {
      product.receiptDate = product.receiptDate ? new Date(product.receiptDate) : null;
      product.validFrom=product.validFrom?new Date(product.validFrom):null;
      product.validTo=product.validTo?new Date(product.validTo):null;

    }
    
    if(this.isNew){
      this.view.splice(0, 0, product);
      this.modifiedRecords.push(product);
    }
    else{
      Object.assign(
        this.view.find(({ fileHistoryID }) => fileHistoryID === product.fileHistoryID),
        product
      );

      if (this.modifiedRecords.find(({ fileHistoryID }) => fileHistoryID === product.fileHistoryID)) {
        Object.assign(
          this.modifiedRecords.find(({ fileHistoryID }) => fileHistoryID === product.fileHistoryID),
          product
        );
      }
      else {
        this.modifiedRecords.push(product);
      }
    }

    this.editDataItem = undefined;
    this.isNew = false;
    this.loadItems();
  }

  saveIndividualBioInfo() {
    this.isPageReady = false;
    this.logValidationErrors();
    this.isPageReady = true;

    this.readFormValues(this.IBIform);
    if (this.ibiDetails.dualCitizenship) {
      this.ibiDetails.secondCitizenship = this.citizenships?.toString();
    }

    this.ibiDetails.fileHistoryReport = this.modifiedRecords;
    this.ibiDetails.fileHistoryReport.forEach(
      item => {
        item.caseId = "0"
      }
    )
    if (this.ibiDetails.dateTo < this.ibiDetails.dateFrom) {
      this.notifier.notify('error', 'Date From value must be less than Date To value. Please check.');
      return false;
    }

    if (this.IBIform.valid) {

      if (this.ibiDetails.notes) {
        this.ibiDetails.notes = this.encodeHTMLEntities(this.ibiDetails.notes);
      }
      if (this.ibiDetails.dateFrom == null) {
        this.dateFrom = null;
      }
      else {
        this.dateFrom = this.ibiDetails.dateFrom.toDateString();
      }
      if (this.ibiDetails.dateTo == null) {
        this.dateTo = null;
      }
      else {
        this.dateTo = this.ibiDetails.dateTo.toDateString();
      }
      if (this.ibiDetails.ped == null) {
        this.ped = null;
      }
      else {
        this.ped = this.ibiDetails.ped.toDateString();
      }
      if (this.ibiDetails.i94ExpDate == null) {
        this.i94ExpDate = null;
      }
      else {
        this.i94ExpDate = this.ibiDetails.i94ExpDate.toDateString();
      }
      if (this.ibiDetails.visaExpDate == null) {
        this.visaExpDate = null;
      }
      else {
        this.visaExpDate = this.ibiDetails.visaExpDate.toDateString();
      }
      if (this.ibiDetails.visaExpDate == null) {
        this.visaExpDate = null;
      }
      else {
        this.visaExpDate = this.ibiDetails.visaExpDate.toDateString();
      }
      if (this.ibiDetails.h1L1statusStartDate == null) {
        this.h1L1statusStartDate = null;
      }
      else {
        this.h1L1statusStartDate = this.ibiDetails.h1L1statusStartDate.toDateString();
      }
      if (this.ibiDetails.h1L1statusStartDate == null) {
        this.h1L1statusStartDate = null;
      }
      else {
        this.h1L1statusStartDate = this.ibiDetails.h1L1statusStartDate.toDateString();
      }
      if (this.ibiDetails.h1L1statusToDate == null) {
        this.h1L1statusToDate = null;
      }
      else {
        this.h1L1statusToDate = this.ibiDetails.h1L1statusToDate.toDateString();
      }
      this._dafService.saveIndividualBioInfo(this.ibiDetails, this.ped, this.i94ExpDate, this.visaExpDate, this.h1L1statusStartDate, this.h1L1statusToDate, this.dateFrom, this.dateTo).toPromise().then(() => {
        this._dafService.disableEnableAllFormControls(this.IBIform, [], true);
        
        this.isEditEnabled = false;
        this.isUnlocked = false;
        this.isPageReady = true;
        this.notifier.notify('success', 'Biographical Information details have been saved successfully');
      }).catch((err)=>{
        this.notifier.notify('error', 'Operation Failed');
      });
    }
    else {
      this.notifier.notify('error', 'Please fill all mandatory fields');
    }
  }

  handleChange(event) {
    let priorTime = this.formControls['priorH1L1Time'].value;
    if (priorTime) {
      this.IBIform.get("ped").setValidators(Validators.required);
      this.IBIform.get("ped").updateValueAndValidity();
    }
    else {
      this.IBIform.get("ped").setValidators(null);
      this.IBIform.get("ped").updateValueAndValidity();
    }
  }

  handleJ1StatusChange(event) {
    let j1Status = this.formControls['priorJ1'].value;
    if (j1Status) {
      this.IBIform.get("dateFrom").setValidators(Validators.required);
      this.IBIform.get("dateTo").setValidators(Validators.required);
      this.IBIform.get("subjectTo212").setValidators(Validators.required);
    }
    else {
      this.IBIform.get("dateFrom").setValidators(null);
      this.IBIform.get("dateTo").setValidators(null);
      this.IBIform.get("subjectTo212").setValidators(null);
    }
    this.IBIform.get("dateFrom").updateValueAndValidity();
    this.IBIform.get("dateTo").updateValueAndValidity();
    this.IBIform.get("subjectTo212").updateValueAndValidity();
  }

  public setDependentIndexToDelete(args: any) {
    this.isDeleteClicked = true;
    this.deleteRow = args;
    this.deleteIndex = args.rowIndex;
    this.showDialog = true;
  }
  
  public deleteDependent() {
    this.view.splice(this.deleteIndex, 1);
    this.deleteRow.dataItem.changeType = "DELETE";
    this.modifiedRecords.push(this.deleteRow.dataItem);
    this.loadItems();
    this.showDialog = false;
  }

  public cancelDelete() {
    this.showDialog = false;
  }

  public onDialogClose() {
    this.showDialog = false;
  }

  encodeHTMLEntities(text) {
    let textArea = document.createElement('textarea');
    let escapeQuotes = text;
    if (text) {
      escapeQuotes = text.replace(/['"()]/g, escape);
    }

    textArea.innerText = escapeQuotes;
    let encodedOutput = textArea.innerHTML;
    let arr = encodedOutput.split('<br>');
    encodedOutput = arr.join('\n');
    return encodedOutput;
  }

  decodeHTMLEntities(text) {
    let textArea = document.createElement('textarea');
    let unescapeQuotes = text;
    if (text) {
      unescapeQuotes = text.replace("%27", unescape);
      unescapeQuotes = unescapeQuotes.replace("%22", unescape);
      unescapeQuotes = unescapeQuotes.replaceAll("%28", unescape);
      unescapeQuotes = unescapeQuotes.replaceAll("%29", unescape);
    }
    textArea.innerHTML = unescapeQuotes;
    return textArea.value;
  }
}


