import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { AddLCAService } from '../../../../immigrationcompilation/add-lca/service/add-lca.service';
import { addressWizardInfos } from '../../../Models/addressWizardInfos';
import { worksiteAddressModal } from '../../../Models/LCAAssessmentDetails';
import { DafService } from '../../../services/dafService';

@Component({
  selector: 'app-worksite-address-wizard',
  templateUrl: './worksite-address-wizard.component.html',
  styleUrls: ['./worksite-address-wizard.component.scss']
})
export class WorksiteAddressWizardComponent implements OnInit {

  public editForm: FormGroup = new FormGroup({
    caseID: new FormControl(''),
    caseSummaryID: new FormControl(0),
    addressWizardID: new FormControl(0),
    addressType: new FormControl('', Validators.required),
    entityName: new FormControl(''),
    streetNumber: new FormControl(''),
    streetName: new FormControl('', Validators.required),
    apt: new FormControl(null),
    city: new FormControl(null),
    state: new FormControl(null),
    postalCode: new FormControl(null),
    county: new FormControl(null),
    geography: new FormControl(null),
    geographyId: new FormControl(null),
    stateId: new FormControl(null),
    zipCodeId: new FormControl(null),
    cityId: new FormControl(null),
    countyId: new FormControl(null),
    countyOnOLFC: new FormControl(''),
    salary: new FormControl(0),
    flcWage: new FormControl(0),
    pwi: new FormControl(null),
    changeType: new FormControl(''),
  });

  @Input() public isNew = false;
  public active = false;
  private notifier: NotifierService;
  public statesList = [];
  public countyList = [];
  public geographyList = [];
  public countySource = [];
  public geographySource = [];
  public cityList = [];
  public citySource = [];
  statesDataSource = [];
  public zipcodeList = [];
  public zipcodeSource = [];
  public oesSOCCode;
  public wageLevelId;
  public addressTypesList = [];
  public addressTypes: any[] = [];


  @Input() public set model(product: worksiteAddressModal) {
    if (product){
      this.editForm.reset(product.addressWizardInfos);
      // toggle the Dialog visibility
      this.active = product.addressWizardInfos !== undefined;
      this.oesSOCCode = product.oesSOCCode;
      this.wageLevelId = product.wageLevelId;
    }
  }
  countrySourceList: Array<any> = [];
  CountryList: Array<any> = [];
  @Output() cancel: EventEmitter<undefined> = new EventEmitter();
  @Output() save: EventEmitter<addressWizardInfos> = new EventEmitter();

  constructor(notifier: NotifierService, private _dafService: DafService, public addLCAService: AddLCAService) { this.notifier = notifier; }

  ngOnInit(): void {
    this.getStates();
    this.GetAddressType();
  }

  public onSave(e: PointerEvent): void {
    e.preventDefault();
    this.save.emit(this.editForm.value);
    this.active = false;
  }

  public onCancel(e: PointerEvent): void {
    e.preventDefault();
    this.closeForm();
  }
  public ngOnChanges() {
    let changeType = "UPDATE";
    if (this.isNew)
      changeType = "INSERT"
    else {
      if (this.editForm.value.stateId)
      this.initializeForm();
    }
    this.editForm.controls["changeType"].setValue(changeType);
  }

  public closeForm(): void {
    this.active = false;
    this.cancel.emit();
  }

  async initializeForm() {
    await this.GetZipcodes(this.editForm.value.stateId);
    await this.GetCounty(this.editForm.value.stateId);
    await this.GetGeography(this.editForm.value.stateId);
    await this.GetCity(this.editForm.value.zipCodeId);
  }

  getStates() {
    this._dafService.getStates().subscribe((data: any) => {
      this.statesDataSource = data;
      this.statesList = data;
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
  }

  GetAddressType() {
    this.addLCAService.GetAddressType().subscribe((data: any) => {
      this.addressTypes = data;
      this.addressTypesList = data;
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
  }

  onStateChange(event) {
    this.editForm.get("state").setValue(event.stateName);
    this.editForm.controls["zipCodeId"].setValue(null);
    this.editForm.controls["cityId"].setValue(null);
    this.editForm.controls["cityId"].disable();
    this.editForm.controls["geographyId"].setValue(null);
    this.editForm.controls["countyId"].setValue(null);
    this.editForm.controls["flcWage"].setValue(null);
    this.GetZipcodes(event.stateId);
    this.GetCounty(event.stateId);
    this.GetGeography(event.stateId);
  }

  GetZipcodes(event): Promise<any> {
    this.zipcodeList = [];
    let res = this._dafService.getZipcodeByState(event).toPromise().then((data: any) => {
      this.zipcodeList = data;
      this.zipcodeSource = data;
      //if (data.length > 0) {
      //  data.forEach(element => {
      //    this.zipcodeList[index].push(element.zipcode.toString());
      //  });
      //}
      return Promise.resolve(data);
    },
      (error) => {
        this.notifier.notify('error', 'Error occured while getting the zipcode details');
      }
    );
    //this._dafService.disableEnableFormControl(this.caseSummaryForm,["signatoryZipcode"],false);
    return res;
  }

  onZipCodeChange(event) {
    this.editForm.get("postalCode").setValue(event.zipcode);
    this.editForm.controls["cityId"].setValue(null);
    this.editForm.controls["cityId"].enable();
    this.GetCity(event.zipCodeId);
  }

  GetCity(event): Promise<any> {
    this.cityList = [];
    let res = this._dafService.getCityByZipcode(event).toPromise().then((data: any) => {
      this.cityList = data;
      this.citySource = data;

      return Promise.resolve(data);
    },
      (error) => {
        this.notifier.notify('error', 'Error occured while fetching the city list');
      }
    );
    //this._dafService.disableEnableFormControl(this.caseSummaryForm,["signatoryZipcode"],false);
    return res;
  }
  GetCounty(event): Promise<any> {
    this.countyList = [];
    let res = this._dafService.getCountyByState(event).toPromise().then((data: any) => {
      this.countyList = data;
      this.countySource = data;

      return Promise.resolve(data);
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
    //this._dafService.disableEnableFormControl(this.caseSummaryForm,["signatoryZipcode"],false);
    return res;
  }

  onCityChange(event) {
    this.editForm.get("city").setValue(event.city);
  }

  public filterChange(filter: any): void {
      this.statesList = this.statesDataSource.filter(x => x.stateName.toLowerCase().includes(filter.toLowerCase()));
  }

  public zipFilterChange(filter: any): void {
    this.zipcodeList = this.zipcodeSource.filter(
      (s) => s.zipcode.includes(filter)
    );
  }

  public cityFilterChange(filter: any): void {
    this.cityList = this.citySource.filter(x => x.city.toLowerCase().includes(filter));
  }

  public msaFilterChange(filter: any): void {
    this.geographyList = this.geographySource.filter(x => x.value.toLowerCase().includes(filter));
  }

  public countyFilterChange(filter: any): void {
    this.countyList = this.countySource.filter(x => x.value.toLowerCase().includes(filter));
  }

  GetGeography(event): Promise<any> {
    this.geographyList = [];
    let res = this._dafService.getGeographyByState(event).toPromise().then((data: any) => {
      this.geographyList = data;
      this.geographySource = data;

      return Promise.resolve(data);
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
    //this._dafService.disableEnableFormControl(this.caseSummaryForm,["signatoryZipcode"],false);
    return res;
  }

  OnCountyChange(event) {
    this.geographyList = [];
    this.editForm.get("county").setValue(event.value);
    let selectedState = this.editForm.get("stateId").value;
    let res = this._dafService.GetGeographyByCounty(selectedState, event.id).toPromise().then((data: any) => {
      this.geographyList = data;
      this.geographySource = data;

      return Promise.resolve(data);
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
    //this._dafService.disableEnableFormControl(this.caseSummaryForm,["signatoryZipcode"],false);
    return res;
  }

  OnGeographyChange(event) {
    this.countyList = [];
    this.editForm.get("geography").setValue(event.value);
    let selectedState = this.editForm.get("stateId").value;
    this.getFLCWage(event.id);
    let res = this._dafService.GetCountyByGeography(selectedState, event.id).toPromise().then((data: any) => {
      this.countyList = data;
      this.countySource = data;

      return Promise.resolve(data);
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
    //this._dafService.disableEnableFormControl(this.caseSummaryForm,["signatoryZipcode"],false);
    return res;
  }

  getFLCWage(geographyId:number) {

    this.editForm.get("pwi").setValue(false);
    let res = this._dafService.getFLCWage(geographyId, this.oesSOCCode, this.wageLevelId).toPromise().then((data: any) => {
      this.editForm.get("flcWage").setValue(data);
      if (data >= this.editForm.get("salary").value)
        this.editForm.get("pwi").setValue(true);
      return Promise.resolve(data);
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );

    
  }
}
