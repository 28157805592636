import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { UserService } from 'src/app/immigrationcompilation/usermanagement/service/user.service';
import { GridDataResult, DataStateChangeEvent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { User } from '../usermanagement/model/user';
import { FormControl } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { MsalService } from '@azure/msal-angular';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-usermanagement',
  templateUrl: './usermanagement.component.html',
  styleUrls: ['./usermanagement.component.scss']
})
export class UsermanagementComponent implements OnInit {
  @ViewChild('openRoleModalButton', { static: false }) openRoleModalButton: ElementRef;
  @ViewChild('openEditModalButton', { static: false }) openEditModalButton: ElementRef;
  public selectedUser: User = new User();
  public deleteId = 0;
  public searchTerm = '';
  public showConfirmation = false;
  public userList: any[] = [];
  roleData:any[]=[];
  public isPageReady = true;
  public pageSize = 10;
  public skip = 0;
  public roleArray=[];
  public gridViewSearch: any;
  public gridView: any;
  public searchName = '';
  public OpenRoles=false;
  public email;
  public role="";
  public gridState: DataStateChangeEvent = {
    skip: this.skip,
    take: this.pageSize
  };
  public totalCount;
  private readonly notifier: NotifierService;
  constructor(
    private userService: UserService, private authService: MsalService,public route:ActivatedRoute,private router: Router,
  ) {
    this.email = authService.getAccount().userName;
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.role = params['role'];
    // Loads data to grid
    this.dataStateChange(this.gridState,this.role);
    });
  }

  viewUserRoles(dataItem: any) {
    this.roleData = Object.assign({}, dataItem);

    this.OpenRoles=true;
    this.openRoleModalButton.nativeElement.click();
}
addUser(){
  this.roleArray=[];
}
  updateUserList() {
    this.dataStateChange(this.gridState,this.role);
  }
  pageChange({ skip, take }: PageChangeEvent): void {
    this.skip = skip;
    this.pageSize = take;
    var t = (skip / this.pageSize) + 1;
  }
  dataStateChange(state: DataStateChangeEvent, role: string) {
    //this.skip = state.skip;
    //this.pageSize = state.take;

   
    this.gridState = state;
    var roles =JSON.parse(localStorage.getItem("isSuperAdmin"));
    //var userRole = roles.filter(item => { return item == "ImmigrationCompilation-Admin" || item == "SuperAdmin" || item == "CourierPackage-Admin" || item =="LCACompliance-Admin";})
    this.getUserData(state.skip, state.take);
    
  }

  getUserData(skip, take) {
    this.isPageReady = false;
    // Gets data from api
    this.userService.refreshUserList(this.email, this.role, skip, take, this.searchName).subscribe((data: any) => {
      // Binds the data to grid
      this.gridViewSearch = data.userList;
      this.totalCount = data.rowCount;
      //let filterData = this.gridViewSearch.filter(r => r.name.toLowerCase().includes(this.searchName)
      //  || r.email.toLowerCase().includes(this.searchName)
      //  );
      let dataParams = process(this.gridViewSearch, { skip: 0, take: this.pageSize }).data;
      this.gridView = { data: dataParams, total: this.totalCount };
      this.userList = data.userList;

      this.isPageReady = true;
    },
      (error) => {
        this.notifier.notify('error', 'Error occured while getting data from server');
        this.isPageReady = true;
      }
    );
  }
  deleteUser() {
    this.userService.deleteUser(this.deleteId).subscribe((data: any) => {
     this.confirmationDialog(false, data);
      this.dataStateChange(this.gridState,this.role);
    },
    (error) => {
     this.notifier.notify('error', 'Error occured while deleting the user from server');
      this.isPageReady = true;
    }
    );
    }
    editUser(user: User , edit: boolean) {
      this.selectedUser = Object.assign({}, user);
      this.roleArray=[];

      this.selectedUser.userRoles.forEach(element => {
        this.roleArray.push({'id':element.roleId,'roleName':element.roleName});
      });
    
      this.openEditModalButton.nativeElement.click();
    }
    confirmationDialog(open: boolean, data) {
      // Opens the confirmation dialog
      if (open) {
        this.showConfirmation = true;
      } else {
        this.showConfirmation = false;
      }
      // setting the delete ID to global variable
      if (data) {
        this.deleteId = data.userId;
      } else {
        this.deleteId = 0;
      }
    }
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
      this.searchName = filterValue;
      this.getUserData(0, this.pageSize);
      //this.dataStateChange(this.gridState, this.role);
      //let filterData = this.gridViewSearch.filter(r => r.name.toLowerCase().includes(filterValue)
      //|| r.email.toLowerCase().includes(filterValue)
      //  );
      //this.gridState.skip = 0;
      //this.gridView = process(filterData, this.gridState);
    }
}
