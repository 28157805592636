import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Lawyer } from '../model/lawyer';
import { LawyerService } from 'src/app/immigrationcompilation/lawyer-email/service/lawyer.service';
import { NgForm } from '@angular/forms';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { DateTimePickerModule } from '@progress/kendo-angular-dateinputs/dist/es2015/datetimepicker/datetimepicker.module';
import { NotifierService } from 'angular-notifier';
import { InsertLawyer } from '../model/insertLawyer';
@Component({
  selector: 'app-editLawyer',
  templateUrl: './editLawyer.component.html',
  styleUrls: ['./editLawyer.component.scss']
})
export class EditLawyerComponent implements OnInit {
  @Input() lawyer: Lawyer;
  @Output() updateLawyerList = new EventEmitter<boolean>();
  @ViewChild('closeButton',  {static: false}) closeButton: ElementRef;
  @ViewChild('addcloseButton',  {static: false}) addcloseButton: ElementRef;
  @ViewChild('autocomplete' ,  {static: false}) autocomplete: any;
  @ViewChild('notification', {static: false}) customNotificationTmpl;
  errorMessage: string;
  public pageSize = 10;
  public skip = 0;
  public gridState: DataStateChangeEvent = {
    skip: this.skip,
    take: this.pageSize
  };
  private notifier: NotifierService;
  public selectedRole = 0;
  public editlawyerDetails: Lawyer;
  public addLawyerDetails: Lawyer;
  public lawyerId: number;
  public addLawyerMail: string;
  public addLawyerName: string;
  constructor(
    private lawyerService: LawyerService,
    notifierService: NotifierService
  ) {
    this.notifier = notifierService;
    }

  ngOnInit() {
    // console.log(this.user);
  }

 closeForm() {
  const closeButton: HTMLElement = this.closeButton
    .nativeElement as HTMLElement;
  closeButton.click();
}
addcloseForm() {
  this.addLawyerMail = '';
  this.addLawyerName = '';
  this.selectedRole = 0;
  const addcloseButton: HTMLElement = this.addcloseButton
    .nativeElement as HTMLElement;
  addcloseButton.click();
}
editLawyer(editLawyerForm: NgForm) {
  this.lawyerId = this.lawyer.lawyerId;
  this.editlawyerDetails = editLawyerForm.value;
  this.editlawyerDetails.email = editLawyerForm.controls['email'].value;
  this.editlawyerDetails.name = editLawyerForm.controls['firstName'].value;
  this.editlawyerDetails.lawyerId = this.lawyerId;
  this.lawyerService.editLawyer(this.lawyerId, this.editlawyerDetails.email,this.editlawyerDetails.name).subscribe((data: any) => {
    this.closeForm();
    this.updateLawyerList.emit(true);
  },
  (error) => {
   this.notifier.notify('error', 'Error occured while updating the Lawyer details');
  }
  );
}
addLawyer(addLawyerForm: NgForm) {
  this.addLawyerDetails = addLawyerForm.value;
  this.addLawyerDetails.email = addLawyerForm.controls['email'].value;
  this.addLawyerDetails.name = addLawyerForm.controls['name'].value;
  var lawyers=new InsertLawyer();
  
  lawyers.name=this.addLawyerDetails.name;
  lawyers.email=this.addLawyerDetails.email;
  this.lawyerService.addLawyer(lawyers).subscribe((data: any) => {
   if (data.message.includes('exists')) {
    this.notifier.notify('error', 'Lawyer already exists');
    this.errorMessage = 'Lawyer details already exists';
    addLawyerForm.resetForm();
    this.selectedRole = 0;
   } else {
    this.addLawyerMail = '';
    this.addLawyerName = '';
    this.selectedRole = 0;
    addLawyerForm.resetForm();
    this.addcloseForm();
    this.updateLawyerList.emit(true);
   }
  },
  (error) => {
    this.notifier.notify('error', 'Error occured while saving the Lawyer details');
  }
  );
}

  selectAddLawyerDetails(email: any) {
    this.addLawyerMail = email.mail;
    this.addLawyerName = email.displayName;
}
}

