import { Component, Input, OnInit } from '@angular/core';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { EYIModel } from '../../../Model/EYIModel';

@Component({
  selector: 'app-view-case-eyiupload',
  templateUrl: './view-case-eyiupload.component.html',
  styleUrls: ['./view-case-eyiupload.component.scss']
})
export class ViewCaseEYIUploadComponent implements OnInit {
  public pageSize = 10;
  public skip = 0;
  public caseEYIList: any;
  public gridViewData: any[];
  @Input() documentInfo: EYIModel[] = [];
  @Input() packagesStatus: any;
  public gridState: DataStateChangeEvent = {

    skip: this.skip,
    take: this.pageSize
  };
  constructor() { }

  ngOnInit(): void {
    
  }
  ngOnChanges(): void {
    console.log(this.documentInfo);
    this.skip = 0;
    this.gridState.skip = this.skip;
    this.loadData();
  }

  pageChange(event: DataStateChangeEvent, gridId: string): void {

      this.gridState = event;
      this.caseEYIList = process(this.documentInfo, this.gridState);
      this.gridViewData = this.documentInfo;
  }

  loadData(): void {
    if (Array.isArray(this.documentInfo)) {

      this.caseEYIList = process(this.documentInfo, this.gridState);
      this.gridViewData = this.documentInfo;
    }
  }
}
