export class FilingDetails {
    fileHistoryID :number;
    caseId :string;
    casesummaryId :number;
    formType :string;
    classification :string;
    rn :string;
    receiptDate:Date;
    validFrom :Date;
    validTo :Date;
    fileStatus :string;
    isFiledByPetitioner :boolean;
    changeType: string;
}


export class EditFilingDetails {
    fileHistoryID :number;
    formType :string;
    classification :string;
    rn :string;
    receiptDate:Date;
    validFrom :Date;
    validTo :Date;
    status :string;
    isFiledByPetitioner :boolean;
}
