import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { AwaitDocExtractionService } from '../awaitdocextraction/service/awaitdocextraction.service';
import { DashType } from '@progress/kendo-drawing';
import { DashboardService } from 'src/app/immigrationcompilation/dashboard/service/dashboard.service';
import { NotifierService } from 'angular-notifier';
import { DashboardView } from '../awaitdocextraction/model/dashboardview';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { DateFilterModel } from '../dashboard/dashboard.component';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-compilationfailed',
  templateUrl: './compilationfailed.component.html',
  styleUrls: ['./compilationfailed.component.scss']
})
export class CompilationfailedComponent implements OnInit, AfterViewInit {
  @ViewChild('openDocModalButton', { static: false }) openDocModalButton: ElementRef;
  public caseList: any;
  public gridViewData: any[];
  private notifier: NotifierService;
  public isClicked = false;
  public dashboardView: DashboardView = new DashboardView();
  public pageSize = 10;
  public skip = 0;
  dateFilterModel: DateFilterModel;
  public gridState: DataStateChangeEvent = {
    skip: this.skip,
    take: this.pageSize
  };
    fromreportDate: any;
    toreportDate:any;

    constructor(private dashboardService: AwaitDocExtractionService, private commonService: CommonService) {
    this.dateFilterModel = new DateFilterModel(new Date(2020, 1, 1), new Date());
  }
  take: number;
  public isPageReady = true;

  ngOnInit() {
    this.getReportDate();
  }

  resetDashboard(){
    this.commonService.resetDate.subscribe(data=>{
      this.commonService.updateDate({fromDate:data,toDate:data});
    });
  }
  getReportDate(){
    this.commonService.currentData.subscribe(data => {
      this.fromreportDate = data.fromDate;
      this.toreportDate=data.toDate;
      this.dateFilterModel = new DateFilterModel(new Date(this.fromreportDate),new Date(this.toreportDate));
        this.refreshCaseList(this.gridState);
    });
  }
  filterCaseList(){
    this.commonService.updateDate({fromDate:this.dateFilterModel.startDate.toDateString(),toDate:this.dateFilterModel.endDate.toDateString()});
  }
  refreshCaseList(state: DataStateChangeEvent) {
    this.isPageReady=false;
    this.gridState = state;
      this.dashboardService.getCaseDetailsWithStatus('CompilationFailed', this.dateFilterModel.startDate.toDateString(), this.dateFilterModel.endDate.toDateString()).subscribe((data: any) => {
      // Binds the data to grid
      this.caseList = process(data, this.gridState);
      console.log(this.caseList);
      this.gridViewData = data;
      this.take = 10;
      this.isPageReady=true;
    },
      (error) => {
        this.isPageReady=true;
        this.notifier.notify('error', 'Error occured while getting data from server');
      }
    );
  }
  ngAfterViewInit() {
    //Adds search placeholder to all filter textbox
    var result = document.getElementsByClassName("k-filtercell-wrapper");
    for (let i = 0; i < result.length; i++) {
      result[i].children[0].setAttribute("placeholder", "search");
    }
  }
  resubmit(insZoomId: any, id: any, message: any) {
    this.dashboardService.resubmitForCompilation(insZoomId, id, message).subscribe((data: any) => {
      this.refreshCaseList(this.gridState);
      this.notifier.notify('success', 'Case Id is submitted for recompilation');
    },
      (error) => {
        this.notifier.notify('error', 'Error occured while updating data from server');
      }
    );
  }
}

