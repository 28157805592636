import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { NotificationService } from '@progress/kendo-angular-notification';
import { NotifierService } from 'angular-notifier';
import { WorkExperience } from 'src/app/DAF/Models/WorkExperience';
import { DafService } from 'src/app/DAF/services/dafService';

@Component({
  selector: 'app-experience-wizard',
  templateUrl: './experience-wizard.component.html',
  styleUrls: ['./experience-wizard.component.scss']
})
export class ExperienceWizardComponent implements OnInit {
  public isReady=false;
  public WorkSite="Work Experience";
  private hideAfter = 2000;
  public WorkSiteDetails: any = [];
  radioButtonOptionValues: Array<any> = [{ value: true, text: "Yes" }, { value: false, text: "No" }];
  public dateDifference=0;
  private notifier: NotifierService;
  public primaryworksiteInfo=[];
  public view: Array<WorkExperience> = [];
  public pageSize = 5;
  public skip = 0;
  public gridView: GridDataResult;
  private isNew: boolean;
  public isGridEditable: boolean;
  public formGroup: FormGroup;
  @ViewChild(GridComponent, {static: false})
  private grid: GridComponent;
  private editedRowIndex: number;
  public showConfirmation: boolean = false;
  public deleteId: Number = 0;

  @Input() caseSummaryId = 0;


  @Output() cancel: EventEmitter<undefined> = new EventEmitter();




  constructor(private formBuilder: FormBuilder,@Inject(NotificationService) private notificationService: NotificationService, private _dafService: DafService,notifier: NotifierService) 
  {   
    this.notifier = notifier; 
  }



  ngOnInit(): void {
    this.getWorkExperienceDetails()

  }


  private onDocumentClick(e: Event): void {
    if (
      this.formGroup &&
      this.formGroup.valid &&
      !this.matches(
        e.target,
        "#productssGrid tbody *, #productssGrid .k-grid-toolbar .k-button"
      )
    ) {
      this.saveCurrent();
    }
  }

  private saveCurrent(): void {
    if (this.formGroup) {
      // this.service.save(this.formGroup.value, this.isNew);
      let maxId: number = Math.max.apply(Math, this.view.map(function (o) { return o.workExperienceWizardID; }));
      let sdDetails = this.formGroup.value;
      let index = maxId + 1;
      if (this.isNew) {       
        sdDetails.workExperienceWizardID = index;
        sdDetails.isNew = true;
        this.view.splice(0, 0, sdDetails);
      } else {
        Object.assign(
          this.view.find(({ workExperienceWizardID }) => workExperienceWizardID === sdDetails.workExperienceWizardID),
          sdDetails
        );      
      }
      this.closeEditor();
      this.loadItems();
    }
  }

  matches(el, selector) {
    return (el.matches || el.msMatchesSelector).call(el, selector);
  }


  private loadItems(): void {
    this.gridView = {
      data: this.view.slice(this.skip, this.skip + this.pageSize),
      total: this.view.length,
    };
  }


  getWorkExperienceDetails(){
    this.isReady = false;
    this._dafService.getWorkExperienceDetails(this.caseSummaryId).toPromise().then((data: any) => {
      this.primaryworksiteInfo = data;

      this.primaryworksiteInfo.forEach(item=>{
        if (item.fromDates!= undefined) {
          item.fromDates = new Date(item.fromDates);        
      } 
      if (item.toDates != undefined) {
        item.toDates = new Date(item.toDates);        
    }


      });

      this.view = this.primaryworksiteInfo;

      this.loadItems();
      
     
      this.isReady = true;
    },
      (error) => {
        console.log(error)
        this.isReady = true;
      }
    );
  }

  public deleteItem() {
    //Sends delete request to api
    this._dafService.deleteWorkExperienceDetails(this.deleteId).toPromise().then(() => {
      this.getWorkExperienceDetails();
      this.showConfirmation = false;
      this.notifier.notify('success', 'WorkSite Experience details have been saved successfully');
    }).catch((err) => {
      this.notifier.notify('error', 'Operation Failed');
    });
  }

  public handleToDateChange(dataItem) {
    this.formGroup.controls["totalNumberOfDays"].setValue(null);
    this.formGroup.controls["months"].setValue(null);
    this.formGroup.controls["years"].setValue(null);
    this.formGroup.controls["days"].setValue(null);
    if (dataItem.value.toDates && dataItem.value.fromDates) {
      this.formGroup.controls["totalNumberOfDays"].setValue(this.getDayDiff(dataItem.value.fromDates, dataItem.value.toDates).toString());
      this.formGroup.controls["months"].setValue(this.getMonthDiff(dataItem.value.fromDates, dataItem.value.toDates).toString());
      this.formGroup.controls["years"].setValue(this.getYearDiff(dataItem.value.fromDates, dataItem.value.toDates).toString());
      this.formGroup.controls["days"].setValue(this.getDayDiff(dataItem.value.fromDates, dataItem.value.toDates).toString());
    }   
  }

  public getDayDiff(startDate: Date, endDate: Date): number {
  const msInDay = 24 * 60 * 60 * 1000;

  return Math.round(
    Math.abs(Number(endDate) - Number(startDate)) / msInDay
  );
  }

  public getYearDiff(startDate: Date, endDate: Date): number {
    let yearsDiff = endDate.getFullYear() - startDate.getFullYear();
    return yearsDiff;
  }

  public getMonthDiff(startDate: Date, endDate: Date): number {
    let years = this.getYearDiff(startDate, endDate);
    let months = (years * 12) + (endDate.getMonth() - startDate.getMonth());
    return months;
  }
  public saveHandler({ sender, rowIndex, formGroup, isNew }) {
    //this.submitted = true;
    //this.isRequiredFailed = false;
    //this.errorList = '';

    //Checks if formgroup is valid
    if (this.formGroup.valid) {
      //this.isPageReady = false;
      
      //Checks if it is new record or existing record
      if (this.isNew) {
        const product = formGroup.value;
        product.workExperienceWizardID = 0;
        product.caseID = "";
        product.caseSummaryID = 0;
        let workExperienceArray = [];
        workExperienceArray.push(product);
        //Post data to api for update
        this._dafService.saveWorkExperienceDetails(this.caseSummaryId, workExperienceArray).toPromise().then(() => {
          sender.closeRow(rowIndex);
          this.getWorkExperienceDetails();
          
          this.notifier.notify('success', 'WorkSite Experience details have been saved successfully');
        }).catch((err) => {
          this.notifier.notify('error', 'Operation Failed');
        });
      }
      else {
        const product = formGroup.value;
        product.caseID = "";
        product.caseSummaryID = 0;
        let workExperienceArray = [];
        workExperienceArray.push(product);
        //Post data to api for update
        this._dafService.updateWorkExperienceDetails(this.caseSummaryId, workExperienceArray).toPromise().then(() => {
          sender.closeRow(rowIndex);
          this.getWorkExperienceDetails();

          this.notifier.notify('success', 'WorkSite Experience details have been saved successfully');
        }).catch((err) => {
          this.notifier.notify('error', 'Operation Failed');
        });
      }

    }
    
  }

 


  public addHandler(): void {
    this.closeEditor();

    this.formGroup = new FormGroup({
      employerName: new FormControl(null, Validators.required),
      jobTitle: new FormControl(null, Validators.required),
      fromDates: new FormControl(null),
      toDates: new FormControl(null),
      totalNumberOfDays: new FormControl(null),
      days: new FormControl(null),
      months: new FormControl(null),
      years: new FormControl(null)
    });
    this.isNew = true;

    this.grid.addRow(this.formGroup);
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    //Closes the editor
    this.closeEditor();

    //Creates form group for edit feature
    this.formGroup = this.createFormGroup(dataItem);
    this.isNew = false;
    this.editedRowIndex = rowIndex;

    //Opens the editor with data populated
    sender.editRow(rowIndex, this.formGroup);
  }

  public cancelHandler(): void {
    this.closeEditor();
  }

  private closeEditor(): void {
    this.grid.closeRow(this.editedRowIndex);

    this.isNew = false;
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public removeHandler({ dataItem }) {
    //Call for delete confirmation
    this.confirmationDialog(true, dataItem)
  }

  confirmationDialog(open: boolean, data) {
    //Opens the confirmation dialog
    if (open) {
      this.showConfirmation = true;
    }
    else {
      this.showConfirmation = false;
    }

    //setting the delete ID to global variable
    if (data)
      this.deleteId = data.workExperienceWizardID;
    else
      this.deleteId = 0;
  }

  

  createFormGroup(dataItem) {
    return new FormGroup({
      workExperienceWizardID: new FormControl(dataItem.workExperienceWizardID),
      employerName: new FormControl(dataItem.employerName, Validators.required),
      jobTitle: new FormControl(dataItem.jobTitle, Validators.required),
      fromDates: new FormControl(dataItem.fromDates),
      toDates: new FormControl(dataItem.toDates),
      totalNumberOfDays : new FormControl(dataItem.totalNumberOfDays),
      days: new FormControl(dataItem.days),
      months: new FormControl(dataItem.months),
      years: new FormControl(dataItem.years)
    });
  }



  saveData(){
    this.WorkSiteDetails = [];
    var saveValues = this.gridView.data;

     
   
    saveValues.forEach(item => {
      if(item.periodFrom==""){
        this.WorkSiteDetails=[];
        this.invalidMessage();
        
      }
      else if (item.periodFrom && item.periodTO && item.periodFrom > item.periodTO) {
        this.notificationService.show({
          content: "Please enter valid dates. End Date should be greather than or equal to Start Date.",
          position: { horizontal: "right", vertical: "top" },
          animation: { type: "fade", duration: 800 },
          type: { style: "warning", icon: true },
          hideAfter: this.hideAfter,
        });
      }    
      else{
     
      var workSite=new WorkExperience();
      workSite.workExperienceWizardID=0;
      workSite.employerName=item.worksiteItenary;
      workSite.jobTitle=item.clientsideAmmendment;
      workSite.fromDates = item.clientlegalName;
      workSite.toDates = item.periodFrom;
      workSite.totalNumberOfDays = item.periodTo;
      workSite.days=item.daysPerMonth.toString();
      workSite.months="";
      workSite.years=item.streetNumber;
      this.WorkSiteDetails.push(workSite);
      }
    });
    if(this.WorkSiteDetails.length>0){
      this._dafService.saveWorkExperienceDetails(this.caseSummaryId, this.WorkSiteDetails).toPromise().then(() => {
        this.notifier.notify('success', 'WorkSite Experience details have been saved successfully');
      }).catch((err) => {
        this.notifier.notify('error', 'Operation Failed');
      });


    }
  
  }


  invalidMessage(){
    this.notificationService.show({
      content: "Please ensure to add all the required Data",
      position: { horizontal: "right", vertical: "top" },
      animation: { type: "fade", duration: 800 },
      type: { style: "warning", icon: true },
      hideAfter: this.hideAfter,
    });
  }


  public onCancel(e: PointerEvent): void {
    e.preventDefault();
    this.closeForm();
}


public closeForm(): void {
    this.cancel.emit();
}



  }



