import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CaseTypeAssessmentComponent } from './case-type-assessment/case-type-assessment.component';
import { GeneralDetailsComponent } from './general-details/general-details.component'
import { LCAAssessmentComponent } from './lca-assessment/lca-assessment.component';


const routes: Routes = [
  {
    path: 'generaldetails', pathMatch: 'full', component: GeneralDetailsComponent
  },
  {
    path: 'lcaassessment', pathMatch: 'full', component: LCAAssessmentComponent
  },
  {
    path: 'casetypeassessment', pathMatch: 'full', component: CaseTypeAssessmentComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AssessmentFormsRoutingModule { }
