import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { User } from 'src/app/immigrationcompilation/usermanagement/model/user';
import { CourierPackageService } from '../../service/courier-package.service';
import { DocumentExtractionModel } from '../../Model/DocumentExraction.Model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-document-extraction-info',
  templateUrl: './document-extraction-info.component.html',
  styleUrls: ['./document-extraction-info.component.scss']
})
export class DocumentExtractionInfoComponent implements OnInit {

  @Input() documentInfo: DocumentExtractionModel;
  @Input() packagesStatus: any;
  @ViewChild('closeButton',  {static: false}) closeButton: ElementRef;
  @ViewChild('addcloseButton',  {static: false}) addcloseButton: ElementRef;
  @ViewChild('autocomplete' ,  {static: false}) autocomplete: any;
  @ViewChild('notification', { static: false }) customNotificationTmpl;
  @Output() save = new EventEmitter<string>();
  errorMessage: string;
  public searchDetails: any;
  public pageSize = 10;
  public skip = 0;
  public documents:DocumentExtractionModel;
  private notifier: NotifierService;
  public selectedRole = 0;
  public userId: number;
  public selectedDate="";
  public addUserMail: string;
  public addUserName: string;
  public isEditable = false;
  public accessTypeList = [
    { "id": 1, "value": "Firm Only" },
    { "id": 2, "value": "All" },
    { "id": 3, "value": "Applicant Only" },
    { "id": 4, "value": "Sponsor Only" }
  ];

  constructor(private packageService:CourierPackageService,public datepipe: DatePipe,
    notifierService: NotifierService
  ) {
    this.notifier = notifierService;
    }

  ngOnInit() {
    this.selectedDate=this.datepipe.transform(this.documentInfo.pickupDate, 'dd-MMM-yyyy');
  }

  ngOnChanges() {
    if (this.packagesStatus == "Failure")
      this.isEditable = true;
    else
      this.isEditable = false;
  }

  closeForm() {
    const closeButton: HTMLElement = this.closeButton
      .nativeElement as HTMLElement;
    closeButton.click();
  }

  addcloseForm() {
    this.addUserMail = '';
    this.addUserName = '';
    this.selectedRole = 0;
    this.autocomplete.reset();
    const addcloseButton: HTMLElement = this.addcloseButton
      .nativeElement as HTMLElement;
    addcloseButton.click();
  }

  selectAddUserDetails(email: any) {
    this.addUserMail = email.mail;
    this.addUserName = email.displayName;
    this.autocomplete.reset();
  }

  saveData() {

    if (!this.documentInfo.caseId || !this.documentInfo.accessTypeId || !this.documentInfo.carrierName || !this.documentInfo.firstName || !this.documentInfo.lastName || !this.documentInfo.wayBill || !this.documentInfo.documentName || !this.documentInfo.packageType) {
      this.notifier.notify('error', 'Please enter value for all the fields');
      return;
    }

    this.packageService.SaveDocumentExtraction(this.documentInfo).subscribe((result) => {
      document.getElementById("btnClose").click();
      this.notifier.notify('success', 'Package details have been saved successfully');
      this.save.emit("refresh");
    }, error => {
      this.notifier.notify('error', 'Error occured while saving the package details');
    });
  }
}

