import { Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AddEvent, CellClickEvent, GridComponent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { NotifierService } from 'angular-notifier';
import { Subscription } from 'rxjs';
import { checklistItems, documentChecklist } from 'src/app/DAF/Models/DocuentChecklist';
import { DafService } from 'src/app/DAF/services/dafService';

@Component({
  selector: 'app-document-checklist',
  templateUrl: './document-checklist.component.html',
  styleUrls: ['./document-checklist.component.scss']
})
export class DocumentChecklistComponent implements OnInit {

  documentChecklistForm: FormGroup;
  documentChecklist: documentChecklist;
  public formGroup: FormGroup;
  isPageReady: boolean;
  @Input() caseSummaryId = 0;
  isEditEnabled: boolean;
  isGridEditable: boolean;
  public documentGridResult: GridDataResult;
  public pageSize = 15;
  public skip = 0;
  @ViewChild(GridComponent, {static: false})
  private grid: GridComponent;
  private docClickSubscription: Subscription = new Subscription();
  isDeleteClicked: boolean = false;
  public checklistItems: Array<checklistItems> = [];
  private isNew: boolean;
  editedRowIndex: number;
  private notifier: NotifierService;
  public editDataItem: checklistItems;


  deleteIndex: any;
  showDialog: boolean;
  constructor( private formBuilder: FormBuilder,
               private _dafService: DafService,
               private renderer: Renderer2,notifier: NotifierService) { this.notifier = notifier; }

  ngOnInit() {
    // this.docClickSubscription.add(
    //   this.renderer.listen("document", "click", this.onDocumentClick.bind(this))
    // );
    this.getDocumentChecklistDetails();
  }

  initializeForm() {
    let decodedNotes = this.decodeHTMLEntities(this.documentChecklist.notes);
    try {
      this.documentChecklistForm = this.formBuilder.group({  
        notes: new FormControl({ value: this.documentChecklist.notes, disabled: true }),
      });     
    }
    catch (err) {
      this.isPageReady = true;
      console.log(err);
    }
  }

  getDocumentChecklistDetails(){
    this.isPageReady = false;
    this._dafService.getDocumentChecklistDetails(this.caseSummaryId).toPromise().then((data: any) => {
      
    
      this.documentChecklist = new documentChecklist();
      this.documentChecklist=data;
      this.checklistItems=this.documentChecklist.checklistDetails;
      this.documentChecklist.notes = this.documentChecklist.notes;
  
      this.loadItems();
      this.initializeForm();
      this.isPageReady = true;
    },
      (error) => {
        console.log(error)
        this.isPageReady = true;
      }
    );


  }

  resetForm(){
    this.isEditEnabled = false;
    this._dafService.disableEnableAllFormControls(this.documentChecklistForm, [], true);
    this.isGridEditable = false;
    this.getDocumentChecklistDetails();
  }

  enableEdit(){
    this.isEditEnabled = true;
    this._dafService.disableEnableAllFormControls(this.documentChecklistForm, [], false);
    this.isGridEditable = true;
  }

  saveDocumentChecklistDetails() {
    this.documentChecklist = this.documentChecklistForm.value;

    var encodedList = this.checklistItems.map(x => {
      return { ...x, documentName : this.encodeHTMLEntities(x.documentName) };
    });
    this.documentChecklist.checklistDetails = encodedList;
    this.isPageReady = false;
    this.documentChecklist.notes = this.documentChecklist.notes ? this.encodeHTMLEntities(this.documentChecklist.notes) : null;
    this._dafService.saveDocumentChecklistDetails(this.caseSummaryId, this.documentChecklist).toPromise().then(() => {
      this._dafService.disableEnableAllFormControls(this.documentChecklistForm, [], true);
      this.getDocumentChecklistDetails();
      this.isEditEnabled = false;
      // this.isUnlocked = false;
      this.isPageReady = true;
      this.notifier.notify('success', 'Document Checklist details have been saved successfully');
    }).catch((err) => {
      this.notifier.notify('error', 'Operation Failed');
    });
  }


  public ngOnDestroy(): void {
    this.docClickSubscription.unsubscribe();
  }

  // public relation(id: number): any {
  //   return this.relationTypeList.find((x) => x.relationId === id);
  // }


  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems();
  }
  
  private loadItems(): void {
    this.documentGridResult = {
      data: this.checklistItems.slice(this.skip, this.skip + this.pageSize),
      total: this.checklistItems.length,
    };
  }
  public addHandler(): void {
    this.editDataItem = new checklistItems();
    this.editDataItem.recievedDrafted=false;
    this.editDataItem.requestDraft=false;
    this.editDataItem.na=false;
    this.editDataItem.documentChecklistDetailId=0;
    this.isNew = true;
  }

  public saveHandler(product: checklistItems): void {
    if(this.isNew){
      this.checklistItems.splice(0, 0, product);
    }
    else{
      Object.assign(
        this.checklistItems.find(({ documentChecklistDetailId }) => documentChecklistDetailId === product.documentChecklistDetailId),
        product
      );  
    }

    this.editDataItem = undefined;
    this.isNew=false;
    this.loadItems();

}





  public cancelHandler(): void {
    this.closeEditor();
  }

  private closeEditor(): void {
    this.grid.closeRow(this.editedRowIndex);

    this.isNew = false;
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }


  public editHandler(args: AddEvent): void {

   
    this.editDataItem = args.dataItem;

 
    this.isNew = false;
}





  createFormGroup(dataItem) {
    return new FormGroup({
      name: new FormControl(dataItem.name, Validators.required),
      individual: new FormControl(dataItem.individual, Validators.required),
      relation: new FormControl(dataItem.relation),
      isReceivedOrDrafted: new FormControl(dataItem.isReceivedOrDrafted),
      isRequestOrDraft: new FormControl(dataItem.isRequestOrDraft),
      isNA: new FormControl(dataItem.isNA),
      notes: new FormControl(dataItem.notes)
    });
  }

  matches(el, selector) {
    return (el.matches || el.msMatchesSelector).call(el, selector);
  }

  public setIndexToDelete(args: any){
    this.isDeleteClicked = true;
    this.deleteIndex = args.rowIndex;
    this.showDialog = true;
  }

  public deleteDependent(){
    this.checklistItems.splice(this.deleteIndex, 1);
    this.loadItems();
    this.showDialog = false;
  }

  public cancelDelete(){
    this.showDialog = false;
  }

  public onDialogClose(){
    this.showDialog = false;
  }

  encodeHTMLEntities(text) {
    let textArea = document.createElement('textarea');
    let escapeQuotes = text;
    //if (text) {
    //  escapeQuotes = text.replace(/[()]/g, escape);
    //}

    textArea.innerText = escapeQuotes;
    let encodedOutput = textArea.innerHTML;
    let arr = encodedOutput.split('<br>');
    encodedOutput = arr.join('\n');
    return encodedOutput;
  }

  decodeHTMLEntities(text) {
    let textArea = document.createElement('textarea');
    let unescapeQuotes = text;
    //if (text) {
    //  unescapeQuotes = text.replace("%27", unescape);
    //  unescapeQuotes = unescapeQuotes.replace("%22", unescape);
    //}
    textArea.innerHTML = unescapeQuotes;
    return textArea.value;
  }
}
