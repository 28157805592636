import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '@progress/kendo-angular-notification';
import { NotifierService } from 'angular-notifier';
import { WorkSitewizards } from 'src/app/DAF/Models/websiteWizard';
import { DafService } from 'src/app/DAF/services/dafService';

@Component({
  selector: 'app-worksite-wizard',
  templateUrl: './worksite-wizard.component.html',
  styleUrls: ['./worksite-wizard.component.scss']
})
export class WorksiteWizardComponent implements OnInit {
  public emailForm: FormGroup;
  public isReady=false;
  public WorkSite="Worksite Itinerary";
  private hideAfter = 2000;
  public WorkSiteDetails: any = [];
  radioButtonOptionValues: Array<any> = [{ value: true, text: "Yes" }, { value: false, text: "No" }];

  public dateDifference=0;
  private notifier: NotifierService;
  public primaryworksiteInfo:WorkSitewizards[]=[];
  statesDataSource = [];
  statesList = [];
  public zipcodeList = [];
  public addressTypesList = [];
  public state;
  public zipcodeSource = [];
  public cityArray = [[]];
  public countyArray = [[]];
  citySourceArray: any[] = [];
  cityDataArray: any[] = [];
  countyDataArray: any[] = [];
  public cityList = [];
  public citySource = [];
  public countyList = [];
  public countySource = [];
  public disabled = true;
  public countydisabled = [true];
  public citydisabled = [true];

  @Input() caseSummaryId = 0;


  @Output() cancel: EventEmitter<undefined> = new EventEmitter();

  optionalFormControls = ["middleName", "signatoryName", "signatoryTitle", "signatoryTelephone", "apt"];

  constructor(private formBuilder: FormBuilder,@Inject(NotificationService) private notificationService: NotificationService, private _dafService: DafService,notifier: NotifierService) 
  {   
    this.createForm();
    this.notifier = notifier; 
  }

  createForm(){
   this.emailForm = this.formBuilder.group({
     emails: this.formBuilder.array([this.createEmailFormGroup()])
   });
   this.isReady=true;
  }

  ngOnInit(): void {
    this.getMasterData();
  }

  async getMasterData() {
    this.getStates();
    this.getprimaryworkSiteItineraryDetails();
  }

  public stateFilterChange(filter: any, i: number): void {
    this.statesList[i] = this.statesDataSource.filter(x => x.stateName.toLowerCase().includes(filter.toLowerCase()));
  }

  public zipFilterChange(filter: any, i: number): void {
    this.zipcodeList[i] = this.zipcodeSource[i].filter(x => x.zipcode.includes(filter));
  }

  public cityFilterChange(filter: any, i: number): void {
    this.cityArray[i] = this.citySource[i].filter(x => x.city.toLowerCase().includes(filter));
  }

  public countyFilterChange(filter: any, i: number): void {
    this.countyArray[i] = this.countySource[i].filter(x => x.value.toLowerCase().includes(filter));
  }

  getStates() {
    this._dafService.getStates().subscribe((data: any) => {
      this.statesDataSource = data;
      this.statesList = data;
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
  }

  onStateChange(event, index) {
    var selectedControl = this.emailForm.controls.emails['controls'][index];
    var datas = selectedControl.value;
    datas.zipCodeId = null;
    datas.cityId = null;
    datas.countyId = null;
    selectedControl.controls["cityId"].disable();
    selectedControl.setValue(datas);
    
        //datas.zipCode="";
    //this.emailForm.controls.emails.value[index].zipCodeId
    //this.emailForm.controls["zipCodeId"][index].setValue(null);
    //this.emailForm.controls["cityId"][index].setValue(null);
    //this.emailForm.controls["cityId"][index].disable();
    //this.emailForm.controls["countyId"][index].setValue(null);
    this.GetZipcodes(event, index);
    this.GetCountyInfo(event, index);
  }

  GetZipcodes(event, index: any): Promise<any> {
    this.zipcodeList[index] = [];
    this.zipcodeSource[index] = [];
    let res = this._dafService.getZipcodeByState(event).toPromise().then((data: any) => {
      if (data.length > 0) {
        //data.forEach(element => {
          this.zipcodeList[index] = data;
          this.zipcodeSource[index] = data;
        //});
        //var datas=this.emailForm.controls.emails['controls'][index].value;
        //datas.zipCode="";
        //this.emailForm.controls.emails['controls'][index].setValue(datas);
        //this.emailForm.controls.emails['controls'][index].updateValueAndValidity();
        this.disabled = false;

      }
      return Promise.resolve(data);
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
    //this._dafService.disableEnableFormControl(this.caseSummaryForm,["signatoryZipcode"],false);
    return res;
  }

  onZipCodeChange(event, index) {
    var selectedControl = this.emailForm.controls.emails['controls'][index];
    var datas = selectedControl.value;
    datas.cityId = null;
    selectedControl.controls["cityId"].enable();
    selectedControl.setValue(datas);

    this.GetCity(event, index);
  }

  GetCountyInfo(event, index: any): Promise<any> {
    this.countyDataArray[index] = [];
    this.countyArray[index] = [];
    this.countySource[index] = [];

    let res = this._dafService.getCountyByState(event).toPromise().then((data: any) => {
      var newData = data;
      if (newData.length > 0) {
        //this.cityArray[index].push(
        //  newData[0].cityName.toString()
        //);
        //if (this.cityArray[index].length > 0) {
        //  this.citydisabled[index] = false;
        //}

        //newData.forEach(element => {
        //  this.countyArray[index].push(
        //    element.countyName.toString()
        //  );
        //});
        //if (this.countyArray[index].length > 0) {
        //  this.countydisabled[index] = false;
        //}
        //data.forEach(element => {
        //  this.zipcodeList[index] = data;
        //  this.zipcodeSource[index] = data;
        //});
        this.countyArray[index] = data;
        this.countyDataArray[index] = data;
        this.countydisabled[index] = false;
        this.countySource[index] = data;
      }
      return Promise.resolve(data);
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
    return res;
  }

  GetCity(event, index): Promise<any> {
    this.cityArray[index] = [];
    this.cityDataArray[index] = [];
    this.citySource[index] = [];
    let res = this._dafService.getCityByZipcode(event).toPromise().then((data: any) => {
      if (data.length > 0) {
        this.cityArray[index] = data;
        this.cityDataArray[index] = data;
        this.citydisabled[index] = false;
        this.citySource[index] = data;
      }

      return Promise.resolve(data);
    },
      (error) => {
        this.notifier.notify('error', 'Error occured while fetching the city list');
      }
    );
    //this._dafService.disableEnableFormControl(this.caseSummaryForm,["signatoryZipcode"],false);
    return res;
  }

  getprimaryworkSiteItineraryDetails(){
    this.isReady = false;
    this._dafService.getprimaryworkSiteItineraryDetails(this.caseSummaryId).toPromise().then((data: any) => {
      this.primaryworksiteInfo = data;

      this.primaryworksiteInfo.forEach(item=>{
        if (item.periodFrom != undefined) {
          item.periodFrom = new Date(item.periodFrom);        
      } 
       if (item.periodTo != undefined) {
        item.periodTo = new Date(item.periodTo);        
    }
      });
      
      var arrayControl = this.emailForm.get('emails') as FormArray;
      if(this.primaryworksiteInfo.length>0){
      while (arrayControl.length) {
        arrayControl.removeAt(0);
      }
        this.primaryworksiteInfo.forEach(async (staff, i) => {
          var r = await this.GetZipcodes(staff.stateId, i);
          var c = await this.GetCountyInfo(staff.stateId, i);
          var d = await this.GetCity(staff.zipCodeId,i);
        arrayControl.push(this.formBuilder.group(staff))
      });
    }
      this.isReady = true;
    },
      (error) => {
        console.log(error)
        this.isReady = true;
      }
    );
  }



  onstartDateChange(event,index){

      var data=this.emailForm.controls.emails['controls'][index].value;
      data.periodFrom=event;
      this.emailForm.controls.emails['controls'][index].setValue(data);
      this.emailForm.controls.emails['controls'][index].updateValueAndValidity();
      this.onStartsDateChange(event,index);
    
  }

  onEndDateChange(event,index){
    var data=this.emailForm.controls.emails['controls'][index].value.periodFrom; 
    if((data!=""&&data!=null) && (event!=""&&event!=null)){
    this.dateDifference = Math.round((event - data)/1000/60/60/24);
    }
    else{
      this.dateDifference=null;
    }
    var data=this.emailForm.controls.emails['controls'][index].value;
    data.daysPerMonth=this.dateDifference;
    this.emailForm.controls.emails['controls'][index].setValue(data);
    this.emailForm.controls.emails['controls'][index].updateValueAndValidity();
    
  }

  public closeForm(): void {
    this.cancel.emit();
}

  onEndDateChanges(event,index){

      var data=this.emailForm.controls.emails['controls'][index].value;
      data.periodTo=event;
      this.emailForm.controls.emails['controls'][index].setValue(data);
      this.emailForm.controls.emails['controls'][index].updateValueAndValidity();
      this.onEndDateChange(event,index);

  }

  onStartsDateChange(event,index){
    var data=this.emailForm.controls.emails['controls'][index].value.periodTo; 
    if((data!=""&&data!=null) && (event!=""&&event!=null)){
      this.dateDifference = Math.round((data - event)/1000/60/60/24);   
       }
      else{
        this.dateDifference=null;
      }
   
    var data=this.emailForm.controls.emails['controls'][index].value;
    data.daysPerMonth=this.dateDifference;
    this.emailForm.controls.emails['controls'][index].setValue(data);
    this.emailForm.controls.emails['controls'][index].updateValueAndValidity();
    }
  


  private createEmailFormGroup(): FormGroup {
    return new FormGroup({
      'worksiteItenary': new FormControl(false,Validators.required),
      'clientsideAmmendment': new FormControl(false),
      'clientlegalName': new FormControl('',Validators.required),
      'periodFrom': new FormControl(null, Validators.required),
      'periodTo': new FormControl(null, Validators.required),
      'daysPerMonth': new FormControl(''),
      'streetNumber': new FormControl('', Validators.required),
      'streetName': new FormControl('', Validators.required),
      'apt':new FormControl(''),
      'cityId': new FormControl(null, Validators.required),
      'stateId': new FormControl(null, Validators.required),
      'zipCodeId': new FormControl(null, Validators.required),
      'countyId':new FormControl(null),
      'primary': new FormControl(false),
      'offsite' : new FormControl(false)
    })
  }

  public addEmailFormGroup() {
    const emails = this.emailForm.get('emails') as FormArray
    if(emails.length<10){
    emails.push(this.createEmailFormGroup())
    }

  }

  public onCancel(e: PointerEvent): void {
    e.preventDefault();
    this.closeForm();
  }

  invalidMessage(){
    this.notificationService.show({
      content: "Please ensure to add all the required Data",
      position: { horizontal: "right", vertical: "top" },
      animation: { type: "fade", duration: 800 },
      type: { style: "error", icon: true },
      hideAfter: this.hideAfter,
    });
  }

  saveData(){
    this.WorkSiteDetails=[];
    var saveValues = this.emailForm.controls.emails.value;
   
    if(this.emailForm.valid){

     
   
      saveValues.forEach(item => {
      
      //if(item.periodFrom==""){
      //  this.WorkSiteDetails=[];
      //  this.invalidMessage();
        
      //}
        if (item.periodFrom && item.periodTo && item.periodFrom > item.periodTo) {
          this.notifier.notify('error', 'Please enter valid dates. Period To should be greather than or equal to Period From.');
      }    
      else{
     
      var workSite=new WorkSitewizards();
      workSite.primWorkItineraryID=0;
      workSite.worksiteItenary=item.worksiteItenary;
      workSite.clientsideAmmendment=item.clientsideAmmendment;
      workSite.clientlegalName = item.clientlegalName;
      workSite.periodFrom = item.periodFrom;
      workSite.periodTo = item.periodTo;
      workSite.daysPerMonth = item.daysPerMonth?item.daysPerMonth.toString():"";
      workSite.streetAddress="";
      workSite.streetNumber=item.streetNumber;
      workSite.streetName=item.streetName;
      workSite.apt = item.apt;
      workSite.cityId=item.cityId;
      workSite.stateId=item.stateId;
      workSite.zipCodeId = item.zipCodeId;
      workSite.countyId = item.countyId;
      workSite.primary = item.primary;
      workSite.offsite=item.offsite;
      this.WorkSiteDetails.push(workSite);
      }
    });
    if(this.WorkSiteDetails.length>0){
      this._dafService.savePrimaryWorkSiteItinerary(this.caseSummaryId, this.WorkSiteDetails).toPromise().then(() => {
        this.notifier.notify('success', 'Worksite Itinerary details have been saved successfully');
      }).catch((err) => {
        this.notifier.notify('error', 'Operation Failed');
      });


    }
  
  }
    else {
      this.notifier.notify('error', 'Please ensure to add all the required Data.');
   }


  }

}
