export class DocumentExtractionModel {
  constructor(
    public id = 0,
    public  caseId= "",
    public documentName="",
    public packageType="",
    public  carrierName="",
    public wayBill="",
    public pickupDate=new Date(),
    public pickupDateString="",
    public firstName= "",
    public lastName = "",
    public accessTypeId = 0,
    public dateOfBirth=new Date())
    {}
}
