export class CaseModel {
  constructor(
    public zoomCaseID = "",
    public createdDate = new Date(),
    public createdDateString = "",
    public caseCountry = "",
    public caseStatus = "",
    public eYMPCaseGUID = "",
    public comments = ""
  ) { }
}
