import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { checklistItems } from 'src/app/DAF/Models/DocuentChecklist';

@Component({
  selector: 'kendo-grid-checklistedit-form',
  templateUrl: './kendo-grid-checklistedit-form.component.html',
  styleUrls: ['./kendo-grid-checklistedit-form.component.scss'],
  styles: [
    `
        input[type='text'] {
            width: 100%;
        }
        .k-inline-checkbox {
            display: inline-flex;
        }
    `
]
})
export class KendoGridChecklistEditFormComponent {

  public active = false;
  private notifier: NotifierService;
  classifications: any = [];
  radioButtonOptionValues: Array<any> = [{ value: true, text: "Yes" }, { value: false, text: "No" }];
  public relationTypeList = [
    {
      relationId: 0,
      relationType: '-Select-'
    },
    {
      relationId: 1,
      relationType: 'Father'
    },
    {
      relationId: 2,
      relationType: 'Mother'
    },
    {
      relationId: 3,
      relationType: 'Spouse'
    }
  ];
    public editForm: FormGroup = new FormGroup({
      documentName: new FormControl('', Validators.required),
      individual: new FormControl('', Validators.required),
      relation: new FormControl(''),
      recievedDrafted: new FormControl(false),
      requestDraft: new FormControl(false),
      na: new FormControl(false),
      notes: new FormControl(''),
      documentChecklistDetailId: new FormControl(0),
    });

    

    @Input() public isNew = false;

    @Input() public set model(product: checklistItems) {
      this.editForm.reset(product);
      this.IsCheckedDraft = product.requestDraft;
      this.IsCheckedNa = product.na;
      this.IsrecievedDrafted = product.recievedDrafted;

        // toggle the Dialog visibility
        this.active = product !== undefined;
    }

    @Output() cancel: EventEmitter<undefined> = new EventEmitter();
    @Output() save: EventEmitter<checklistItems> = new EventEmitter();

    constructor(notifier: NotifierService) {this.notifier = notifier;}

  IsCheckedDraft: boolean;
  IsCheckedNa: boolean;
  IsrecievedDrafted: boolean;
  
  public onChange(event: Event): void {
    const isChecked: boolean = event.target['checked']; 
    
    if (isChecked) {
      this.IsCheckedNa = false;
      this.IsCheckedDraft = false;
    }
   
  }
  public onChangereq(event: Event): void {
    const isChecked: boolean = event.target['checked']; 

    if (isChecked) {
      this.IsCheckedNa = false;
      this.IsrecievedDrafted = false;
    }

  }
  public onChangena(event: Event): void {
    const isChecked: boolean = event.target['checked']; 

    if (isChecked) {
      this.IsCheckedDraft = false;
      this.IsrecievedDrafted = false;
    }

  }
  public onSave(e: PointerEvent): void {
    e.preventDefault();
    this.save.emit(this.editForm.value);
    this.active = false;
    this.IsCheckedDraft = false;
    this.IsCheckedNa = false;
    this.IsrecievedDrafted = false;
  }

    public onCancel(e: PointerEvent): void {
        e.preventDefault();
        this.closeForm();
    }


  public closeForm(): void {
    this.IsCheckedDraft = false;
    this.IsCheckedNa = false;
    this.IsrecievedDrafted = false;
    this.active = false;
    this.cancel.emit();

  }
 

}
