import { addressWizardInfos } from "./addressWizardInfos";

export class lcaAssessmentDetails {
    jobTitle: string;
    oesSOCTitle: string;
    onetOcssId: number;
  oessocCode: string;
    lcaValidityFrom: Date;
  lcaValidityTo: Date;
  dotCode: string;
    wageLevel:  Array<wageLevel> = [];
    basisOfClassification: Array<basisOfClassification> = [];
    wageSourceAndYear : string;
    //OFLC : string;
    prevailingWage: number;
    actualSalary: number;
    isInSalaryRange: boolean;
    salaryRangeFrom: number;
    salaryRangeTo: number;
    emailAddressForLCA: string;
    headcount: number;
    additionalWorksite: boolean;
    isSecondaryEntity : boolean;
    secondaryEntityName: string;
    streetNumber: string;
    streetName: string;
    apartment: string;
    cityName: string;
    cityId: number;
    stateId: number;
    postalCodeId: string;
    county: string;
    countryOnOLFC: string;
    isAdditionalLCA: boolean;
    additionalLCAJobTitle: string;
    additionalLCAOESSOCTitle: string;
    additionalLCAOESSOCCode: string;
    additionalLCAonetOcssId: number;
    additionalLCAPrevailingWage: number;
    additionalLCAactualSalary: number;
    isAdditionalLCAWageRange: boolean;
    additionalLCASalaryRangeFrom: number;
    additionalLCASalaryRangeTo: number;
    additionalLCAType: Array<basisOfClassification> = [];;
    assessmentNotes: string;
    wageLevelId: number;
    wageLevelName: string;
    geography: string;
    worksiteAddress: Array<addressWizardInfos> = [];
    isbasis: number;
}

export class wageLevel {
    wageLevelId: number;
    wageLevelName: string;
    isSelected: boolean;
}

export class basisOfClassification {
    basisForClassificationType: string;
    classificationId: number;
    basisForClassificationId: number;
    isSelected: boolean;
    shortForm: string;
}

export class worksiteAddressModal {
  public wageLevelId: number;
  public oesSOCCode: string;
  public addressWizardInfos: addressWizardInfos;
}
