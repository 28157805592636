import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { checklistItems } from 'src/app/DAF/Models/DocuentChecklist';
import { DafService } from 'src/app/DAF/services/dafService';

@Component({
  selector: 'kendo-grid-specialist-form',
  templateUrl: './kendo-grid-specialist-form.component.html',
  styleUrls: ['./kendo-grid-specialist-form.component.scss'],
  styles: [
    `
        input[type='text'] {
            width: 100%;
        }
        .k-inline-checkbox {
            display: inline-flex;
        }
    `
]
})
export class KendoGridSpecialistFormComponent implements OnInit   {

  public active = false;
  private notifier: NotifierService;
  degrees=["Associate","Bachelor","Master","Doctorate"];

  allowCustom=true;
  radioButtonOptionValues: Array<any> = [{ value: true, text: "Yes" }, { value: false, text: "No" }];
  public relationTypeList = [
    {
      relationId: 0,
      relationType: '-Select-'
    },
    {
      relationId: 1,
      relationType: 'Father'
    },
    {
      relationId: 2,
      relationType: 'Mother'
    },
    {
      relationId: 3,
      relationType: 'Spouse'
    }
  ];
     
    @Input() public isNew = false;

    @Input() public set model(product: checklistItems) {
        this.editForm.reset(product);

        // toggle the Dialog visibility
        this.active = product !== undefined;
    }
    countrySourceList: Array<any> = [];
    CountryList: Array<any> = [];
    @Output() cancel: EventEmitter<undefined> = new EventEmitter();
    @Output() save: EventEmitter<checklistItems> = new EventEmitter();

    constructor(notifier: NotifierService,private _dafService: DafService) {this.notifier = notifier;}

    ngOnInit(){
      this.getCountryList();
    }
    public onSave(e: PointerEvent): void {
        e.preventDefault();
        this.save.emit(this.editForm.value);
        this.active = false;

    }

    public onCancel(e: PointerEvent): void {
        e.preventDefault();
        this.closeForm();
    }
    public ngOnChanges() {
      let changeType = "UPDATE";
      if (this.isNew)
        changeType = "INSERT";

      this.editForm.controls["changeType"].setValue(changeType);
    }

    public editForm: FormGroup = new FormGroup({
      universityName: new FormControl('', Validators.required),
      degree: new FormControl('', Validators.required),
      degreeField: new FormControl(''),
      enrollFrom: new FormControl(null),
      enrollTo: new FormControl(null),
      awardDate: new FormControl(null),
      degissuingCountry: new FormControl(''),
      degreesOnFile: new FormControl(false),
      spedegassmtId: new FormControl(0),
      changeType: new FormControl('')
    });

    public closeForm(): void {
        this.active = false;
        this.cancel.emit();
    }

    countryFilterChange(filter: any) {
      try {
          this.CountryList = this.countrySourceList.filter(x => x.countryName.toLowerCase().includes(filter.toLowerCase()));
      }
      catch (err) {
        console.log(err);
      }
      finally {
  
      }
    }

    getCountryList(){
      this._dafService.getCountriesCitizenship().subscribe((data: any) => {
        this.CountryList = data;
        this.countrySourceList = data;
      },
        (error) => {
          //this.notifier.notify('error', 'Error occured while updating the user details');
        }
      );
    }

}
