import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EympINSZoomIntegrationRoutingModule } from './eymp-inszoom-integration-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DatePickerModule, DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule, StepperComponent } from "@progress/kendo-angular-layout";
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { FloatingLabelModule } from '@progress/kendo-angular-label';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DashboardDetailsComponent } from './dashboard-details/dashboard-details.component';
import { ViewProfileComponent } from './dashboard-details/ViewProfile/view-profile/view-profile.component';
import { ViewCaseComponent } from './dashboard-details/ViewCase/view-case/view-case.component';
import { ViewCaseEYIUploadComponent } from './dashboard-details/View-CaseEYI/view-case-eyiupload/view-case-eyiupload.component';
import { ViewQuestionareComponent } from './dashboard-details/View-Questionaire/view-questionare/view-questionare.component';

@NgModule({
  declarations: [
    DashboardComponent,
    DashboardDetailsComponent,
    ViewProfileComponent,
    ViewCaseComponent,
    ViewCaseEYIUploadComponent,
    ViewQuestionareComponent
  ],
  imports: [
    CommonModule,
    EympINSZoomIntegrationRoutingModule,
    DatePickerModule,
    FormsModule,
    GridModule,
    ExcelModule,
    TextBoxModule,
    FloatingLabelModule,
    ReactiveFormsModule,
    DialogsModule
  ]
})
export class EympINSZoomIntegrationModule { }
