import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartModule } from '@progress/kendo-angular-charts';
import { SharedModule } from '../shared/shared.module'
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardPageComponent } from './components/dashboard-page/dashboard-page.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { FormControl, FormsModule } from "@angular/forms";
import { Component, ViewEncapsulation, ViewChild } from "@angular/core";

import { LayoutModule, StepperComponent } from "@progress/kendo-angular-layout";
import { ReactiveFormsModule } from '@angular/forms';
import {  CaseDetailsComponent } from './components/case-details/case-details.component';
import { DatePickerModule, DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { FloatingLabelModule } from '@progress/kendo-angular-label';
import { TextBoxModule } from '@progress/kendo-angular-inputs';




@NgModule({
  declarations: [DashboardPageComponent, PieChartComponent, CaseDetailsComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    ChartModule,
    SharedModule,
    LayoutModule,
    FormsModule,
    DatePickerModule,
    DateInputsModule,
    ReactiveFormsModule,
    GridModule,
    ExcelModule,
    TextBoxModule,
    FloatingLabelModule
  ]
})
export class DashboardModule { }
