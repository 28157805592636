import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { LCAService } from './service/lca.service';
import { MsalService } from '@azure/msal-angular';
import { NotifierService } from 'angular-notifier';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { LcaData } from './model/Lca.model';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { AddLCAService } from '../add-lca/service/add-lca.service';


@Component({
  selector: 'app-lca-compliance',
  templateUrl: './lca-compliance.component.html',
  styleUrls: ['./lca-compliance.component.scss']
})
export class LcaComplianceComponent implements OnInit {
  @ViewChild('openEditModalButton', { static: false }) openEditModalButton: ElementRef;
  public selectedCaseId="";
  public deleteId = 0;
  public searchTerm = '';
  public showConfirmation = false;
  public userList: any[] = [];
  roleData:any[]=[];
  public isPageReady = true;
  public pageSize = 10;
  public skip = 0;
  public roleArray=[];
  public gridViewSearch: any;
  public gridView: any;
  public CaseDescription="";
  public searchName = '';
  public caseStatus = '';
  public validDate = '';
  public approvedDate = '';
  public filedDate = '';
  public caseNumber = '';
  public userLists=[];
  public addl1=true;
  public userListSource=[];
  public enaggementName;
  public addl2=true;
  public OpenRoles=false;
  public email;
  public engagements=[];
  public engagementSource=[];
  public gridState: DataStateChangeEvent = {
    skip: this.skip,
    take: this.pageSize
  };
  private readonly notifier: NotifierService;
  profile: string="";
  @Input() lcaType="";
  @Output() page = new EventEmitter<number>();
  @Output() changeNWSStepper = new EventEmitter<number>();
  optionalFormControls = ["middleName", "signatoryName", "signatoryTitle", "signatoryTelephone", "department"];
  formErrors = {
    'firstName': '',
    'lastName': '',
    'dateOfBirth': '',
    'caseManager': '',
    'caseAttorney': '',
    'companyName': '',
    'supportTeamMember': '',
    'signatoryName': '',
    'signatoryTitle': '',
    'signatoryTelephone': '',
    'signatoryEmail': '',
    'signatoryStreetName': '',
    'signatoryStreetNo': '',
    'signatoryAptSteFlrDoorNo': '',
    'signatoryCity': '',
    'signatoryState': '',
    'signatoryZipcode': '',
    'department': '',
    'employeeId': '',
    'employeeEmail': '',
    'expediteCase': '',
    'reasonToExpediteCase': '',
    'placeOnHold': '',
    'reasonToDelayCase': '',
    'notes': ''
  };
  validationMessages = {
    'firstName': {
      'required': 'First Name is required.',
    },
    'lastName': {
      'required': 'Last Name is required.',
    },
    'dateOfBirth': {
      'required': 'DOB is required.',
    },
    'caseManager': {
      'required': 'Case Manager is required.',
    },
    'caseAttorney': {
      'required': 'Case Attorney is required.',
    },
    'companyName': {
      'required': 'Company Name is required.',
    },
    'supportTeamMember': {
      'required': 'Support team member required.',
    },
    'signatoryName': {
      'required': 'Signatory Name is required.',
    },
    'signatoryTitle': {
      'required': 'Signotary Title is required.',
    },
    'signatoryTelephone': {
      'required': 'Signotary Telephone is required.',
    },
    'signatoryEmail': {
      'required': 'Signatory Email is required.',
      'email': 'Please enter a valid email address'
    },
    'signatoryStreetName': {
      'required': 'Street Name is required.',
    },
    'signatoryStreetNo': {
      'required': 'Street number is required.',
    },
    'signatoryAptSteFlrDoorNo': {
      'required': 'An apartment/floor/door number is required',
    },
    'signatoryCity': {
      'required': 'City is required.',
    },
    'signatoryState': {
      'required': 'State is required.',
    },
    'signatoryZipcode': {
      'required': 'Zipcode is required.',
    },
    'department': {
      'required': 'Department is required.',
    },
    'employeeId': {
      'required': 'EmployeeId is required.',
    },
    'employeeEmail': {
      'required': 'Employee Emial is required.',
      'email': 'Please enter a valid email address'
    },
    'expediteCase': {
      'required': 'First Name is required.',
    },
    'reasonToExpediteCase': {
      'required': 'Reason is required.',
    },
    'placeOnHold': {
      'required': 'Reason is required.',
    },
    'reasonToDelayCase': {
      'required': 'Reason is required.',
    },
    'notes': {
      'required': 'Notes are required.',
    },
    'cityId': {
      'required': 'City is required.',
    },
    'stateId': {
      'required': 'State is required.',
    },
    'zipCodeId': {
      'required': 'Zipcode is required.',
    }
  };

  constructor(
    private lcaService: LCAService,private addLCAService:AddLCAService, private authService: MsalService,private router:Router
  ) {
    this.email = authService.getAccount().userName;
    this.profile = authService.getAccount().name;
    
  }

  ngOnInit() {
    if(this.lcaType=="replicator"){
    this.getEngagements();
    }
    else{

      this.getData(this.email);
      this.isPageReady=true;
      this.addLCAService.currentData.subscribe((data: any) => {
        // Binds the data to grid
        this.email=data.emailId;
        this.dataStateChange(this.gridState,this.email);
    });
    }
    // Loads data to grid
    
  }

getEngagements(){
  this.lcaService.getLCAEngagements().subscribe((data: any) => {
    data.forEach(element => {
      this.engagements.push(element.engagementName);
    });
    this.engagementSource=this.engagements;
    this.addLCAService.engagementData.subscribe((data: any) => {
      // Binds the data to grid
      this.enaggementName=data.engagementName;
      this.getLCAUsers(this.enaggementName);
      
  });
    
  });
}
 
  
  redirectToLca(){
    this.page.emit(2);
  }


  getLCAUsers(engagement) {
    this.isPageReady = false;
    this.userListSource=[];
    this.userLists=[];
    // Gets data from api
    this.lcaService.getLCAUserList(engagement).subscribe((data: any) => {
      // Binds the data to grid
      data.forEach(element => {
        this.userLists.push(element.emailId);
      });
      this.userListSource=this.userLists;
      this.isPageReady=true;
      this.addLCAService.currentData.subscribe((data: any) => {
        // Binds the data to grid
        this.email=data.emailId;
        this.dataStateChange(this.gridState,this.email);
    });
  });
  }

  
  public filterChange(filter: any): void {
    this.userLists= this.userListSource.filter(
      (s) => s.toLowerCase().includes(filter.toLowerCase()) 
    );
  }

  public filterCorpChanges(filter: any): void {
    this.engagements= this.engagementSource.filter(
      (s) => s.toLowerCase().includes(filter.toLowerCase()) 
    );
  }

  public getData(event: any){
    this.addLCAService.changeUser({emailId:event,id:1});
  }

  public getUserData(event: any){
    this.addLCAService.changeEngagement({ engagementName: event, id: 1 });
    this.email = "";
  }


addUser(){
  this.roleArray=[];
}
  updateUserList() {
    this.dataStateChange(this.gridState,this.email);
  }
  dataStateChange(state: DataStateChangeEvent,emailId:string) {
    this.isPageReady = false;
    this.gridState = state;

    // Gets data from api
    this.lcaService.refreshLCAList(emailId,this.searchTerm).subscribe((data: any) => {
      // Binds the data to grid
      this.gridViewSearch = data;
      this.CaseDescription = "";
        this.caseNumber = "";
        this.caseStatus = "";
        this.filedDate = "";
        this.approvedDate = "";
      this.validDate = "";
      this.changeNWSStepper.emit(this.gridViewSearch.length);
      if(this.gridViewSearch.length>0){
        this.CaseDescription=this.gridViewSearch[0].case_Description;
        this.caseNumber=this.gridViewSearch[0].case_ID;
        this.caseStatus=this.gridViewSearch[0].currentStatus;
        this.filedDate=formatDate(this.gridViewSearch[0].filedDate, 'yyyy-MM-dd', 'en-US');
        this.approvedDate = this.gridViewSearch[0].approvalDate?formatDate(this.gridViewSearch[0].approvalDate, 'yyyy-MM-dd', 'en-US'):this.gridViewSearch[0].approvalDate;
        this.validDate = formatDate(this.gridViewSearch[0].i129SExpiry, 'yyyy-MM-dd', 'en-US');
      }
      var filter1=this.gridViewSearch.filter(item=>item.lcA_Addl_Primary_Work_1_City!=null);
      if(filter1&&filter1.length>0){
        this.addl1=false;
      }
      var filter2 = this.gridViewSearch.filter(item=>item.lcA_Addl_Primary_Work_2_City!=null);
      if(filter2&&filter2.length>0){
        this.addl2=false;
      }
      let filterData = this.gridViewSearch;

      this.gridView = process(filterData, this.gridState);
      this.userList = data;

      this.isPageReady = true;
    },
    (error) => {
      this.notifier.notify('error', 'Error occured while getting data from server');
      this.isPageReady = true;
    }
    );
  }


    confirmationDialog(open: boolean, data) {
      // Opens the confirmation dialog
      if (open) {
        this.showConfirmation = true;
      } else {
        this.showConfirmation = false;
      }
      // setting the delete ID to global variable
      if (data) {
        this.deleteId = data.userId;
      } else {
        this.deleteId = 0;
      }
    }
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
      let filterData = this.gridViewSearch.filter(r => r.name.toLowerCase().includes(filterValue)
      || r.email.toLowerCase().includes(filterValue)
        );
      this.gridState.skip = 0;
      this.gridView = process(filterData, this.gridState);

      // console.log(this.gridView);
    }

    GotoNext(){
      this.router.navigate(['/addLca']);
    }

    editUser(edit: boolean) {
      this.selectedCaseId=this.caseNumber; 
      this.openEditModalButton.nativeElement.click();
    }

}
