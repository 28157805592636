import { GeneralDetailsModel } from "./GeneralDetails";

export class jobDetailsWizardInfo {
    jobTitleWizardSaveID: number = 0;
    caseId:string=null;
    caseSummaryId: number = 0;
    jobTitle: string = null;
    salaryOffered: string = null;
    jobDetails: string = null;

    jobRequirements: string = null;
    constructor(obj?: any) {
        try {
            if (obj) {
                
                this.caseId = obj.caseId ? obj.caseId : null;
                this.jobTitleWizardSaveID = obj.jobTitleWizardSaveID ? obj.jobTitleWizardSaveID : 0;
                this.caseSummaryId = obj.caseSummaryId ? obj.caseSummaryId : 0;
                this.jobTitle = obj.jobTitle ? obj.jobTitle : null;
                this.salaryOffered = obj.salaryOffered ? obj.salaryOffered : null;
                this.jobDetails = obj.jobDetails ? obj.jobDetails :null;
                this.jobRequirements = obj.jobRequirements ? obj.jobRequirements : null;
            }
        }
        catch (err) {
            console.log(err);
        }
    }
}