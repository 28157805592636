import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-role-view',
  templateUrl: './role-view.component.html',
  styleUrls: ['./role-view.component.scss']
})
export class RoleViewComponent implements OnInit {
  public gridData: any ;
  @Input() roles: any;
  @ViewChild('closeButton',  {static: false}) closeButton: ElementRef;
  constructor() { 
    this.gridData=this.roles;
    console.log(this.roles);
  }

  ngOnInit() {
    this.gridData=this.roles;
    console.log(this.roles);

  }


  closeForm() {
    const closeButton: HTMLElement = this.closeButton
      .nativeElement as HTMLElement;
    closeButton.click();
  }

}
