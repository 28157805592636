import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { User } from '../model/user';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { InsertUser, Roles } from '../model/insertUser';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private _http: HttpClient) { }
  refreshUserList(emailId: string, role: string, skip, take, searchTerm: string) {
    return this.getUsers(emailId, role, skip, take, searchTerm);
  }
  searchUser(emailId: string, role: string, skip,take,searchTerm: string) {
    if (!searchTerm) {
      return this.getUsers(emailId,role,skip,take, searchTerm);
     } // else {
    //   const urlString = `/client/search/${searchTerm}/`;
    //   return ();
    // }
  }
  getUsers(emailId, role, skip, take, searchTerm) {
    return this._http.get<any>(environment.ApiUrl + 'User/GetAllUsers?emailId=' + emailId + '&role=' + role + '&pageNumber=' + skip + '&pageSize=' + take + '&searchTerm=' + searchTerm);
  }

  deleteUser(UserId: number) {
return this._http.post<any>(environment.ApiUrl + 'User/DeleteUser?userId=' + UserId , {});
  }
  getRolesUrl() {
    return this._http.get<any>(environment.ApiUrl + 'User/GetUserRoles');
  }
  editUser(userId: number, selectedRole: Roles[]) {
    var userInfo={
      "userId":userId,
      "roles":selectedRole
    }
    return this._http.post<any>(environment.ApiUrl + 'User/EditUser',userInfo, {});
  }
  addUser(user:InsertUser) {
    return this._http.post<any>(environment.ApiUrl + 'User/InsertUser',user, {});
  }
  searchUserDetails(email: string) {
    let username = "'" + email + "'";
    return this._http.get<any>(environment.GraphUri + 'v1.0/users?$filter=startswith(mail,' + username + ')');
  }
}
