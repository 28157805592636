import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DataStateChangeEvent, GridComponent, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { DafService } from 'src/app/DAF/services/dafService';
import { Router, ActivatedRoute } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { EympService } from 'src/app/eymp-inszoom-integration/services/eympservice';
import { DatePipe } from '@angular/common';
import { ProfileModel } from '../Model/ProfileModel';
import { CaseModel } from '../Model/CaseModel';
import { EYIModel } from '../Model/EYIModel';
import { QuestModel } from '../Model/QuestModel';
import { QuestAddModel } from '../Model/QuestAddModel';
import { QuestInfoModel } from '../Model/QuestInfoModel';
import { ExportParams, PagingParams } from '../Model/filterparams';
import * as fileSaver from 'file-saver';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-dashboard-details',
  templateUrl: './dashboard-details.component.html',
  styleUrls: ['./dashboard-details.component.scss']
})
export class DashboardDetailsComponent implements OnInit {
  @ViewChild('openDocumentInfoModalButton', { static: false }) openEditModalButton: ElementRef;
  @ViewChild('openDocumentInfoModalButton1', { static: false }) openEditModalButton1: ElementRef;
  @ViewChild('openDocumentInfoModalButton2', { static: false }) openEditModalButton2: ElementRef;
  @ViewChild('openDocumentInfoModalButton3', { static: false }) openEditModalButton3: ElementRef;
  @ViewChild('openDocumentInfoModalButton4', { static: false }) openEditModalButton4: ElementRef;
  public pageSize = 10;
  public skip = 0;
  public isPageReady = false;
  public caseList: any;
  public caseFormList: any;
  public gridViewData: any[];
  public ProfilecaseStatus = "";
  public Status = "";
  isActivate = false;
  isfailure = false;
  isreattempt = false;
  selectedCaseId;
  isshare = false;
  isProfile = false;
  isCase = false;
  isCaseState = false;
  isCaseGuidState = false;
  isEYIUpload = false;
  isEYIUpateStatus = false;
  isQuest = false;
  isCaseDocState = false;
  isCaseDocNRState = true;
  gridFilteredData: any;
  private notifier: NotifierService;
  public selecteddocumentId = 0;
  public documentInfo = new ProfileModel();
  public documentInfo1 = new CaseModel();
  public documentInfo2 = new EYIModel();
  public documentInfo3 = new QuestModel();
  public documentInfo4 = new QuestAddModel();
  public documentInfo5 = new QuestInfoModel();
   
  public showModel = false;
  public showConfirmation = false;
  public isRushEnabled = false;
  public selectedGuid;
  public popupMessage;
  public isQuestionnaireAvailable = false;
  public showStopConfirmation = false;
  public statusId;
  public isStopEnabled = false;
  public response;

  public gridState: DataStateChangeEvent = {

    skip: this.skip,
    take: this.pageSize
  };
  dateFilterModel: DateFiltersModel;
  fromreportDate: any;
  toreportDate: any
  constructor(public _dafService: DafService, private router: Router, private route: ActivatedRoute, notifier: NotifierService, public _eympservice: EympService, public datepipe: DatePipe) {
    this.dateFilterModel = new DateFiltersModel(new Date(), new Date());
    this.notifier = notifier;
  }
  take: number;
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.ProfilecaseStatus = params['ProfileCase'];

      this.Status = params['Status'];
      if (this.ProfilecaseStatus == "Profile")
        this.isProfile = true;

      else if (this.ProfilecaseStatus == "Document") {
        this.isCaseDocState = true;
        this.isCaseState = true;
        this.isCaseDocNRState = false;
        this.isEYIUpload = false;
        //if (this.Status == "Ready for Document Upload" || this.Status == "") {
        //this.isEYIUpload = true;
        //}
      }
      else {
        this.isCaseState = true;
        this.isCaseGuidState = true;
        this.isEYIUpateStatus = false;
        if (this.Status == "Ready for Profile and Case Creation") {
          this.isCase = true;
          this.isEYIUpload = false;
          this.isQuest = false;
          this.isStopEnabled = true;
        }
        else if (this.Status == "Ready for Case Questionnaire Update") {
          this.isCase = false;
          this.isEYIUpload = true;
          this.isQuest = false;
        }
        else if (this.Status == "Ready for EYI Upload") {
          this.isCase = false;
          this.isEYIUpload = false;
          this.isQuest = false;
          this.isEYIUpateStatus = true;
          this.isStopEnabled = true;
        }
        else if (this.Status == "Success" || this.Status == "Failure") {
          if(this.Status == "Failure")
            this.isCase = false;
          this.isEYIUpload = true;
          this.isQuest = true;
        }
        else if (this.Status == "Waiting for Case Questionnaire milestone completion in EYMP") {
          this.isCase = false;
          this.isEYIUpload = false;
          this.isQuest = false;
          this.isRushEnabled = true;
        }
      }




    });
    this._dafService.currentInsDates.subscribe(data => {
      this.fromreportDate = data.fromDate;
      this.toreportDate = data.toDate;
      this.dateFilterModel = new DateFiltersModel(new Date(this.fromreportDate), new Date(this.toreportDate));
      this.refreshCaseList(this.gridState);
    });
    //this.refreshCaseList(this.gridState);
  }

  onSave(action: string) {
    if (action === 'refresh') {
      this.filterCaseList(); // Refresh the grid data
    }
  }

  filterCaseList() {

    this._dafService.updateINSDate({ fromDate: this.dateFilterModel.startDate.toDateString(), toDate: this.dateFilterModel.endDate.toDateString() });
  }


  refreshCaseList(state: DataStateChangeEvent) {

    this.isPageReady = false;
    var kets = state;
    this.gridState = kets;
    let skip = 0;
    if (state.skip !== 0) {
      skip = state.skip / 10;
    }
    var summaryParams = new ExportParams();
    summaryParams.PagingParams = new PagingParams();
    summaryParams.PagingParams.PageNumber = skip + 1;
    summaryParams.PagingParams.PageSize = state.take;
    if (state.filter != null) {
      summaryParams.FilterParams = state.filter.filters;
    }
    else {
      summaryParams.FilterParams = null;
    }
    this._eympservice.getEympDetailsWithStatusNew(this.Status, this.ProfilecaseStatus, this.dateFilterModel.startDate.toDateString(), this.dateFilterModel.endDate.toDateString(), 'G', summaryParams).subscribe((data: any) => {
      this.gridFilteredData = data;
      // Binds the data to grid
      let dataParams = process(this.gridFilteredData.caseSummaryModel, { skip: 0, take: this.pageSize, filter: this.gridState.filter, sort: this.gridState.sort }).data;
      this.caseList = { data: dataParams, total: this.gridFilteredData.totalRowCount };
      this.isPageReady = true;

    },
      (error) => {
        this.isPageReady = true;
        //this.notifier.notify('error', 'Error occured while getting data from server');
      }
    );
  }
  private rowCallback(row: any) {
    const isEven = row.index % 2 == 0;
    return {
      even: isEven,
      odd: !isEven
    };
  }

  viewProfileInfo(documentId: number, edit: boolean) {
    console.log(documentId);
    this.selecteddocumentId = documentId;
    this._eympservice.getProfileInfoById(documentId).subscribe((result: ProfileModel) => {
      this.documentInfo = result;
      this.documentInfo.createdDateString = this.datepipe.transform(this.documentInfo.createdDate, 'dd-MMM-yyyy');
      this.showModel = true;
      this.openEditModalButton.nativeElement.click();
      console.log(this.documentInfo);
    }, error => { console.error(error); console.log('error'); });
  }
  updateCaseStatus() {
    console.log("updateCaseStatus");
    console.log(this.statusId);
    this._eympservice.updateCaseEYIStatus(this.statusId).subscribe((result) => {
      this.filterCaseList();
      this.showStopConfirmation = false;
    }, error => { console.error(error); console.log('error'); });
  }

  exportToExcel(state: DataStateChangeEvent): Promise<any> {
    var summaryParams = new ExportParams();
    summaryParams.PagingParams = new PagingParams();
    summaryParams.PagingParams.PageNumber = 1;
    summaryParams.PagingParams.PageSize = this.caseList.total;
    if (state.filter != null) {
      summaryParams.FilterParams = state.filter.filters;
    }
    else {
      summaryParams.FilterParams = null;
    }
    this.response = this._eympservice.getEympDetailsWithStatusNew(this.Status, this.ProfilecaseStatus, this.dateFilterModel.startDate.toDateString(), this.dateFilterModel.endDate.toDateString(), 'E', summaryParams).subscribe((data: any) => {
      var excelDt = data.caseSummaryModel.map(({
        corporation, serviceCountry, attorney, lawClerk, zoomProfileID, zoomCaseId, eympCaseID, applicantName, caseType, caseSubType, caseSubSubType, zoomCaseType, caseGUID,
        createdDate, requestedDate, homeCompanyName, destinationCompanyCode, isVIP, caseFee, caseFeeComment, homeCareerStage, comments, clientName, documentName, uploadedDate, receivedDate,
        zoomUploadStatus, eyiUploadStatus, zoomUploadComments, eyiUploadComments
      }) => ({
        
        Corporation: corporation, ServiceCountry: serviceCountry, Attorney: attorney, LawClerk: lawClerk, ZoomProfileID: zoomProfileID, ZoomCaseId: zoomCaseId, EYMPCaseID: eympCaseID, ApplicantName: applicantName,
        CaseType: caseType, CaseSubType: caseSubType, CaseSubSubType: caseSubSubType, ZoomCaseType: zoomCaseType, EYMPCaseGUID: caseGUID, CreatedDate: createdDate, RequestedDate: requestedDate,
        HomeCompanyName: homeCompanyName, DestinationCompanyCode: destinationCompanyCode, IsVIP: isVIP, CaseFee: caseFee, CaseFeeComment: caseFeeComment, HomeCareerStage: homeCareerStage, Comments: comments, ClientName: clientName, DocumentName: documentName,
        UploadedDate: uploadedDate, ReceivedDate: receivedDate, ZoomUploadStatus: zoomUploadStatus, EYIUploadStatus: eyiUploadStatus,
        ZoomUploadComments: zoomUploadComments, EYIUploadComments: eyiUploadComments
      }));


        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelDt);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        let blob: any = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

        fileSaver.saveAs(blob, 'INSZoomDetails.xlsx');

      },
        err => {
          this.notifier.notify('error', 'Error occured while getting data from server');
          this.isPageReady = true;
        });
    return this.response;
  }

  viewCaseInfo(documentId: number, edit: boolean) {
    console.log(documentId);
    this.selecteddocumentId = documentId;
    this._eympservice.getCaseEYITravelPathInfoById(documentId).subscribe((result: QuestInfoModel) => {
      this.documentInfo5 = result;
      this.showModel = true;
      this.openEditModalButton4.nativeElement.click();
      console.log(this.documentInfo5);
    }, error => { console.error(error); console.log('error'); });
  }

  viewCaseEYIInfo(documentId: number, edit: boolean) {
    console.log(documentId);
    this.selecteddocumentId = documentId;
    this._eympservice.getCaseEYIInfoById(documentId).subscribe((result: EYIModel) => {
      this.documentInfo2 = result;
      this.documentInfo2.createdDateString = this.datepipe.transform(this.documentInfo1.createdDate, 'dd-MMM-yyyy');
      this.showModel = true;
      this.openEditModalButton2.nativeElement.click();
      console.log(this.documentInfo2);
    }, error => { console.error(error); console.log('error'); });
  }

  viewCaseQuestInfo(documentId: number, edit: boolean) {
    console.log(documentId);
    this.selecteddocumentId = documentId;
    this._eympservice.getCaseQuestInfoById(documentId).subscribe((result: QuestModel) => {
      this.documentInfo3 = result;
      this.documentInfo3.createdDateString = this.datepipe.transform(this.documentInfo3.createdDate, 'dd-MMM-yyyy');
      this._eympservice.getCaseQuestAddInfoById(documentId).subscribe((result: QuestAddModel) => {

        this.documentInfo4 = result;
        this.documentInfo4.createdDateString = this.datepipe.transform(this.documentInfo4.createdDate, 'dd-MMM-yyyy');
      }, error => { console.error(error); console.log('error'); });
      this.showModel = true;
      this.openEditModalButton3.nativeElement.click();
      console.log(this.documentInfo3);

    }, error => { console.error(error); console.log('error'); });
  }
  stopConfirmationDialog(open: boolean, statusId) {
    // Opens the confirmation dialog
    if (open) {
      this.showStopConfirmation = true;
    } else {
      this.showStopConfirmation = false;
    }
    // setting the delete ID to global variable
    if (statusId) {
      this.statusId = statusId;
    } else {
      this.statusId = 0;
    }
  }
  confirmationDialog(open: boolean, data) {
    this.isPageReady = false;
    // setting the delete ID to global variable
    if (data) {
      this.selectedGuid = data.caseGUID;
      this.selectedCaseId = data.id;
    }
    // Opens the confirmation dialog
    if (open) {
      this._eympservice.checkQuestionnaireDetails(this.selectedGuid).subscribe((data: any) => {
        if (data) {
          this.isQuestionnaireAvailable = true;
          this.popupMessage = 'Questionnaire details are available in Agility for the selected case. Click Confirm, if you wish to proceed.';
        }
        else {
          this.isQuestionnaireAvailable = false;
          this.popupMessage = 'Questionnaire details are not available in Agility for the selected case. Please try again later.';
        }
        this.showConfirmation = true;
        this.isPageReady = true;
      },
        (error) => {
          this.notifier.notify('error', 'Error occured while deleting the user from server');
          this.isPageReady = true;
        }
      );

    } else {
      this.showConfirmation = false;
      this.isPageReady = true;
    }

  }

  getQuestionnaire() {
    this.isPageReady = false;
    this._eympservice.getQuestionnaire(this.selectedCaseId, this.selectedGuid).subscribe((data: any) => {
      this.confirmationDialog(false, data);
      this.isPageReady = true;
      this.refreshCaseList(this.gridState);
    },
      (error) => {
        this.notifier.notify('error', 'Error occured while deleting the user from server');
        this.isPageReady = true;
      }
    );
  }

  resetDashboard() {
    this._dafService.resetDate.subscribe(data => {
      this._dafService.updateINSDate({ fromDate: data, toDate: data });
    });
  }

}

export class DateFiltersModel {
  public startDate: Date;
  public endDate: Date;
  constructor(startDate: Date, endDate: Date) {
    this.startDate = startDate;
    this.endDate = endDate;
  }
}
