import {Component, OnInit, OnDestroy, ViewChild, Renderer2, Input, ViewEncapsulation,} from "@angular/core";
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { AddEvent, CellClickEvent, DataStateChangeEvent, GridComponent, GridDataResult, PageChangeEvent } from "@progress/kendo-angular-grid";
import { NotifierService } from "angular-notifier";
import { Subscription } from "rxjs";
import { relationDetail, spouseAndDependentDetails } from "src/app/DAF/Models/SpouseAndDependentDetails";
import { DafService } from "src/app/DAF/services/dafService";

@Component({
  selector: 'app-spouse-and-dependent-application',
  templateUrl: './spouse-and-dependent-application.component.html',
  styleUrls: ['./spouse-and-dependent-application.component.scss']
})
export class SpouseAndDependentApplicationComponent implements OnInit, OnDestroy {
  isPageReady: boolean;
  @Input() caseSummaryId = 0;
  @ViewChild(GridComponent, {static: false})
  private grid: GridComponent;
  public view: Array<relationDetail> = [];
  public formGroup: FormGroup;
  private editedRowIndex: number;
  private docClickSubscription: Subscription = new Subscription();
  private isNew: boolean;
  public isEditEnabled: boolean;
  public isGridEditable: boolean;
  relationTypeList: { relationId: number; relationType: string; }[];
  spouseAndDependentForm: FormGroup;
  spouseAndDependentDetail: spouseAndDependentDetails;
  radioButtonOptionValues: Array<any> = [{ value: true, text: "Yes" }, { value: false, text: "No" }];
  isDisabled: boolean = false;
  public pageSize = 5;
  public skip = 0;
  public gridView: GridDataResult;
  private notifier: NotifierService;
  private isDeleteClicked: boolean = false;
  public gridState: DataStateChangeEvent = {
    skip: this.skip,
    take: this.pageSize
  };
  isSpouseSelected:boolean=false;
  showDialog:boolean = false;
  deleteIndex: number;
  public editDataItem: relationDetail;

  
  spouseAndDependentMaster: Array<relationDetail> = [];
  constructor(private renderer: Renderer2,
              private _dafService: DafService,
              private formBuilder: FormBuilder,
              notifier: NotifierService) { this.notifier = notifier; }


  
  


  ngOnInit() {

   

    this.relationTypeList = [
      {
        relationId: 1,
        relationType: 'Dependents'
      },
      {
        relationId: 2,
        relationType: 'Spouse'
      }
    ];
    // this.spouseAndDependentDetail= new spouseAndDependentDetails();
    // this.spouseAndDependentDetail.isDependentExist = true;
    // this.spouseAndDependentDetail.relationDetail = this.view;
    // this.docClickSubscription.add(
    //   this.renderer.listen("document", "click", this.onDocumentClick.bind(this))
    // );
    //this.initializeForm();
    this.getSpouseAndDependentDetails();
    
  }

  getSpouseAndDependentDetails(){
    this.isPageReady = false;
    this._dafService.getSpouseAndDependentDetails(this.caseSummaryId).toPromise().then((data: any) => {
      this.spouseAndDependentDetail = data;
      this.view = this.spouseAndDependentDetail.relationDetails;
      console.log(data);
      console.log(this.view);
      console.log(this.spouseAndDependentDetail);
      this.initializeForm();
      this.loadItems();   
      this.isPageReady = true;
    },
      (error) => {
        console.log(error)
        this.isPageReady = true;
      }
    );
  }

  initializeForm() {
    let decodedNotes = this.decodeHTMLEntities(this.spouseAndDependentDetail.notes);
    try {
      this.spouseAndDependentForm = this.formBuilder.group({  
        isDependentExist: new FormControl({ value: this.spouseAndDependentDetail.isDependentExist, disabled: true }),
        notes: new FormControl({ value: decodedNotes, disabled: true }),
      });
      
      this.subscribeToFormControlValueChange();
    }
    catch (err) {
      this.isPageReady = true;
      console.log(err);
    }
  }


  public ngOnDestroy(): void {
    this.docClickSubscription.unsubscribe();
  }

  public relation(id: number): any {
    return this.relationTypeList.find((x) => x.relationId === id);
  }


  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems();
  }
  
  private loadItems(): void {
    this.gridView = {
      data: this.view.slice(this.skip, this.skip + this.pageSize),
      total: this.view.length,
    };
  }

  public saveHandler(product: relationDetail): void {
    if(this.isNew){
      this.view.splice(0, 0, product);
    }
    else{
      Object.assign(
        this.view.find(({ spouseDepAppID }) => spouseDepAppID === product.spouseDepAppID),
        product
      );  
    }

    this.editDataItem = undefined;
    this.isNew=false;
    this.loadItems();

}

  public addHandler(): void {
    // this.closeEditor();

    // this.formGroup = this.createFormGroup({
    //     firstName: "",
    //     i131Required: false,
    //     i539Required: false,
    //     i765Required: false,
    //     isSpouseEmployedFollowingLatestEntry: false,
    //     lastName: "",
    //     middleName: "",
    //     relationId: null,
    //     spouseAndDepRelationId: 0
    // });
    if(this.view.filter(item=>item.relationId==2).length>0){
      this.isSpouseSelected=true;
    }
    else{
      this.isSpouseSelected=false;
    }

    this.editDataItem = new relationDetail();
    this.editDataItem.isSpouseEmployedFollowingLatestEntry=false;
    this.editDataItem.i131Required=false;
    this.editDataItem.i539Required=false;
    this.editDataItem.i765Required=false;
    this.editDataItem.spouseDepAppID =0;



    this.isNew = true;

    // this.grid.addRow(this.formGroup);
  }

  // public saveRow(): void {
  //   if (this.formGroup && this.formGroup.valid) {
  //     this.saveCurrent();
  //   }
  // }

  // public cellClickHandler({
  //   isEdited,
  //   dataItem,
  //   rowIndex,
  // }: CellClickEvent): void {
  //   if (isEdited || (this.formGroup && !this.formGroup.valid) || !this.isGridEditable || this.isDeleteClicked) {
  //     this.isDeleteClicked = false;
  //     return;
  //   }

  //   if (this.isNew) {
  //     rowIndex += 1;
  //   }

  //   this.saveCurrent();

  //   this.formGroup = this.createFormGroup(dataItem);
  //   this.editedRowIndex = rowIndex;

  //   this.grid.editRow(rowIndex, this.formGroup);
  // }

  public cancelHandler(): void {
    this.closeEditor();
  }

  private closeEditor(): void {
    this.grid.closeRow(this.editedRowIndex);

    this.isNew = false;
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }


  public editHandler(args: AddEvent): void {
    this.editDataItem = args.dataItem;

    if(this.view.filter(item=>item.relationId==2).length>0){

      if (this.view.filter(item => item.relationId == 2)[0].spouseDepAppID != this.editDataItem.spouseDepAppID)
      {
      this.isSpouseSelected=true;
      }
      else{
        this.isSpouseSelected=false;
      }
    }
    else{
      this.isSpouseSelected=false;
    }
    this.isNew = false;
}

  

  // private saveCurrent(): void {
  //   if (this.formGroup) {
  //     // this.service.save(this.formGroup.value, this.isNew);
  //     let maxId: number = Math.max.apply(Math, this.view.map(function (o) { return o.spouseAndDepRelationId; }));
  //     let sdDetails = this.formGroup.value;
  //     let index = maxId + 1;
  //     if (this.isNew) {       
  //       sdDetails.spouseAndDepRelationId = index;
  //       sdDetails.isNew = true;
  //       this.view.splice(0, 0, sdDetails);
  //     } else {
  //       Object.assign(
  //         this.view.find(({ spouseAndDepRelationId }) => spouseAndDepRelationId === sdDetails.spouseAndDepRelationId),
  //         sdDetails
  //       );      
  //     }
  //     this.closeEditor();
  //     this.loadItems();
  //   }
  // }

  saveSpouseAndDependentDetails(){
    this.spouseAndDependentDetail = this.spouseAndDependentForm.value;
    console.log( this.spouseAndDependentDetail);
    this.spouseAndDependentDetail.relationDetails = this.view;
    this.isPageReady = false;
    this.spouseAndDependentDetail.notes = this.spouseAndDependentDetail.notes ? this.encodeHTMLEntities(this.spouseAndDependentDetail.notes) : null;
    this._dafService.saveSpouseAndDependentDetails(this.caseSummaryId, this.spouseAndDependentDetail).toPromise().then(() => {
      this._dafService.disableEnableAllFormControls(this.spouseAndDependentForm, [], true);
      this.getSpouseAndDependentDetails();
      this.isEditEnabled = false;
      // this.isUnlocked = false;
      this.isPageReady = true;
      this.notifier.notify('success', 'Spouse and dependent details have been saved successfully');
    }).catch((err) => {
      this.notifier.notify('error', 'Operation Failed');
    });
    
  }
  createFormGroup(dataItem) {
    return new FormGroup({
      relationId: new FormControl(dataItem.relationId, Validators.required),
      firstName: new FormControl(dataItem.firstName, Validators.required),
      middleName: new FormControl(dataItem.middleName),
      lastName: new FormControl(dataItem.lastName),
      isSpouseEmployedFollowingLatestEntry: new FormControl(dataItem.isSpouseEmployedFollowingLatestEntry),
      i539Required: new FormControl(dataItem.i539Required),
      i765Required: new FormControl(dataItem.i765Required),
      i131Required: new FormControl(dataItem.i131Required),
      spouseDepAppID: new FormControl(dataItem.spouseDepAppID),
    });
  }

  matches(el, selector) {
    return (el.matches || el.msMatchesSelector).call(el, selector);
  }

  resetForm(){
    this.isEditEnabled = false;
    this._dafService.disableEnableAllFormControls(this.spouseAndDependentForm, [], true);
    this.isGridEditable = false;
    this.getSpouseAndDependentDetails();
  }

  enableEdit(){
    this.isEditEnabled = true;
    this._dafService.disableEnableAllFormControls(this.spouseAndDependentForm, [], false);
    if(this.spouseAndDependentForm.get("isDependentExist").value == true){
      this.isGridEditable = true;
    }
  }

  private subscribeToFormControlValueChange() {
    this.spouseAndDependentForm.get("isDependentExist").valueChanges.subscribe(isTrue => {
      if (isTrue) {
        this.isGridEditable = true;
        if(this.view == null || this.view.length == 0){
          this.view = this.spouseAndDependentMaster;
        }
        this.loadItems();
        //this.view = this.spouseAndDependentMaster;
      } else {
        this.spouseAndDependentMaster = this.view;
        this.view = []
        this.isGridEditable = false;
        this.loadItems();
      }
    });
  }

  public setDependentIndexToDelete(args: any){
    this.isDeleteClicked = true;
    this.deleteIndex = args.rowIndex;
    this.showDialog = true;
    //this.view.splice(args.rowIndex, 1);
    //this.loadItems();
  }

  public deleteDependent(){
    this.view.splice(this.deleteIndex, 1);
    this.loadItems();
    this.showDialog = false;
  }

  public cancelDelete(){
    this.showDialog = false;
  }

  public onDialogClose(){
    this.showDialog = false;
  }

  encodeHTMLEntities(text) {
    let textArea = document.createElement('textarea');
    let escapeQuotes = text;
    if (text) {
      escapeQuotes = text.replace(/['"()]/g, escape);
    }

    textArea.innerText = escapeQuotes;
    let encodedOutput = textArea.innerHTML;
    let arr = encodedOutput.split('<br>');
    encodedOutput = arr.join('\n');
    return encodedOutput;
  }

  decodeHTMLEntities(text) {
    let textArea = document.createElement('textarea');
    let unescapeQuotes = text;
    if (text) {
      unescapeQuotes = text.replace("%27", unescape);
      unescapeQuotes = unescapeQuotes.replace("%22", unescape);
      unescapeQuotes = unescapeQuotes.replaceAll("%28", unescape);
      unescapeQuotes = unescapeQuotes.replaceAll("%29", unescape);
    }
    textArea.innerHTML = unescapeQuotes;
    return textArea.value;
  }
}
