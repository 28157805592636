import { Component, Input, OnInit } from '@angular/core';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { QuestModel } from '../../../Model/QuestModel';

@Component({
  selector: 'app-view-questionare',
  templateUrl: './view-questionare.component.html',
  styleUrls: ['./view-questionare.component.scss']
})
export class ViewQuestionareComponent implements OnInit {
  public pageSize = 10;
  public skip = 0;
  public caseQuestList: any;
  public DocStatusList: any;
  public gridViewData: any[];
  public gridViewData1: any[];
  public gridState: DataStateChangeEvent = {

    skip: this.skip,
    take: this.pageSize
  };
  public gridState1: DataStateChangeEvent = {

    skip: this.skip,
    take: this.pageSize
  };
  @Input() documentInfo: QuestModel[] = [];
  @Input() documentInfo1: QuestModel[] = [];
  @Input() packagesStatus: any;
  constructor() { }

  ngOnInit(): void {

  }
  ngOnChanges(): void {
    console.log("this.documentInfo-ngOnChanges");
    console.log(this.documentInfo);
    this.skip = 0;
    this.gridState.skip = this.skip;
    this.loadData();
  }

  pageChange(event: DataStateChangeEvent, gridId: string): void {
    console.log("this.documentInfo-pageChange");    

    if (gridId === 'grid1') {

      this.gridState = event;
      this.caseQuestList = process(this.documentInfo, this.gridState);
      this.gridViewData = this.documentInfo;

    } else if (gridId === 'grid2') {
      this.gridState1 = event;
      this.DocStatusList = process(this.documentInfo1, this.gridState1);
      this.gridViewData1 = this.documentInfo1;
    }
  }

  loadData(): void {

    if (Array.isArray(this.documentInfo)) {

      this.caseQuestList = process(this.documentInfo, this.gridState);
      this.gridViewData = this.documentInfo;
    }
    
    if (Array.isArray(this.documentInfo1)) {
      this.DocStatusList = process(this.documentInfo1, this.gridState1);
      this.gridViewData1 = this.documentInfo1;
    }
  }

}
