import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-await-lcadetermination',
  templateUrl: './await-lcadetermination.component.html',
  styleUrls: ['./await-lcadetermination.component.scss']
})
export class AwaitLCADeterminationComponent implements OnInit {
  public isPageReady = false;
  public createdDate:Date=new Date();
  @Input() lcaType="";
  @Input() RequestId:any;

  constructor() { }

  ngOnInit() {
    this.isPageReady=true;
  }

}
