import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { LcaData, WorkSiteData, WorkSiteInfo } from '../model/Lca.model';
@Injectable({
  providedIn: 'root'
})
export class LCAService {

  constructor(private _http: HttpClient) { }
  refreshLCAList(emailId:string,searchTerm?: string) {
    return this.searchLCA(emailId,searchTerm);
  }
  searchLCA(emailId:string,searchTerm: string) {
    if (!searchTerm) {
      return this.getLCAInformation(emailId);
     } // else {
    //   const urlString = `/client/search/${searchTerm}/`;
    //   return ();
    // }
  }

  getLCAUserList(engagementName){
    var engagementNames=encodeURIComponent(engagementName)
    return this._http.get<any>(environment.ApiUrl + 'User/GetAllLCAUsers?engagementName='+engagementNames);
  }

  getLCAEngagements(){
    return this._http.get<any>(environment.ApiUrl + 'LCAData/GetEngagementList');
  }

  editUser(LCAData:LcaData) {

    return this._http.post<any>(environment.ApiUrl + 'User/EditUser',LCAData, {});
  }
  insertWorkSite(lcaData:any,emailId:string) {
    var workSite=new WorkSiteInfo();
    workSite.workSiteData=lcaData;
    return this._http.post<any>(environment.ApiUrl + 'LCAData/InsertWorkSite?emailId='+emailId,workSite, {});
  }
  getLCAInformation(emailId) {
    return this._http.get<any>(environment.ApiUrl + 'LCAData/GetAllLCAInformation?emailId='+emailId);
  }


  searchWorkSite(searchTerm: string,status:string, startDate:any, endDate:any) {
    if (!searchTerm) {
      return this.getWorkSiteInformation(status, startDate, endDate);
     } // else {
    //   const urlString = `/client/search/${searchTerm}/`;
    //   return ();
    // }
  }

  searchRepostingData(searchTerm: string, status: string, startDate: any, endDate: any) {
    if (!searchTerm) {
      return this.getRepostingInformation(status, startDate, endDate);
     } // else {
    //   const urlString = `/client/search/${searchTerm}/`;
    //   return ();
    // }
  }

  getWorkSiteInformation(status: string, startDate: any, endDate: any) {

    let params = new HttpParams();
    params = params.append('status', status);
    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);
    
    return this._http.get<any>(environment.ApiUrl + 'LCAData/GetAllWorkSiteInfo', { params: params });
  }

  getRepostingInformation(status: string, startDate: any, endDate: any) {
    let params = new HttpParams();
    params = params.append('status', status);
    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);
    return this._http.get<any>(environment.ApiUrl + 'LCAData/GetAllRepostingInfo', { params: params });
  }

  
}
