
export class ProfileModel {
  constructor(
    public zoomProfileID = "",
    public createdDate = new Date(),
    public createdDateString = "",
    public firstName = "",
    public lastName = "",
    public profileGUID = "",
    public comments = ""
   ) { }
}
