import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class AwaitDocExtractionService {
  public headers = new HttpHeaders ({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  });
  public options = {
    headers: this.headers
  };
  constructor(private _http: HttpClient) { }
  getCaseDetails(status: string,startDate,endDate) {
    let params = new HttpParams();
    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);
    params = params.append('status', status);
    return this._http.get<any>(environment.ApiUrl + 'Dashboard/GetAllCaseDetails', {params: params });
  }

  getCaseDetailsWithStatus(status: string,startDate,endDate) {
    let params = new HttpParams();
    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);
    params = params.append('status', status);
    
    return this._http.get<any>(environment.ApiUrl + 'Dashboard/GetCaseDetails',  { params: params });
  }

  getDocumentDetails(caseType: string, applicantType: string, requestType: string) {
    return this._http.get<any>(environment.ApiUrl + 'Dashboard/GetDocumentCheckList?caseType=' + caseType + '&applicantType=' +
     applicantType + '&requestType=' + requestType, {});
  }
  resubmitForCompilation(insZoomId: string, id: number, message: string) {
   return this._http.post<any>(environment.ApiUrl + 'Dashboard/Recompile?insZoomId=' + insZoomId +
   '&id=' + id + '&message=' + message, {});
  }
}
