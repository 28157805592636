import { Component, Input, OnInit } from '@angular/core';
import { ProfileModel } from '../../../Model/ProfileModel';

@Component({
  selector: 'app-view-case',
  templateUrl: './view-case.component.html',
  styleUrls: ['./view-case.component.scss']
})
export class ViewCaseComponent implements OnInit {
  @Input() documentInfo: ProfileModel;
  @Input() packagesStatus: any;
  constructor() { }

  ngOnInit(): void {
  }

}
