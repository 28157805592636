export class addressWizardInfos {
    addressWizardID: number = 0;
    caseID:string=null;
    caseSummaryID: number = 0;
    addressType: string = null;
    entityName: string = null;
    streetNumber: string = null;
    streetName: string = null;
    apt: string = null;
    city: string = null;
    state: string = null;
    geography: string = null;
    postalCode: string = null;
    county: string = null;
    countyOnOLFC: string = null;
    salary: number = 0;
    flcWage: number = 0;
    pwi: boolean = null;
    geographyId: number = null;
    stateId: number = null;
    zipCodeId: number = null;
    cityId: number = null;
    countyId: number = null;
    constructor(obj?: any) {
        try {
            if (obj) {
                
                this.caseID = obj.caseID ? obj.caseID : null;
                this.addressWizardID = obj.addressWizardID ? obj.addressWizardID : 0;
                this.caseSummaryID = obj.caseSummaryID ? obj.caseSummaryID : 0;
                this.addressType = obj.addressType ? obj.addressType : null;
                this.entityName = obj.entityName ? obj.entityName : null;
                this.streetName = obj.streetName ? obj.streetName :null;
                this.streetNumber = obj.streetNumber ? obj.streetNumber : null;
                this.apt = obj.apt ? obj.apt : null;
                this.city = obj.city ? obj.city : null;
                this.state = obj.state ? obj.state :null;
                this.postalCode = obj.postalCode ? obj.postalCode : null;
                this.county = obj.county ? obj.county : null;
                this.countyOnOLFC = obj.countyOnOLFC ? obj.countyOnOLFC : null;
                this.salary = obj.salary ? obj.salary :0;
                this.flcWage = obj.flcWage ? obj.flcWage : 0;
                this.pwi = obj.flcWage ? obj.flcWage : null;
                this.stateId = obj.stateId ? obj.stateId : null;
                this.zipCodeId = obj.zipCodeId ? obj.zipCodeId : null;
                this.cityId = obj.cityId ? obj.cityId : null;
                this.countyId = obj.countyId ? obj.countyId : null;
                this.geographyId = obj.geographyId ? obj.geographyId : null;
                this.geography = obj.geography ? obj.geography : null;
            }
        }
        catch (err) {
            console.log(err);
        }
    }
}
