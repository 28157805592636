import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, OnChanges} from '@angular/core';
import {DashboardView} from '../model/dashboardview';
import {AwaitDocExtractionService} from '../service/awaitdocextraction.service';
import { NotifierService } from 'angular-notifier';
@Component({
  selector: 'app-documentchklist',
  templateUrl: './documentchklist.component.html',
  styleUrls: ['./documentchklist.component.scss']
})
export class DocumentchklistComponent implements OnInit, OnChanges {
  @Input() dashboardView: DashboardView;
  @ViewChild('closeButton',  {static: false}) closeButton: ElementRef;
  bnfId: string;
  caseType: string;
  applicantType: string;
  requestType: string;
  public documentSearch: any;
  public searchText = '';
  private notifier: NotifierService;
  public documentList: any[];
  public newPageReady = true;
  constructor(private dashboardService: AwaitDocExtractionService) {
  }

  ngOnInit() {
  }
  ngOnChanges() {
    this.loadDocumentCheckList(this.dashboardView);
  }
  closeForm() {
    const closeButton: HTMLElement = this.closeButton.nativeElement as HTMLElement;
    closeButton.click();
  }
  loadDocumentCheckList(caseDetail: DashboardView) {
    this.newPageReady=false;
     this.bnfId = caseDetail.parentBnfId;
this.caseType = caseDetail.caseType;
this.applicantType = caseDetail.applicantType;
this.requestType = caseDetail.requestType;
this.dashboardService.getDocumentDetails(this.caseType, this.applicantType, this.requestType).subscribe((data: any) => {
  this.documentList = data;
  this.documentSearch = this.documentList;
  this.newPageReady=true;
  console.log(this.documentList);
},
(error) => {
  this.notifier.notify('error', 'Error occured while getting data from server');
}
);
}
clearFilter() {
  // Resets search textbox
  this.searchText = '';
}
applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
  let filterData = this.documentSearch.filter(r => r.applicantType.toLowerCase().includes(filterValue)
  || r.documentType.toLowerCase().includes(filterValue));
  this.documentList = filterData;
}
}
