import { Component, OnInit, Output, EventEmitter, ViewChild, TemplateRef, Inject, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { WorkSiteData } from '../lca-compliance/model/Lca.model';
import { LCAService } from '../lca-compliance/service/lca.service';
import { AddLCAService } from './service/add-lca.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { NotificationService } from "@progress/kendo-angular-notification";



export class streetAddresses {
  streetAddress: string;
}

@Component({
  selector: 'app-add-lca',
  templateUrl: './add-lca.component.html',
  styleUrls: ['./add-lca.component.scss']
})
export class AddLcaComponent implements OnInit {
  public caseData: WorkSiteData = new WorkSiteData();
  public emailForm: FormGroup;
  public statesList=[];
  public zipcodeList = [[]];
  public addressTypesList = [];
  public state;
  public statesListSource=[];
  public startDate;
  public zipcodeSource=[[]];
  public disabled = true;
  public countydisabled=[true];
  public citydisabled = [true];
  public selectedType=[];
  public cityArray=[[]];
  public countyArray = [[]];
  public showConfirmation = false;
  public isReady=false;
  //public WorkSiteDetails:WorkSiteData[]=[];
  public WorkSiteDetails: any = [];
  cityDataArray: any[]=[];
  countyDataArray: any[]=[];
  addressTypes: any[]=[];
  public listItems: Array<string> = [];
  public emailId="";
  lcaAddressInfo=[];
  public dateDifference=0;
  public primaryAddressIndex: number = -1;
  public residenceAddressIndex: number = -1;
  public specialAddressTypes: any[] = ['Primary Worksite Address with Employer', 'Home Address/Residence']
  options : streetAddresses[]= [
  ];
  filteredOptions: Observable< streetAddresses[]>[] = [];
  private hideAfter = 2000;
  public houseItems: Array<string> = [
    "Apt.",
    "Ste.",
    "Flr.",
    "Door"
  ];
  @Output() worksiteDatats = new EventEmitter<any>();
  @Output() page = new EventEmitter<number>();
  @Input() lcaType="";


  constructor(private formBuilder: FormBuilder,private lcaService: LCAService,public addLCAService: AddLCAService,@Inject(NotificationService) private notificationService: NotificationService) {
    this.createForm();
   }

   createForm(){
    this.emailForm = this.formBuilder.group({
      emails: this.formBuilder.array([this.createEmailFormGroup()])
    });
    this.ManageNameControl(0);
    this.isReady=true;
   }

  ngOnInit() {
  

    this.GetStates();

    this.GetAddressType(0);

    this.getEmailId();
    this.getLCAData();
    
  }

  getEmailId(){
    this.addLCAService.currentData.subscribe((data: any) => {
      // Binds the data to grid
      this.emailId=data.emailId;
    });
  }

  getLCAData(){
    this.addLCAService.addedLCAData.subscribe((data:any) => {
        if(data.length>0){
          var arrayControl = this.emailForm.get('emails') as FormArray;
          while (arrayControl.length) {
            arrayControl.removeAt(0);
          }
          data.forEach(async (staff,i) => {
            this.GetAddressType(i);
            var r = await this.GetZipcodes(staff.state,i);
            var c = await this.GetCountyInfo(staff.zipCode,i);
            arrayControl.push(this.formBuilder.group(staff))
          });
        }
    });
  }

  ManageNameControl(index: number) {
    var arrayControl = this.emailForm.get('emails') as FormArray;
    this.filteredOptions[index] = arrayControl.at(index).get('streetAddress').valueChanges
      .pipe(
      startWith<string |  streetAddresses>(''),
      map(value => 
        typeof value === 'string' ? value : value.streetAddress
      ),
      map(streetAddress => 
        streetAddress ? this._filter(streetAddress) : this.options.slice()
        )
      );

  }

  public displayFn(user?:  streetAddresses){
    return user ;
  }

  public _filter(name: string):  streetAddresses[] {
    const filterValue = name.toLowerCase();
    var newValue= this.options.filter(option => option.streetAddress.toLowerCase().includes(filterValue))
    return newValue;
  }

  confirmationDialog(open: boolean) {
    // Opens the confirmation dialog
    if (open) {
      this.showConfirmation = true;
    } else {
      this.showConfirmation = false;
    }
  }

  invalidMessage(){
    this.notificationService.show({
      content: "Please ensure to add all the required Data",
      position: { horizontal: "right", vertical: "top" },
      animation: { type: "fade", duration: 800 },
      type: { style: "warning", icon: true },
      hideAfter: this.hideAfter,
    });
  }

  saveData(){
    this.WorkSiteDetails=[];
    var saveValues = this.emailForm.controls.emails.value;

    if(this.emailForm.valid){

     
   
    saveValues.forEach(item => {
      if(item.state==""||item.startDate==""||item.streetAddress.trim()==""){
        this.WorkSiteDetails=[];
        this.invalidMessage();
        
      }
      else if (item.startDate && item.expiryDate && item.startDate > item.expiryDate) {
        this.notificationService.show({
          content: "Please enter valid dates. End Date should be greather than or equal to Start Date.",
          position: { horizontal: "right", vertical: "top" },
          animation: { type: "fade", duration: 800 },
          type: { style: "warning", icon: true },
          hideAfter: this.hideAfter,
        });
      }    
      else if (item.addressType == "Third Party Placement or Client Worksite" && (item.workFrom == "" || item.clientName == "" || item.engagementPartner == "" || item.experienceManager == "")){
        this.WorkSiteDetails=[];
        this.invalidMessage();
      }
      else if (item.addressType == "Third Party Placement or Client Worksite" && item.workFrom && item.workTo && item.workFrom > item.workTo) {
        this.notificationService.show({
          content: "Please enter valid dates. Period of Work From should be greather than or equal to Period of Work To.",
          position: { horizontal: "right", vertical: "top" },
          animation: { type: "fade", duration: 800 },
          type: { style: "warning", icon: true },
          hideAfter: this.hideAfter,
        });
      }
      else{
     
      var workSite=new WorkSiteData();
      workSite.county=item.county;
      workSite.startDate=item.startDate;
      workSite.expiryDate = item.expiryDate==""?null:item.expiryDate;
      workSite.zipCode = item.zipCode;
      workSite.streetAddress = item.streetAddress instanceof Object?item.streetAddress.streetAddress:item.streetAddress;
      workSite.streetNumber=item.streetNumber;
      workSite.houseNumber=item.houseNumber;
      workSite.houseName=item.houseName;
      workSite.city = item.city;
      workSite.clientName = item.clientName;
      workSite.workFrom=item.workFrom==""?null:item.workFrom;
      workSite.workTo=item.workTo==""?null:item.workTo;
      workSite.toalDays=item.toalDays;
      workSite.telePhone=item.telePhone;
      workSite.engagementCode = item.engagementCode;
      workSite.interactionId=item.interactionId;
      workSite.engagementPartner=item.engagementPartner;
      workSite.experienceManager=item.experienceManager;    
      workSite.state=item.state;
      workSite.addressType = item.addressType;
      this.WorkSiteDetails.push(workSite);
      }
    });
    if(this.WorkSiteDetails.length>0){
    this.addLCAService.setLCAData(this.WorkSiteDetails);

    this.worksiteDatats.emit(this.WorkSiteDetails);
    }
  
  }
  else {
    this.notificationService.show({
      content: "Please ensure to add all the required Data",
      position: { horizontal: "right", vertical: "top" },
      animation: { type: "fade", duration: 800 },
      type: { style: "warning", icon: true },
      hideAfter: this.hideAfter,
    });

  }


  }

  public addEmailFormGroup() {
    const emails = this.emailForm.get('emails') as FormArray
    if(emails.length<10){
    emails.push(this.createEmailFormGroup())
    this.ManageNameControl(emails.length - 1);
    this.citydisabled[emails.length - 1]=true;
    this.countydisabled[emails.length - 1] = true;
    this.handleAddressType(emails.length - 1);
    }

  }

  public handleAddressType(i: number) {
    var formArrayValues = this.emailForm.controls.emails.value;
    var tempAddressTypes = this.addressTypesList;
    formArrayValues.forEach(item => {
      if (this.specialAddressTypes.includes(item.addressType)) {
        tempAddressTypes = tempAddressTypes.filter(x => x.addressTypes != item.addressType);
      }
    })
    this.addressTypes[i] = tempAddressTypes;
  }

  public cascadeChanges(event, i) {
    var tempAddressTypes1 = this.addressTypesList;
    if (event == 'Primary Worksite Address with Employer') {
      this.primaryAddressIndex = i;
      var formArrayValues1 = this.emailForm.controls.emails.value;
      formArrayValues1.forEach((item, index) => {
        if (index != i) {
          if (this.residenceAddressIndex != -1 && item.addressType != 'Home Address/Residence')
            this.addressTypes[index] = this.addressTypesList.filter(x => x.addressTypes != 'Primary Worksite Address with Employer' && x.addressTypes != 'Home Address/Residence');
          else
            this.addressTypes[index] = tempAddressTypes1.filter(x => x.addressTypes != 'Primary Worksite Address with Employer');
        }
          
      });
    }
    if (event != 'Primary Worksite Address with Employer' && this.primaryAddressIndex == i) {
      this.primaryAddressIndex = -1;
      var formArrayValues1 = this.emailForm.controls.emails.value;
      formArrayValues1.forEach((item, index) => {
        if (index != i) {
          if (this.residenceAddressIndex != -1 && item.addressType != 'Home Address/Residence')
            this.addressTypes[index] = this.addressTypesList.filter(x => x.addressTypes != 'Home Address/Residence');
          else
            this.addressTypes[index] = this.addressTypesList;
        }       
      });
      
    }
    if (event == 'Home Address/Residence') {
      this.residenceAddressIndex = i;
      var formArrayValues1 = this.emailForm.controls.emails.value;
      formArrayValues1.forEach((item, index) => {
        if (index != i)
          if (this.primaryAddressIndex != -1 && item.addressType != 'Primary Worksite Address with Employer')
            this.addressTypes[index] = this.addressTypesList.filter(x => x.addressTypes != 'Primary Worksite Address with Employer' && x.addressTypes != 'Home Address/Residence');
          else
            this.addressTypes[index] = tempAddressTypes1.filter(x => x.addressTypes != 'Home Address/Residence');
      });
    }
    if (event != 'Home Address/Residence' && this.residenceAddressIndex == i) {
      this.residenceAddressIndex = -1;
      var formArrayValues1 = this.emailForm.controls.emails.value;
      formArrayValues1.forEach((item, index) => {
        if (index != i) {
          if (this.primaryAddressIndex != -1 && item.addressType != 'Primary Worksite Address with Employer')
            this.addressTypes[index] = this.addressTypesList.filter(x => x.addressTypes != 'Primary Worksite Address with Employer');
          else
            this.addressTypes[index] = this.addressTypesList;
        }
        
      });
    }

    if(this.emailForm.controls.emails.value[i].addressType=="Third Party Placement or Client Worksite")
    {
      var data=this.emailForm.controls.emails['controls'][i].value;
      if(data.startDate){
      data.workFrom=data.startDate;
      data.workTo=data.expiryDate;
      this.emailForm.controls.emails['controls'][i].setValue(data);
      this.emailForm.controls.emails['controls'][i].updateValueAndValidity();
      this.onStartsDateChange(data.startDate,i);
      }
    }

  }

  public removeOrClearEmail(i: number) {
    const emails = this.emailForm.get('emails') as FormArray
    if (emails.length > 1) {
      emails.removeAt(i)
      this.addressTypes.splice(i, 1);
      if (this.primaryAddressIndex == i) {        
        this.primaryAddressIndex = -1;     
        var formArrayValues1 = emails.value;
        formArrayValues1.forEach((item, index) => {
        if (this.residenceAddressIndex != -1 && item.addressType != 'Home Address/Residence')
          this.addressTypes[index] = this.addressTypesList.filter(x => x.addressTypes != 'Home Address/Residence');
        else
            this.addressTypes[index] = this.addressTypesList;
        });
      }
      if (this.primaryAddressIndex > i) {
        this.primaryAddressIndex = this.primaryAddressIndex - 1;
      }

      if (this.residenceAddressIndex == i) {
        this.residenceAddressIndex = -1;
        var formArrayValues1 = emails.value;
        formArrayValues1.forEach((item, index) => {
          if (this.primaryAddressIndex != -1 && item.addressType != 'Primary Worksite Address with Employer')
            this.addressTypes[index] = this.addressTypesList.filter(x => x.addressTypes != 'Primary Worksite Address with Employer');
          else
            this.addressTypes[index] = this.addressTypesList;
        });
      }
      if (this.residenceAddressIndex > i) {
        this.residenceAddressIndex = this.residenceAddressIndex - 1;
      }

    } else {
      emails.reset()
    }
  }
  optionalFormControls = ["middleName", "signatoryName", "signatoryTitle", "signatoryTelephone", "apt"];
  formErrors = {
    'addressType': '',
    'streetAddress':'',
    'clientName':'',
    'state': '',
    'zipcode': '',
    'experienceManager': '',
    'engagementPartner': '',
    'startDate': '',
    'workfrom':''
  };

  validationMessages = {
   
    'addressType': {
      'required': 'addressType is required.',
    },
    'streetAddress': {
      'required': 'Street Address is required.',
    },
    'clientName': {
      'required': 'Client Name is required.',
    },
    'state': {
      'required': 'State is required.',
    },
    'zipcode': {
      'required': 'Zipcode is required.',
    },
  
    'experienceManager': {
      'required': 'Experience Manager is required.',
    },
    'engagementPartner': {
      'required': 'Engagement Partner is required.',
    },
    'startDate': {
      'required': 'Start Date  is required.',
    },
    'workfrom': {
      'required': 'Period of Work From  is required.',
    },
  };
  private createEmailFormGroup(): FormGroup {
    return new FormGroup({
      'startDate': new FormControl('',Validators.required),
      'expiryDate': new FormControl(''),
      'streetAddress': new FormControl('', Validators.required),
      /*'streetAddress': new FormControl({ value: this.caseData.streetAddress, }, [Validators.required]),*/
      'state': new FormControl('',Validators.required),
      'zipCode': new FormControl('',Validators.required),
      'addressType': new FormControl('',Validators.required),
      'county' : new FormControl(''),
      'houseNumber':new FormControl(''),
      'houseName':new FormControl(''),
      'city':new FormControl(''),
      'clientName': new FormControl(''),
      'workFrom':new FormControl('',null),
      'workTo':new FormControl('',null),
      'toalDays': new FormControl(''),
      'telePhone' : new FormControl(''),
      'engagementCode': new FormControl(''),
      'interactionId': new FormControl(''),
      'engagementPartner': new FormControl(''),
      'experienceManager': new FormControl('')
    })
  }

  GetStates() {
    this.addLCAService.getStates().subscribe((data: any) => {
      this.statesList = data;
      this.statesListSource = data;
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
  }


  GetAddressType(i) {
    this.addLCAService.GetAddressType().subscribe((data: any) => {
      this.addressTypes[i] = data;
      this.addressTypesList = data;
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
  }


  GetLCAAddress(zipCode,index)
  {
    this.listItems=[];
    this.addLCAService.GetLCAAddress(zipCode,this.emailId).subscribe((data: any) => {
      this.lcaAddressInfo = data;
      this.lcaAddressInfo.forEach(item=>{
       if(item.primaryAddress){
         this.listItems.push(item.primaryAddress);
       }
       if(item.addl1Address){
        this.listItems.push(item.addl1Address);
      }
      if(item.addl2Address){
        this.listItems.push(item.addl2Address);
      }
      this.options=[];
      this.listItems.forEach(item=>{
        var data= new streetAddresses();
        data["streetAddress"]=item;
        this.options.push(data);
      });
      this.ManageNameControl(index);
      })
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
  }

  GetZipcodes(event, index: any): Promise<any> {
    this.zipcodeList[index] = [];
    this.zipcodeSource[index] = [];
    let res = this.addLCAService.GetZipcodes(event).toPromise().then((data: any) => {
      if (data.length > 0) {
        data.forEach(element => {
          this.zipcodeList[index].push(element.zipcode.toString());
          this.zipcodeSource[index].push(element.zipcode.toString());
        });
      //var datas=this.emailForm.controls.emails['controls'][index].value;
      //datas.zipCode="";
      //this.emailForm.controls.emails['controls'][index].setValue(datas);
      //this.emailForm.controls.emails['controls'][index].updateValueAndValidity();
      this.disabled = false;

      }
      return Promise.resolve(data);
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
    return res;
  }
  //GetZipcodes(event,index:any) {  
  //  this.zipcodeList[index]=[];
  //  this.zipcodeSource[index]=[];
  //  this.addLCAService.GetZipcodes(event).subscribe((data: any) => {
  //    if(data.length>0){
  //      data.forEach(element => {
  //        this.zipcodeList[index].push(element.zipcode.toString());
  //        this.zipcodeSource[index].push(element.zipcode.toString());
  //      });
     
  //    var datas=this.emailForm.controls.emails['controls'][index].value;
  //    datas.zipCode="";
  //    this.emailForm.controls.emails['controls'][index].setValue(datas);
  //    this.emailForm.controls.emails['controls'][index].updateValueAndValidity();
  //    this.disabled = false;
  //    }
  //  },
  //    (error) => {
  //      //this.notifier.notify('error', 'Error occured while updating the user details');
  //    }
  //  );
  //}

  onstartDateChange(event,index){
    if(this.emailForm.controls.emails.value[index].addressType=="Third Party Placement or Client Worksite")
    {
      var data=this.emailForm.controls.emails['controls'][index].value;
      data.workFrom=event;
      this.emailForm.controls.emails['controls'][index].setValue(data);
      this.emailForm.controls.emails['controls'][index].updateValueAndValidity();
      this.onStartsDateChange(event,index);
    }
  }

  onEndDateChange(event,index){
    var data=this.emailForm.controls.emails['controls'][index].value.workFrom; 
    if((data!=""&&data!=null) && (event!=""&&event!=null)){
    this.dateDifference = Math.round((event - data)/1000/60/60/24);
    }
    else{
      this.dateDifference=null;
    }
    var data=this.emailForm.controls.emails['controls'][index].value;
    data.toalDays=this.dateDifference;
    this.emailForm.controls.emails['controls'][index].setValue(data);
    this.emailForm.controls.emails['controls'][index].updateValueAndValidity();
    
  }

  onEndDateChanges(event,index){
    if(this.emailForm.controls.emails.value[index].addressType=="Third Party Placement or Client Worksite")
    {
      var data=this.emailForm.controls.emails['controls'][index].value;
      data.workTo=event;
      this.emailForm.controls.emails['controls'][index].setValue(data);
      this.emailForm.controls.emails['controls'][index].updateValueAndValidity();
      this.onEndDateChange(event,index);
    }

  }

  onStartsDateChange(event,index){
    var data=this.emailForm.controls.emails['controls'][index].value.workTo; 
    if((data!=""&&data!=null) && (event!=""&&event!=null)){
      this.dateDifference = Math.round((data - event)/1000/60/60/24);   
       }
      else{
        this.dateDifference=null;
      }
   
    var data=this.emailForm.controls.emails['controls'][index].value;
    data.toalDays=this.dateDifference;
    this.emailForm.controls.emails['controls'][index].setValue(data);
    this.emailForm.controls.emails['controls'][index].updateValueAndValidity();
    }
  


  GetCountyInfo(event, index: any): Promise<any>  {
    this.cityArray[index]=[];
    this.countyArray[index]=[];
    
    let res = this.addLCAService.GetCountyInfo(event).toPromise().then((data: any) => {
      var newData = data;
      if(newData.length>0){
     this.cityArray[index].push(
      newData[0].cityName.toString()
     );
     if(this.cityArray[index].length>0)
     {
     this.citydisabled[index]=false;
     }

     newData.forEach(element => {
       this.countyArray[index].push(
         element.countyName.toString()
       );
     });
     if(this.countyArray[index].length>0){
      this.countydisabled[index]=false;
     }
     this.cityDataArray[index]=this.cityArray[index];
     this.countyDataArray[index] = this.countyArray[index];
    }
     //var data=this.emailForm.controls.emails['controls'][index].value;
     // data.county="";
     // data.city="";
     // this.emailForm.controls.emails['controls'][index].setValue(data);
     // this.emailForm.controls.emails['controls'][index].updateValueAndValidity();
     this.GetLCAAddress(event,index);
    
      return Promise.resolve(data);
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
    return res;
  }



  public cityValueChange(event:any){

  }

  public countyValueChange(event:any){
    
  }



  public filterChange(filter: any): void {
    this.statesList= this.statesListSource.filter(
      (s) => s.stateName.toLowerCase().startsWith(filter.toLowerCase()) 
    );
  }

  public zipFilterChange(filter: any,index:any): void {
    this.zipcodeList[index] = this.zipcodeSource[index].filter(
      (s) => s.toString().startsWith(filter)
    );
  }

  public cityFilterChange(filter: any,index:any): void {
    this.cityArray[index] = this.cityDataArray[index].filter(
      (s) => s.toString().startsWith(filter)
    );
  }

  public countyFilterChange(filter: any): void {
    this.countyArray = this.countyDataArray.filter(
      (s) => s.toString().startsWith(filter)
    );
  }

  goBack() {
    let par: any = "back";
    //this.saveData();
    this.WorkSiteDetails = [];
    var saveValues = this.emailForm.controls.emails.value;
    saveValues.forEach(item => {

        var workSite = new WorkSiteData();
        workSite.county = item.county;
        workSite.startDate = item.startDate;
        workSite.expiryDate = item.expiryDate == "" ? null : item.expiryDate;
        workSite.zipCode = item.zipCode;
        workSite.streetAddress = item.streetAddress instanceof Object ? item.streetAddress.streetAddress : item.streetAddress;
        workSite.streetNumber = item.streetNumber;
        workSite.houseNumber = item.houseNumber;
        workSite.houseName = item.houseName;
        workSite.city = item.city;
        workSite.clientName = item.clientName;
        workSite.workFrom = item.workFrom == "" ? null : item.workFrom;
        workSite.workTo = item.workTo == "" ? null : item.workTo;
        workSite.toalDays = item.toalDays;
        workSite.telePhone = item.telePhone;
        workSite.engagementCode = item.engagementCode;
        workSite.interactionId = item.interactionId;
        workSite.engagementPartner = item.engagementPartner;
        workSite.experienceManager = item.experienceManager;
        workSite.state = item.state;
        workSite.addressType = item.addressType;
        this.WorkSiteDetails.push(workSite);
      
    });
    if (this.WorkSiteDetails.length > 0) {
      this.addLCAService.setLCAData(this.WorkSiteDetails);

      //this.worksiteDatats.emit(this.WorkSiteDetails);
    }
    this.page.emit(this.WorkSiteDetails);
  }
  logValidationErrors(group: FormGroup = this.emailForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid
          // && (abstractControl.touched || abstractControl.dirty)
        ) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  logOnChangeValidationErrors(group: FormGroup = this.emailForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid
          && (abstractControl.touched || abstractControl.dirty)
        ) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }
}
