import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MsalGuard } from '@azure/msal-angular';
import { DashboardDetailsComponent } from './dashboard-details/dashboard-details.component';
const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full', data: { breadcrumb: 'Home' } },
  { path: 'dashboard', component: DashboardComponent, canActivate: [MsalGuard], data: { breadcrumb: 'EYMPINSZoom Home' } },
  { path: 'draftdetails', component: DashboardDetailsComponent, canActivate: [MsalGuard], data: { breadcrumb: 'EYMP Details' } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EympINSZoomIntegrationRoutingModule { }
