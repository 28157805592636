import { addressWizardInfos } from "./addressWizardInfos";
import { GeneralDetailsModel } from "./GeneralDetails";
import { basisOfClassification, wageLevel } from "./LCAAssessmentDetails";

export class primaryWorksiteAddress extends GeneralDetailsModel{
    primWorkAddID: number = 0;
  state: string = null;
  officeaddress: string = null;
    city: string = null;
    caseSummaryId: number = 0;
    county: string = null;
    postalCode: string = null;
    streetName: string = null;
    streetNumber : number = null;
    apt: number = null;
    countryOnOLFC: string = null;
    employeeOffsite: boolean = false;
    compliance: boolean = false;
    addnlWorksites: boolean = false;
    h1BDependantER: boolean = false;
    willfulViolator: boolean = false;
    exemptH1Bworker: boolean = false;
    reasonForExemption: string = null;
    educCoveredByACIWA: boolean = false;
    notes: string = null;
    stateID: number = 0;
    zipCodeId: number = 0;
    cityId: number = 0;
    countyId: number = 0;
    geographyId: number = 0;
    officeaddressId: number = 0;
    companyName: string = null;

    //LCA Assessment
    jobTitle: string;
    oesSOCTitle: string;
    onetOcssId: number;
    oessocCode: string;
    lcaValidityFrom: Date;
    lcaValidityTo: Date;
    dotCode: string;
    wageLevel: Array<wageLevel> = [];
    basisOfClassification: Array<basisOfClassification> = [];
    wageSourceAndYear: string;
    prevailingWage: number;
    actualSalary: number;
    isInSalaryRange: boolean;
    salaryRangeFrom: number;
    salaryRangeTo: number;
    emailAddressForLCA: string;
    headcount: number;
    worksiteAddress: Array<addressWizardInfos> = [];
    wageLevelId: number;
    wageLevelName: string;
    isbasis: number;
    lcaAssmtID: number;
    officeType: string;
    constructor(obj?: any) {
        super();
        try{
          if (obj) {
            this.caseId = obj.caseId ? obj.caseId : null;
            this.caseSummaryId = obj.caseSummaryId ? obj.caseSummaryId : 0;
            this.primWorkAddID = obj.primWorkAddID ? obj.primWorkAddID : 0;
            this.state = obj.state ? obj.state : null;
            this.city = obj.city ? obj.city : null;
            this.county = obj.county ? obj.county : null;
            this.postalCode = obj.postalCode ? obj.postalCode : null;
            this.streetName = obj.streetName ? obj.streetName : null;
            this.streetNumber = obj.streetNumber ? obj.streetNumber : null;
            this.apt = obj.apt ? obj.apt : null;
            this.countryOnOLFC = obj.countryOnOLFC ? obj.countryOnOLFC : null;
            this.addnlWorksites = obj.addnlWorksites ? obj.addnlWorksites : false;
            this.compliance = obj.compliance ? obj.compliance : false;
            this.educCoveredByACIWA = obj.educCoveredByACIWA ? obj.educCoveredByACIWA : false;
            this.employeeOffsite = obj.employeeOffsite ? obj.employeeOffsite : false;
            this.exemptH1Bworker = obj.exemptH1BWorker ? obj.exemptH1BWorker : false;
            this.reasonForExemption = obj.reasonForExemption ? obj.reasonForExemption : "";
            this.h1BDependantER = obj.h1BDependantER ? obj.h1BDependantER : false;
            this.notes = obj.notes ? obj.notes : null;
            this.willfulViolator = obj.willfulViolator ? obj.willfulViolator : false;
            this.stateID = obj.stateID ? obj.stateID : null;
            this.zipCodeId = obj.zipCodeId ? obj.zipCodeId : null;
            this.cityId = obj.cityId ? obj.cityId : null;
            this.countyId = obj.countyId ? obj.countyId : null;
            this.geographyId = obj.geographyId ? obj.geographyId : null;
            this.officeaddressId = obj.officeaddressId ? obj.officeaddressId : null;
            this.companyName = obj.companyName ? obj.companyName : null;

            this.lcaAssmtID = obj.lcaAssmtID ? obj.lcaAssmtID : null;
            this.jobTitle = obj.jobTitle ? obj.jobTitle : null;
            this.oesSOCTitle = obj.oesSOCTitle ? obj.oesSOCTitle : null;
            this.onetOcssId = obj.onetOcssId ? obj.onetOcssId : null;
            this.oessocCode = obj.oessocCode ? obj.oessocCode : null;
            this.lcaValidityFrom = obj.lcaValidityFrom ? obj.lcaValidityFrom : null;
            this.lcaValidityTo = obj.lcaValidityTo ? obj.lcaValidityTo : null;
            this.dotCode = obj.dotCode ? obj.dotCode : null;
            this.wageLevel = obj.wageLevel ? obj.wageLevel : null;
            this.basisOfClassification = obj.basisOfClassification ? obj.basisOfClassification : null;
            this.wageSourceAndYear = obj.wageSourceAndYear ? obj.wageSourceAndYear : null;
            this.prevailingWage = obj.prevailingWage ? obj.prevailingWage : null;
            this.actualSalary = obj.actualSalary ? obj.actualSalary : null;
            this.isInSalaryRange = obj.isInSalaryRange ? obj.isInSalaryRange : null;
            this.salaryRangeFrom = obj.salaryRangeFrom ? obj.salaryRangeFrom : null;
            this.salaryRangeTo = obj.salaryRangeTo ? obj.salaryRangeTo : null;
            this.emailAddressForLCA = obj.emailAddressForLCA ? obj.emailAddressForLCA : null;
            this.headcount = obj.headcount ? obj.headcount : null;
            this.worksiteAddress = obj.worksiteAddress ? obj.worksiteAddress : null;
            this.wageLevelId = obj.wageLevelId ? obj.wageLevelId : null;
            this.wageLevelName = obj.wageLevelName ? obj.wageLevelName : null;
            this.isbasis = obj.isbasis ? obj.isbasis : null;
            this.officeType = obj.officeType ? obj.officeType : null;
          }
        }
        catch(err){
            console.log(err);
        }
    }
}
