import { BehaviorSubject } from 'rxjs';
import { FormGroup } from '@angular/forms'; import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ProfileModel } from '../Model/ProfileModel';
import { CaseModel } from '../Model/CaseModel';
import { EYIModel } from '../Model/EYIModel';
import { QuestModel } from '../Model/QuestModel';
import { QuestAddModel } from '../Model/QuestAddModel';


@Injectable({
  providedIn: 'root'
})
export class EympService {
  constructor(private _http: HttpClient) { }
  getDashboardCount(startDate, endDate) {
    let params = new HttpParams();
    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);


    return this._http.get<any>(environment.ApiUrl + 'EYMPINSZoomIntegration/GetEympDashboardCount', { params: params });
  }
  getEympDetailsWithStatus(status: string, ProfileCaseStatus: string, startDate, endDate) {
    let params = new HttpParams();
    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);
    params = params.append('status', status);
    params = params.append('ProfileCaseStatus', ProfileCaseStatus);
    return this._http.get<any>(environment.ApiUrl + 'EYMPINSZoomIntegration/GetEympDetailsbyStatus', { params: params });
  }

  getProfileInfoById(documentId: number) {
    return this._http.get<ProfileModel>(environment.ApiUrl + 'EYMPINSZoomIntegration/GetProfileInfoByID?zoomProfileId=' + documentId, {});
  }
  getCaseInfoById(documentId: number) {
    return this._http.get<CaseModel>(environment.ApiUrl + 'EYMPINSZoomIntegration/GetCaseInfoByCaseID?Id=' + documentId, {});
  }
  getCaseEYIInfoById(documentId: number) {
    return this._http.get<EYIModel>(environment.ApiUrl + 'EYMPINSZoomIntegration/GetCaseEYIInfoByCaseID?Id=' + documentId, {});
  }
  getCaseQuestInfoById(documentId: number) {
    return this._http.get<QuestModel>(environment.ApiUrl + 'EYMPINSZoomIntegration/GetCaseQuestInfoByCaseID?Id=' + documentId, {});
  }
  getCaseQuestAddInfoById(documentId: number) {
    return this._http.get<QuestAddModel>(environment.ApiUrl + 'EYMPINSZoomIntegration/GetCaseQuestAddInfoByCaseID?Id=' + documentId, {});
  }
}
