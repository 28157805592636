import { Component, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AddEvent, DataStateChangeEvent, GridComponent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { NotifierService } from 'angular-notifier';
import { Subscription } from 'rxjs';
import { SpecialDegreeAssessment, specialityDegreeDetails } from 'src/app/DAF/Models/specialityDegreeDetails';
import { DafService } from 'src/app/DAF/services/dafService';

@Component({
  selector: 'app-specialist-degree',
  templateUrl: './specialist-degree.component.html',
  styleUrls: ['./specialist-degree.component.scss']
})
export class SpecialistDegreeComponent implements OnInit, OnDestroy {
  isPageReady: boolean;
  @Input() caseSummaryId = 0;
  @ViewChild(GridComponent, {static: false})
  private grid: GridComponent;
  public view: Array<SpecialDegreeAssessment> = [];
  public modifiedRecords: Array<SpecialDegreeAssessment> = [];
  public formGroup: FormGroup;
  private editedRowIndex: number;
  private docClickSubscription: Subscription = new Subscription();
  private isNew: boolean;
  public isEditEnabled: boolean;
  public isActivate=false;

  public isGridEditable: boolean;
  relationTypeList: { relationId: number; relationType: string; }[];
  specialityDegreeForm: FormGroup;
  specialDegreeDetail: specialityDegreeDetails;
  radioButtonOptionValues: Array<any> = [{ value: true, text: "Yes" }, { value: false, text: "No" }];
  isDisabled: boolean = false;
  public pageSize = 5;
  public skip = 0;
  public gridView: GridDataResult;
  private notifier: NotifierService;
  private isDeleteClicked: boolean = false;
  public gridState: DataStateChangeEvent = {
    skip: this.skip,
    take: this.pageSize
  };
  public deleteRow: any;
  degrees=["Associate","Bachelor","Master","Doctorate"];
  allowCustom=true;


  public educationLists: Array<string> = [
    "Bachelor",
    "Master",
    "Prof. Degree",
    "Phd/Doc"
  ];
  public educations: Array<string> = [
    "Bachelor",
    "Master",
    "Prof. Degree",
    "Phd/Doc"
  ];

  public experienceLists:Array<string>=[
    "No",
    "Edge",
    "Ed + Exp Prof Eval",
    "d. Eval."
  ];

  public experiences:Array<string>=[
    "No",
    "Edge",
    "Ed + Exp Prof Eval",
    "d. Eval."
  ];
  isSpouseSelected:boolean=false;
  showDialog:boolean = false;
  deleteIndex: number;
  public editDataItem: SpecialDegreeAssessment;

  
  spouseAndDependentMaster: Array<SpecialDegreeAssessment> = [];
  constructor(private renderer: Renderer2,
              private _dafService: DafService,
              private formBuilder: FormBuilder,
              notifier: NotifierService) { this.notifier = notifier; }


  
  


  ngOnInit() {

   

    this.relationTypeList = [
      {
        relationId: 0,
        relationType: '-Select-'
      },
      {
        relationId: 1,
        relationType: 'Dependents'
      },
      {
        relationId: 2,
        relationType: 'Spouse'
      }
    ];
    // this.specialDegreeDetail= new specialDegreeDetails();
    // this.specialDegreeDetail.isDependentExist = true;
    // this.specialDegreeDetail.relationDetail = this.view;
    // this.docClickSubscription.add(
    //   this.renderer.listen("document", "click", this.onDocumentClick.bind(this))
    // );
    //this.initializeForm();
    this.getSpecialityDegreeDetails();
    
  }


  openWizard(){
    this.isActivate=true;
  }

  public cancelHandlers(): void {
    this.isActivate=false;  }

  getSpecialityDegreeDetails(){
    this.isPageReady = false;
    this._dafService.getSpecialityDegreeDetails(this.caseSummaryId).toPromise().then((data: any) => {
      this.specialDegreeDetail = data;

      this.specialDegreeDetail.specialityDegree.forEach(item=>{
      if (item.enrollFrom != undefined) {
        item.enrollFrom = new Date(item.enrollFrom);        
    } 
     if (item.enrollTo != undefined) {
      item.enrollTo = new Date(item.enrollTo);        
  }
  if(item.awardDate != undefined) {
    item.awardDate = new Date(item.awardDate);        
}
});
      this.view = this.specialDegreeDetail.specialityDegree;
      console.log(data);
      console.log(this.view);
      console.log(this.specialDegreeDetail);
      this.initializeForm();
      this.loadItems();   
      this.isPageReady = true;
    },
      (error) => {
        console.log(error)
        this.isPageReady = true;
      }
    );
  }

  initializeForm() {
    let decodedNotes = this.decodeHTMLEntities(this.specialDegreeDetail.notes);
    try {
      this.specialityDegreeForm = this.formBuilder.group({  
        highestEduLevel: new FormControl({ value: this.specialDegreeDetail.highestEdulevel, disabled: true }),
        profExpReqForEval: new FormControl({ value: this.specialDegreeDetail.profExpreqforeval, disabled: true }),
        expRequiresEval: new FormControl({ value: this.specialDegreeDetail.expRequireseval, disabled: true }),
        certification: new FormControl({ value: this.specialDegreeDetail.certification, disabled: true }),

        degLevelEquivalency: new FormControl({ value: this.specialDegreeDetail.degLevelequivalency, disabled: true }),
        degFieldEquivalency: new FormControl({ value: this.specialDegreeDetail.degFieldequivalency, disabled: true }),

        notes: new FormControl({ value: decodedNotes, disabled: true }),
      });
      
      this.subscribeToFormControlValueChange();
    }
    catch (err) {
      this.isPageReady = true;
      console.log(err);
    }
  }


  public ngOnDestroy(): void {
    this.docClickSubscription.unsubscribe();
  }

  public filterChange(filter: any): void {
    if(filter.length>2){
      this.educationLists = this.educations.filter(x=>x.toLowerCase().includes(filter.toLowerCase()));
    }
  }

  public filterChanges(filter: any): void {
    if(filter.length>2){
      this.experienceLists = this.experiences.filter(x=>x.toLowerCase().includes(filter.toLowerCase()));
    }
  }

  public relation(id: number): any {
    return this.relationTypeList.find((x) => x.relationId === id);
  }


  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems();
  }
  
  private loadItems(): void {
    this.gridView = {
      data: this.view.slice(this.skip, this.skip + this.pageSize),
      total: this.view.length,
    };
  }

  public saveHandler(product: SpecialDegreeAssessment): void {
    if(this.isNew){
      this.view.splice(0, 0, product);
      this.modifiedRecords.push(product);
    }
    else{
      Object.assign(
        this.view.find(({ spedegassmtId }) => spedegassmtId === product.spedegassmtId),
        product
      );

      if (this.modifiedRecords.find(({ spedegassmtId }) => spedegassmtId === product.spedegassmtId)) {
        Object.assign(
          this.modifiedRecords.find(({ spedegassmtId }) => spedegassmtId === product.spedegassmtId),
          product
        );
      }
      else {
        this.modifiedRecords.push(product);
      }
    }
    
    this.editDataItem = undefined;
    this.isNew=false;
    this.loadItems();

}

  public addHandler(): void {
    this.editDataItem = new SpecialDegreeAssessment();
    this.editDataItem.universityName="";
    this.editDataItem.degree ="";
    this.editDataItem.degreeField  ="";
    this.editDataItem.enrollFrom =null;
    this.editDataItem.enrollTo =null;
    this.editDataItem.awardDate =null;
    this.editDataItem.degissuingCountry ="";
    this.editDataItem.degreesOnFile=false;
    this.editDataItem.spedegassmtId=0;

    this.isNew = true;
  }

  public cancelHandler(): void {
    this.closeEditor();
  }

  private closeEditor(): void {
    this.grid.closeRow(this.editedRowIndex);

    this.isNew = false;
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }


  public editHandler(args: AddEvent): void {
    this.editDataItem = args.dataItem;

    this.isNew = false;
  }

  SaveSpecialityDegreeDetails(){
    this.specialDegreeDetail = this.specialityDegreeForm.value;
    this.specialDegreeDetail.specialityDegree = this.modifiedRecords;
    this.specialDegreeDetail.specialityDegree.forEach(
      item=>{item.caseId="0";
    item.casesummaryId=Number(this.caseSummaryId);}
    )
    this.isPageReady = false;
    if (this.specialDegreeDetail.notes) {
      this.specialDegreeDetail.notes = this.encodeHTMLEntities(this.specialDegreeDetail.notes);
      }
    this._dafService.SaveSpecialityDegreeDetails(this.caseSummaryId, this.specialDegreeDetail).toPromise().then(() => {
      this._dafService.disableEnableAllFormControls(this.specialityDegreeForm, [], true);
      this.getSpecialityDegreeDetails();
      this.isEditEnabled = false;
      // this.isUnlocked = false;
      this.isPageReady = true;
      this.notifier.notify('success', 'Speciality Degree details have been saved successfully');
    }).catch((err) => {
      this.notifier.notify('error', 'Operation Failed');
    });
    
  }

  createFormGroup(dataItem) {
    return new FormGroup({
      relationId: new FormControl(dataItem.relationId, Validators.required),
      firstName: new FormControl(dataItem.firstName, Validators.required),
      middleName: new FormControl(dataItem.middleName),
      lastName: new FormControl(dataItem.lastName),
      isSpouseEmployedFollowingLatestEntry: new FormControl(dataItem.isSpouseEmployedFollowingLatestEntry),
      i539Required: new FormControl(dataItem.i539Required),
      i765Required: new FormControl(dataItem.i765Required),
      i131Required: new FormControl(dataItem.i131Required),
      spouseAndDepRelationId: new FormControl(dataItem.spouseAndDepRelationId),
      certification: new FormControl(dataItem.certification)
      
       
      
    });
  }

  matches(el, selector) {
    return (el.matches || el.msMatchesSelector).call(el, selector);
  }

  resetForm() {
    
    this.isEditEnabled = false;
    this._dafService.disableEnableAllFormControls(this.specialityDegreeForm, [], true);
    this.isGridEditable = false;
    this.specialityDegreeForm.controls["notes"].setValue('testval');
    this.getSpecialityDegreeDetails();

    
  }

  enableEdit(){
    this.isEditEnabled = true;
    this._dafService.disableEnableAllFormControls(this.specialityDegreeForm, ["certification"], false);
    if (this.specialDegreeDetail.expRequireseval) {
      this._dafService.disableEnableFormControl(this.specialityDegreeForm, ["certification"], true);    }
      this.isGridEditable = true;
    
  }

  private subscribeToFormControlValueChange() {

    // this.specialityDegreeForm.get("isDependentExist").valueChanges.subscribe(isTrue => {
    //   if (isTrue) {
    //     this.isGridEditable = true;
    //     if(this.view == null || this.view.length == 0){
    //       this.view = this.spouseAndDependentMaster;
    //     }
    //     this.loadItems();
    //     //this.view = this.spouseAndDependentMaster;
    //   } else {
    //     this.spouseAndDependentMaster = this.view;
    //     this.view = []
    //     this.isGridEditable = false;
    //     this.loadItems();
    //   }
    // });

    this.specialityDegreeForm.get("expRequiresEval").valueChanges.subscribe(val => {
      if (val) {
        
        this._dafService.disableEnableFormControl(this.specialityDegreeForm, ["certification"], false);

        this.specialityDegreeForm.updateValueAndValidity();
      } else {
        this._dafService.disableEnableFormControl(this.specialityDegreeForm, ["certification"], true);


        this.specialityDegreeForm.updateValueAndValidity();
        // this.FilingStratergyForm.patchValue({
        //   reasonToExpediteCase: null,
        // });
      }
    });
  }

  public setDependentIndexToDelete(args: any){
    this.isDeleteClicked = true;
    this.deleteRow = args;
    this.deleteIndex = args.rowIndex;
    this.showDialog = true;
  }

  public deleteDependent(){
    this.view.splice(this.deleteIndex, 1);
    this.deleteRow.dataItem.changeType = "DELETE";
    this.modifiedRecords.push(this.deleteRow.dataItem);
    this.loadItems();
    this.showDialog = false;
  }

  public cancelDelete(){
    this.showDialog = false;
  }

  public onDialogClose(){
    this.showDialog = false;
  }

  encodeHTMLEntities(text) {
    let textArea = document.createElement('textarea');
    let escapeQuotes = text;
    if (text) {
      escapeQuotes = text.replace(/['"()]/g, escape);
    }

    textArea.innerText = escapeQuotes;
    let encodedOutput = textArea.innerHTML;
    let arr = encodedOutput.split('<br>');
    encodedOutput = arr.join('\n');
    return encodedOutput;
  }

  decodeHTMLEntities(text) {
    let textArea = document.createElement('textarea');
    let unescapeQuotes = text;
    if (text) {
      unescapeQuotes = text.replace("%27", unescape);
      unescapeQuotes = unescapeQuotes.replace("%22", unescape);
      unescapeQuotes = unescapeQuotes.replaceAll("%28", unescape);
      unescapeQuotes = unescapeQuotes.replaceAll("%29", unescape);
    }
    textArea.innerHTML = unescapeQuotes;
    return textArea.value;
  }
}
