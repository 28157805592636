import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Lawyer } from '../model/lawyer';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { InsertLawyer } from '../model/insertLawyer';
@Injectable({
  providedIn: 'root'
})
export class LawyerService {

  constructor(private _http: HttpClient) { }
  refreshLawyerList(searchTerm?: string) {
    return this.searchLawyer(searchTerm);
  }
  searchLawyer(searchTerm: string) {
    if (!searchTerm) {
      return this.getLawyers();
     } // else {
    //   const urlString = `/client/search/${searchTerm}/`;
    //   return ();
    // }
  }
  getLawyers() {
    return this._http.get<any>(environment.ApiUrl + 'Lawyer/GetAllLawyers');
  }

  deleteLawyer(LawyerId: number) {
return this._http.post<any>(environment.ApiUrl + 'Lawyer/deleteLawyer?lawyerId=' + LawyerId , {});
  }
  editLawyer(lawyerId: number, email: string,name:string) {
    return this._http.post<any>(environment.ApiUrl + 'Lawyer/EditLawyer?lawyerId=' + lawyerId + '&email=' + email + '&name=' +name, {});
  }
  addLawyer(lawyer:InsertLawyer) {
    return this._http.post<any>(environment.ApiUrl + 'Lawyer/InsertLawyer',lawyer, {});
  }

}
