import { Component, Input, OnInit } from '@angular/core';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { QuestModel } from '../../../Model/QuestModel';

@Component({
  selector: 'app-view-questionare',
  templateUrl: './view-questionare.component.html',
  styleUrls: ['./view-questionare.component.scss']
})
export class ViewQuestionareComponent implements OnInit {
  public pageSize = 10;
  public skip = 0;
  public caseList: any;
  public gridViewData: any[];
  public gridState: DataStateChangeEvent = {

    skip: this.skip,
    take: this.pageSize
  };
  @Input() documentInfo: QuestModel;
  @Input() documentInfo1: QuestModel;
  @Input() packagesStatus: any;
  constructor() { }

  ngOnInit(): void {
  }

}
