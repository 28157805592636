import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { DafService } from 'src/app/DAF/services/dafService';
import { Router, ActivatedRoute } from '@angular/router';
import { DataStateChangeEvent, GridComponent, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { NotifierService } from 'angular-notifier';
import { process } from '@progress/kendo-data-query';
import { ExportParams, PagingParams } from '../../models/filterParams.model';
import { GroupResult, State } from '@progress/kendo-data-query';
import { DateTimePickerComponent } from '@progress/kendo-angular-dateinputs';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-case-details',
  templateUrl: './case-details.component.html',
  styleUrls: ['./case-details.component.scss']
})
export class CaseDetailsComponent implements OnInit {
  chartDatas: any[] = [];
  public caseList: any;
  public gridViewData: any[];
  public isClicked = false;
  private notifier: NotifierService;
  public pageSize = 10;
  public skip = 0;
  dateFilterModel: DateFiltersModel;
  fromreportDate: any;
  toreportDate: any
  public searchDetails: any;
  public addUserMail: string;
  public addUserName: string;
  public gridState: DataStateChangeEvent = {
    skip: this.skip,
    take: this.pageSize
  };
  public caseStatus = "";
  public isPageReady = true;
  public caseType = "";
  public filterselected = true;
  gridFilteredData: any;
  public CaseIdsData: any[];
  public memberemail: string;
  isChkEnabled: boolean;
  public mySelection = [];
  public selectAllState: SelectAllCheckboxState = 'checked';
  public isSuppEnabled: boolean = true;
  public fromStartDate: any;
  public toEndDate: any;
  CaseStatusList: Array<any> = [];
  public caseliststatus = "";


  constructor(public _dafService: DafService, private router: Router,
    private route: ActivatedRoute,
    notifier: NotifierService, @Inject(LOCALE_ID) private locale: string) {
    this.notifier = notifier;
    this.dateFilterModel = new DateFiltersModel(new Date(), new Date());
    this.CaseIdsData = [];


  }
  take: number;
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.caseStatus = params['caseStatus'];
      this.caseType = params['caseType'];
      
    });
    this.getCaseStatusList();
    
    this.filterselected = true;
    //this.dateFilterModel = new DateFiltersModel(new Date(), new Date());
    this._dafService.currentDates.subscribe(data => {
      this.fromreportDate = data.fromDate;
      this.toreportDate = data.toDate;
      this.dateFilterModel = new DateFiltersModel(new Date(this.fromreportDate), new Date(this.toreportDate));
      this.refreshCaseList(this.gridState);
    });
  }

  filterCaseList() {
    this.filterselected = true;
    const zipres = this.CaseStatusList.filter(s => s.dafStatusTypeId == this.caseliststatus);
    this.caseStatus = zipres[0].statusType;
    this._dafService.updateDate({ fromDate: this.dateFilterModel.startDate.toDateString(), toDate: this.dateFilterModel.endDate.toDateString() });
  }

  resetDashboard() {

    this.filterselected = true;

    //this.dateFilterModel.startDate = null;
    //this.dateFilterModel.endDate = null;
    //this.refreshCaseList(this.gridState);
    this.dateFilterModel = new DateFiltersModel(new Date(), new Date());
    this._dafService.resetDate.subscribe(data => {
      this._dafService.updateDate({ fromDate: this.dateFilterModel.startDate.toDateString(), toDate: this.dateFilterModel.endDate.toDateString() });
    });
  }

  viewData(dataItem: any) {
    this.router.navigate(['/dafdashboard/dafc-home'], { queryParams: { 'caseId': dataItem.caseSummaryId, 'caseStatus': dataItem.status, 'zoomId': dataItem.caseId } })

  }

  getG28Form(dataItem: any) {
    this.isPageReady = false;
    //Triggers template download from api
    this._dafService.downloadG28Form(dataItem.caseSummaryId).subscribe(response => {
      let blob: any = new Blob([response.body], { type: 'application/pdf' });
      const url= window.URL.createObjectURL(blob);
      window.open(url, '_blank');
      //window.location.href = response.url;
      //fileSaver.saveAs(blob, 'G28.pdf');
      this.isPageReady = true;
    }), (error) => {
    }
  }

  addCaseIds(dataItem: any) {

    var arraycontains = (this.CaseIdsData.indexOf(dataItem.caseId) > -1);
    if (arraycontains) {

      this.CaseIdsData.splice(this.CaseIdsData.indexOf(dataItem.caseId), 1);

    }
    else {
      this.CaseIdsData.push(dataItem.caseId);


    }
    if (this.CaseIdsData.length == 0) {
      this.isSuppEnabled = true;
    }
    else {
      this.isSuppEnabled = false;
    }

  }
  refreshCaseList(state: DataStateChangeEvent) {
    this.selectAllState = 'unchecked';
    this.isPageReady = false;
    var kets = state;
    this.gridState = kets;
    let skip = 0;
    if (state.skip !== 0) {
      skip = state.skip / 10;
    }

    var summaryParams = new ExportParams();
    summaryParams.PagingParams = new PagingParams();
    summaryParams.PagingParams.PageNumber = skip + 1;
    summaryParams.PagingParams.PageSize = state.take;
    if (state.filter != null) {
      summaryParams.FilterParams = state.filter.filters;
    }
    else {
      summaryParams.FilterParams = null;
    }
    if (this.filterselected) {

      summaryParams.FromDate = null;// this.convertUTCDateToLocalDate(this.dateFilterModel.startDate);
      summaryParams.ToDate = null;// this.convertUTCDateToLocalDate(this.dateFilterModel.endDate);//formatDate(this.dateFilterModel.endDate, 'YYYY-MM-ddTHH:mm:ss.sssZ', this.locale);//this.dateFilterModel.endDate.toLocaleDateString();

    }
    else {
      summaryParams.FromDate = null;
      summaryParams.ToDate = null;
    }
    
    this._dafService.getCasesByStatus(this.caseStatus, this.caseType, this.dateFilterModel.startDate.toDateString(), this.dateFilterModel.endDate.toDateString(), summaryParams).subscribe((data: any) => {
      this.gridFilteredData = data;

      // Binds the data to grid
      let dataParams = process(this.gridFilteredData.caseSummaryModel, { skip: 0, take: this.pageSize, filter: this.gridState.filter, sort: this.gridState.sort }).data;
      this.caseList = { data: dataParams, total: this.gridFilteredData.totalRowCount };
      this.isPageReady = true;
      this.selectAllState = 'unchecked';
      

    },
      (error) => {
        this.isPageReady = true;
        this.notifier.notify('error', 'Error occured while getting data from server');
      }
    );
  }

  ngAfterViewInit() {
    //Adds search placeholder to all filter textbox
    var result = document.getElementsByClassName("k-filtercell-wrapper");
    for (let i = 0; i < result.length; i++) {
      result[i].children[0].setAttribute("placeholder", "search");
    }
  }

  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  getCaseDetails() {
    this._dafService.GetCaseSummary().subscribe((data: any) => {
      this.chartDatas = data;
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
  }

  private rowCallback(row: any) {
    const isEven = row.index % 2 == 0;
    return {
      even: isEven,
      odd: !isEven
    };
  }

  public onDateChange(event: any) {
    const diffDays = (date, otherDate) => Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));
    if (this.dateFilterModel.endDate != null && this.dateFilterModel.startDate != null) {
      if (this.dateFilterModel.endDate < this.dateFilterModel.startDate) {
        this.notifier.notify('error', 'To date cannot be less than from date');
      }

      let totalDays = diffDays(this.dateFilterModel.endDate, this.dateFilterModel.startDate);
      console.log(totalDays);

      if (totalDays > 730) {
        this.notifier.notify('error', 'Date difference cannot be greater than 2 years');
      }
    }


  }

  goToDashboard() {
    try {
      this.router.navigate(['/daf/dashboard']);
    }
    catch (err) {
      console.log(err);
    }
  }

  searchUserDetails(email: any) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase().trim();
    if (filterValue) {
      this._dafService.searchSupportUserDetails(filterValue).subscribe((data: any) => {
        this.searchDetails = data.value;
      },
        (error) => {
          this.notifier.notify('error', 'Error occured while searching the user details');
        });
    }

  }

  selectAddUserDetails(email: any) {
    try {
      this.addUserMail = email.mail;
      this.addUserName = email.displayName;
      //this.autocomplete.reset();
    }
    catch (err) {
      console.log(err);
    }
  }

  UpdateSupportingmemberByCaseIds() {

    if (this.memberemail == undefined || this.memberemail == "") {
      this.notifier.notify('error', 'Suporting member cannot be blank');
      return;
    }

    this._dafService.UpdateSupportingmemberByCaseIds(this.CaseIdsData, this.memberemail).toPromise().then(() => {

      this.CaseIdsData = [];

      this.notifier.notify('success', 'CaseIds assigned successfully');
      this.refreshCaseList(this.gridState);
      this.isSuppEnabled = true;


    }).catch((err) => {
      this.notifier.notify('error', 'Operation Failed');
    });

  }



  public onSelectedKeysChange(): void {

    const len = this.mySelection.length;



    if (len === 0) {

      this.selectAllState = "unchecked";

    } else if (len > 0 && len < this.caseList.length) {

      this.selectAllState = "indeterminate";

    } else {

      this.selectAllState = "checked";

    }

  }



  public onSelectAllChange(checkedState: SelectAllCheckboxState): void {

    if (checkedState === "checked") {

      this.selectAllState = "checked";

    } else {

      this.selectAllState = "unchecked";

    }

  }


  public convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  }

  getCaseStatusList() {
    this._dafService.getDAFStatus().subscribe((data: any) => {
      this.CaseStatusList = data;
      console.log(this.CaseStatusList);
      const zipres = this.CaseStatusList.filter(s => s.statusType == this.caseStatus);
      this.caseliststatus = zipres[0].dafStatusTypeId;
    });
  }
}

export class DateFiltersModel {
  public startDate: Date;
  public endDate: Date;
  constructor(startDate: Date, endDate: Date) {
    this.startDate = startDate;
    this.endDate = endDate;
  }
}
