export class User {
   userId: number;
   name: string;
   email: string;
   status: boolean;
   createdBy: string;
   createdDate: Date;
   modifiedBy: string;
   modifiedDate: Date;
   userRoles:UserRole[];
}

export class UserRole{
   roleId:number;
   appName:string;
   roleName:string;
}
