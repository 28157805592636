import { Component, OnInit } from '@angular/core';
import { DataStateChangeEvent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { User } from '../usermanagement/model/user';
import { process } from '@progress/kendo-data-query';
import { NotifierService } from 'angular-notifier';
import { CourierPackageService } from './service/courier-package.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { CommonService } from 'src/app/service/common.service';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';


@Component({
  selector: 'app-courier-package',
  templateUrl: './courier-package.component.html',
  styleUrls: ['./courier-package.component.scss']
})
export class CourierPackageComponent implements OnInit {
  public selectedUser: User = new User();
  public deleteId = 0;
  public searchTerm = '';
  public showConfirmation = false;
  public userList: any[] = [];
  public isPageReady = true;
  public pageSize = 10;
  public skip = 0;
  public gridViewSearch: any;
  public gridView: any;
  public gridState: DataStateChangeEvent = {
    skip: this.skip,
    take: this.pageSize
  };
  private readonly notifier: NotifierService;
  constructor(private packageService: CourierPackageService,private router: Router,public datepipe: DatePipe) {
    this.allData = this.allData.bind(this);

   }

  ngOnInit() {
    // Loads data to grid
    this.dataStateChange(this.gridState);
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: this.userList
    };
  
    return result;
  }


  
  updateUserList() {
    this.dataStateChange(this.gridState);
  }

  NavigatetoSuccess(date:Date,$event: MouseEvent) {
    $event.preventDefault();
    this.router.navigate(['/courierpackageSuccess'], { queryParams: { 'incomingDate': date },skipLocationChange: true });
  }

  NavigatetoFailure(date:Date,$event: MouseEvent, status:any) {
    $event.preventDefault();
    this.router.navigate(['/courierpackageFailure'], { queryParams: { 'incomingDate': date, 'packageStatus': status },skipLocationChange: true });
  }

  NavigatetoPending(date:Date,$event: MouseEvent, status:any) {
    $event.preventDefault();
    this.router.navigate(['/courierpackagePendings'], { queryParams: { 'incomingDate': date, 'packageStatus': status },skipLocationChange: true  });
  }

  NavigateToAccessFailure(date: Date, $event: MouseEvent, status: any) {
    $event.preventDefault();
    this.router.navigate(['/courierpackageAccessFailure'], { queryParams: { 'incomingDate': date, 'packageStatus': status }, skipLocationChange: true });
  }

  public pageChange(state: DataStateChangeEvent): void {
    this.gridState = state;
    this.loadItems();
}

private loadItems(): void {
  this.gridView = process(this.userList, this.gridState);
}

  dataStateChange(state: DataStateChangeEvent) {
    this.isPageReady = false;
    this.gridState = state;
    // Gets data from api
    this.packageService.getDocumentDetails().subscribe((data: any) => {
      // Binds the data to grid
      if(data){
        var items="";
      data.forEach(item=>{
        item.incomingDate=this.datepipe.transform(item.incomingDate, 'dd-MMM-yyyy');
      });
      this.gridView = {
        data: data.slice(this.skip, this.skip + this.pageSize),
        total: data.length
    };
    this.gridViewSearch = data.map(x => Object.assign({}, x));
      this.userList = data;
      this.isPageReady = true;
  }
    },
    (error) => {
      this.notifier.notify('error', 'Error occured while getting data from server');
      this.isPageReady = true;
    }
    );
  }



    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value.toLowerCase();

      let filterData = this.gridViewSearch.filter(r => r.incomingDate.toLowerCase().includes(filterValue)
      || r.total.toString().includes(filterValue)
      || r.scanned.toString().includes(filterValue)|| r.success.toString().includes(filterValue)
      || r.failure.toString().includes(filterValue));
      this.gridView.data = filterData;
      this.gridView.total=filterData.length;
      // console.log(this.gridView);
    }
}

