import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AddLCAService } from '../add-lca/service/add-lca.service';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-work-site-acknowledge',
  templateUrl: './work-site-acknowledge.component.html',
  styleUrls: ['./work-site-acknowledge.component.scss']
})
export class WorkSiteAcknowledgeComponent implements OnInit {
  @ViewChild('openLCAInfoModalButton', { static: false }) openLcaModalButton: ElementRef;
  public pageSize = 5;
  public skip = 0;
  public notallowedWorkSite=[];
  rows=[];
  public workSiteData=[];
  public allowedWorkSite=[];
  public showData=false;
  public gridView:any;
  public lcaData=[];
  public partialWorkSite=[];
  public gridState: DataStateChangeEvent = {
    skip: this.skip,
    take: this.pageSize
  };
  constructor(public addLCAService: AddLCAService) { }

  ngOnInit() {
    var requestId="3SS135C86";
    this.GetWorkSiteData(requestId);
  }


  viewLCAInfo(lcaData:any , edit: boolean) {
      this.lcaData=lcaData;
   
      this.openLcaModalButton.nativeElement.click();
   
  }

  
  GetWorkSiteData(requestId) {

    this.addLCAService.GetWorkSiteData(requestId).subscribe((data: any) => {
      this.workSiteData=data;
      this.workSiteData.forEach(item=>{
        if(item.recommendedOutput=="Y"){
          item.recommendedOutput="Allowed";
        }
        else if(item.recommendedOutput=="N"){
          item.recommendedOutput="Not Allowed";
        }
        else if(item.recommendedOutput=="P"){
          item.recommendedOutput="Partially Allowed";
        }
      });
      this.gridView = process(this.workSiteData, this.gridState);
      this.showData=true;
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
  }

  // GetLCAHistory(requestId) {
    
  //   this.addLCAService.getLCAHistory(requestId).subscribe((data: any) => {
  //     this.rows = data;
  //     var datePipe = new DatePipe('en-US');
  //     this.rows.forEach(item=>{
  //       item.lcA_Validity_StartDate=datePipe.transform(item.lcA_Validity_StartDate, 'yyyy-MM-dd');
  //       item.lcA_ExpiryDate=datePipe.transform(item.lcA_ExpiryDate, 'yyyy-MM-dd');

  //     });
  //     this.showData=true;
  //   },
  //     (error) => {
  //       //this.notifier.notify('error', 'Error occured while updating the user details');
  //     }
  //   );
  // }

}
