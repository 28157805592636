import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DataStateChangeEvent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { NotifierService } from 'angular-notifier';
import { CourierPackageService } from '../service/courier-package.service';
import { Router, ActivatedRoute } from '@angular/router';
import { process } from '@progress/kendo-data-query';
import { DatePipe } from '@angular/common';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { DocumentExtractionModel } from '../Model/DocumentExraction.Model';

@Component({
  selector: 'app-courierpackage-failure',
  templateUrl: './courierpackage-failure.component.html',
  styleUrls: ['./courierpackage-failure.component.scss']
})
export class CourierpackageFailureComponent implements OnInit {
  @ViewChild('openDocumentInfoModalButton', { static: false }) openEditModalButton: ElementRef;
  public deleteId = 0;
  public searchTerm = '';
  public packagesStatus = '';
  public showConfirmation = false;
  public userList: any[] = [];
  public isPageReady = true;
  public pageSize = 10;
  public skip = 0;
  public gridState: DataStateChangeEvent = {
    skip: this.skip,
    take: this.pageSize
  };
  public gridViewSearch: any;
  public gridView: any;
  public incomingDate:Date=new Date();
  public inputDate;
  private readonly notifier: NotifierService;
  public selecteddocumentId = 0;
  public documentInfo = new DocumentExtractionModel();
  public showModel = false;

  constructor(private packageService: CourierPackageService,private router: Router,private route:ActivatedRoute,public datepipe: DatePipe) {
    this.allData = this.allData.bind(this);

   }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      var updatedDate = params['incomingDate'];
      this.packagesStatus = params['packageStatus'];
      if(updatedDate){
        this.incomingDate = new Date(updatedDate);
        this.inputDate = updatedDate;
      }
  })
    this.dataStateChange(this.gridState,this.inputDate);
  }

  public pageChange(state: DataStateChangeEvent): void {
    this.gridState = state;
    this.loadItems();
}

private loadItems(): void {
  this.gridView = process(this.userList, this.gridState);
}

public allData(): ExcelExportData {
  const result: ExcelExportData = {
    data: this.userList
  };

  return result;
}



  dataStateChange(state: DataStateChangeEvent,incomingDate:any) {
    this.isPageReady = false;
    this.gridState = state;

    // Gets data from api
    if (this.packagesStatus == 'Failure') {
      this.packageService.getDocumentFailureDetails(incomingDate).subscribe((data: any) => {
        // Binds the data to grid
        if (data) {
          var items = "";
          data.forEach(item => {
            item.incomingDate = this.datepipe.transform(item.incomingDate, 'dd-MMM-yyyy');
          });
          this.gridView = {
            data: data.slice(this.skip, this.skip + this.pageSize),
            total: data.length
          };
          this.gridViewSearch = data.map(x => Object.assign({}, x));
          this.userList = data;
          this.isPageReady = true;
        }
      },
        (error) => {
          this.notifier.notify('error', 'Error occured while getting data from server');
          this.isPageReady = true;
        }
      );
    }
    else if (this.packagesStatus == 'Pending'){
      this.packageService.getDocumentPendingDetails(incomingDate).subscribe((data: any) => {
        // Binds the data to grid
        if (data) {
          var items = "";
          data.forEach(item => {
            item.incomingDate = this.datepipe.transform(item.incomingDate, 'dd-MMM-yyyy');
          });
          this.gridView = {
            data: data.slice(this.skip, this.skip + this.pageSize),
            total: data.length
          };
          this.gridViewSearch = data.map(x => Object.assign({}, x));
          this.userList = data;
          this.isPageReady = true;
        }
      },
        (error) => {
          this.notifier.notify('error', 'Error occured while getting data from server');
          this.isPageReady = true;
        }
      );
    }
    else if (this.packagesStatus == 'AccessFailure') {
      this.packageService.getDocumentAccessFailureDetails(incomingDate).subscribe((data: any) => {
        // Binds the data to grid
        if (data) {
          var items = "";
          data.forEach(item => {
            item.incomingDate = this.datepipe.transform(item.incomingDate, 'dd-MMM-yyyy');
          });
          this.gridView = {
            data: data.slice(this.skip, this.skip + this.pageSize),
            total: data.length
          };
          this.gridViewSearch = data.map(x => Object.assign({}, x));
          this.userList = data;
          this.isPageReady = true;
        }
      },
        (error) => {
          this.notifier.notify('error', 'Error occured while getting data from server');
          this.isPageReady = true;
        }
      );
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    let filterData = this.gridViewSearch.filter(r => r.incomingDate.toLowerCase().includes(filterValue)
    || r.office.toLowerCase().includes(filterValue)
    || r.fileName.toLowerCase().includes(filterValue)|| r.scanned.toLowerCase().includes(filterValue)
    || r.aiExtracted.toLowerCase().includes(filterValue)|| r.uploadStatus.toLowerCase().includes(filterValue)
    || r.remarks.toLowerCase().includes(filterValue));
    this.gridView.data = filterData;
    this.gridView.total=filterData.length;
    // console.log(this.gridView);
  }

  viewDocumentInfo(documentId: number, edit: boolean) {
    this.selecteddocumentId = documentId;
    this.packageService.getdocumentInfoById(documentId).subscribe((result: DocumentExtractionModel) => {
      this.documentInfo = result;
      this.documentInfo.pickupDateString = this.datepipe.transform(this.documentInfo.pickupDate, 'dd-MMM-yyyy');
      this.showModel = true;
      this.openEditModalButton.nativeElement.click();
    }, error => { console.error(error); console.log('error'); });
  }

  saveHandler(event) {
    this.dataStateChange(this.gridState, this.inputDate);
  }
}

