import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DafService } from 'src/app/DAF/services/dafService';
import { NotifierService } from 'angular-notifier';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { caseSummaryDetails, Summaryconstants } from 'src/app/DAF/Models/CaseSummaryDetails';
import { AddLCAService } from 'src/app/immigrationcompilation/add-lca/service/add-lca.service';

@Component({
  selector: 'app-case-summary-details',
  templateUrl: './case-summary-details.component.html',
  styleUrls: ['./case-summary-details.component.scss']
})
export class CaseSummaryDetailsComponent implements OnInit, OnDestroy {
  //public textboxValue = '';
  radioButtonOptionValues: Array<any> = [{ value: true, text: "Yes" }, { value: false, text: "No" }];
  private notifier: NotifierService;
  public searchDetails: any;
  public addUserMail: string;
  public addUserName: string;
  public caseData: caseSummaryDetails = new caseSummaryDetails();
  public isUnlocked: boolean = false;
  public isEditEnabled: boolean = false;
  public isPageReady = true;
  caseSummaryForm: FormGroup = null;

  statesDataSource = [];
  statesList=[];
  public zipcodeList = [];
  public addressTypesList = [];
  public state;
  public zipcodeSource=[];
  public selectedType=[];
  public cityArray=[[]];
  public countyArray = [[]];
  public isReady=false;
  cityDataArray: any[]=[];
  countyDataArray: any[]=[];
  addressTypes: any[] = [];
  public countyList = [];
  public geographyList = [];
  public countySource = [];
  public geographySource = [];
  public cityList = [];
  public citySource = [];
  public caseManagersList = [];
  public caseManagersSourceList = [];
  public caseAttorneysList = [];
  dateOfBirth: any;
  public caseAttorneysSourceList = [];

  @Input() caseId = 0;
  @Output() page = new EventEmitter<number>();
  
  @Output() collapseCSPanel = new EventEmitter<any>();
  @ViewChild('autocomplete', { static: false }) autocomplete: any;

  optionalFormControls = ["middleName","signatoryName","signatoryTitle","signatoryTelephone","department"];
  formErrors = {
    'firstName': '',
    'lastName': '',
    'dateOfBirth': '',
    'caseManager': '',
    'caseAttorney': '',
    'companyName': '',
    'supportTeamMember': '',
    'signatoryName': '',
    'signatoryTitle': '',
    'signatoryTelephone': '',
    'signatoryEmail': '',
    'signatoryStreetName': '',
    'signatoryStreetNo': '',
    'signatoryAptSteFlrDoorNo': '',
    'signatoryCity': '',
    'signatoryState': '',
    'signatoryZipcode': '',
    'department': '',
    'employeeId': '',
    'employeeEmail': '',
    'expediteCase': '',  
    'reasonToExpediteCase': '',
    'placeOnHold':'',
    'reasonToDelayCase':'',
    'notes':''
  };
  
  validationMessages = {
    'firstName': {
      'required': 'First Name is required.',
    },
    'lastName': { 
      'required': 'Last Name is required.', 
    },
    'dateOfBirth': { 
      'required': 'DOB is required.', 
    },
    'caseManager': { 
      'required': 'Case Manager is required.', 
    },
    'caseAttorney': { 
      'required': 'Case Attorney is required.', 
    },
    'companyName': { 
      'required': 'Company Name is required.', 
    },
    'supportTeamMember': { 
      'required': 'Support team member required.', 
    },
    'signatoryName': { 
      'required': 'Signatory Name is required.', 
    },
    'signatoryTitle': { 
      'required': 'Signotary Title is required.', 
    },
    'signatoryTelephone': { 
      'required': 'Signotary Telephone is required.', 
    },
    'signatoryEmail': { 
      'required': 'Signatory Email is required.', 
      'email': 'Please enter a valid email address'
    },
    'signatoryStreetName': { 
      'required': 'Street Name is required.', 
    },
    'signatoryStreetNo': { 
      'required': 'Street number is required.', 
    },
    'signatoryAptSteFlrDoorNo': { 
      'required': 'An apartment/floor/door number is required', 
    },
    'signatoryCity': {
       'required': 'City is required.',
       },
    'signatoryState': { 
      'required': 'State is required.', 
    },
    'signatoryZipcode': { 
      'required': 'Zipcode is required.',
     },
    'department': { 
      'required': 'Department is required.', 
    },
    'employeeId': { 
      'required': 'EmployeeId is required.', 
    },
    'employeeEmail': { 
      'required': 'Employee Emial is required.',
      'email': 'Please enter a valid email address'
     },
    'expediteCase': { 
      'required': 'First Name is required.', 
    },
    'reasonToExpediteCase': { 
      'required': 'Reason is required.',
   },
    'placeOnHold': { 
      'required': 'Reason is required.', 
    },
    'reasonToDelayCase': { 
      'required': 'Reason is required.', 
    },
    'notes': {
      'required': 'Notes are required.',
    },
    'cityId': {
      'required': 'City is required.',
    },
    'stateId': {
      'required': 'State is required.',
    },
    'zipCodeId': {
      'required': 'Zipcode is required.',
    }
  };

  constructor(private _dafService: DafService, notifierService: NotifierService, public _addLCAService: AddLCAService,private formBuilder: FormBuilder) {
    this.notifier = notifierService; 
  }

  ngOnInit() {

    this.getMasterData();
    // this.getStates();
    // this.fetchcaseData(this.caseId);
  }

  async getMasterData (){
    this.getStates();

    this.fetchcaseData(this.caseId);

    
    //var c = await this.GetCountyInfo(staff.zipCode);
  }

  async fetchcaseData(caseId: number) {
   
    this.isPageReady = false;
    this._dafService.getCasesById(caseId).toPromise().then((data: any) => {
      this.caseData = new caseSummaryDetails(data);
     
      this.initializeForm();
    },
      (error) => {
      }
    );
  }

  async initializeForm() {
    await this.GetZipcodes(this.caseData.stateId);
    await this.GetCounty(this.caseData.stateId);
    await this.GetGeography(this.caseData.stateId);
    await this.GetCity(this.caseData.zipCodeId);
    await this.GetCaseManagers();
    await this.GetCaseAttorneys();
    let decodedNotes = this.decodeHTMLEntities(this.caseData.notes);
    try {
      this.caseSummaryForm = this.formBuilder.group({
        caseId: new FormControl(this.caseId),
        caseSummaryId: new FormControl(this.caseData.caseSummaryId),
        firstName: new FormControl({ value: this.caseData.firstName, disabled: true }, [Validators.required, Validators.nullValidator]),
        middleName: new FormControl({ value: this.caseData.middleName, disabled: true}),
        lastName: new FormControl({ value: this.caseData.lastName, disabled: true }, [Validators.required,Validators.nullValidator]),
        dateOfBirth: new FormControl({ value: this.caseData.dateOfBirth ? new Date(this.caseData.dateOfBirth) : null, disabled: true }, Validators.required),
        caseManager: new FormControl({ value: this.caseData.caseManager, disabled: true },[ Validators.required,Validators.nullValidator]),
        caseAttorney: new FormControl({ value: this.caseData.caseAttorney, disabled: true }, [Validators.required,Validators.nullValidator]),
        companyName: new FormControl({ value: this.caseData.companyName, disabled: true }, [Validators.required,Validators.nullValidator]),
        supportTeamMember: new FormControl({ value: this.caseData.supportTeamMember, disabled: false }),
        signatoryName: new FormControl({ value: this.caseData.signatoryName, disabled: true }),
        signatoryTitle: new FormControl({ value: this.caseData.signatoryTitle, disabled: true }),
        signatoryTelephone: new FormControl({ value: this.caseData.signatoryTelephone, disabled: true }),
        signatoryEmail: new FormControl({ value: this.caseData.signatoryEmail, disabled: true }, [Validators.required,Validators.nullValidator]),
        //corporationAddress: new FormControl({ value: this.caseData.corporationAddress, disabled: true }, Validators.required),
        //signatoryPrimaryOfficeState: new FormControl({ value: this.caseData.signatoryPrimaryOfficeState, disabled: true }, Validators.required),
        department: new FormControl({ value: this.caseData.department, disabled: true }),
        employeeId: new FormControl({ value: this.caseData.employeeId, disabled: true }, [Validators.required,Validators.nullValidator]),
        employeeEmail: new FormControl({ value: this.caseData.employeeEmail, disabled: true }, [Validators.required, Validators.email]),
        expediteCase: new FormControl({ value: this.caseData.expediteCase, disabled: true }, [Validators.required,Validators.nullValidator]),
        reasonToExpediteCase: new FormControl({ value: this.caseData.reasonToExpediteCase, disabled: true }),
        placeOnHold: new FormControl({ value: this.caseData.placeOnHold, disabled: true }, [Validators.required,Validators.nullValidator]),
        reasonToDelayCase: new FormControl({ value: this.caseData.reasonToDelayCase, disabled: true }),
        notes: new FormControl({ value: decodedNotes, disabled: true }),
        signatoryStreetNo: new FormControl({value: this.caseData.signatoryStreetNo, disabled: true},[Validators.required,Validators.nullValidator]),
        signatoryStreetName: new FormControl({value: this.caseData.signatoryStreetName, disabled: true},[Validators.required,Validators.nullValidator]),
        signatoryAptSteFlrDoorNo: new FormControl({ value: this.caseData.signatoryAptSteFlrDoorNo, disabled: true }),
        signatoryCity: new FormControl({ value: null, disabled: true }),
        countyId: new FormControl({ value: this.caseData.countyId, disabled: true }),
        signatoryState: new FormControl({ value: null, disabled: true }),
        signatoryZipcode: new FormControl({ value: null, disabled: true }),
        geographyId: new FormControl({ value: this.caseData.geographyId, disabled: true }),
        stateId: new FormControl({ value: this.caseData.stateId, disabled: true }, [Validators.required, Validators.nullValidator]),
        zipCodeId: new FormControl({ value: this.caseData.zipCodeId, disabled: true }, [Validators.required, Validators.nullValidator]),
        cityId: new FormControl({ value: this.caseData.cityId, disabled: true }, [Validators.required, Validators.nullValidator]),
        caseManagerId: new FormControl({ value: this.caseData.caseManagerId, disabled: true }, [Validators.required, Validators.nullValidator]),
        caseAttorneyId: new FormControl({ value: this.caseData.caseAttorneyId, disabled: true }, [Validators.required, Validators.nullValidator])
      });

      this.isPageReady = true;
      this.subscribeToFormControlValueChanges();
      this.caseSummaryForm.valueChanges.subscribe((val=>{
        this.logOnChangeValidationErrors();
      }));
    }
    catch (err) {
      console.log(err);
    }
  }

  subscribeToFormControlValueChanges() {
    if(this.caseData.expediteCase)
      this.f['reasonToExpediteCase'].setValidators([Validators.required,Validators.nullValidator]);
    if(this.caseData.placeOnHold)
      this.f['reasonToDelayCase'].setValidators([Validators.required,Validators.nullValidator]);
    this.caseSummaryForm.updateValueAndValidity();
    this.caseSummaryForm.get("expediteCase").valueChanges.subscribe(val => {
      if (val) {
        this._dafService.disableEnableFormControl(this.caseSummaryForm, ["reasonToExpediteCase"], false);
        this.caseData.expediteCase = true;
        this.f['reasonToExpediteCase'].setValidators([Validators.required,Validators.nullValidator]);
        this.caseSummaryForm.updateValueAndValidity();
      } else {
        this._dafService.disableEnableFormControl(this.caseSummaryForm, ["reasonToExpediteCase"], true);
        this.caseData.expediteCase = false;
        this.f["reasonToExpediteCase"].clearValidators();
        this.f['reasonToExpediteCase'].setValue(null);
        this.caseSummaryForm.updateValueAndValidity();
        // this.caseSummaryForm.patchValue({
        //   reasonToExpediteCase: null,
        // });
      }
    });
    this.caseSummaryForm.get("placeOnHold").valueChanges.subscribe(val => {
      if (val) {
        this._dafService.disableEnableFormControl(this.caseSummaryForm, ["reasonToDelayCase"], false);
        this.caseData.placeOnHold = true;
        this.f['reasonToDelayCase'].setValidators([Validators.required,Validators.nullValidator]);
        this.caseSummaryForm.updateValueAndValidity();
      } else {
        this._dafService.disableEnableFormControl(this.caseSummaryForm, ["reasonToDelayCase"], true);
        this.caseData.placeOnHold = false;
        this.f['reasonToDelayCase'].setValue(null);
        this.f["reasonToDelayCase"].clearValidators();
        this.caseSummaryForm.updateValueAndValidity();
        // this.caseSummaryForm.patchValue({
        //   reasonToDelayCase: null,
        // });
      }
    });
  }

  enableEdit() {
    try {
      this.isEditEnabled = true;
      this._dafService.disableEnableAllFormControls(this.caseSummaryForm, ["reasonToExpediteCase", "reasonToDelayCase"], false);

      if (!this.caseSummaryForm.get("stateId").value)
        this._dafService.disableEnableAllFormControls(this.caseSummaryForm, ["zipCodeId", "cityId", "countyId"], false);

      if (this.caseData.expediteCase) {
        this._dafService.disableEnableFormControl(this.caseSummaryForm, ["reasonToExpediteCase"], false);
      }
      if (this.caseData.placeOnHold) {
        this._dafService.disableEnableFormControl(this.caseSummaryForm, ["reasonToDelayCase"], false);
      }
    }
    catch (err) {
      console.log(err);
    }
  }

  unlockFields() {
    try {
      this.isUnlocked = true;
      this._dafService.disableEnableFormControl(this.caseSummaryForm,["employeeEmail"], false);
    }
    catch (err) {
      console.log(err);
    }
  }

  lockFields() {
    try {
      this.isUnlocked = false;
      this._dafService.disableEnableFormControl(this.caseSummaryForm,["employeeEmail"], true);
    }
    catch (err) {
      console.log(err);
    }
  }

  saveCaseSummaryData() {
    this.isPageReady = false;
    this.logValidationErrors();
    this.isPageReady = true;

    this.readFormValues(this.caseSummaryForm);
    if (this.caseSummaryForm.valid) {
      if (this.caseData.dateOfBirth && this.caseData.dateOfBirth !== null) {
        //var userTimezoneOffset = new Date(this.caseData.dateOfBirth).getTimezoneOffset() * 60000;
        //this.caseData.dateOfBirth = new Date(this.caseData.dateOfBirth.getTime() - userTimezoneOffset);
        this.dateOfBirth = this.caseData.dateOfBirth.toDateString();
      }
      else {
        this.dateOfBirth = null;
      }
      var caseSummaryData = this.caseSummaryForm.value;
      if (caseSummaryData.notes) {
        caseSummaryData.notes = this.encodeHTMLEntities(this.caseSummaryForm.get("notes").value);
      }
      this._dafService.saveCaseSummary(caseSummaryData, this.dateOfBirth).subscribe((data: any) => {
        this._dafService.disableEnableAllFormControls(this.caseSummaryForm, [], true);
        this.isEditEnabled = false;
        this.isUnlocked = false;
        this.page.emit(1);
        this.isPageReady = true;
        this.notifier.notify('success', 'Case summary details have been saved successfully');
      },
        (error) => {
          this.isPageReady = true;
          this.notifier.notify('error', 'Error occured while saving case summary details');
        });
    }
    else {
      this.notifier.notify('error', 'All fields are required and should have data');
      this.isPageReady = true;
    }

    if(this.caseSummaryForm.valid){
      
    }
    else{
      this.logValidationErrors();
    }

  }

  private readFormValues(group: FormGroup) {
    Object.keys(this.caseSummaryForm.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.readFormValues(abstractControl);
      }
      else {
        this.caseData[key] = abstractControl.value;
      }
    });
  }

  resetForm() {
    try {
      this.fetchcaseData(this.caseId);
      this.isEditEnabled = false;
      this.isUnlocked = false;
      this._dafService.disableEnableAllFormControls(this.caseSummaryForm, ["supportTeamMember"],true);
    }
    catch (err) {
      console.log(err);
    }
  }

  get f() {
    return this.caseSummaryForm.controls;
  }

  logValidationErrors(group: FormGroup = this.caseSummaryForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid
            // && (abstractControl.touched || abstractControl.dirty)
            ) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

    logOnChangeValidationErrors(group: FormGroup = this.caseSummaryForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid
             && (abstractControl.touched || abstractControl.dirty)
            ) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  setCSPanelState() {
    this.collapseCSPanel.emit(0);
  }

  searchUserDetails(email: any) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase().trim();
    if (filterValue) {
      this._dafService.searchSupportUserDetails(filterValue).subscribe((data: any) => {
        this.searchDetails = data.value;
      },
        (error) => {
          this.notifier.notify('error', 'Error occured while searching the user details');
        });
    }

  }

  selectAddUserDetails(email: any) {
    try {
      this.addUserMail = email.mail;
      this.addUserName = email.displayName;
      this.autocomplete.reset();
    }
    catch (err) {
      console.log(err);
    }
  }

  ngOnDestroy(): void {
    this.caseSummaryForm.get("expediteCase").valueChanges.subscribe().unsubscribe();
    this.caseSummaryForm.get("placeOnHold").valueChanges.subscribe().unsubscribe();
  }

  getStates(){
    this._dafService.getStates().subscribe((data: any) => {
      this.statesDataSource = data;
      this.statesList = data;
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
  }

  onStateChange(event) {
    this.caseSummaryForm.controls["zipCodeId"].setValue(null);
    this.caseSummaryForm.controls["cityId"].setValue(null);
    this.caseSummaryForm.controls["cityId"].disable();
    this.caseSummaryForm.controls["geographyId"].setValue(null);
    this.caseSummaryForm.controls["countyId"].setValue(null);
    this.GetZipcodes(event);
    this.GetCounty(event);
    this.GetGeography(event);
  }

  GetZipcodes(event): Promise<any> {
    this.zipcodeList = [];
    let res = this._dafService.getZipcodeByState(event).toPromise().then((data: any) => {
      this.zipcodeList = data;
      this.zipcodeSource = data;
      //if (data.length > 0) {
      //  data.forEach(element => {
      //    this.zipcodeList[index].push(element.zipcode.toString());
      //  });
      //}
      return Promise.resolve(data);
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
    //this._dafService.disableEnableFormControl(this.caseSummaryForm,["signatoryZipcode"],false);
    return res;
  }

  onZipCodeChange(event) {
    this.caseSummaryForm.controls["cityId"].setValue(null);
    this.caseSummaryForm.controls["cityId"].enable();
    this.GetCity(event);
  }

  GetCountyInfo(event, index: any): Promise<any>  {
    this.cityArray[index]=[];
    this.countyArray[index]=[];
    let res = this._addLCAService.GetCountyInfo(event).toPromise().then((data: any) => {
      if (data.length > 0) {
        this.caseSummaryForm.controls["cityId"].setValue(data[0].cityName);
        this.caseSummaryForm.controls["countyId"].setValue(data[0].countyName);
        this.caseSummaryForm.updateValueAndValidity();
      }
      return Promise.resolve(data);
    },
      (error) => {
      }
    );
    return res;
  }

  public caseManagerFilterChange(filter: any): void {
    this.caseManagersList = this.caseManagersSourceList.filter(x => x.caseManager.toLowerCase().includes(filter.toLowerCase()));
  }

  public attorneyFilterChange(filter: any): void {
    this.caseAttorneysList = this.caseAttorneysSourceList.filter(x => x.caseAttorney.toLowerCase().includes(filter.toLowerCase()));
  }

  public stateFilterChange(filter: any): void {
      this.statesList = this.statesDataSource.filter(x=>x.stateName.toLowerCase().includes(filter.toLowerCase()));
  }

  public zipFilterChange(filter: any): void {
    this.zipcodeList = this.zipcodeSource.filter(x => x.zipcode.includes(filter));
  }

  public cityFilterChange(filter: any): void {
    this.cityList = this.citySource.filter(x => x.city.toLowerCase().includes(filter));
  }

  public msaFilterChange(filter: any): void {
    this.geographyList = this.geographySource.filter(x => x.value.toLowerCase().includes(filter));
  }

  public countyFilterChange(filter: any): void {
    this.countyList = this.countySource.filter(x => x.value.toLowerCase().includes(filter));
  }

  GetCounty(event): Promise<any> {
    this.countyList = [];
    let res = this._dafService.getCountyByState(event).toPromise().then((data: any) => {
      this.countyList = data;
      this.countySource = data;

      return Promise.resolve(data);
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
    //this._dafService.disableEnableFormControl(this.caseSummaryForm,["signatoryZipcode"],false);
    return res;
  }

  GetCity(event): Promise<any> {
    this.cityList = [];
    let res = this._dafService.getCityByZipcode(event).toPromise().then((data: any) => {
      this.cityList = data;
      this.citySource = data;

      return Promise.resolve(data);
    },
      (error) => {
        this.notifier.notify('error', 'Error occured while fetching the city list');
      }
    );
    //this._dafService.disableEnableFormControl(this.caseSummaryForm,["signatoryZipcode"],false);
    return res;
  }

  GetGeography(event): Promise<any> {
    this.geographyList = [];
    let res = this._dafService.getGeographyByState(event).toPromise().then((data: any) => {
      this.geographyList = data;
      this.geographySource = data;

      return Promise.resolve(data);
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
    //this._dafService.disableEnableFormControl(this.caseSummaryForm,["signatoryZipcode"],false);
    return res;
  }

  OnCountyChange(event) {
    this.geographyList = [];
    //this.caseSummaryForm.get("signatoryMSA").setValue(null);
    let selectedState = this.caseSummaryForm.get("stateId").value;
    let res = this._dafService.GetGeographyByCounty(selectedState, event).toPromise().then((data: any) => {
      this.geographyList = data;
      this.geographySource = data;

      return Promise.resolve(data);
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
    //this._dafService.disableEnableFormControl(this.caseSummaryForm,["signatoryZipcode"],false);
    return res;
  }

  OnGeographyChange(event) {
    this.countyList = [];
    //this.caseSummaryForm.get("signatoryCounty").setValue(null);
    let selectedState = this.caseSummaryForm.get("stateId").value;
    let res = this._dafService.GetCountyByGeography(selectedState, event).toPromise().then((data: any) => {
      this.countyList = data;
      this.countySource = data;

      return Promise.resolve(data);
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
    //this._dafService.disableEnableFormControl(this.caseSummaryForm,["signatoryZipcode"],false);
    return res;
  }

  GetCaseManagers() {
    this.caseManagersList = [];
    let res = this._dafService.GetCaseManagers().toPromise().then((data: any) => {
      this.caseManagersList = data;
      this.caseManagersSourceList = data;

      return Promise.resolve(data);
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
    //this._dafService.disableEnableFormControl(this.caseSummaryForm,["signatoryZipcode"],false);
    return res;
  }

  GetCaseAttorneys() {
    this.caseAttorneysList = [];
    let res = this._dafService.GetAttorneys().toPromise().then((data: any) => {
      this.caseAttorneysList = data;
      this.caseAttorneysSourceList = data;
      return Promise.resolve(data);
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
    //this._dafService.disableEnableFormControl(this.caseSummaryForm,["signatoryZipcode"],false);
    return res;
  }

  encodeHTMLEntities(text) {
    let textArea = document.createElement('textarea');
    let escapeQuotes = text;
    if (text) {
      escapeQuotes = text.replace(/['"()]/g, escape);
    }

    textArea.innerText = escapeQuotes;
    let encodedOutput = textArea.innerHTML;
    let arr = encodedOutput.split('<br>');
    encodedOutput = arr.join('\n');
    return encodedOutput;
  }

  decodeHTMLEntities(text) {
    let textArea = document.createElement('textarea');
    let unescapeQuotes = text;
    if (text) {
      unescapeQuotes = text.replace("%27", unescape);
      unescapeQuotes = unescapeQuotes.replace("%22", unescape);
      unescapeQuotes = unescapeQuotes.replaceAll("%28", unescape);
      unescapeQuotes = unescapeQuotes.replaceAll("%29", unescape);
    }
    textArea.innerHTML = unescapeQuotes;
    return textArea.value;
  }
}

