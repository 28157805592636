import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';

@Component({
  selector: 'app-lcachklist',
  templateUrl: './lcachklist.component.html',
  styleUrls: ['./lcachklist.component.scss']
})
export class LcachklistComponent implements OnInit {
  @Input() lcaData: any;
  @ViewChild('closeButton',  {static: false}) closeButton: ElementRef;
  bnfId: string;
  caseType: string;
  applicantType: string;
  requestType: string;
  public documentSearch: any;
  public searchText = '';
  public documentList: any[];
  public newPageReady = true;
  constructor() {
  }

  ngOnInit() {
  }

  closeForm() {
    const closeButton: HTMLElement = this.closeButton.nativeElement as HTMLElement;
    closeButton.click();
  }

clearFilter() {
  // Resets search textbox
  this.searchText = '';
}
applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
  let filterData = this.documentSearch.filter(r => r.applicantType.toLowerCase().includes(filterValue)
  || r.documentType.toLowerCase().includes(filterValue));
  this.documentList = filterData;
}
}
