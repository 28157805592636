import { Component, OnInit } from '@angular/core';
import { WorkSiteData } from '../lca-compliance/model/Lca.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-lca-home',
  templateUrl: './lca-home.component.html',
  styleUrls: ['./lca-home.component.scss']
})
export class LcaHomeComponent implements OnInit {
  public current = 0;
  requestId="";

  public steps = [
      { label: 'Welcome Page', icon: 'user' },
      { label: 'Most Recent/Active LCA(s)', icon: 'dictionary-add' },
      { label: 'New Work Site Details', icon: 'attachment', disabled:true },
      { label: 'New Work Site Review', icon: 'preview',disabled:'false' },
      { label: 'Submission', icon: 'file-add' }
  ];

  public workSiteInfo:WorkSiteData[];
  public lcaType="internal";

  constructor(public route:ActivatedRoute) { }


  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.lcaType = params['lcaType'];
    // Loads data to grid
    });
  }

  changePage(value){
    console.log(value);
    this.current=value;
  }

  determineLCA(value){
    if (value != "") {
      if (value == "back") {
        this.current = 2;
      }
      else {
        this.requestId = value;
        this.steps[3].disabled = 'true';
        this.current = 4;
        this.workSiteInfo = [];
        this.steps[2].disabled = true;
      }
    
   }
  }


  assignData(data){
    this.workSiteInfo=data;
    this.current=3;
  }

  completeLCA(data){
    this.steps[3].disabled='false';
    this.current=0;
  }

  draftData(data) {
    this.workSiteInfo = data;
    this.current = 1;
  }

  changeNWSStepper(data) {
    if (data > 0)
      this.steps[2].disabled = false;
    else
      this.steps[2].disabled = true;
  }
}
