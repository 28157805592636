import { Component, Input, OnInit } from '@angular/core';
import { ProfileModel } from '../../../Model/ProfileModel';

@Component({
  selector: 'app-view-profile',
  templateUrl: './view-profile.component.html',
  styleUrls: ['./view-profile.component.scss']
})
export class ViewProfileComponent implements OnInit {
  @Input() documentInfo: ProfileModel;
  @Input() packagesStatus: any;
  constructor() { }

  ngOnInit(): void {
  }

}
