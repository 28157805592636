import { Component, OnInit, Input,Output,EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutoCompleteComponent),
      multi: true
    }
  ]
})
export class AutoCompleteComponent implements OnInit {
  @Input() dataToBind: string = "";
  @Input() label : string ="";
  onChange: any = () => { };
  onTouch: any = () => { };
  isDisabled = false;
  @Input() isReadOnly: boolean = false;
  @Output() changeEmitter = new EventEmitter<any>();
  
  constructor() { }

  writeValue(obj: any): void {
    try {
      this.dataToBind = obj;
    }
    catch (err) {
      console.log(err);
    }
  }

  registerOnChange(fn: any): void {
    try {
      this.onChange = fn;
    }
    catch (err) {
      console.log(err);
    }
  }

  onValueChange(event: any) {
    try {
      if (this.isDisabled) return;
      this.dataToBind = event.value;
      this.onChange(this.dataToBind);
      this.onTouch();
      this.changeEmitter.emit(event);
    }
    catch (err) {
      console.log(err);
    }
  }

  registerOnTouched(fn: any): void {
    try {
      this.onTouch = fn;
    }
    catch (err) {
      console.log(err);
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    try {
      this.isDisabled = isDisabled;
    }
    catch (err) {
      console.log(err);
    }
  }

  ngOnInit(): void {
  }

}

