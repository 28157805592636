import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ValidationMessage } from 'src/app/DAF/Models/validationMessage';

@Component({
  selector: 'app-validation-message',
  templateUrl: './validation-message.component.html',
  styleUrls: ['./validation-message.component.scss']
})
export class ValidationMessageComponent implements OnInit {

  @Input() validationData: ValidationMessage[];
  @ViewChild('closeButton',  {static: false}) closeButton: ElementRef;
  bnfId: string;
  caseType: string;
  applicantType: string;
  requestType: string;
  public documentSearch: any;
  public searchText = '';
  private notifier: NotifierService;
  public validationsearch: any[];
  public newPageReady = true;
  constructor() {
    var data=this.validationData;
this.validationsearch=data;
  }

  ngOnInit() {

  }
  ngOnChanges() {
  }
  closeForm() {
    const closeButton: HTMLElement = this.closeButton.nativeElement as HTMLElement;
    closeButton.click();
  }

clearFilter() {
  // Resets search textbox
  this.searchText = '';
}
applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
  let filterData = this.validationsearch.filter(r => r.caseSummaryId.toLowerCase().includes(filterValue)
  || r.columnName.toLowerCase().includes(filterValue));
  this.validationData = filterData;
}

}
