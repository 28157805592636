import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AddLCAService } from '../add-lca/service/add-lca.service';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';

@Component({
  selector: 'app-lca-determination',
  templateUrl: './lca-determination.component.html',
  styleUrls: ['./lca-determination.component.scss']
})
export class LcaDeterminationComponent implements OnInit {
  public pageSize = 5;
  public skip = 0;
  public gridState: DataStateChangeEvent = {
    skip: this.skip,
    take: this.pageSize
  };
  public workSiteData=[];
  public showData=false;
  public LCAData=[];
  public newWorkSites=[];
  public gridView2:any;
  public gridView:any;
  public RecommendedOutput="";
  public isPageReady = false;
  public createdDate:Date=new Date();
  @Input() RequestId:any;
  @Input() lcaType="";

  @Output() pages = new EventEmitter<number>();
  constructor(public addLCAService: AddLCAService) { }

  ngOnInit() {
    if(this.RequestId){
    this.GetWorkSiteData(this.RequestId);
    }
  }

  AddNewWorkSites(){
    this.addLCAService.setLCAData([]);
    this.pages.emit(5);
  }



  
  GetWorkSiteData(requestId) {

    this.addLCAService.GetWorkSiteData(requestId).subscribe((data: any) => {
      this.workSiteData=data;
      this.newWorkSites=data.workSiteData;
      this.createdDate=data.createdDate;
      this.LCAData=data.lcaHistory;
      this.RecommendedOutput=data.recommendedOutput;
      this.isPageReady=true;
      this.gridView = process(this.newWorkSites, this.gridState);
      this.gridView2 = process(this.LCAData, this.gridState);
      this.showData=true;
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
  }

}
