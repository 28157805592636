import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { InteractionRequiredAuthError, AuthError } from 'msal';
import { environment } from '../../environments/environment';
import { CommonService } from '../service/common.service';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { CheckUser } from '../immigrationcompilation/usermanagement/model/checkUser';
@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})

export class NavMenuComponent implements OnInit {
  isExpanded = false;
  profile;
  photo: any = "../../assets/images/user.jpg";
  email;
  public isSuperAdmin=[];
  public isUserExist = null;
  private readonly notifier: NotifierService;
  ReportDate: any;
  constructor(private http: HttpClient, private commonService: CommonService, private router: Router, private authService: MsalService) {
    this.profile = authService.getAccount().name;
    this.email = authService.getAccount().userName;
  }

  ngOnInit() {
    this.getReportDate();
    this.getAvatar();
    this.getUserRole(this.email);  
  }

  getReportDate(){
    this.commonService.getReportDate()
        .subscribe((data: any) => {
         this.commonService.updateDate({fromDate:data.reportDate,toDate:data.reportDate});
         this.commonService.setresetDate(data.reportDate);
        });
      }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  getAvatar() {
    this.http.get(environment.GraphUri + 'v1.0/users/' + this.email + '/photos/48x48/$value')
      .subscribe((photo: any) => {
        this.createImageFromBlob(photo);
      },
      () => {
      });
  }



  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener('load', () => {
      this.photo = reader.result;
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  logout() {
    this.commonService.logoutSession().subscribe((data: any) => {
      this.authService.logout();
    });
  }
  getUserRole(email) {
    var user=new CheckUser();
    user.email=email;
    // Gets user role from api using email
      this.commonService.getUserRoleByEmail(user.email)
        .subscribe((data: any) => {
          // Navigates to accessdenied component if user doesn't exist
          if (data.length==0) {
            this.isUserExist = 'noaccess';
            localStorage.setItem('isUserExist', this.isUserExist);
            this.router.navigate(['/accessdenied']);
          } else {
            // Assigns values to local storage if user exists
            this.isUserExist = 'hasaccess';
            localStorage.setItem('isUserExist', this.isUserExist);
          
            var roleArray = data;
            this.isSuperAdmin = roleArray.map(a => a.roleName);
            localStorage.setItem('isSuperAdmin', JSON.stringify(this.isSuperAdmin));
          }
        },
        (error) => {
          this.notifier.notify('error', 'Error occured while getting user role from server');
        });
    }
}
