import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Lawyer } from './model/lawyer';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { NotifierService } from 'angular-notifier';
import { LawyerService } from './service/lawyer.service';
import { process } from '@progress/kendo-data-query';


@Component({
  selector: 'app-lawyer-email',
  templateUrl: './lawyer-email.component.html',
  styleUrls: ['./lawyer-email.component.scss']
})
export class LawyerEmailComponent implements OnInit {
  @ViewChild('openEditModalButton', { static: false }) openEditModalButton: ElementRef;
  public selectedLawyer: Lawyer = new Lawyer();
  public deleteId = 0;
  public searchTerm = '';
  public showConfirmation = false;
  public lawyerList: any[] = [];
  public isPageReady = true;
  public pageSize = 10;
  public skip = 0;
  public gridViewSearch: any;
  public gridView: any;
  public searchName = '';
  public gridState: DataStateChangeEvent = {
    skip: this.skip,
    take: this.pageSize
  };
  private readonly notifier: NotifierService;
  constructor(
    private lawyerService: LawyerService
  ) {
  }

  ngOnInit() {
    // Loads data to grid
    this.dataStateChange(this.gridState);
  }
  updateLawyerList() {
    this.dataStateChange(this.gridState);
  }
  dataStateChange(state: DataStateChangeEvent) {
    this.isPageReady = false;
    this.gridState = state;
    // Gets data from api
    this.lawyerService.refreshLawyerList(this.searchTerm).subscribe((data: any) => {
      // Binds the data to grid
      this.gridViewSearch = data;

      let filterData = this.gridViewSearch.filter(r => r.name.toLowerCase().includes(this.searchName)
        || r.email.toLowerCase().includes(this.searchName));

      this.gridView = process(filterData, this.gridState);
      this.lawyerList = data;
      this.isPageReady = true;
    },
    (error) => {
      this.notifier.notify('error', 'Error occured while getting data from server');
      this.isPageReady = true;
    }
    );
  }
  deleteLawyer() {
    this.lawyerService.deleteLawyer(this.deleteId).subscribe((data: any) => {
     this.confirmationDialog(false, data);
      this.dataStateChange(this.gridState);
    },
    (error) => {
     this.notifier.notify('error', 'Error occured while deleting the lawyer from server');
      this.isPageReady = true;
    }
    );
    }
    editLawyer(lawyer: Lawyer , edit: boolean) {
      this.selectedLawyer = Object.assign({}, lawyer);
      this.openEditModalButton.nativeElement.click();
    }
    confirmationDialog(open: boolean, data) {
      // Opens the confirmation dialog
      if (open) {
        this.showConfirmation = true;
      } else {
        this.showConfirmation = false;
      }
      // setting the delete ID to global variable
      if (data) {
        this.deleteId = data.lawyerId;
      } else {
        this.deleteId = 0;
      }
    }
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
      let filterData = this.gridViewSearch.filter(r => r.name.toLowerCase().includes(filterValue)
        || r.email.toLowerCase().includes(filterValue));
      this.gridState.skip = 0;
      this.gridView = process(filterData, this.gridState);
      // console.log(this.gridView);
    }
}
