import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { dafAppConstants } from 'src/app/DAF/Models/applicationConstants';
import { DafService } from 'src/app/DAF/services/dafService';
import { DashboardModel } from '../../../../Models/Dashboard';
import { CommonService } from 'src/app/service/common.service';
import { waitForAsync } from '@angular/core/testing';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent implements OnInit {
  overallStatusData: Array<DashboardModel> = [];
  dataByLoggedInUser: Array<DashboardModel> = [];
  totalCases: number = 0;
  totalCasesByLoggedInUser: number = 0;
  isPageReady: boolean = false;
  dateFilterModel: DateFiltersModel;
  fromReportDate: any;
  toReportDate: any;
  constructor(public _dafService: DafService, private router: Router, private commonService: CommonService) {
    this.dateFilterModel = new DateFiltersModel(new Date(), new Date());
  }


  async ngOnInit() {
    //this.dateFilterModel = new DateFiltersModel(new Date(), new Date());
    await this.getCaseSummaryReportDate();
    //await this.getCaseDetails();
  }

  getCaseSummaryReportDate() {
    this.commonService.getCaseSummaryReportDate()
      .subscribe((data: any) => {
        this._dafService.updateDate({ fromDate: data.reportDate, toDate: data.reportDate });
        this.getCaseDetails();
      });
  }
  getCaseDetails() {

    this._dafService.currentDates
      .subscribe((data: any) => {
        this.fromReportDate = data.fromDate;
        this.toReportDate = data.toDate;
        this.dateFilterModel = new DateFiltersModel(new Date(this.fromReportDate), new Date(this.toReportDate));
        this._dafService.GetCaseSummaryDashboard(this.dateFilterModel.startDate.toDateString(), this.dateFilterModel.endDate.toDateString()).subscribe((data: any) => {
          this.overallStatusData = [];
          this.totalCases = 0;
          data.forEach(element => {
            this.overallStatusData.push(new DashboardModel(element))
          });
          const countsArr = this.overallStatusData.map(x => x.value);
          this.totalCases = countsArr.reduce((a, b) => a + b, 0);
          this.getCaseAssessorDetails();
      },
        (error) => {
        });
    //this._dafService.GetCaseSummaryDashboard(this.dateFilterModel.startDate.toDateString(), this.dateFilterModel.endDate.toDateString()).subscribe((data: any) => {
    //  this.overallStatusData=[];
    //  this.totalCases=0;
    //  data.forEach(element => {
    //    this.overallStatusData.push(new DashboardModel(element))
    //  });
    //  const countsArr = this.overallStatusData.map(x => x.value);
    //  this.totalCases = countsArr.reduce((a, b) => a + b, 0);
    //  this.getCaseAssessorDetails();
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
  }

  getCaseAssessorDetails() {
    this._dafService.GetCaseAssessorSummary(this.dateFilterModel.startDate.toDateString(), this.dateFilterModel.endDate.toDateString()).subscribe((data: any) => {
      this.dataByLoggedInUser=[];
      this.totalCasesByLoggedInUser=0;
      const filteredData = data;
      filteredData.forEach(element => {
        this.dataByLoggedInUser.push(new DashboardModel(element))
      });
    
      const countsArr = this.dataByLoggedInUser.map(x => x.value);
      this.totalCasesByLoggedInUser = countsArr.reduce((a, b) => a + b, 0);
      this.isPageReady = true;
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
  }

  caseasessorOpen(status) {
    // this._dafService.setSessionStorage(dafAppConstants.sessionStorageVariables.currentStatus,status);
    // this._dafService.setSessionStorage(dafAppConstants.sessionStorageVariables.currentCaseType,"caseAssessor")
    // this.router.navigate(['/caseDeatils'], { queryParams: { 'caseStatus': status, 'caseType': 'caseAssessor' }});
  }

  onSeriesClick(event: any, caseType: string) {
    try {
      console.log(event);
      this._dafService.setSessionStorage(dafAppConstants.sessionStorageVariables.currentStatus, event.category);
      this._dafService.setSessionStorage(dafAppConstants.sessionStorageVariables.currentCaseType, caseType)
      this.router.navigate(['/caseDeatils'], { queryParams: { 'caseStatus': event.category, 'caseType': caseType } });
    }
    catch (err) {
      console.log(err);
    }
  }
  filterCaseList() {
    //this.getCaseDetails();
    this._dafService.updateDate({ fromDate: this.dateFilterModel.startDate.toDateString(), toDate: this.dateFilterModel.endDate.toDateString() });
  }

}
export class DateFiltersModel {
  public startDate: Date;
  public endDate: Date;
  constructor(startDate: Date, endDate: Date) {
    this.startDate = startDate;
    this.endDate = endDate;
  }
}
