import { GeneralDetailsModel } from "./GeneralDetails";

export class caseSummaryDetails extends GeneralDetailsModel {
    caseSummaryId: number = 0;
    firstName: string = null;
    middleName: string = null;
    lastName: string = null;
    dateOfBirth: Date = null;
    caseManager: string = null;
    caseAttorney: string = null;
    companyName: string = null;
    supportTeamMember: string = null;
    signatoryName: string = null;
    signatoryTitle: string = null;
    signatoryTelephone: string = null;
    signatoryEmail: string = null;
    corporationAddress: string = null;
    signatoryStreetName: string = null;
    signatoryStreetNo : number = null;
    signatoryAptSteFlrDoorNo: number = null;
    signatoryCity: string = null;
    signatoryState: string = null;
    signatoryZipcode: string = null;
    department: string = null;
    employeeId: string = null;
    employeeEmail: string = null;
    expediteCase: boolean = false;
    reasonToExpediteCase: string = null;
    placeOnHold: boolean = false;
    reasonToDelayCase: string = null;
    notes: string = null;
    stateId: number = 0;
    zipCodeId: number = 0;
    cityId: number = 0;
    signatoryCounty: number = 0;
    signatoryMSA: number = 0;
    geographyId: number = 0;
    countyId: number = 0;
    caseManagerId: number = 0;
    caseAttorneyId: number = 0;
    constructor(obj?: any) {
        super();
        try{
            if (obj) {
                this.caseId = obj.caseId ? obj.caseId : null;
                this.caseSummaryId = obj.caseSummaryId ? obj.caseSummaryId : null;
                this.firstName = obj.firstName ? obj.firstName : null;
                this.middleName = obj.middleName ? obj.middleName : null;
                this.lastName = obj.lastName ? obj.lastName : null;
                this.dateOfBirth = obj.dateOfBirth ? obj.dateOfBirth : new Date();
                this.caseManager = obj.caseManager ? obj.caseManager : null;
                this.caseAttorney = obj.caseAttorney ? obj.caseAttorney : null;
                this.companyName = obj.companyName ? obj.companyName : null;
                this.supportTeamMember = obj.supportTeamMember ? obj.supportTeamMember : null;
                this.signatoryEmail = obj.signatoryEmail ? obj.signatoryEmail : null;
                this.signatoryName = obj.signatoryName ? obj.signatoryName : null;
                this.signatoryTitle = obj.signatoryTitle ? obj.signatoryTitle : null;
                this.signatoryAptSteFlrDoorNo = obj.signatoryAptSteFlrDoorNo ? obj.signatoryAptSteFlrDoorNo : null;
                this.signatoryStreetNo = obj.signatoryStreetNo ? obj.signatoryStreetNo : null;
                this.signatoryStreetName = obj.signatoryStreetName ? obj.signatoryStreetName : null;
                this.signatoryState = obj.signatoryState ? obj.signatoryState : null;
                this.signatoryZipcode = obj.signatoryZipcode ? obj.signatoryZipcode : null;
                this.signatoryCity = obj.signatoryCity ? obj.signatoryCity : null;
                this.signatoryTelephone = obj.signatoryTelephone ? obj.signatoryTelephone : null;
                this.corporationAddress = obj.corporationAddress ? obj.corporationAddress : null;
                this.department = obj.department ? obj.department : null;
                this.employeeEmail = obj.employeeEmail ? obj.employeeEmail : null;
                this.employeeId = obj.employeeID ? obj.employeeID : null;
                this.expediteCase = obj.expediteCase ? obj.expediteCase : false;
                this.reasonToDelayCase = obj.reasonToDelayCase ? obj.reasonToDelayCase : null;
                this.reasonToExpediteCase = obj.reasonToExpediteCase ? obj.reasonToExpediteCase : null;
                this.placeOnHold = obj.placeOnHold ? obj.placeOnHold : false;
                this.notes = obj.notes ? obj.notes : null;
                this.stateId = obj.stateId ? obj.stateId : null;
                this.zipCodeId = obj.zipCodeId ? obj.zipCodeId : null;
                this.cityId = obj.cityId ? obj.cityId : null;
                this.signatoryCounty = obj.signatoryCounty ? obj.signatoryCounty : null;
                this.signatoryMSA = obj.signatoryMSA ? obj.signatoryMSA : null;
                this.countyId = obj.countyId ? obj.countyId : null;
                this.geographyId = obj.geographyId ? obj.geographyId : null;
                this.caseManagerId = obj.caseManagerId ? obj.caseManagerId : null;
                this.caseAttorneyId = obj.caseAttorneyId ? obj.caseAttorneyId : null;
            }
        }
        catch(err){
            console.log(err);
        }
    }
}

export namespace Summaryconstants{
    export class formControlConstants {
        static lockedFields: Array<string> = ["employeeId", "employeeEmail"]
    }
}
