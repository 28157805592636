import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AddLCAService } from '../add-lca/service/add-lca.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-update-lca-modal',
  templateUrl: './update-lca-modal.component.html',
  styleUrls: ['./update-lca-modal.component.scss']
})
export class UpdateLcaModalComponent {
  @Input() workSiteInfo: any;

  @Output() updateLCAList = new EventEmitter<any>();
  @ViewChild('closeButton', { static: false }) closeButton: ElementRef;
  @ViewChild('addcloseButton', { static: false }) addcloseButton: ElementRef;
  @ViewChild('notification', { static: false }) customNotificationTmpl;
  errorMessage: string;
  options: string[];// = ['One', 'Two', 'Three'];
  filteredOptions: any;
  selectedLCA = new FormControl('');
  
  public isPageReady = true;
  constructor(public addLCAService: AddLCAService, private notifier: NotifierService) {
    
  }
  public lcaForm = new FormGroup({
    WorksiteId: new FormControl(''),
    LCANumber: new FormControl('', Validators.required),
    Comment: new FormControl(''),
    CaseId: new FormControl(''),
    ApplicantName: new FormControl(''),
    Corporation: new FormControl(''),
    Department: new FormControl('')
  });
  
  ngOnChanges() {
    if (this.workSiteInfo.worksite) {
      this.lcaForm.controls["WorksiteId"].setValue(this.workSiteInfo.worksite.id);
      this.lcaForm.controls["Comment"].setValue(this.workSiteInfo.worksite.comment);
      this.lcaForm.controls["LCANumber"].setValue(this.workSiteInfo.worksite.lcaNumber);
      this.lcaForm.controls["CaseId"].setValue(this.workSiteInfo.caseID);
      this.lcaForm.controls["ApplicantName"].setValue(this.workSiteInfo.applicantName);
      this.lcaForm.controls["Corporation"].setValue(this.workSiteInfo.corporation);
      this.lcaForm.controls["Department"].setValue(this.workSiteInfo.department);
    }
    
  }

  addLCANumber(addWorksiteForm) {
    this.isPageReady = false;
    this.addLCAService.updateLCANumber(this.lcaForm.value).subscribe((data: any) => {
      this.updateLCAList.emit();
      this.isPageReady = true;
      this.addcloseForm();
    },
    (error) => {
      this.notifier.notify('error', 'Error occured while updating the LCA details');
      this.isPageReady = true;
    });
  }

  addcloseForm() {
    const addcloseButton: HTMLElement = this.addcloseButton
      .nativeElement as HTMLElement;
    addcloseButton.click();
  }
}
