import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "../shared/shared.module";
import { DAFHomeRoutingModule } from './dafhome-routing.module';
import { DashboardModule } from '../dashboard/dashboard.module';
import { DAFcHomeComponent } from './dafc-home/dafc-home.component';
import { AssessmentFormsModule } from '../assessment-forms/assessment-forms.module';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { CaseSummaryDetailsComponent } from './case-summary-details/case-summary-details.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';

import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { IconsModule } from "@progress/kendo-angular-icons";
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { IndividualBioGraphicalInformationComponent } from './individual-bio-graphical-information/individual-bio-graphical-information.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { EditorModule } from '@progress/kendo-angular-editor';
import { JobPositionAssessmentComponent } from './job-position-assessment/job-position-assessment.component';
import { CheckBoxModule } from '@progress/kendo-angular-inputs';
import { PrimaryWorksiteAddressComponent } from './primary-worksite-address/primary-worksite-address.component';
import { LcaAssessmentComponent } from './lca-assessment/lca-assessment.component';
import { SpouseAndDependentApplicationComponent } from './spouse-and-dependent-application/spouse-and-dependent-application.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { FilingStratergyComponent } from './filing-stratergy/filing-stratergy.component';
import { KendoGridEditFormComponent } from './kendo-grid-edit-form/kendo-grid-edit-form.component';
import { DocumentChecklistComponent } from './document-checklist/document-checklist.component';
import { KendoGridChecklistEditFormComponent } from './kendo-grid-checklistEdit-form/kendo-grid-checklistEdit-form.component';
import { WorksiteWizardComponent } from './worksite-wizard/worksite-wizard.component';
import { SpecialistDegreeComponent } from './specialist-degree/specialist-degree.component';
import { KendoGridSpecialistFormComponent } from './kendo-grid-specialist-form/kendo-grid-specialist-form.component';
import { ExperienceWizardComponent } from './experience-wizard/experience-wizard.component';
import { RecaptureWizardComponent } from './recapture-wizard/recapture-wizard.component';
import { JobDetailsWizardComponent } from './job-details-wizard/job-details-wizard.component';
import { AddressInfoWizardComponent } from './address-info-wizard/address-info-wizard.component';
import { LcaInfoWizardComponent } from './lca-info-wizard/lca-info-wizard.component';
import { KendoGridFilingDetailComponent } from './kendo-grid-filing-detail/kendo-grid-filing-detail.component';
import { ValidationMessageComponent } from './validation-message/validation-message.component';
import { WorksiteAddressWizardComponent } from './worksite-address-wizard/worksite-address-wizard.component';
import { FilingTravelModalComponent } from './filing-travel-modal/filing-travel-modal.component';
import { DafCaseFeesComponent } from './daf-case-fees/daf-case-fees.component';


@NgModule({
    declarations: [DAFcHomeComponent, CaseSummaryDetailsComponent, IndividualBioGraphicalInformationComponent, JobPositionAssessmentComponent, PrimaryWorksiteAddressComponent, LcaAssessmentComponent, SpouseAndDependentApplicationComponent, FilingStratergyComponent, KendoGridEditFormComponent, DocumentChecklistComponent,KendoGridChecklistEditFormComponent, WorksiteWizardComponent, SpecialistDegreeComponent, KendoGridSpecialistFormComponent, ExperienceWizardComponent, RecaptureWizardComponent, JobDetailsWizardComponent, AddressInfoWizardComponent, LcaInfoWizardComponent, KendoGridFilingDetailComponent, ValidationMessageComponent, WorksiteAddressWizardComponent, FilingTravelModalComponent, DafCaseFeesComponent],
  imports: [
    CommonModule,
    DAFHomeRoutingModule,
    DashboardModule,
    AssessmentFormsModule,
    LayoutModule,
    DropDownsModule,
    ButtonsModule,
    InputsModule,
    LabelModule,
    TextBoxModule,
    IconsModule,
    GridModule,
    EditorModule,
    SharedModule,
    CheckBoxModule,
    DialogsModule
  ]
})
export class DAFHomeModule { }
