export class WorkSitewizards{
    primWorkItineraryID:number;
    worksiteItenary:boolean;
    clientsideAmmendment:boolean;
    clientlegalName:string;
    periodFrom:Date;
    periodTo:Date;
    daysPerMonth:string;
    streetAddress:string;
    streetNumber :string;
    streetName:string;
    apt:string;
    cityId: number;
    stateId: number;
    zipCodeId: number;
    countyId: number;
    primary: boolean;
    offsite: boolean;
}
