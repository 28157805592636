import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ImmigrationcompilationRoutingModule } from './immigrationcompilation-routing.module';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { ModaldesignComponent } from './modaldesign/modaldesign.component';
import { UsermanagementComponent } from './usermanagement/usermanagement.component';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { EdituserComponent } from './usermanagement/edituser/edituser.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FloatingLabelModule } from '@progress/kendo-angular-label';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { AccessDeniedComponent } from './shared/components/access-denied/access-denied.component';
import { AwaitdocextractionComponent } from './awaitdocextraction/awaitdocextraction.component';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { DatePickerModule, DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DocumentchklistComponent } from './awaitdocextraction/documentchklist/documentchklist.component';
import { AwaitcompilationComponent } from './awaitcompilation/awaitcompilation.component';
import { CompilationfailedComponent } from './compilationfailed/compilationfailed.component';
import { CompilationrejectedComponent } from './compilationrejected/compilationrejected.component';
import { SubmittedtolawyerComponent } from './submittedtolawyer/submittedtolawyer.component';
import { AwaitlawclerkreviewComponent } from './awaitlawclerkreview/awaitlawclerkreview.component';
import { DetailedviewComponent } from './detailedview/detailedview.component';
import { CourierPackageComponent } from './courier-package/courier-package.component';
import { CourierpackageSuccessComponent } from './courier-package/courierpackage-success/courierpackage-success.component';
import { CourierpackageFailureComponent } from './courier-package/courierpackage-failure/courierpackage-failure.component';
import { DocumentExtractionInfoComponent } from './courier-package/courierpackage-success/document-extraction-info/document-extraction-info.component';
import { DatePipe } from '@angular/common';
import { LawyerEmailComponent } from './lawyer-email/lawyer-email.component'
import { EditLawyerComponent } from './lawyer-email/editlawyer/editLawyer.component';
import { RoleViewComponent } from './usermanagement/role-view/role-view.component';
import { MainPageComponent } from './main-page/main-page.component';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { LcaComplianceComponent } from './lca-compliance/lca-compliance.component';
import { EditLcaComplianceComponent } from './lca-compliance/edit-lca-compliance/edit-lca-compliance.component';
import { AddLcaComponent } from './add-lca/add-lca.component';
import { WorkSiteAcknowledgeComponent } from './work-site-acknowledge/work-site-acknowledge.component';
import { LcachklistComponent } from './lcachklist/lcachklist.component';
import { ListViewModule } from "@progress/kendo-angular-listview";
import { LcaHomeComponent } from './lca-home/lca-home.component';
import { IconsModule } from "@progress/kendo-angular-icons";
import { LcaHomeInfoComponent } from './lca-home-info/lca-home-info.component';
import { EyLCAAssessmentComponent } from './ey-lcaassessment/ey-lcaassessment.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { LcaDeterminationComponent } from './lca-determination/lca-determination.component';
import { LcaReviewComponent } from './lca-review/lca-review.component';
import { AwaitLCADeterminationComponent } from './await-lcadetermination/await-lcadetermination.component';
import { NotificationModule } from "@progress/kendo-angular-notification";
import { LcaInternalDashboardComponent } from './lca-internal-dashboard/lca-internal-dashboard.component';
import { EditInternalWorkSiteInfoComponent } from './edit-internal-work-site-info/edit-internal-work-site-info.component';
import { NavigationModule } from "@progress/kendo-angular-navigation";
import { RepostingCompletedComponent } from './reposting-completed/reposting-completed.component';
import { UpdateLcaModalComponent } from './update-lca-modal/update-lca-modal.component';





@NgModule({
  declarations: [DashboardComponent, ModaldesignComponent, UsermanagementComponent, EdituserComponent,MainPageComponent,
     AccessDeniedComponent, AwaitdocextractionComponent, DocumentchklistComponent, AwaitcompilationComponent, CompilationfailedComponent, CompilationrejectedComponent, SubmittedtolawyerComponent, AwaitlawclerkreviewComponent, DetailedviewComponent, CourierPackageComponent, CourierpackageSuccessComponent, CourierpackageFailureComponent, DocumentExtractionInfoComponent, LawyerEmailComponent,EditLawyerComponent, RoleViewComponent, LcaComplianceComponent, EditLcaComplianceComponent, AddLcaComponent, WorkSiteAcknowledgeComponent, LcachklistComponent, LcaHomeComponent, LcaHomeInfoComponent, EyLCAAssessmentComponent, LcaDeterminationComponent, LcaReviewComponent, AwaitLCADeterminationComponent, LcaInternalDashboardComponent, EditInternalWorkSiteInfoComponent, RepostingCompletedComponent, UpdateLcaModalComponent],
  imports: [
    CommonModule,
    DialogModule,
    ExcelModule,
    ImmigrationcompilationRoutingModule,
    GridModule,
    FormsModule,
    ReactiveFormsModule,
    DropDownsModule,
    BrowserModule,
    BrowserAnimationsModule,
    FloatingLabelModule,
    TextBoxModule,
    InputsModule,
    TreeViewModule,
    TreeListModule,
    DatePickerModule,
    LayoutModule,
    DateInputsModule,
    ListViewModule,
    NotificationModule,
    IconsModule,
    MatAutocompleteModule,
    NavigationModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers:[DatePipe]
})
export class ImmigrationcompilationModule { }
