import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Logger, CryptoUtils } from 'msal';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { CommonService } from '../app/service/common.service';
import { Subject, Subscription, timer } from 'rxjs';
import { take, startWith, switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  isIframe = false;
  loggedIn = false;
  profile;
  isUserExist = '';
  requestObj = null;
  userInactive: Subject<any> = new Subject();
  public showConfirmation: boolean = false;
  public isLogout = false;
  sessioTimeout: any;
  userActivity: any;
  unsubscribe$: Subject<void> = new Subject();

  constructor(private broadcastService: BroadcastService, private authService: MsalService,
    private http: HttpClient, private router: Router, private commonService: CommonService) {

  }

  ngOnInit() {
    this.isUserExist = localStorage.getItem('isUserExist');
    this.isIframe = window !== window.parent && !window.opener;

    this.broadcastService.subscribe('msal:loginSuccess', () => {
      this.checkAccount();
    });

    this.broadcastService.subscribe('msal:acquireTokenSuccess', () => {
      this.checkAccount();
    });

    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        return;
      }
      console.log('Redirect Success: ', response.accessToken);
    });

    this.requestObj = {
      scopes: ["user.read", environment.APIScope]
    };
    this.broadcastService.subscribe("msal:acquireTokenFailure", payload => {
      this.renewToken();
      //this.login();
    });

    this.authService.setLogger(new Logger((logLevel, message, piiEnabled) => {
      console.log('MSAL Logging: ', message);
    }, {
      correlationId: CryptoUtils.createNewGuid(),
      piiLoggingEnabled: false
    }));

    this.setUserIdleTimeout();
    this.userInactive.subscribe(() => {
      this.logout();
    });
  }

  @HostListener('window:mousedown', ['$event'])
  @HostListener('window:mousemove', ['$event'])
  @HostListener('document:keyup', ['$event'])
  @HostListener('document:keydown', ['$event'])
  @HostListener('document:wheel', ['$event'])
  @HostListener('window:click', ['$event'])
  onEvent(event) {
    clearTimeout(this.userActivity);
    this.setUserIdleTimeout();
  }

  setUserIdleTimeout() {
    this.userActivity = setTimeout(() => {
      if (!!this.authService.getAccount()) {
        this.userInactive.next(undefined);
      }
    }, 1800000);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  checkAccount() {
    this.loggedIn = !!this.authService.getAccount();
  }

  login() {
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

    if (isIE) {
      this.authService.loginRedirect();
    } else {
      this.authService.loginPopup();
    }
  }

  logout() {
    this.commonService.logoutSession().subscribe((data: any) => {
      this.authService.logout();
    });
  }

  renewToken() {
    this.authService.acquireTokenRedirect(this.requestObj);
    //.then(
    //function (accessTokenResponse) {
    //  // call API
    //}).catch(function (error) {
    //  console.log(error);
    //});
  }

  //@HostListener("window:beforeunload", ["$event"])
  //clearLocalStorage(event) {
  //  localStorage.clear();
  //}
  
}
