import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private dataSource = new BehaviorSubject( {fromDate:new Date(),toDate:new Date()});
currentData = this.dataSource.asObservable();
private resetData = new BehaviorSubject( new Date());
  resetDate = this.resetData.asObservable();

  private dataSource1 = new BehaviorSubject({ fromDate: new Date(), toDate: new Date() });
  currentData1 = this.dataSource1.asObservable();

  constructor(private _http: HttpClient) { }
  CheckUserAdmin(user): Observable<any> {
   return this._http.post<any>(environment.ApiUrl + 'User/CheckUserAdmin',user , {});
  }

  getUserRoleByEmail(UserEmail: string) {
    return this._http.get<any>(environment.ApiUrl + 'User/getUserRolesbyEmailId?emailId=' + UserEmail , {});
      }

  public updateDate(data: any) {
    this.dataSource.next(data);
  }
  public updateDate1(data: any) {
    this.dataSource1.next(data);
  }

  public setresetDate(data: any) {
    this.resetData.next(data);
  }

  getReportDate(): Observable<any> {
    return this._http.get<any>(environment.ApiUrl + 'Dashboard/getReportDate', {});
  }

  getCaseSummaryReportDate(): Observable<any> {
    return this._http.get<any>(environment.ApiUrl + 'daf/GetCaseSummaryReportDate', {});
  }

  getINSZoomReportDate(): Observable<any> {
    return this._http.get<any>(environment.ApiUrl + 'EYMPINSZoomIntegration/GetINSZoomReportDate', {});
  }

  logoutSession() : Observable<any> {
    return this._http.post<any>(environment.ApiUrl + "User/LogoutUserSession", null)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }


  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }



}
