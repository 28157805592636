import { Component, OnInit, Input, Inject, ViewChild, ElementRef } from '@angular/core';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { AddLCAService } from '../add-lca/service/add-lca.service';
import { process } from '@progress/kendo-data-query';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from "@progress/kendo-angular-notification";
import { FinalDetermination } from '../lca-compliance/model/Lca.model';



@Component({
  selector: 'app-edit-internal-work-site-info',
  templateUrl: './edit-internal-work-site-info.component.html',
  styleUrls: ['./edit-internal-work-site-info.component.scss']
})
export class EditInternalWorkSiteInfoComponent implements OnInit {
  @ViewChild('openLCAModalButton', { static: false }) openEditModalButton: ElementRef;
  @Input() workSiteDetails: any[] = [];
  public pageSize = 5;
  public skip = 0;
  public gridState: DataStateChangeEvent = {
    skip: this.skip,
    take: this.pageSize
  };
  public workSiteData=[];
  public showData=false;
  public LCAData=[];
  public newWorkSites=[];
  public gridView2:any;
  public gridView:any;
  public RecommendedOutput="";
  public isPageReady = false;
  private hideAfter = 4000;
  public CaseId="";
  public Department="";
  public Corporation="";
  public Description="";
  public employeeId="";
  public attorneyList = [];
  public caseManagerList = [];
  public determination="";
  public attorney = "";
  public caseManager = "";
  public determinationList = ["No Action Required", "LCA Re-Posting Required", "H-1B Amendment Required","More Info Required"]
  public individualName="";
  public  requestId="";
  public Remarks="";
  public clerkRemarks="";
  public status = "submitted";
  public selectedLCA: any = [];
  public jobTitle: any = "";
  public degree: any = "";
  public socCode: any = "";
  public wageLevel: any = "";
  public activeLCAData = [];
  public worksiteCaseId = "";
  constructor(public addLCAService: AddLCAService,private router: Router,private route: ActivatedRoute,@Inject(NotificationService) private notificationService: NotificationService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.requestId = params['requestId'];
      this.status = params['status'];
      if(this.requestId!=""){
         // Loads data to grid
         this.GetWorkSiteData(this.requestId);
        this.getLawyerList();
        this.getCaseManagerList();
        this.getActiveLCA();
      }
  });
  }


  Save(){
    if(this.determination!=this.RecommendedOutput && this.Remarks.trim()==""){
      this.invalidMessage("Please ensure to fill all the mandatory fields");
    }
    else if (this.determination == 'H-1B Amendment Required' && (this.jobTitle.trim() == "" || this.degree.trim() == "" || this.socCode.trim() == "" || this.wageLevel.trim() == "")) {
      this.invalidMessage("Please ensure to fill all the mandatory fields");
    }
    else{
      var finalDetermination = new FinalDetermination();
      finalDetermination.finalDeterminations=this.determination;
      finalDetermination.lawyer=this.attorney;
      finalDetermination.remarks=this.Remarks;
      finalDetermination.requestId = this.requestId;
      finalDetermination.caseManager = this.caseManager;
      finalDetermination.jobTitle = this.jobTitle;
      finalDetermination.degree = this.degree;
      finalDetermination.socCode = this.socCode;
      finalDetermination.wageLevel = this.wageLevel;
  
      this.addLCAService.saveFinalDetermination(finalDetermination).subscribe((data: any) => {
        if(data){
          this.router.navigate(['/EyLca'], { queryParams: { status: this.status } })
        }
      },
        (error) => {
          //this.notifier.notify('error', 'Error occured while updating the user details');
        });
    }
  }

  approveReposting() {
    let wsMissingLcaCount = this.gridView.data.filter(x => x.lcaNumber == null || x.lcaNumber.trim() == '').length;
    if (wsMissingLcaCount > 0) {
      this.invalidMessage("Please update LCA number for all new worksites");
    }
    else if(this.clerkRemarks.trim()==""){
      this.invalidMessage("Please ensure to fill all the mandatory fields");
    }
    
    else{
      this.addLCAService.updateRepostStatus(this.requestId,this.clerkRemarks).subscribe((data: any) => {
        if(data){
          this.router.navigate(['/EyLca'], { queryParams: { status: this.status } })
        }
      });
    }
  }

  Cancel(){
    this.router.navigate(['/EyLca'], { queryParams: { status: this.status } });
  }

  getLawyerList() {

    this.addLCAService.getLawyerList().subscribe((data: any) => {
      if (data && data.length > 0) {
        this.attorneyList = data;
        //data.forEach(element => {
        //  this.attorneyList.push(element.lawyerName);
        //});
      }
    });
  }


  Approve(){
    if(this.determination!=""&&this.determination!=this.RecommendedOutput){
      this.invalidMessage("Cannot Approve request with final determination option other than Recommended Determination");

    }

  }

  Reject(){
    if(this.determination!=""&&this.determination==this.RecommendedOutput){
      this.invalidMessage("Cannot Reject Request with Same Determination as Recommended Determination")
      
    }
    else if(this.Remarks.trim()==""){
      this.invalidMessage("Please ensure to fill all the mandatory fields");

    }

  }


  invalidMessage(dataToShow){
    this.notificationService.show({
      content: dataToShow,
      position: { horizontal: "right", vertical: "top" },
      animation: { type: "fade", duration: 800 },
      type: { style: "warning", icon: true },
      hideAfter: this.hideAfter,
    });
  }

    
  GetWorkSiteData(requestId) {

    this.addLCAService.GetWorkSiteDeterminationDetails(requestId).subscribe((data: any) => {
      this.workSiteData=data;
      this.newWorkSites=data.workSiteData;
      this.LCAData=data.lcaHistory;
      this.RecommendedOutput=data.recommendedOutput;
      this.requestId=data.ticketNumber;
      this.employeeId=data.employeeId;
      this.Department =data.department;
      this.Description=data.description;
      this.individualName=data.individualName;
      this.Corporation=data.corporation;
      this.CaseId=data.caseId;
      this.attorney = data.attorney;
      this.caseManager = data.caseManager;
      this.determination=data.finalDetermination;
      this.isPageReady=true;
      this.Remarks=data.remarks;
      this.gridView = process(this.newWorkSites, this.gridState);
      this.gridView2 = process(this.LCAData, this.gridState);
      this.showData=true;
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
  }

  editUser(worksite:any, rowIndex: number) {
    //lcaData["index"] = rowIndex;
    let obj = [];
    obj["worksite"] = worksite;
    obj["requestID"] = this.requestId;
    obj["caseID"] = this.CaseId;
    obj["applicantName"] = this.individualName;
    obj["corporation"] = this.Corporation;
    obj["department"] = this.Department;
    obj["lcaList"] = [];
    if (this.LCAData.length > 0) {
      let unique = [...new Set(this.LCAData.map(x => x.lcA_Number))];
      obj["lcaList"] = unique;
    }
    this.selectedLCA = obj;
    this.openEditModalButton.nativeElement.click();
  }

  refreshPage() {
    this.GetWorkSiteData(this.requestId);
  }

  getCaseManagerList() {

    this.addLCAService.getCaseManagerList().subscribe((data: any) => {
      if (data && data.length > 0) {
        this.caseManagerList = data;
        //data.forEach(element => {
        //  this.caseManagerList.push(element);
        //});
      }
    });
  }

  getActiveLCA() {

    this.addLCAService.getActiveLCA(this.requestId).subscribe((data: any) => {
      if (data && data.length > 0) {
        this.activeLCAData = data;

      }
    });
  }
}
