import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import {AwaitDocExtractionService} from '../awaitdocextraction/service/awaitdocextraction.service';
import { DashType } from '@progress/kendo-drawing';
import { DashboardService } from 'src/app/immigrationcompilation/dashboard/service/dashboard.service';
import { NotifierService } from 'angular-notifier';
import {DashboardView} from '../awaitdocextraction/model/dashboardview';
import { Router } from '@angular/router';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-awaitlawclerkreview',
  templateUrl: './awaitlawclerkreview.component.html',
  styleUrls: ['./awaitlawclerkreview.component.scss']
})
export class AwaitlawclerkreviewComponent implements OnInit, AfterViewInit {
  @ViewChild('openDocModalButton', { static: false }) openDocModalButton: ElementRef;
  public caseList: any;
  public gridViewData: any[];
  private notifier: NotifierService;
  public isClicked = false;
  public dashboardView: DashboardView = new DashboardView();
  public pageSize = 10;
  public skip = 0;
  dateFilterModel: DateFilterModel;
  public gridState: DataStateChangeEvent = {
    skip: this.skip,
    take: this.pageSize
  };
  public take: number;
  fromreportDate: any;
  toreportDate:any;
  public isPageReady = true;

    constructor(private dashboardService: AwaitDocExtractionService, private router: Router,private commonService:CommonService) {
      this.dateFilterModel = new DateFilterModel(new Date(2020, 1, 1), new Date());
    }
      
      ngOnInit() {
        this.getReportDate();
    }

    getReportDate(){
      this.commonService.currentData.subscribe(data => {
        this.fromreportDate = data.fromDate;
        this.toreportDate=data.toDate;
        this.dateFilterModel = new DateFilterModel(new Date(this.fromreportDate),new Date(this.toreportDate));
        this.refreshCaseList(this.gridState);
      });
    }
    filterCaseList(){
      this.commonService.updateDate({fromDate:this.dateFilterModel.startDate.toDateString(),toDate:this.dateFilterModel.endDate.toDateString()});
    }

    resetDashboard(){
      this.commonService.resetDate.subscribe(data=>{
        this.commonService.updateDate({fromDate:data,toDate:data});
      });
    }

    refreshCaseList(state: DataStateChangeEvent) {
      this.isPageReady=false;
      this.gridState = state;
      this.dashboardService.getCaseDetailsWithStatus('AwaitLawClerkReview',this.dateFilterModel.startDate.toDateString(), this.dateFilterModel.endDate.toDateString()).subscribe((data: any) => {
            // Binds the data to grid
            this.caseList = process(data, this.gridState);
            this.gridViewData = data;
            this.take = 10;
            this.isPageReady=true;
      },
      (error) => {
        this.isPageReady=true;
        this.notifier.notify('error', 'Error occured while getting data from server');
      }
      );
    }

    viewDocumentCheckList(dataItem: any) {
      this.isClicked = true;
      this.dashboardView = Object.assign({}, dataItem);
      // this.openDocModalButton.nativeElement.click();
      this.router.navigate(['/detailedview']);
    }

    public isExpanded = (dataItem: any): boolean => {
      return true;
    }

    ngAfterViewInit() {
        // Adds search placeholder to all filter textbox
        var result = document.getElementsByClassName("k-filtercell-wrapper");
        for (let i = 0; i < result.length; i++) {
          result[i].children[0].setAttribute("placeholder", "search");
        }
      }
    }

    export class DateFilterModel {
      public startDate: Date;
      public endDate: Date;
      constructor(startDate: Date, endDate: Date) {
        this.startDate = startDate;
        this.endDate = endDate;
      }
    }
