import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lca-assessment',
  templateUrl: './lca-assessment.component.html',
  styleUrls: ['./lca-assessment.component.scss']
})
export class LCAAssessmentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
