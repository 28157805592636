export class documentChecklist {
    checklistDetails: Array<checklistItems> = [];
    notes: string;
}

export class checklistItems {
    documentChecklistDetailId: number;
    documentName: string;
    individual: string;
    relation: string;
    recievedDrafted: boolean;
    requestDraft: boolean;
    na: boolean;
    notes: string;
}
