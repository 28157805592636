import { Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AddEvent, CellClickEvent, DataStateChangeEvent, GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { NotifierService } from 'angular-notifier';
import { Subscription } from 'rxjs';
import { filingStratergyDetails, FilingTableDetail } from 'src/app/DAF/Models/FilingStratergy';
import { DafService } from 'src/app/DAF/services/dafService';
import { AddLCAService } from 'src/app/immigrationcompilation/add-lca/service/add-lca.service';

@Component({
  selector: 'app-filing-stratergy',
  templateUrl: './filing-stratergy.component.html',
  styleUrls: ['./filing-stratergy.component.scss']
})
export class FilingStratergyComponent implements OnInit {

  radioButtonOptionValues: Array<any> = [{ value: true, text: "Yes" }, { value: false, text: "No" }];
  radioButtonSecondOptionValues: Array<any> = [{ value: true, text: "Days" }, { value: false, text: "Months" }];

  private notifier: NotifierService;
  public searchDetails: any;
  public addUserMail: string;
  public addUserName: string;
  public filingData: filingStratergyDetails = new filingStratergyDetails();
  public isUnlocked: boolean = false;
  public isEditEnabled: boolean = false;
  public isPageReady = true;
  FilingStratergyForm: FormGroup = null;
  serviceCenter: any = [];
  public isActivate=false;

  serviceCodes = [];
  serviceCodesList = [];
  public filingVenue = [];
  public zipcodeList = [[]];
  public addressTypesList = [];
  public state;
  public zipcodeSource=[[]];
  public selectedType=[];
  public cityArray=[[]];
  public countyArray = [[]];
  public isReady=false;
  cityDataArray: any[]=[];
  countyDataArray: any[]=[];
  addressTypes: any[]=[];

  countrySourceList: Array<any> = [];
  CountryList: Array<any> = [];

  public view: Array<FilingTableDetail> = [];

  filingtravelTbl: Array<FilingTableDetail> = [];

  public editDataItem: FilingTableDetail;



  isDisabled: boolean = false;
  public pageSize = 5;
  public skip = 0;
  public gridView: GridDataResult;
  private isDeleteClicked: boolean = false;
  public gridState: DataStateChangeEvent = {
    skip: this.skip,
    take: this.pageSize
  };
  showDialog:boolean = false;
  deleteIndex: number;
  @ViewChild(GridComponent, {static: false})
  private grid: GridComponent;
  private editedRowIndex: number;
  private docClicksSubscription: Subscription = new Subscription();
  private isNew: boolean;
  public isGridEditable: boolean;
  public formGroup: FormGroup;
  public caseRecieptDetails:any;
  public modifiedRecords: Array<FilingTableDetail> = [];

  @Input() caseId = 0;
  @Output() page = new EventEmitter<number>();
  @Output() collapseCSPanel = new EventEmitter<any>();
  @ViewChild('autocomplete', { static: false }) autocomplete: any;

  optionalFormControls = ["middleName","signatoryName","signatoryTitle","signatoryTelephone"];
  formErrors = {
    'h1B2ndOrSubsExt': '',
    'l1ClassificationId': '',
    'caseTypeId': '',
    'filingVenueId': '',
    'filingAddress': '',
    'visaApptDate': '',
    'serviceCenterCodeId': '',
    'aMENDwOrNoExt': '',
    'anticipatedTravel': '',
    'advIssuedOnTravelOrCaseImpact': '',
    'departureDate': '',
    'arrivalDate': '',
    'countryId': '',
    'recapture': '',
    'recaptureTimeAvailable': '',
    'days': '',
    'months': '',
    'newProposedVisaMax': '', 
    'premiumProcessingI907': '',
    'emailAddressI907': '',
    'aC21Extension': '',
    'a106a1yrPERM': '',
    'datePERMFiledwithDOL': '',
    'i485AppFiledWinYrPDBecomingCurr': '',
    'a104c3YearI140': '',
    'dateI140FiledUSCIS': '',
    'priorityDate': '',
    'priorityCountryId': '',
    'visaBulletinCutoffDate': '',
    'dateI140PetitionApproved': '',
    'priorityClassification': '',
    'priorityDateCurrent': '',
    'notes': '',
  };
  
  validationMessages = {
    'h1B2ndOrSubsExt': {
      'required': 'h1B2ndOrSubsExt is required.',
    },
    'l1ClassificationId': { 
      'required': 'l1ClassificationId is required.', 
    },
    'caseTypeId': { 
      'required': 'caseTypeId is required.', 
    },
    'filingVenueId': { 
      'required': 'filingVenueId is required.', 
    },
    'filingAddress': { 
      'required': 'filingAddress is required.', 
    },
    'visaApptDate': { 
      'required': 'visaApptDate is required.', 
    },
    'serviceCenterCodeId': { 
      'required': 'serviceCenter member required.', 
    },
    'aMENDwOrNoExt': { 
      'required': 'aMENDwOrNoExt is required.', 
    },
    'anticipatedTravel': { 
      'required': 'anticipatedTravel is required.', 
    },
    'advIssuedOnTravelOrCaseImpact': { 
      'required': 'advIssuedOnTravelOrCaseImpact is required.', 
    },
    'departureDate': { 
      'required': 'departureDate is required.', 
    },
    'arrivalDate': { 
      'required': 'arrivalDate is required.', 
    },
    'countryId': { 
      'required': 'countryId is required.', 
    },
    'recapture': { 
      'required': 'recapture is required', 
    },
    'recaptureTimeAvailable': {
       'required': 'recaptureTimeAvailable is required.',
       },
    'days': { 
      'required': 'days is required.', 
    },
    'months': { 
      'required': 'months is required.',
     },
    'newProposedVisaMax': { 
      'required': 'newProposedVisaMax is required.', 
    },
    'premiumProcessingI907': { 
      'required': 'premiumProcessingI907 is required.', 
    },
    'aC21Extension': { 
      'required': 'aC21Extension  is required.',
     },
    'a106a1yrPERM': { 
      'required': 'a106a1yrPERM is required.', 
    },
    'datePERMFiledwithDOL': { 
      'required': 'datePERMFiledwithDOL is required.',
   },
    'i485AppFiledWinYrPDBecomingCurr': { 
      'required': 'i485AppFiledWinYrPDBecomingCurr is required.', 
    },
    'a104c3YearI140': { 
      'required': 'a104c3YearI140 is required.', 
    },
    'priorityDate': {
      'required': 'priorityDate are required.',
    },
    'priorityCountryId': { 
      'required': 'priorityCountryId  is required.',
     },
    'visaBulletinCutoffDate': { 
      'required': 'visaBulletinCutoffDate is required.', 
    },
    'dateI140PetitionApproved': { 
      'required': 'dateI140PetitionApproved is required.',
   },
    'priorityClassification': { 
      'required': 'priorityClassification is required.', 
    },
    'priorityDateCurrent': { 
      'required': 'priorityDateCurrent is required.', 
    },
    'notes': {
      'required': 'notes are required.',
    }
  };

  constructor(private _dafService: DafService, private renderer: Renderer2,notifierService: NotifierService, public _addLCAService: AddLCAService,private formBuilder: FormBuilder) {
    this.notifier = notifierService; 
  }

  ngOnInit() {

    this.docClicksSubscription.add(
      this.renderer.listen("document", "click", this.onDocumentClick.bind(this))
    );
    this.getMasterData();
    // this.getStates();
    // this.fetchfilingData(this.caseId);
  }

  async getMasterData (){
    this.getServiceCodes();
    this.getCountryList();
    this.getCaseRecieptDetails();
    this.fetchfilingData(this.caseId);
    this.getFilingVenue();
  }

  private onDocumentClick(e: Event): void {
    if (
      this.formGroup &&
      this.formGroup.valid &&
      !this.matches(
        e.target,
        "#productssGrid tbody *, #productssGrid .k-grid-toolbar .k-button"
      )
    ) {
      this.saveCurrent();
    }
  }


  public country(id: number): any {
    return this.countrySourceList.find((x) => x.countryId === id);
  }

  matches(el, selector) {
    return (el.matches || el.msMatchesSelector).call(el, selector);
  }

  



  fetchfilingData(caseId: number) {
    this.isPageReady = false;
    this._dafService.getFilingStratergyDetailsByCaseSummaryId(caseId).toPromise().then((data: any) => {
      this.filingData = new filingStratergyDetails(data);

      this.filingData.filingTravelDetails.forEach(item => {
        if (item.arrivalDate != undefined) {
          item.arrivalDate = new Date(item.arrivalDate);
        }
        if (item.departureDate != undefined) {
          item.departureDate = new Date(item.departureDate);
        }
      });

     this.view=this.filingData.filingTravelDetails;
      this.initializeForm();
      this.loadItems(); 
    },
      (error) => {
      }
    );
  }

  
  getCountryList() {
    this._dafService.getCountriesCitizenship().subscribe((data: any) => {
      this.CountryList = data;
      this.countrySourceList = data;
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
  }

  initializeForm() {
    let decodedNotes = this.decodeHTMLEntities(this.filingData.notes);
    try {
      this.FilingStratergyForm = this.formBuilder.group({
        caseSummaryId: new FormControl(this.filingData.caseSummaryId),
        h1B2ndOrSubsExt: new FormControl({ value: this.filingData.h1B2ndOrSubsExt, disabled: true }, [Validators.required, Validators.nullValidator]),
        l1ClassificationId: new FormControl({ value: this.filingData.l1ClassificationId, disabled: true}),
        caseTypeId: new FormControl({ value: this.filingData.caseTypeId, disabled: true }),
        filingVenueId: new FormControl({ value: this.filingData.filingVenueId, disabled: true }),
        filingAddress: new FormControl({ value: this.filingData.filingAddress, disabled: true }),
        visaApptDate: new FormControl({ value: this.filingData.visaApptDate ? new Date(this.filingData.visaApptDate) : null, disabled: true }),
        serviceCenterCodeId: new FormControl({ value: this.filingData.serviceCenterCodeId, disabled: true }, [Validators.required,Validators.nullValidator]),
        aMENDwOrNoExt: new FormControl({ value: this.filingData.amenDwOrNoExt, disabled: true }, [Validators.required,Validators.nullValidator]),
        anticipatedTravel: new FormControl({ value: this.filingData.anticipatedTravel, disabled: true }),
        advIssuedOnTravelOrCaseImpact: new FormControl({ value: this.filingData.advIssuedOnTravelOrCaseImpact, disabled: true }),
        departureDate: new FormControl({ value: this.filingData.departureDate ? new Date(this.filingData.departureDate) : null, disabled: true }),
        arrivalDate: new FormControl({ value: this.filingData.arrivalDate ? new Date(this.filingData.arrivalDate) : null, disabled: true }),
        //corporationAddress: new FormControl({ value: this.filingData.corporationAddress, disabled: true }, Validators.required),
        //signatoryPrimaryOfficeState: new FormControl({ value: this.filingData.signatoryPrimaryOfficeState, disabled: true }, Validators.required),
        countryId: new FormControl({ value: this.filingData.countryId, disabled: true }),
        recapture: new FormControl({ value: this.filingData.recapture, disabled: true }, [Validators.required,Validators.nullValidator]),
        recaptureTimeAvailable: new FormControl({ value: this.filingData.recaptureTimeAvailable, disabled: true }),
        days: new FormControl({ value: this.filingData.days, disabled: true }),
        months: new FormControl({ value: this.filingData.months, disabled: true }),
        newProposedVisaMax: new FormControl({ value: this.filingData.newProposedVisaMax == null ? null : new Date(this.filingData.newProposedVisaMax), disabled: true }),
        premiumProcessingI907: new FormControl({ value: this.filingData.premiumProcessingI907, disabled: true }),
        emailAddressI907: new FormControl({ value: this.filingData.emailAddressI907, disabled: true }),
        aC21Extension: new FormControl({value: this.filingData.aC21Extension, disabled: true},[Validators.required,Validators.nullValidator]),
        a106a1yrPERM: new FormControl({value: this.filingData.a106a1yrPERM, disabled: true}),
        datePERMFiledwithDOL: new FormControl({ value: this.filingData.datePERMFiledwithDOL == null ? null : new Date(this.filingData.datePERMFiledwithDOL), disabled: true}),
        i485AppFiledWinYrPDBecomingCurr: new FormControl({value: this.filingData.i485AppFiledWinYrPDBecomingCurr, disabled: true}),
        a104c3YearI140: new FormControl({value: this.filingData.a104c3YearI140, disabled: true}),
        dateI140FiledUSCIS: new FormControl({ value:  this.filingData.dateI140FiledUSCIS ? new Date(this.filingData.dateI140FiledUSCIS) : null, disabled: true}),
        priorityDate: new FormControl({ value: this.filingData.priorityDate ? new Date(this.filingData.priorityDate) : null, disabled: true}),
      
        priorityCountryId: new FormControl({value: this.filingData.priorityCountryId, disabled: true}),
        visaBulletinCutoffDate: new FormControl({ value: this.filingData.visaBulletinCutoffDate ? new Date(this.filingData.visaBulletinCutoffDate) : null, disabled: true}),
        dateI140PetitionApproved: new FormControl({ value: this.filingData.dateI140PetitionApproved ? new Date(this.filingData.dateI140PetitionApproved) : null, disabled: true}),
        priorityClassification: new FormControl({value: this.filingData.priorityClassification, disabled: true}),
        priorityDateCurrent: new FormControl({value: this.filingData.priorityDateCurrent, disabled: true}),
        notes: new FormControl({ value: decodedNotes, disabled: true }),
        filingStateOrCountry: new FormControl({ value: this.filingData.filingStateOrCountry, disabled: true }),
        eyPetitioner: new FormControl({ value: this.filingData.eyPetitioner, disabled: true })
      });
      this.isPageReady = true;
      this.subscribeToFormControlValueChanges();
      this.FilingStratergyForm.valueChanges.subscribe((val=>{
        this.logOnChangeValidationErrors();
      }));
    }
    catch (err) {
      console.log(err);
    }
  }


  // private getDynamicControlValue(formGroup: FormGroup, childFormGroup: string){

  //   Object.keys(formGroup.controls).forEach((key: string) => {
  //     const abstractControl = formGroup.get(key);
  //     console.log(abstractControl);     
  //     if(childFormGroup === 'serviceCenter'){
  //       this.filingData.se.filter(p=>p.shortForm == key)[0].isSelected = abstractControl.value;
  //     }
      
  //   });

  // }

  subscribeToFormControlValueChanges() {
    if(this.filingData.anticipatedTravel)
    {
      this.f['advIssuedOnTravelOrCaseImpact'].setValidators([Validators.required,Validators.nullValidator]);
      //this.f['arrivalDate'].setValidators([Validators.required,Validators.nullValidator]);
      //this.f['departureDate'].setValidators([Validators.required,Validators.nullValidator]);
      //this.f['countryId'].setValidators([Validators.required,Validators.nullValidator]);
      
    }

    if(this.filingData.recapture)
    {
      this.f['recaptureTimeAvailable'].setValidators([Validators.required,Validators.nullValidator]);
      this.f['days'].setValidators([Validators.required,Validators.nullValidator]);
      this.f['months'].setValidators([Validators.required,Validators.nullValidator]);
      this.f['newProposedVisaMax'].setValidators([Validators.required,Validators.nullValidator]);

    }
    if(this.filingData.aC21Extension)
    {
      this.f['a106a1yrPERM'].setValidators([Validators.required,Validators.nullValidator]);
      this.f['a104c3YearI140'].setValidators([Validators.required,Validators.nullValidator]);

    }
    this.FilingStratergyForm.updateValueAndValidity();
    this.FilingStratergyForm.get("anticipatedTravel").valueChanges.subscribe(val => {
      if (val) {
        
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["advIssuedOnTravelOrCaseImpact"], false);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["arrivalDate"], false);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["departureDate"], false);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["countryId"], false);

          this.isGridEditable = true;
          if(this.view == null || this.view.length == 0){
            this.view = this.filingtravelTbl;
          }
          this.loadItems();
          //this.view = this.spouseAndDependentMaster;
        
         

        this.filingData.anticipatedTravel = true;
        //this.f['advIssuedOnTravelOrCaseImpact'].setValue(null);
        this.f['advIssuedOnTravelOrCaseImpact'].setValidators([Validators.required,Validators.nullValidator]);
        // this.f['arrivalDate'].setValue(null);
        // this.f['arrivalDate'].setValidators([Validators.required,Validators.nullValidator]);
        // this.f['countryId'].setValue(null);
        // this.f['countryId'].setValidators([Validators.required,Validators.nullValidator]);
        // this.f['departureDate'].setValue(null);
        // this.f['departureDate'].setValidators([Validators.required,Validators.nullValidator]);
        this.FilingStratergyForm.updateValueAndValidity();
      } else {
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["reasonToExpediteCase"], true);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["arrivalDate"], true);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["departureDate"], true);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["countryId"], true);

        this.filingtravelTbl = this.view;
        this.view = []
        this.isGridEditable = false;
        this.loadItems();
      

        this.filingData.anticipatedTravel = false;
        //this.f['advIssuedOnTravelOrCaseImpact'].setValue(null);
        this.f['advIssuedOnTravelOrCaseImpact'].clearValidators();
        //this.f['arrivalDate'].setValue(null);
        this.f['arrivalDate'].clearValidators();
        //this.f['departureDate'].setValue(null);
        this.f['departureDate'].clearValidators();
        //this.f['countryId'].setValue(null);
        this.f['countryId'].clearValidators();
        this.FilingStratergyForm.updateValueAndValidity();
        // this.FilingStratergyForm.patchValue({
        //   reasonToExpediteCase: null,
        // });
      }
    });
    this.FilingStratergyForm.get("recapture").valueChanges.subscribe(val => {
      if (val) {
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["recaptureTimeAvailable"], false);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["days"], false);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["months"], false);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["newProposedVisaMax"], false);

        this.filingData.recapture = true;
        //this.f['recaptureTimeAvailable'].setValue(null);
        this.f['recaptureTimeAvailable'].setValidators([Validators.required,Validators.nullValidator]);
        //this.f['days'].setValue(null);
        this.f['days'].setValidators([Validators.required,Validators.nullValidator]);
        //this.f['months'].setValue(null);
        this.f['months'].setValidators([Validators.required,Validators.nullValidator]);
        //this.f['newProposedVisaMax'].setValue(null);
        this.f['newProposedVisaMax'].setValidators([Validators.required,Validators.nullValidator]);
        this.FilingStratergyForm.updateValueAndValidity();
      } else {
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["recaptureTimeAvailable"], true);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["days"], true);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["months"], true);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["newProposedVisaMax"], true);
        this.filingData.recapture = false;
        //this.f['recaptureTimeAvailable'].setValue(null);
        this.f['recaptureTimeAvailable'].clearValidators();
        //this.f['days'].setValue(null);
        this.f['days'].clearValidators();
        //this.f['months'].setValue(null);
        this.f['months'].clearValidators();
        //this.f['newProposedVisaMax'].setValue(null);
        this.f['newProposedVisaMax'].clearValidators();
        this.FilingStratergyForm.updateValueAndValidity();
        // this.FilingStratergyForm.patchValue({
        //   reasonToDelayCase: null,
        // });
      }
    });
    this.FilingStratergyForm.get("aC21Extension").valueChanges.subscribe(val => {
      if (val) {
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["a106a1yrPERM"], false);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["a104c3YearI140"], false);

        this.filingData.aC21Extension = true;
        //this.f['a106a1yrPERM'].setValue(null);
        this.f['a106a1yrPERM'].setValidators([Validators.required,Validators.nullValidator]);
        //this.f['a104c3YearI140'].setValue(null);
        this.f['a104c3YearI140'].setValidators([Validators.required,Validators.nullValidator]);

        this.FilingStratergyForm.updateValueAndValidity();
      } else {
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["a106a1yrPERM"], true);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["a104c3YearI140"], true);
        this.filingData.aC21Extension = false;
        this.f['a106a1yrPERM'].setValue(false);
        this.f['a106a1yrPERM'].clearValidators();

        //this.f['a104c3YearI140'].setValue(null);
        this.f['a104c3YearI140'].clearValidators();
        this.FilingStratergyForm.updateValueAndValidity();
        
      }
    });
    this.FilingStratergyForm.get("a106a1yrPERM").valueChanges.subscribe(val => {
      if (val) {
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["datePERMFiledwithDOL"], false);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["i485AppFiledWinYrPDBecomingCurr"], false);

        this.filingData.a106a1yrPERM = true;
        //this.f['datePERMFiledwithDOL'].setValue(this.caseRecieptDetails.caseMainReceiptDate?new Date(this.caseRecieptDetails.caseMainReceiptDate):null);
        //this.f['i485AppFiledWinYrPDBecomingCurr'].setValue(null);

        this.FilingStratergyForm.updateValueAndValidity();
      } else {
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["datePERMFiledwithDOL"], true);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["i485AppFiledWinYrPDBecomingCurr"], true);
        this.filingData.a106a1yrPERM = false;
        this.f['datePERMFiledwithDOL'].setValue(null);
        this.f['datePERMFiledwithDOL'].clearValidators();
        this.f['i485AppFiledWinYrPDBecomingCurr'].setValue(false);
        this.f['i485AppFiledWinYrPDBecomingCurr'].clearValidators();
        this.FilingStratergyForm.updateValueAndValidity();
        
      }
    });
    this.FilingStratergyForm.get("a104c3YearI140").valueChanges.subscribe(val => {
      if (val) {
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["dateI140FiledUSCIS"], false);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["dateI140PetitionApproved"], false);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["priorityDate"], false);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["priorityCountryId"], false);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["priorityClassification"], false);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["priorityDateCurrent"], false);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["visaBulletinCutoffDate"], false);

        this.filingData.a104c3YearI140 = true;
        //this.f['dateI140FiledUSCIS'].setValue(this.caseRecieptDetails.caseMainReceiptDate?new Date(this.caseRecieptDetails.caseMainReceiptDate):null);
        //this.f['dateI140PetitionApproved'].setValue(this.caseRecieptDetails.receiptValidFromDate?new Date(this.caseRecieptDetails.receiptValidFromDate):null);
        //this.f['priorityDate'].setValue(this.caseRecieptDetails.casePriorityDate?new Date(this.caseRecieptDetails.casePriorityDate):null);
        //this.f['priorityCountryId'].setValue(this.caseRecieptDetails.casePriorityCountryId);
        //this.f['priorityClassification'].setValue(this.caseRecieptDetails.casePriorityCategory);
        //this.f['priorityDateCurrent'].setValue(null);
        //this.f['visaBulletinCutoffDate'].setValue(null);

        this.FilingStratergyForm.updateValueAndValidity();
      } else {
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["dateI140FiledUSCIS"], true);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["dateI140PetitionApproved"], true);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["priorityDate"], true);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["priorityCountryId"], true);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["priorityClassification"], true);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["priorityDateCurrent"], true);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["visaBulletinCutoffDate"], true);
        this.filingData.a104c3YearI140 = false;
        //this.f['dateI140FiledUSCIS'].setValue(null);
        this.f['dateI140FiledUSCIS'].clearValidators();
        //this.f['dateI140PetitionApproved'].setValue(null);
        this.f['dateI140PetitionApproved'].clearValidators();
        //this.f['priorityDate'].setValue(null);
        this.f['priorityDate'].clearValidators();
        //this.f['priorityCountryId'].setValue(null);
        this.f['priorityCountryId'].clearValidators();

        //this.f['priorityClassification'].setValue(null);
        this.f['priorityClassification'].clearValidators();
        //this.f['priorityDateCurrent'].setValue(null);
        this.f['priorityDateCurrent'].clearValidators();

        //this.f['visaBulletinCutoffDate'].setValue(null);
        this.f['visaBulletinCutoffDate'].clearValidators();

        this.FilingStratergyForm.updateValueAndValidity();
        
      }
    });
  }

  public countryFilterChange(filter: any): void {
    this.CountryList = this.countrySourceList.filter(x => x.countryName.toLowerCase().includes(filter));
  }

  enableEdit() {
    try {
      this.isEditEnabled = true;
      this._dafService.disableEnableAllFormControls(this.FilingStratergyForm, ["advIssuedOnTravelOrCaseImpact", "arrivalDate", "departureDate", "countryId", "recaptureTimeAvailable", "days", "months", "newProposedVisaMax", "datePERMFiledwithDOL", "i485AppFiledWinYrPDBecomingCurr", "a106a1yrPERM", "a104c3YearI140", "dateI140FiledUSCIS", "dateI140PetitionApproved", "priorityDate", "priorityClassification", "priorityCountryId", "priorityDateCurrent", "visaBulletinCutoffDate"], false);

      if (this.filingData.anticipatedTravel) {
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["advIssuedOnTravelOrCaseImpact"], false);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["arrivalDate"], false);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["departureDate"], false);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["countryId"], false);      }
      if (this.filingData.recapture) {
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["recaptureTimeAvailable"], false);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["days"], false);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["months"], false);
        this._dafService.disableEnableFormControl(this.FilingStratergyForm, ["newProposedVisaMax"], false);
      }
    }
    catch (err) {
      console.log(err);
    }
  }


  private loadItems(): void {
    this.gridView = {
      data: this.view.slice(this.skip, this.skip + this.pageSize),
      total: this.view.length,
    };
  }
  public addHandler(): void {
    this.editDataItem = new FilingTableDetail();
    this.editDataItem.filingTravelID = 0;
    this.editDataItem.arrivalDate = null;
    this.editDataItem.departureDate = null;
    this.editDataItem.countryId = null;
    //this.closeEditor();

    //this.formGroup = this.createFormGroup({
    //    departureDate: null,
    //    arrivalDate: null,
    //    countryId: null,
    //    filingTravelID:0
    //});
    this.isNew = true;

    //this.grid.addRow(this.formGroup);
  }

  public saveRow(): void {
    if (this.formGroup && this.formGroup.valid) {
      this.saveCurrent();
    }
  }

 

  public cellClickHandler({
    isEdited,
    dataItem,
    rowIndex,
  }: CellClickEvent): void {
    if (isEdited || (this.formGroup && !this.formGroup.valid) || !this.isGridEditable || this.isDeleteClicked) {
      this.isDeleteClicked = false;
      return;
    }

    if (this.isNew) {
      rowIndex += 1;
    }

    this.saveCurrent();

    this.formGroup = this.createFormGroup(dataItem);
    this.editedRowIndex = rowIndex;

    this.grid.editRow(rowIndex, this.formGroup);
  }

  public cancelHandler(): void {
    this.closeEditor();
  }

  private closeEditor(): void {
    this.grid.closeRow(this.editedRowIndex);

    this.isNew = false;
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  private saveCurrent(): void {
    if (this.formGroup) {
      // this.service.save(this.formGroup.value, this.isNew);
      let maxId: number = Math.max.apply(Math, this.view.map(function (o) { return o.filingTravelID; }));
      let sdDetails = this.formGroup.value;
      let index = maxId + 1;
      if (this.isNew) {       
        sdDetails.filingTravelID = index;
        sdDetails.isNew = true;
        this.view.splice(0, 0, sdDetails);
      } else {
        Object.assign(
          this.view.find(({ filingTravelID }) => filingTravelID === sdDetails.filingTravelID),
          sdDetails
        );      
      }
      this.closeEditor();
      this.loadItems();
    }
  }

  createFormGroup(dataItem) {
    return new FormGroup({
      arrivalDate: new FormControl({ value: dataItem.arrivalDate ? new Date(dataItem.arrivalDate) : null, disabled: true }, Validators.required),
      departureDate: new FormControl({ value: dataItem.departureDate ? new Date(dataItem.departureDate) : null, disabled: true }, Validators.required),
      countryId: new FormControl(dataItem.countryId),
      filingTravelID: new FormControl(dataItem.filingTravelID)
    });
  }

  unlockFields() {
    try {
      this.isUnlocked = true;
      this._dafService.disableEnableFormControl(this.FilingStratergyForm,["employeeEmail"], false);
    }
    catch (err) {
      console.log(err);
    }
  }

  lockFields() {
    try {
      this.isUnlocked = false;
      this._dafService.disableEnableFormControl(this.FilingStratergyForm,["employeeEmail"], true);
    }
    catch (err) {
      console.log(err);
    }
  }

  saveFilingStratergyDetails() {
    this.isPageReady = false;
    this.logValidationErrors();
    this.isPageReady = true;
   
    this.readFormValues(this.FilingStratergyForm);
    this.filingData.filingTravelDetails=this.view;
    if (this.FilingStratergyForm.valid) {
      // if (this.filingData.visaApptDate && this.filingData.visaApptDate !== null) {
      //   var userTimezoneOffset = new Date(this.filingData.visaApptDate).getTimezoneOffset() * 60000;
      //   this.filingData.visaApptDate = new Date(this.filingData.visaApptDate.getTime() - userTimezoneOffset);
      // }
      this.filingData.notes = this.filingData.notes ? this.encodeHTMLEntities(this.filingData.notes) : null;
      this._dafService.saveFilingStratergyDetails(this.caseId,this.filingData).subscribe((data: any) => {
        this._dafService.disableEnableAllFormControls(this.FilingStratergyForm, [], true);
        this.isEditEnabled = false;
        this.isUnlocked = false;
        this.page.emit(1);
        this.isPageReady = true;
        this.notifier.notify('success', 'Filing Strategy details have been saved successfully');
      },
        (error) => {
          this.isPageReady = true;
          this.notifier.notify('error', 'Error occured while saving case summary details');
        });
    }
    else {
      this.notifier.notify('error', 'All fields are required and should have data');
      this.isPageReady = true;
    }

    if(this.FilingStratergyForm.valid){
      
    }
    else{
      this.logValidationErrors();
    }

  }

  private readFormValues(group: FormGroup) {
    Object.keys(this.FilingStratergyForm.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
       // this.getDynamicControlValue(abstractControl,key);
      }
      else {
        this.filingData[key] = abstractControl.value;
      }
    });
  }
  
  

  resetForm() {
    try {
      this.fetchfilingData(this.caseId);
      this.isEditEnabled = false;
      this.isUnlocked = false;
      this._dafService.disableEnableAllFormControls(this.FilingStratergyForm, ["supportTeamMember"],true);
    }
    catch (err) {
      console.log(err);
    }
  }

  get f() {
    return this.FilingStratergyForm.controls;
  }

  logValidationErrors(group: FormGroup = this.FilingStratergyForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid
            // && (abstractControl.touched || abstractControl.dirty)
            ) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

    logOnChangeValidationErrors(group: FormGroup = this.FilingStratergyForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid
             && (abstractControl.touched || abstractControl.dirty)
            ) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  setCSPanelState() {
    this.collapseCSPanel.emit(0);
  }

  searchUserDetails(email: any) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase().trim();
    if (filterValue) {
      this._dafService.searchSupportUserDetails(filterValue).subscribe((data: any) => {
        this.searchDetails = data.value;
      },
        (error) => {
          this.notifier.notify('error', 'Error occured while searching the user details');
        });
    }

  }

  selectAddUserDetails(email: any) {
    try {
      this.addUserMail = email.mail;
      this.addUserName = email.displayName;
      this.autocomplete.reset();
    }
    catch (err) {
      console.log(err);
    }
  }

  ngOnDestroy(): void {
    this.FilingStratergyForm.get("expediteCase").valueChanges.subscribe().unsubscribe();
    this.FilingStratergyForm.get("placeOnHold").valueChanges.subscribe().unsubscribe();
  }

  openWizard(){
    this.isActivate=true;
  }

  
  public cancelHandlers(): void {
    this.isActivate=false;  }

  getServiceCodes(){
    this._dafService.GetServiceCodes().subscribe((data: any) => {
      this.serviceCodes = data;
      this.serviceCodesList = data;

    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
  }

  getCaseRecieptDetails(){
    this._dafService.getCaseRecieptDetails(this.caseId).subscribe((data: any) => {
      this.caseRecieptDetails = data;

    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
  }


  public setDependentIndexToDelete(args: any){
    this.isDeleteClicked = true;
    this.deleteIndex = args.rowIndex;
    this.showDialog = true;
    //this.view.splice(args.rowIndex, 1);
    //this.loadItems();
  }

  public deleteDependent(){
    this.view.splice(this.deleteIndex, 1);
    this.loadItems();
    this.showDialog = false;
  }

  public cancelDelete(){
    this.showDialog = false;
  }

  public onDialogClose(){
    this.showDialog = false;
  }

  GetZipcodes(event, index: any): Promise<any> {
    this.zipcodeList[index] = [];
    this.zipcodeSource[index] = [];
    let res = this._addLCAService.GetZipcodes(event).toPromise().then((data: any) => {
      if (data.length > 0) {
        data.forEach(element => {
          this.zipcodeList[index].push(element.zipcode.toString());
          this.zipcodeSource[index].push(element.zipcode.toString());
        });
      }
      return Promise.resolve(data);
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
    this._dafService.disableEnableFormControl(this.FilingStratergyForm,["signatoryZipcode"],false);
    return res;
  }

  GetCountyInfo(event, index: any): Promise<any>  {
    this.cityArray[index]=[];
    this.countyArray[index]=[];
    let res = this._addLCAService.GetCountyInfo(event).toPromise().then((data: any) => {
      if (data.length > 0) {
        this.FilingStratergyForm.controls["signatoryCity"].setValue(data[0].cityName);
        this.FilingStratergyForm.controls["signatoryCounty"].setValue(data[0].countyName);
        this.FilingStratergyForm.updateValueAndValidity();
      }
      return Promise.resolve(data);
    },
      (error) => {
      }
    );
    return res;
  }

  public filterChange(filter: any): void {
    if(filter.length>2){
      this.serviceCodesList = this.serviceCodes.filter(x=>x.stateName.toLowerCase().includes(filter.toLowerCase()));
    }
  }

  public zipFilterChange(filter: any,index:any): void {
    this.zipcodeList[index] = this.zipcodeSource[index].filter(
      (s) => s.toString().startsWith(filter)
    );
  }

  encodeHTMLEntities(text) {
    let textArea = document.createElement('textarea');
    let escapeQuotes = text;
    if (text) {
      escapeQuotes = text.replace(/['"()]/g, escape);
    }

    textArea.innerText = escapeQuotes;
    let encodedOutput = textArea.innerHTML;
    let arr = encodedOutput.split('<br>');
    encodedOutput = arr.join('\n');
    return encodedOutput;
  }

  decodeHTMLEntities(text) {
    let textArea = document.createElement('textarea');
    let unescapeQuotes = text;
    if (text) {
      unescapeQuotes = text.replace("%27", unescape);
      unescapeQuotes = unescapeQuotes.replace("%22", unescape);
      unescapeQuotes = unescapeQuotes.replaceAll("%28", unescape);
      unescapeQuotes = unescapeQuotes.replaceAll("%29", unescape);
    }
    textArea.innerHTML = unescapeQuotes;
    return textArea.value;
  }

  public saveHandler(product: FilingTableDetail): void {
    if (product) {
      product.arrivalDate = product.arrivalDate ? new Date(product.arrivalDate) : null;
      product.departureDate = product.departureDate ? new Date(product.departureDate) : null;
      product.countryId = product.countryId ? product.countryId : null;
    }
    if (this.isNew) {
      this.view.splice(0, 0, product);
      this.modifiedRecords.push(product);
    }
    else {
      Object.assign(
        this.view.find(({ filingTravelID }) => filingTravelID === product.filingTravelID),
        product
      );

      if (this.modifiedRecords.find(({ filingTravelID }) => filingTravelID === product.filingTravelID)) {
        Object.assign(
          this.modifiedRecords.find(({ filingTravelID }) => filingTravelID === product.filingTravelID),
          product
        );
      }
      else {
        this.modifiedRecords.push(product);
      }
    }

    this.editDataItem = undefined;
    this.isNew = false;
    this.loadItems();
  }

  public editHandler(args: AddEvent): void {
    this.editDataItem = args.dataItem;

    this.isNew = false;
  }

  getFilingVenue() {
    this._dafService.GetFilingVenue().subscribe((data: any) => {
      this.filingVenue = data;
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
  }
}
