import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FilingTableDetail } from '../../../Models/FilingStratergy';
import { DafService } from 'src/app/DAF/services/dafService';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-filing-travel-modal',
  templateUrl: './filing-travel-modal.component.html',
  styleUrls: ['./filing-travel-modal.component.scss']
})
export class FilingTravelModalComponent implements OnInit {
  public active = false;
  countrySourceList: Array<any> = [];
  countryList: Array<any> = [];
  @Output() cancel: EventEmitter<undefined> = new EventEmitter();
  @Output() save: EventEmitter<FilingTableDetail> = new EventEmitter();
  @Input() public isNew = false;
  private notifier: NotifierService;
  @Input() public set model(product: FilingTableDetail) {
    this.editForm.reset(product);
    // toggle the Dialog visibility
    this.active = product !== undefined;
  }
  constructor(notifier: NotifierService, private _dafService: DafService) { this.notifier = notifier; }

  ngOnInit(): void {
    this.getCountryList();
  }

  public editForm: FormGroup = new FormGroup({
    filingTravelID: new FormControl(0),
    arrivalDate: new FormControl(null, Validators.required),
    departureDate: new FormControl(null, Validators.required),
    countryId: new FormControl(null, Validators.required),
  });

  getCountryList() {
    this._dafService.getCountriesCitizenship().subscribe((data: any) => {
      this.countryList = data;
      this.countrySourceList = data;
    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
  }

  public onCancel(e: PointerEvent): void {
    e.preventDefault();
    this.closeForm();
  }

  public closeForm(): void {
    this.active = false;
    this.cancel.emit();
  }

  public onSave(e: PointerEvent): void {
    e.preventDefault();
    if (this.editForm.get('departureDate').value > this.editForm.get('arrivalDate').value) {
      this.notifier.notify('error', 'Arrival date cannot be less than Departure Date');
      return;
    }
    this.save.emit(this.editForm.value);
    this.active = false;
  }

  public countryFilterChange(filter: any): void {
    this.countryList = this.countrySourceList.filter(x => x.countryName.toLowerCase().includes(filter));
  }
}
