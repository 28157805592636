export class spouseAndDependentDetails {
    isDependentExist: boolean;   
    relationDetails: Array<relationDetail> = [];
    notes: string;
}

export class relationDetail {
    relation: string;
    firstName: string;
    middleName: string;
    lastName: string;
    isSpouseEmployedFollowingLatestEntry: boolean;
    i539Required: boolean;
    i765Required: boolean;
    i131Required: boolean;
    spouseAndDependentNotes: string;
    spouseDepAppID: number;
    relationId: number;
    isNew: boolean;
}
