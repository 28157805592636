import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DAFcHomeComponent } from './dafc-home/dafc-home.component';


const routes: Routes = [
  {
    path: 'dafc-home', component: DAFcHomeComponent
  }
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DAFHomeRoutingModule { }
