import { Component, OnInit, Input, Output, ViewChild, EventEmitter, ElementRef, Inject } from '@angular/core';
import { LcaData, WorkSiteData, MissingLCA } from '../model/Lca.model';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { NotifierService } from 'angular-notifier';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { LCAService } from '../service/lca.service';
import { NgForm } from '@angular/forms';
import { AddLCAService } from '../../add-lca/service/add-lca.service';
import { NotificationService } from '@progress/kendo-angular-notification';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-lca-compliance',
  templateUrl: './edit-lca-compliance.component.html',
  styleUrls: ['./edit-lca-compliance.component.scss']
})
export class EditLcaComplianceComponent implements OnInit {
 public Lcadata:WorkSiteData=new WorkSiteData();
  @Input() 
  public set Lcadatas(val: any) {
    this.Lcadata = val;
    this.InitializeData(this.Lcadata);
    }
  
  @Output() updateLCAList = new EventEmitter<any>();
  @ViewChild('closeButton',  {static: false}) closeButton: ElementRef;
  @ViewChild('addcloseButton',  {static: false}) addcloseButton: ElementRef;
  @ViewChild('notification', {static: false}) customNotificationTmpl;
  errorMessage: string;
  public editLcaForm: FormGroup;
  public searchDetails: any;
  public pageSize = 10;
  public skip = 0;
  public gridState: DataStateChangeEvent = {
    skip: this.skip,
    take: this.pageSize
  };
  private notifier: NotifierService;
  public statesList=[];
  public zipcodeList=[];
  public state=""
  public statesListSource=[];

  public startDate;
  public zipcodeSource=[];
  public disabled = true;
  public countydisabled=true;
  public citydisabled = true;
  public selectedType=[];
  public cityArray=[];
  public countyArray = [];
  public showConfirmation = false;
  public lcaNumber="";
public expiryDate;
public zipCode="";
public city="";
public county="";
public streetAddress="";
  public isReady=false;
  public houseItems: Array<string> = [
    "Apt.",
    "Ste.",
    "Flr.",
    "Door"
  ];
  public WorkSiteDetails:WorkSiteData[]=[];
  cityDataArray: any[]=[];
  private hideAfter = 2000;
  countyDataArray: any[]=[];
  constructor(
    private addLCAService: AddLCAService,
    notifierService: NotifierService,
    @Inject(NotificationService) private notificationService: NotificationService
  ) {
    this.notifier = notifierService;
    }

  ngOnInit() {
     this.GetStates();

  }

  optionalFormControls = ["middleName", "signatoryName", "signatoryTitle", "signatoryTelephone", "apt"];
  formErrors = {
    
    'streetAddress': '',
    'clientName': '',
    'state': '',
    'zipcode': '',
    'startDate': '',
    'workfrom': ''
  };

  validationMessages = {

   
    'streetAddress': {
      'required': 'Street Address is required.',
    },
    'clientName': {
      'required': 'Client Name is required.',
    },
    'state': {
      'required': 'State is required.',
    },
    'zipcode': {
      'required': 'Zipcode is required.',
    },

    'startDate': {
      'required': 'Start Date  is required.',
    },
    'workfrom': {
      'required': 'Period of Work From  is required.',
    },
  };


 closeForm() {

  const closeButton: HTMLElement = this.closeButton
    .nativeElement as HTMLElement;
  closeButton.click();
}








GetStates() {

  this.addLCAService.getStates().subscribe((data: any) => {
    this.statesList = data;
    this.statesListSource = data;
  },
    (error) => {
      //this.notifier.notify('error', 'Error occured while updating the user details');
    }
  );
}

InitializeData(lcaData){
  this.addLCAService.GetZipcodes(lcaData.state).subscribe((data: any) => {
    this.zipcodeList = data;
    this.zipcodeSource = data;
    
    this.disabled = false;
    this.GetCountyInfo(lcaData.zipCode);
  });
}


GetZipcodes(event) {  
  this.addLCAService.GetZipcodes(event).subscribe((data: any) => {
    this.zipcodeList = data;
    this.zipcodeSource = data;
    this.disabled = false;
  },
    (error) => {
      //this.notifier.notify('error', 'Error occured while updating the user details');
    }
  );
}


GetCountyInfo(event) {
  this.cityArray=[];
  this.countyArray=[];
  
  this.addLCAService.GetCountyInfo(event).subscribe((data: any) => {
    var newData = data;
    if(newData.length>0){
   this.cityArray.push(
     newData[0].cityName
   );
  }
   if(this.cityArray.length>0)
   {
   this.citydisabled=false;
   }

   newData.forEach(element => {
     this.countyArray.push(
       element.countyName
     );
   });
   if(this.countyArray.length>0){
    this.countydisabled=false;
   }
   this.cityDataArray=this.cityArray;
   this.countyDataArray = this.countyArray;

  },
    (error) => {
      //this.notifier.notify('error', 'Error occured while updating the user details');
    }
  );
}

public disabledDates = (date: Date): boolean => {
  return date <= new Date();
}

public disabledEndDates = (date: Date): boolean => {
  return date <= this.startDate;
}

onEndDateChanges(event,index){
  if(this.Lcadata.addressType=="Third Party Placement or Client Worksite")
  {
    
      this.Lcadata.workTo=event;
      this.onEndDateChange(event);
    }
    else{
      this.Lcadata.workTo=null;
    }
  

}

onstartDateChanges(event){
  if(this.Lcadata.addressType=="Third Party Placement or Client Worksite")
  {
    this.Lcadata.workFrom=event;
    this.onStartsDateChange(event);
  }
  else{
    this.Lcadata.workFrom=null;
  }
}

onEndDateChange(event){
  var data=null;

  if(this.Lcadata.workTo!=null && this.Lcadata.workFrom!=null){
     data = Math.round((this.Lcadata.workTo.getDate() - this.Lcadata.workFrom.getDate()));
    }
    else{
      data=null;
    } 
  this.Lcadata.toalDays=data;

}

onStartsDateChange(event){
  var data=null;

  if(this.Lcadata.workTo!=null && this.Lcadata.workFrom!=null){
     data = Math.round((this.Lcadata.workTo.getDate() - this.Lcadata.workFrom.getDate()));
    }
    else{
      data=null;
    } 

  this.Lcadata.toalDays=data;
}

public filterChange(filter: any): void {
  this.statesList= this.statesListSource.filter(
    (s) => s.states.toLowerCase().startsWith(filter.toLowerCase()) 
  );
}

public zipFilterChange(filter: any): void {
  this.zipcodeList = this.zipcodeSource.filter(
    (s) => s.zipcode.toString().startsWith(filter)
  );
}

public cityFilterChange(filter: any): void {
  this.cityArray = this.cityDataArray.filter(
    (s) => s.city.toString().startsWith(filter)
  );
}

public countyFilterChange(filter: any): void {
  this.countyArray = this.countyDataArray.filter(
    (s) => s.county.toString().startsWith(filter)
  );
}

addcloseForm() {
  this.Lcadata=new WorkSiteData();
  const addcloseButton: HTMLElement = this.addcloseButton
    .nativeElement as HTMLElement;
  addcloseButton.click();
}

  public addLCA() {
    if (this.Lcadata.state == "" || this.Lcadata.startDate == null || this.Lcadata.zipCode == "" || this.Lcadata.streetAddress == "") {
    this.notificationService.show({
      content: "Please ensure to add all the required Data",
      position: { horizontal: "right", vertical: "top" },
      animation: { type: "fade", duration: 800 },
      type: { style: "warning", icon: true },
      hideAfter: this.hideAfter,
    });
    }
    else if (this.Lcadata.startDate && this.Lcadata.expiryDate && this.Lcadata.startDate > this.Lcadata.expiryDate) {
      this.notificationService.show({
        content: "Please enter valid dates. End Date should be greather than or equal to Start Date.",
        position: { horizontal: "right", vertical: "top" },
        animation: { type: "fade", duration: 800 },
        type: { style: "warning", icon: true },
        hideAfter: this.hideAfter,
      });
    }
  else if(this.Lcadata.addressType=="Third Party Placement or Client Worksite" &&(this.Lcadata.workFrom==null||this.Lcadata.clientName=="")){
    this.notificationService.show({
      content: "Please ensure to add all the required Data",
      position: { horizontal: "right", vertical: "top" },
      animation: { type: "fade", duration: 800 },
      type: { style: "warning", icon: true },
      hideAfter: this.hideAfter,
    });
   
    }
    else if (this.Lcadata.addressType == "Third Party Placement or Client Worksite" && this.Lcadata.workFrom && this.Lcadata.workTo && this.Lcadata.workFrom > this.Lcadata.workTo) {
      this.notificationService.show({
        content: "Please enter valid dates. Period of Work From should be greather than or equal to Period of Work To.",
        position: { horizontal: "right", vertical: "top" },
        animation: { type: "fade", duration: 800 },
        type: { style: "warning", icon: true },
        hideAfter: this.hideAfter,
      });
    }
  else{
 this.updateLCAList.emit(this.Lcadata);
 this.addcloseForm();
  }
}

  logValidationErrors(group: FormGroup = this.editLcaForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid
          // && (abstractControl.touched || abstractControl.dirty)
        ) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  logOnChangeValidationErrors(group: FormGroup = this.editLcaForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid
          && (abstractControl.touched || abstractControl.dirty)
        ) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }


}
