import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output } from '@angular/core';
import { WorkSiteData } from '../lca-compliance/model/Lca.model';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { LCAService } from '../lca-compliance/service/lca.service';
import { AddLCAService } from '../add-lca/service/add-lca.service';


@Component({
  selector: 'app-lca-review',
  templateUrl: './lca-review.component.html',
  styleUrls: ['./lca-review.component.scss']
})
export class LcaReviewComponent implements OnInit {

  @ViewChild('openEditModalButton', { static: false }) openEditModalButton: ElementRef;
  @Input() workSiteDetails:WorkSiteData[]=[];
  public isPageReady = true;
  public pageSize = 10;
  public skip = 0;
  public roleArray=[];
  public gridViewSearch: any;
  public WorkSiteDetails=[];
  public selectedLCA:WorkSiteData= new WorkSiteData();
  public gridView: any;
  public searchName = '';
  public emailId="";
  public gridState: DataStateChangeEvent = {
    skip: this.skip,
    take: this.pageSize
  };  
  @Output() page = new EventEmitter<number>();
  @Input() lcaType="";
  constructor(private lcaService: LCAService,private addLCAService:AddLCAService) { }

  ngOnInit() {
    this.dataStateChange(this.gridState);
    this.getEmailId();
  }

  editUser(lcaData: WorkSiteData , rowIndex: number) {
    lcaData["index"]=rowIndex;
    this.selectedLCA = Object.assign({}, lcaData);
    this.openEditModalButton.nativeElement.click();
  }

  dataStateChange(state: DataStateChangeEvent){
    this.isPageReady = false;
    this.gridState = state;
    this.gridViewSearch = this.workSiteDetails;

      this.gridView = process(this.gridViewSearch, this.gridState);
      this.isPageReady = true;
  }

  getEmailId(){
    this.addLCAService.currentData.subscribe((data: any) => {
      // Binds the data to grid
      this.emailId=data.emailId;
    });
  }


  updateLCALists(lcaData: WorkSiteData){
    var dataIndex=this.gridView.data.indexOf(lcaData['index']);
    this.gridView.data[dataIndex]=lcaData;
    this.addLCAService.setLCAData(this.gridView.data);
  }


  saveLCAData() {
    this.isPageReady = false;
    this.workSiteDetails=[];
    this.gridView.data.forEach(item => {
      var workSite=new WorkSiteData();
      workSite.county=item.county;
      workSite.startDate=item.startDate;
      workSite.expiryDate = item.expiryDate==""?null:item.expiryDate;
      workSite.zipCode = item.zipCode;
      workSite.streetAddress = item.streetAddress instanceof Object?item.streetAddress.streetAddress:item.streetAddress;
      workSite.streetNumber=item.streetNumber;
      workSite.houseNumber=item.houseNumber;
      workSite.houseName=item.houseName;
      workSite.city = item.city;
      workSite.clientName = item.clientName;
      workSite.workFrom=item.workFrom==""?null:item.workFrom;
      workSite.workTo=item.workTo==""?null:item.workTo;
      workSite.toalDays=item.toalDays?item.toalDays.toString():"";
      workSite.telePhone=item.telePhone;
      workSite.engagementCode = item.engagementCode;
      workSite.interactionId=item.interactionId;
      workSite.engagementPartner=item.engagementPartner;
      workSite.experienceManager=item.experienceManager;    
      workSite.state=item.state;
      workSite.addressType = item.addressType;
      this.WorkSiteDetails.push(workSite);
    });


    this.lcaService.insertWorkSite(this.WorkSiteDetails,this.emailId).subscribe((data: any) => {
      if (data.message != "") {
        this.addLCAService.resetUser();
      this.page.emit(data.message);
      this.isPageReady = true;
      }

    });
  }

  goBack() {
    let par: any = "back";
    this.page.emit(par);
  }
}
