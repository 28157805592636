import { Component, OnInit } from '@angular/core';
import { AddLCAService } from '../add-lca/service/add-lca.service';
import { WorkSiteRequestCount } from '../lca-compliance/model/Lca.model';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-lca-internal-dashboard',
  templateUrl: './lca-internal-dashboard.component.html',
  styleUrls: ['./lca-internal-dashboard.component.scss']
})
export class LcaInternalDashboardComponent implements OnInit {
  public workSiteDataCounts:WorkSiteRequestCount[]=[];
  public H1bComplianceandRepostCount=0;
  public H1bCompliancewithoutRepostCount=0;
  public H1bComplianceRequiredCount=0;
  public submittedRequestCount=0;
  public UnableToDetermineCount=0;
  public RepostingCompletedCount=0;
  public isPageReady=false;
  constructor(public addLCAService: AddLCAService,private router: Router) { }

  ngOnInit() {
    this.getWorkSiteRequestCount();

  }


  getWorkSiteRequestCount() {

    this.addLCAService.getWorkSiteRequestCount().subscribe((data: any) => {
      this.workSiteDataCounts=data;
      this.isPageReady=true;
      if(this.workSiteDataCounts.length>0){
        this.H1bComplianceandRepostCount=this.workSiteDataCounts.filter(item=>item.ticketStatus=='No Action Required')[0].count;
        this.H1bCompliancewithoutRepostCount=this.workSiteDataCounts.filter(item=>item.ticketStatus=='LCA Re-Posting Required')[0].count;
        this.H1bComplianceRequiredCount=this.workSiteDataCounts.filter(item=>item.ticketStatus=='H-1B Amendment Required')[0].count;
        this.submittedRequestCount=this.workSiteDataCounts.filter(item=>item.ticketStatus=='Submitted')[0].count;
        this.UnableToDetermineCount = this.workSiteDataCounts.filter(item => item.ticketStatus =='More Info Required')[0].count;
        this.RepostingCompletedCount=this.workSiteDataCounts.filter(item=>item.ticketStatus=='Reposting Completed')[0].count;
      }

    },
      (error) => {
        //this.notifier.notify('error', 'Error occured while updating the user details');
      }
    );
  }

  opneEYLCAasessment(status:string){
    if(status =="Reposting Completed"){
      this.router.navigate(['/Repostings'], { queryParams: { status: status } })
    }
    else{
    this.router.navigate(['/EyLca'], { queryParams: { status: status } })
    }
  }

  

}
