import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { MissingLCA, FinalDetermination } from '../../lca-compliance/model/Lca.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddLCAService {
  //private dataSource = new BehaviorSubject({emailId: 'Ashish.Kudva@ey.com',id:1});
  private dataSource = new BehaviorSubject({});
  private engagementdataSource = new BehaviorSubject({engagementName:'Ernst & Young U.S. LLP',id:1});
  currentData = this.dataSource.asObservable();
  engagementData =this.engagementdataSource.asObservable();
  mySubject : BehaviorSubject<any> = new BehaviorSubject<any>([]);
  addedLCAData=this.mySubject.asObservable();

  constructor(private _http: HttpClient) { }
  
  getStates() {
    return this._http.get<any>(environment.ApiUrl + 'LCAData/GetStates');
  }

  GetZipcodes(state) {
    return this._http.get<any>(environment.ApiUrl + 'LCAData/GetZipcodes?state=' + state);
  }
  public changeUser(data: any) {
    if (this.dataSource.value["emailId"] != data.emailId) {
      this.mySubject = new BehaviorSubject<any>([]);
      this.addedLCAData = this.mySubject.asObservable();
    }
    this.dataSource.next(data);
    
  }

  public resetUser() {
    this.dataSource.next([]);
  }

  public changeEngagement(data: any) {
    this.engagementdataSource.next(data);
  }

  public setLCAData(data : any){
    this.mySubject.next(data);
   }

  GetCountyInfo(zipcode) {
    return this._http.get<any>(environment.ApiUrl + 'LCAData/GetCountyInfo?zipCode=' + zipcode);
  }

  getLCAHistory(requestId) {
    return this._http.get<any>(environment.ApiUrl + 'LCAData/GetLCAHistoryData?requestId='+requestId);
  }

  GetWorkSiteData(requestId) {
    return this._http.get<any>(environment.ApiUrl + 'LCAData/GetWorkSiteData?requestId=' + requestId);
  }

  GetWorkSiteDeterminationDetails(requestId) {
    return this._http.get<any>(environment.ApiUrl + 'LCAInternalData/GetWorkSiteDeterminationDetails?requestId=' + requestId);
  }

  getLawyerList() {
    return this._http.get<any>(environment.ApiUrl + 'LCAInternalData/GetLawyerList');
  }


  GetAddressType() {
    return this._http.get<any>(environment.ApiUrl + 'LCAData/GetAddressTypes');
  }

  saveFinalDetermination(determinations:FinalDetermination){
    return this._http.post<any>(environment.ApiUrl + 'LCAInternalData/UpdateFinalDetermination',determinations, {});
  }

  updateRepostStatus(requestId:string, clientRemarks:string){
    return this._http.post<any>(environment.ApiUrl + 'LCAInternalData/UpdateRepostStatus?requestId='+requestId+'&clerkRemarks='+clientRemarks, {});
  }

  GetLCAAddress(zipCode,emailId) {
    return this._http.get<any>(environment.ApiUrl + 'LCAData/GetLCAAddress?zipCode=' + zipCode+'&emailId='+emailId);
  }

  addMissingLCA(lcaInfo:MissingLCA){
    return this._http.post<any>(environment.ApiUrl + 'LCAData/InserMissingLCA',lcaInfo, {});
  }

  getWorkSiteRequestCount(){
    return this._http.get<any>(environment.ApiUrl + 'LCAInternalData/GetWorkSiteRequestCount');
  }
  updateLCANumber(lcaInfo) {
    return this._http.post<any>(environment.ApiUrl + 'LCAInternalData/UpdateLCANumberForWorksite', lcaInfo, {});
  }
  getCaseManagerList() {
    return this._http.get<any>(environment.ApiUrl + 'LCAInternalData/GetAllCaseManager');
  }

  getActiveLCA(requestId: any) {
    return this._http.get<any>(environment.ApiUrl + 'LCAInternalData/GetActiveLCADetails?requestId=' + requestId);
  }
}
