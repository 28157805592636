import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { DocumentExtractionModel } from '../Model/DocumentExraction.Model';
@Injectable({
  providedIn: 'root'
})
export class CourierPackageService {
  public headers = new HttpHeaders ({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  });
  public options = {
    headers: this.headers
  };
  constructor(private _http: HttpClient) { }
  getDocumentDetails() {
    return this._http.get<any>(environment.ApiUrl + 'Courier/GetAllCourierPackageDetails' , {});
  }


  getDocumentSuccessDetails(incomingDate:any){
    return this._http.get<any>(environment.ApiUrl + 'Courier/GetAllCourierPackageSuccessDetails?incomingDate=' + incomingDate , {});
  }


  getDocumentFailureDetails(incomingDate:any){
    return this._http.get<any>(environment.ApiUrl + 'Courier/GetAllCourierPackageFailureDetails?incomingDate=' + incomingDate , {});
  }


  getdocumentInfoById(documentId:number){
    return this._http.get<DocumentExtractionModel>(environment.ApiUrl + 'Courier/GetDocumentExtractionInfoByID?documentId=' + documentId , {});
  }


  getDocumentSuccessInfo(incomingDate:any){
    return this._http.get<any>(environment.ApiUrl + 'Courier/GetCourierPackageSuccessInfo?incomingDate=' + incomingDate , {});
  }

  getDocumentPendingDetails(incomingDate: any) {
    return this._http.get<any>(environment.ApiUrl + 'Courier/GetAllCourierPackagePendingDetails?incomingDate=' + incomingDate, {});
  }
  getDocumentAccessFailureDetails(incomingDate: any) {
    return this._http.get<any>(environment.ApiUrl + 'Courier/GetCPAccessFailureDetails?incomingDate=' + incomingDate, {});
  }
  SaveDocumentExtraction(documentExtractionData: any) {
    return this._http.post<any>(environment.ApiUrl + 'Courier/SaveDocumentExtraction', documentExtractionData, {});
  }

}
