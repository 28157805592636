

export class filingStratergyDetails {
    caseSummaryId: string = null;
    h1B2ndOrSubsExt: boolean = false;
    l1ClassificationId: number = 0;
    caseTypeId: number = 0;
    filingVenueId: number = 0;
    filingAddress: string = null;
    visaApptDate: Date = null;
    serviceCenterCodeId: number = 0;
    amenDwOrNoExt: boolean = false;
    anticipatedTravel: boolean = false;
    advIssuedOnTravelOrCaseImpact: boolean = false;
    departureDate: Date = null;
    arrivalDate: Date = null;
    countryId: number = 0;
    recapture: boolean = false;
    recaptureTimeAvailable : boolean = false;
    days: number = 0;
    months: number = 0;
    newProposedVisaMax: Date = null;
    premiumProcessingI907: boolean = null;
    emailAddressI907: string = null;
    aC21Extension: boolean = null;
    a106a1yrPERM: boolean = null;
    datePERMFiledwithDOL: Date = null;
    i485AppFiledWinYrPDBecomingCurr: boolean = false;
    a104c3YearI140: boolean = null;
    dateI140FiledUSCIS: Date = null;
    priorityDate: Date = null;
    priorityCountryId: number = null;

    visaBulletinCutoffDate: Date = null;
    dateI140PetitionApproved: Date = null;
    priorityClassification: string = null;
    priorityDateCurrent: boolean = null;
    notes:string=null;
    filingTravelDetails: Array<FilingTableDetail> = [];
    filingStateOrCountry: string = null;
    eyPetitioner: boolean = null;
    constructor(obj?: any) {
        try{
            if (obj) {
                this.caseSummaryId = obj.caseSummaryId ? obj.caseSummaryId : null;
                this.h1B2ndOrSubsExt = obj.h1B2ndOrSubsExt ? obj.h1B2ndOrSubsExt : false;
                this.l1ClassificationId = obj.l1ClassificationId ? obj.l1ClassificationId : null;
                this.caseTypeId = obj.caseTypeId ? obj.caseTypeId : null;
                this.filingVenueId = obj.filingVenueId ? obj.filingVenueId : null;
                this.filingAddress = obj.filingAddress ? obj.filingAddress : null;
                this.visaApptDate = obj.visaApptDate ? obj.visaApptDate : null;
                this.serviceCenterCodeId = obj.serviceCenterCodeId ? obj.serviceCenterCodeId : null;
                this.amenDwOrNoExt = obj.amenDwOrNoExt ? obj.amenDwOrNoExt : false;
                this.anticipatedTravel = obj.anticipatedTravel ? obj.anticipatedTravel : false;
                this.advIssuedOnTravelOrCaseImpact = obj.advIssuedOnTravelOrCaseImpact ? obj.advIssuedOnTravelOrCaseImpact : false;
                this.departureDate = obj.departureDate ? obj.departureDate : null;
                this.arrivalDate = obj.arrivalDate ? obj.arrivalDate : null;
                this.countryId = obj.countryId ? obj.countryId : null;
                this.recapture = obj.recapture ? obj.recapture : false;
                this.recaptureTimeAvailable = obj.recaptureTimeAvailable ? obj.recaptureTimeAvailable : false;
                this.days = obj.days ? obj.days : null;
                this.months = obj.months ? obj.months : null;
                this.newProposedVisaMax = obj.newProposedVisaMax ? obj.newProposedVisaMax : null;
                this.premiumProcessingI907 = obj.premiumProcessingI907 ? obj.premiumProcessingI907 : false;
                this.emailAddressI907 = obj.emailAddressI907 ? obj.emailAddressI907 : null;
                this.aC21Extension = obj.aC21Extension ? obj.aC21Extension : false;
                this.a106a1yrPERM = obj.a106a1yrPERM ? obj.a106a1yrPERM : false;
                this.datePERMFiledwithDOL = obj.datePERMFiledwithDOL ? obj.datePERMFiledwithDOL : null;
                this.i485AppFiledWinYrPDBecomingCurr = obj.i485AppFiledWinYrPDBecomingCurr ? obj.i485AppFiledWinYrPDBecomingCurr : null;
                this.a104c3YearI140 = obj.a104c3YearI140 ? obj.a104c3YearI140 : null;
                this.dateI140FiledUSCIS = obj.dateI140FiledUSCIS ? obj.dateI140FiledUSCIS : null;
                this.priorityDate = obj.priorityDate ? obj.priorityDate : null;

                this.priorityCountryId = obj.priorityCountryId ? obj.priorityCountryId : null;
                this.visaBulletinCutoffDate = obj.visaBulletinCutoffDate ? obj.visaBulletinCutoffDate : null;
                this.dateI140PetitionApproved = obj.dateI140PetitionApproved ? obj.dateI140PetitionApproved : null;
                this.priorityClassification = obj.priorityClassification ? obj.priorityClassification : null;
                this.priorityDateCurrent = obj.priorityDateCurrent ? obj.priorityDateCurrent : false;
                this.notes = obj.notes?obj.notes :null;
                this.filingTravelDetails=obj.filingTravelDetails?obj.filingTravelDetails:[];
                this.filingStateOrCountry = obj.filingStateOrCountry ? obj.filingStateOrCountry : [];
                this.eyPetitioner = obj.eyPetitioner ? obj.eyPetitioner : null;
            }
        }
        catch(err){
            console.log(err);
        }
    }
}



export class FilingTableDetail {
  filingTravelID: number;
  //caseId: string;
  //caseSummaryID: number;
  departureDate: Date;
  arrivalDate: Date;
  countryId: boolean;
  //changeType: string;
}
