import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { checklistItems } from 'src/app/DAF/Models/DocuentChecklist';
import { EditFilingDetails } from 'src/app/DAF/Models/filingdetails';
import { DafService } from 'src/app/DAF/services/dafService';

@Component({
  selector: 'app-kendo-grid-filing-detail',
  templateUrl: './kendo-grid-filing-detail.component.html',
  styleUrls: ['./kendo-grid-filing-detail.component.scss'],
    styles: [
    `
        input[type='text'] {
            width: 100%;
        }
        .k-inline-checkbox {
            display: inline-flex;
        }
    `
]
})
export class KendoGridFilingDetailComponent implements OnInit {

  public active = false;
  private notifier: NotifierService;
  degrees=["Associate","Bachelor","Master","Doctorate"];

  allowCustom=true;
  radioButtonOptionValues: Array<any> = [{ value: true, text: "Yes" }, { value: false, text: "No" }];
  public relationTypeList = [
    {
      relationId: 0,
      relationType: '-Select-'
    },
    {
      relationId: 1,
      relationType: 'Father'
    },
    {
      relationId: 2,
      relationType: 'Mother'
    },
    {
      relationId: 3,
      relationType: 'Spouse'
    }
  ];
    public editForm: FormGroup = new FormGroup({
      formType: new FormControl('', Validators.required),
      classification: new FormControl('', Validators.required),
      rn: new FormControl(''),
      receiptDate: new FormControl(null),
      validFrom: new FormControl(null),
      validTo: new FormControl(null),
      status: new FormControl(''),
      isFiledByPetitioner: new FormControl(false),
      changeType: new FormControl(''),
      fileHistoryID: new FormControl(0),
    });

    

    @Input() public isNew = false;

    @Input() public set model(product: EditFilingDetails) {
        this.editForm.reset(product);
        // toggle the Dialog visibility
        this.active = product !== undefined;
    }
    countrySourceList: Array<any> = [];
    CountryList: Array<any> = [];
    @Output() cancel: EventEmitter<undefined> = new EventEmitter();
    @Output() save: EventEmitter<EditFilingDetails> = new EventEmitter();

    constructor(notifier: NotifierService,private _dafService: DafService) {this.notifier = notifier;}

    ngOnInit(){
      this.getCountryList();
    }
    public onSave(e: PointerEvent): void {
      e.preventDefault();
      this.editForm.value
      let validFrom = this.editForm.value.validFrom ? new Date(this.editForm.value.validFrom) : null;
      let validTo = this.editForm.value.validTo ? new Date(this.editForm.value.validTo) : null;
      if (validFrom && validTo && (validTo < validFrom)) {
        this.notifier.notify('error', 'Valid From value must be less than Valid To value. Please check.');
        return;
      }
        this.save.emit(this.editForm.value);
        this.active = false;
    }

    public onCancel(e: PointerEvent): void {
        e.preventDefault();
        this.closeForm();
    }
    public ngOnChanges() {
      let changeType = "UPDATE";
      if (this.isNew)
        changeType = "INSERT"
      this.editForm.controls["changeType"].setValue(changeType);
    }

    public closeForm(): void {
        this.active = false;
        this.cancel.emit();
    }

    countryFilterChange(filter: any) {
      try {
        if (filter.length > 2) {
          this.CountryList = this.countrySourceList.filter(x => x.countryName.toLowerCase().includes(filter.toLowerCase()));
        }
      }
      catch (err) {
        console.log(err);
      }
      finally {
  
      }
    }

    getCountryList(){
      this._dafService.getCountriesCitizenship().subscribe((data: any) => {
        this.CountryList = data;
        this.countrySourceList = data;
      },
        (error) => {
          //this.notifier.notify('error', 'Error occured while updating the user details');
        }
      );
    }


}
