import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private _http: HttpClient) { }
  getDashboardCount(startDate, endDate, engagement) {
    let params = new HttpParams();
    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);

    if (engagement) {
      params = params.append('engagement', engagement);
    }
    //?startDate=' + startDate + '&endDate=' + endDate + '&engagement=' + engagement
    return this._http.get<any>(environment.ApiUrl + 'Dashboard/GetDashboardCount', { params: params });
  }
}
