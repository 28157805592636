export class specialityDegreeDetails {
     specialtyDegAssmtCertID:number;
     highestEdulevel:string;
     expRequireseval:boolean;
     profExpreqforeval:boolean;
     certification:boolean;
     degLevelequivalency:string;
     degFieldequivalency:string;

     caseID:string;

     caseSummaryID:number;
     notes:string;
     specialityDegree: Array<SpecialDegreeAssessment> = [];
}

export class SpecialDegreeAssessment {
    spedegassmtId :number;
    caseId :string;
    casesummaryId :number;
    universityName :string;
    degree :string;
    degreeField :string;
    enrollFrom :Date;
    enrollTo :Date;
    awardDate :Date;
    degissuingCountry :  string;
    degreesOnFile :boolean;
    changeType: string;
}
