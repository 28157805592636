import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { dafAppConstants } from 'src/app/DAF/Models/applicationConstants';
import { ValidationMessage } from 'src/app/DAF/Models/validationMessage';
import { DafService } from 'src/app/DAF/services/dafService';
import { AddLCAService } from 'src/app/immigrationcompilation/add-lca/service/add-lca.service';

@Component({
  selector: 'app-dafc-home',
  templateUrl: './dafc-home.component.html',
  styleUrls: ['./dafc-home.component.scss']
})
export class DAFcHomeComponent implements OnInit {
  @ViewChild('openValidModalButton', { static: false }) openValidModalButton: ElementRef;

  public current = 0;
  public currentofc = 0;
  requestId = "";
  zipCodeData: any[];
  statesDataSource=[];
  statusId=0;
  validationData:ValidationMessage[];
  isCSPanelExpanded = false;
  private notifier: NotifierService;
  public showConfirmation = false;
  public steps = [
    { label: 'Initiated', icon: 'k-icon k-i-page-properties', isValid: true },
    { label: 'Support Review', icon: 'k-icon k-i-user', isValid: true },
    { label: 'Case Manager Review', icon: 'k-icon k-i-user', isValid: true },
    { label: 'Attorney Review', icon: 'k-icon k-i-user', isValid: true },
    { label: 'Approved', icon: 'k-icon k-i-track-changes-accept-all', isValid: true }
];

  public caseId = 0;
  public caseStatus = 'Initiated';
  public zoomId = '';
  public caseData:any;

  constructor(public route: ActivatedRoute,public router: Router ,public _addLCAService: AddLCAService,notifier: NotifierService, public _dafService:DafService) {this.notifier = notifier;

   }


  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.caseId = params['caseId'];
      this.caseStatus = params['caseStatus'];
      this.zoomId = params['zoomId'];
      if(this.caseStatus=="Case Drafted"||this.caseStatus=="On Hold"||this.caseStatus=="Rejected")
      {
        this.current=0;
      }
      else if(this.caseStatus=="Initiated"){
        this.current=0;
      }
      else if(this.caseStatus.replace(/%20/g, " ")=="Support Review"){
        this.current=1;
      }
      else if(this.caseStatus.replace(/%20/g, " ")=="Case Manager Review"){
        this.current=2;
      }
      else if(this.caseStatus.replace(/%20/g, " ")=="Attorney Review"){
        this.current=3;
      }
      else if(this.caseStatus.replace(/%20/g, " ")=="Approved"){
        this.current=4;
      }
      //this.getStates();
      // Loads data to grid
    });    
  }


  changePage(value) {
    this.current = value;
  }
  

  confirmationDialog(open: boolean,statusId) {
    // Opens the confirmation dialog
    if (open) {
      this.showConfirmation = true;
    } else {
      this.showConfirmation = false;
    }
    // setting the delete ID to global variable
    if (statusId) {
      this.statusId = statusId;
    } else {
      this.statusId = 0;
    }
  }
  determineLCA(value) {
    if (value != "") {
      this.requestId = value;
      //this.steps[3].disabled = 'true';
      this.current = 4;
    }
  }

  validationCheck(statusId){
    this._dafService.GetDAFValidation(this.caseId,statusId).toPromise().then((data: any) => {
      if(data.length>0){
        this.validationData =  data;
        this.openValidModalButton.nativeElement.click();
      }
      else{
        this.confirmationDialog(true,statusId);
      }
  });
}

  assignData(data) {
    //this.workSiteInfo = data;
    this.current = 3;
  }

  completeLCA(data) {
    //this.steps[3].disabled = 'false';
    this.current = 0;
  }

  CSPanel(data) {
    this.isCSPanelExpanded = true;

  }

  onPanelChange(event) {
    if (event[0].expanded == true)
      this.isCSPanelExpanded = true;
  }

  UpdateCaseStatus(){
    if(this.statusId!=0){
 

    this._dafService.UpdateCaseStatus(this.caseId, this.statusId).toPromise().then(() => {
      // this.isUnlocked = false;
      this.notifier.notify('success', 'Case details have been updated successfully');
      this.router.navigate(['daf/dashboard']);

    });
  }
  }

  goToList(){
    try{
      this.router.navigate(['/caseDeatils'], { queryParams: { 'caseStatus': this._dafService.getSessionStorage(dafAppConstants.sessionStorageVariables.currentStatus), 'caseType': this._dafService.getSessionStorage(dafAppConstants.sessionStorageVariables.currentCaseType) } });
    }
    catch(err){
      console.log(err);
    }
  }
}
