import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DataStateChangeEvent, GridComponent, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { DafService } from 'src/app/DAF/services/dafService';
import { Router, ActivatedRoute } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { EympService } from 'src/app/eymp-inszoom-integration/services/EympService';
import { DatePipe } from '@angular/common';
import { ProfileModel } from '../Model/ProfileModel';
import { CaseModel } from '../Model/CaseModel';
import { EYIModel } from '../Model/EYIModel';
import { QuestModel } from '../Model/QuestModel';
import { QuestAddModel } from '../Model/QuestAddModel';


@Component({
  selector: 'app-dashboard-details',
  templateUrl: './dashboard-details.component.html',
  styleUrls: ['./dashboard-details.component.scss']
})
export class DashboardDetailsComponent implements OnInit {
  @ViewChild('openDocumentInfoModalButton', { static: false }) openEditModalButton: ElementRef;
  @ViewChild('openDocumentInfoModalButton1', { static: false }) openEditModalButton1: ElementRef;
  @ViewChild('openDocumentInfoModalButton2', { static: false }) openEditModalButton2: ElementRef;
  @ViewChild('openDocumentInfoModalButton3', { static: false }) openEditModalButton3: ElementRef;
  public pageSize = 10;
  public skip = 0;
  public isPageReady = true;
  public caseList: any;
  public caseFormList: any;
  public gridViewData: any[];
  public ProfilecaseStatus = "";
  public Status = "";
  isActivate = false;
  isfailure = false;
  isreattempt = false;
  caseIdtoUpd: string;
  isshare = false;
  isProfile = false;
  isCase = false;
  isCaseState = false;
  isCaseGuidState = false;
  isEYIUpload = false;
  isQuest = false;
  isCaseDocState = false;
  isCaseDocNRState = true;
  private notifier: NotifierService;
  public selecteddocumentId = 0;
  public documentInfo = new ProfileModel();
  public documentInfo1 = new CaseModel();
  public documentInfo2 = new EYIModel();
  public documentInfo3 = new QuestModel();
  public documentInfo4 = new QuestAddModel();
  public showModel = false;

  public gridState: DataStateChangeEvent = {

    skip: this.skip,
    take: this.pageSize
  };
  dateFilterModel: DateFiltersModel;
  fromreportDate: any;
  toreportDate: any
  constructor(public _dafService: DafService, private router: Router, private route: ActivatedRoute, notifier: NotifierService, public _eympservice: EympService, public datepipe: DatePipe) {
    this.dateFilterModel = new DateFiltersModel(new Date(), new Date());
    this.notifier = notifier;
  }
  take: number;
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.ProfilecaseStatus = params['ProfileCase'];

      this.Status = params['Status'];
      if (this.ProfilecaseStatus == "Profile")
        this.isProfile = true;

      else if (this.ProfilecaseStatus == "Document")
      {
        this.isCaseDocState = true;        
        this.isCaseState = true;
        this.isCaseDocNRState = false;
        this.isEYIUpload = false;
        //if (this.Status == "Ready for Document Upload" || this.Status == "") {
          //this.isEYIUpload = true;
        //}
      }        
      else {
        this.isCaseState = true;
        this.isCaseGuidState = true;
        if (this.Status == "Ready for Profile and Case Creation") {
          this.isCase = false;
          this.isEYIUpload = false;
          this.isQuest = false;
        }
        else if (this.Status == "Ready for Case Questionnaire Update") {
          this.isCase = true;
          this.isEYIUpload = false;
          this.isQuest = true;
        }
        else if (this.Status == "Ready for EYI Upload") {
          this.isCase = true;
          this.isEYIUpload = false;
          this.isQuest = true;
        }
        else if (this.Status == "Success" || this.Status == "Failure") {
          this.isCase = true;
          this.isEYIUpload = true;
          this.isQuest = true;
        }
      }

     
     

    });
    this._dafService.currentInsDates.subscribe(data => {
      this.fromreportDate = data.fromDate;
      this.toreportDate = data.toDate;
      this.dateFilterModel = new DateFiltersModel(new Date(this.fromreportDate), new Date(this.toreportDate));
      this.refreshCaseList(this.gridState);
    });
    this.refreshCaseList(this.gridState);
  }

  

  filterCaseList() {

    this._dafService.updateINSDate({ fromDate: this.dateFilterModel.startDate.toDateString(), toDate: this.dateFilterModel.endDate.toDateString() });
  }
  
  
  refreshCaseList(state: DataStateChangeEvent) {

    this.isPageReady = false;
    this.gridState = state;
    this._eympservice.getEympDetailsWithStatus(this.Status, this.ProfilecaseStatus, this.dateFilterModel.startDate.toDateString(), this.dateFilterModel.endDate.toDateString()).subscribe((data: any) => {
      // Binds the data to grid
      this.caseList = process(data, this.gridState);
      console.log(this.caseList);
      this.gridViewData = data;
      this.take = 10;
      this.isPageReady = true;
     
    },
      (error) => {
        this.isPageReady = true;
        //this.notifier.notify('error', 'Error occured while getting data from server');
      }
    );
  }
  private rowCallback(row: any) {
    const isEven = row.index % 2 == 0;
    return {
      even: isEven,
      odd: !isEven
    };
  }
  
  viewProfileInfo(documentId: number, edit: boolean) {
    console.log(documentId);
    this.selecteddocumentId = documentId;
    this._eympservice.getProfileInfoById(documentId).subscribe((result: ProfileModel) => {
      this.documentInfo = result;
      this.documentInfo.createdDateString = this.datepipe.transform(this.documentInfo.createdDate, 'dd-MMM-yyyy');
      this.showModel = true;
      this.openEditModalButton.nativeElement.click();
      console.log(this.documentInfo);
    }, error => { console.error(error); console.log('error'); });
  }

  viewCaseInfo(documentId: number, edit: boolean) {
    console.log(documentId);
    this.selecteddocumentId = documentId;
    this._eympservice.getCaseInfoById(documentId).subscribe((result: CaseModel) => {
      this.documentInfo1 = result;
      this.documentInfo1.createdDateString = this.datepipe.transform(this.documentInfo1.createdDate, 'dd-MMM-yyyy');
      this.showModel = true;
      this.openEditModalButton1.nativeElement.click();
      console.log(this.documentInfo1);
    }, error => { console.error(error); console.log('error'); });
  }

  viewCaseEYIInfo(documentId: number, edit: boolean) {
    console.log(documentId);
    this.selecteddocumentId = documentId;
    this._eympservice.getCaseEYIInfoById(documentId).subscribe((result: EYIModel) => {
      this.documentInfo2 = result;
      this.documentInfo2.createdDateString = this.datepipe.transform(this.documentInfo1.createdDate, 'dd-MMM-yyyy');
      this.showModel = true;
      this.openEditModalButton2.nativeElement.click();
      console.log(this.documentInfo2);
    }, error => { console.error(error); console.log('error'); });
  }

  viewCaseQuestInfo(documentId: number, edit: boolean) {
    console.log(documentId);
    this.selecteddocumentId = documentId;
    this._eympservice.getCaseQuestInfoById(documentId).subscribe((result: QuestModel) => {
      this.documentInfo3 = result;
      this.documentInfo3.createdDateString = this.datepipe.transform(this.documentInfo1.createdDate, 'dd-MMM-yyyy');
      this._eympservice.getCaseQuestAddInfoById(documentId).subscribe((result: QuestAddModel) => {

        this.documentInfo4 = result;
        this.documentInfo4.createdDateString = this.datepipe.transform(this.documentInfo1.createdDate, 'dd-MMM-yyyy');
      }, error => { console.error(error); console.log('error'); });
      this.showModel = true;
      this.openEditModalButton3.nativeElement.click();
      console.log(this.documentInfo3);
      
    }, error => { console.error(error); console.log('error'); });
  }
}

export class DateFiltersModel {
  public startDate: Date;
  public endDate: Date;
  constructor(startDate: Date, endDate: Date) {
    this.startDate = startDate;
    this.endDate = endDate;
  }
}
